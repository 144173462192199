// React.
import { useEffect } from "react"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// ComunicazioniSlice.
import { comunicazioniSelector } from "../../features/comunicazioni/ComunicazioniSlice"
// ScadenzeSlice.
import { scadenzeSelector } from "../../features/scadenze/ScadenzeSlice"
// UserSlice.
import { userSelector, getUserInfo } from "../../features/user/UserSlice"
// UI.
import LayoutDetail from "../LayoutDetail"
import LayoutDetailContainer from "../LayoutDetailContainer"
import PanoramicaListItem from "./PanoramicaListItem"
// Icons.
import { Camera, DocumentText, Star } from "../../base/Icons";
import { isNotEmptyString } from "../../../common/util";
/**
 * 
 * @dev  Panoramica UI Component.
 * @dev General detail view.
 *
 * @function Panoramica
 * 
 * @returns JSX.Element
 *
 */
export default function Panoramica() {
    //
    // comunicazioniSlice State.
    //
    const { noticesItems } = useSelector(comunicazioniSelector)
    //
    // scadenzeSlice State.
    //
    const { deadlinesItems } = useSelector(scadenzeSelector)
    //
    // userSlice state.
    //
    const { userName, companyName, type, role, adminUser, adminMode } = useSelector(userSelector)

    const dispatch = useDispatch()

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => { }, [noticesItems, deadlinesItems])

    useEffect(() => {
        dispatch(getUserInfo())
    }, [
        dispatch,
        userName
    ])

    /**
     *
     * Component render.
     *
     */
    return (
        <LayoutDetail title={"Panoramica"}>
            <LayoutDetailContainer>
                <div
                    className="
                        flex 
                        flex-wrap
                        space-x-10 
                        items-center 
                        justify-start"
                >
                    <div
                        className="
                            h-28 
                            w-28 
                            flex
                            items-center justify-center 
                            bg-afm-box-light-gray
                            text-white"
                    >
                        <Camera />
                    </div>
                    <div
                        className="
                            text-left 
                            basis-1/2 lg:basis-1/6"
                    >
                        <p className="font-bold text-base xl:text-lg">
                            {isNotEmptyString(companyName) ? companyName : userName}
                        </p>
                        {(isNotEmptyString(userName) && isNotEmptyString(role) && (role !== 'Normale')) &&
                            <p className="text-base xl:text-lg">{role}</p>
                        }
                        {/*
                        <div className="mt-4 text-sm xl:text-base">
                            <p>ultimo accesso</p>
                            <p>GG mese AAAA 00:00</p>
                        </div>
                        */}
                    </div>

                    {noticesItems.length > 0 && (
                        <div className="flex mt-10 lg:mt-0">
                            <div className="h-32 w-3 bg-afm-box-success-green"></div>

                            <div
                                className="
                                    pl-10 
                                    pt-4
                                    text-left 
                                    text-base xl:text-lg"
                            >
                                <p>Comunicazioni</p>
                                <div className="pt-5">
                                    <p className="font-bold">{noticesItems.length}</p>
                                    <p className="text-afm-box-success-green">
                                        Nuove comunicazioni
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    {deadlinesItems.length > 0 && (
                        <div className="flex mt-10 lg:mt-0">
                            <div className="h-32 w-3 bg-afm-box-error-red"></div>

                            <div
                                className="
                                    pl-10 
                                    pt-4
                                    text-left 
                                    text-base xl:text-lg"
                            >
                                <p>Scadenze</p>
                                <div className="pt-5">
                                    <p className="font-bold">{deadlinesItems.length}</p>
                                    <p className="text-afm-box-error-red">Nuove scadenze</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* <div
                    className="
                    flex flex-col lg:flex-row 
                    space-x-0 lg:space-x-10
                    pt-2 lg:pt-24"
                >
                    <PanoramicaListItem
                        title={"Ultimi documenti caricati"}
                        icon={<Star />}
                        iconBgColor={"bg-afm-box-dark-gray"}
                        documentTitle={"Nome documento Lorem Ipsum"}
                        documentSubtitle={"Duvri"}
                        documentDate={"4 mar 21"}
                    />
                    <PanoramicaListItem
                        title={"Ultimi documenti scaricati"}
                        icon={<DocumentText />}
                        iconBgColor={"bg-afm-box-blue"}
                        documentTitle={"Nome documento Lorem Ipsum"}
                        documentSubtitle={"Duvri"}
                        documentDate={"4 mar 21"}
                    />
                </div> */}
            </LayoutDetailContainer>
        </LayoutDetail>
    )
}

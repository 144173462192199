// React.
import { useEffect, useState } from "react"

/**
 *
 * @function Tooltip
 *
 * @param { string } message
 * @param { string } twBackground
 * @param { bool } translateTooltip
 * @param { JSX.Element } children
 *
 * @returns  JSX.Element
 *
 */
export default function Tooltip(props) {
    // Message text state.
    const [hintMessage, setHintMesage] = useState("")

    const hasMessage = hintMessage ? "group-hover:flex" : ""

    useEffect(() => {
        setHintMesage(props.message)
    }, [props.message])

    return (
        <div className="relative flex flex-col items-center group">
            <div
                className={`
                    ${hasMessage}
                    absolute 
                    bottom-0 
                    mb-6 
                    flex-col items-center 
                    w-max 
                    z-30
                    hidden
                    max-w-xs`}
            >
                <span
                    className={`
                    relative 
                    p-2 
                    text-xs leading-none text-white whitespace-no-wrap 
                    ${props.twBackground} 
                    ${props.translateTooltip ? "translate-x-32" : ""}
                    shadow-lg`}
                >
                    {props.message}
                </span>
                <div
                    className={`
                        w-3 
                        h-3 
                        -mt-2 
                        mb-1 
                        rotate-45 
                        ${props.twBackground}`}
                ></div>
            </div>
            {props.children}
        </div>
    )
}

/**
 *
 * @notice UserSlice to handle state of the User globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 */

// Redux.
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// Axios.
import axios from "axios"
// gmapi.
import { API_KEY, cambiaPasswordAPI, datiUtenteAPI, loginAfmboxAPI } from "../../../common/gmApi"
// cookie.
import { tkbox, setCookie, checkCookie, deleteCookie, getCookie } from "../../../common/cookie"

/********************************************
 *                                          *
 * @notice AsyncThunk API(s) Implementation *
 *                                          *
 ********************************************/

/**
 *
 * @dev User Login thunk.
 *
 */
export const loginUser = createAsyncThunk(
    // Action route.
    "user/loginUser",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { Object } obj
     * @param { string } obj.name
     * @param { string } obj.password
     * 
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async ({ name, password, userview }, thunkAPI) => {
        try {
            const response = await axios.post(
                // url.
                loginAfmboxAPI,
                // null body.
                null,
                // Queryparams.
                {
                    params: {
                        userview: userview ? userview : '',
                        user: name,
                        psw: password,
                        apikey: API_KEY,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    const token = response.data.gmapi.response.data[0].token
                    const expiration = response.data.gmapi.response.data[0].scadenza

                    //setTkbox(response.data.gmapi.response.data[0].token); // debug.
                    setCookie(
                        tkbox,
                        token,
                        expiration
                    )
                    return response.data.gmapi.response.data[0]
                } else {
                    // else reject gmapi response error.
                    //console.error('Error "loginUser":',response.data.gmapi.response.error[0]) // debug.
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "loginUser":',response.data.gmapi.error) // debug.
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            // If something get wrong then reject the error.
            console.error('Error "loginUser":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Log out the user thunk.
 *
 */
export const logoutUser = createAsyncThunk(
    // Action route.
    "user/logoutUser",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (thunkAPI) => {
        try {
            // Remove user token from Cookies.
            const removeUser = async () => {
                const tkboxHasBeenFound = checkCookie(tkbox)

                // If token exist, remove it and return true, else false.
                if (tkboxHasBeenFound) {
                    deleteCookie(tkbox)
                    return true
                } else {
                    return false
                }
            }

            const response = await removeUser() // Fire removeUser.

            return response

        } catch (error) {
            // If something get wrong then reject the error.
            console.error("Error", error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Change user password thunk.
 *
 */
export const changePassword = createAsyncThunk(
    // Action route.
    "user/changePassword",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { string } newPass
     * 
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (newPass, thunkAPI) => {
        try {
            const response = await axios.post(
                // url.
                cambiaPasswordAPI,
                // null body.
                null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        newpass: newPass,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data.gmapi.response.data
                } else {
                    // else reject gmapi response error.
                    console.error('Error "changePassword":', response.data.gmapi.response.error[0]) // debug.
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                console.error('Error "changePassword":', response.data.gmapi.error) // debug.
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            // If something get wrong then reject the error.
            console.error('Error "changePassword":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Get user info.
 *
 */
export const getUserInfo = createAsyncThunk(
    // Action route.
    "user/getUserInfo",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                datiUtenteAPI,
                // null body.
                //null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    // else reject gmapi response error.
                    //console.error('Error "getUserInfo":',response.data.gmapi.response.error[0]) // debug.
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "getUserInfo":',response.data.gmapi.error) // debug.
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            // If something get wrong then reject the error.
            console.error('Error "getUserInfo":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/***************************************
 *                                     *
 * @notice createSlice implementation. *
 *                                     *
 * @dev Create a "userSlice" feature.  *
 * @dev UI views state controller.     *
 *                                     *
 ***************************************/
export const userSlice = createSlice({
    // Name, used in action types.
    name: "user",
    // The initial state for the reducer.
    initialState: {
        token: "",               // Access token.
        userName: "",            // User Name.
        userNameFirstLetter: "", // User Name.
        companyFirstLetter: "", // Company first letter.
        type: 'Normale',         // Normale | Premium
        role: 'Normale',         // Normale | Premium | Amministratore
        adminUser: '',           // Admin username if in userview mode
        adminMode: false,        // Admin capabilities.
        userView: "",            // User View.
        isFetching: false,       // AsyncThunk is calling an API.
        isSuccess: false,        // AsyncThunk is success from API call.
        isError: false,          // AsyncThunk is fail from API call.
        errorMsg: "",            // Error message container.
        company: "",            // Company.
        companyName: "",            // Company name.
        userManutenzione: true,
    },
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {
        /**
         * 
         * @dev clearState
         * 
         * @param {*} state 
         * @returns void 
         * 
         */
        clearState: (state) => {
            state.isError = false
            state.isSuccess = false
            state.isFetching = false
            //state.token = ""
            //state.userName = ""

            return state
        },
    },
    // AsyncThunk Reducers.
    // A "builder callback" function used to add more reducers, or
    // an additional object of "case reducers", where the keys should be other
    // action types.
    extraReducers: (builder) => {
        builder
            /*******************************************************************
             *
             * @dev loginUser.
             *
             */
            .addCase(loginUser.fulfilled, (state, { payload }) => {
                state.isFetching = false
                state.isSuccess = true
                state.token = payload.token;
                state.userName = payload.user;
                state.userNameFirstLetter = (state.userName || '').length > 0 ? state.userName.charAt(0).toUpperCase() : '';
                state.type = (payload?.type !== undefined) ? payload.type : 'Normale';
                state.role = (payload?.role !== undefined) ? payload.role : 'Normale';
                state.adminUser = (payload?.admin_user !== undefined) ? (payload.admin_user || '') : '';
                state.adminMode = (payload?.admin_mode !== undefined) ? payload.admin_mode : false;
                state.userManutenzione = (state.role === 'Manutenzione' && !state.adminMode);
                state.companyName = (payload?.company_name !== undefined) ? payload.company_name : state.userName;
                state.companyFirstLetter = (state.companyName || '').length > 0 ? state.companyName.charAt(0).toUpperCase() : '';
            })
            .addCase(loginUser.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(loginUser.rejected, (state, { payload }) => {
                state.isFetching = false
                state.isError = true
                switch (payload) {
                    case "User non valido":
                        state.errorMsg = "Utente non valido"
                        break

                    default:
                        state.errorMsg = "errore"
                        break
                }
            })
            /*******************************************************************
            *
            * @dev changePassword.
            *
            */
            .addCase(changePassword.fulfilled, (state, { payload }) => {
                state.isFetching = false
                state.isSuccess = true

                alert("La password è stata cambiata correttamente!")
            })
            .addCase(changePassword.pending, (state) => {
                state.isFetching = true
            })
            .addCase(changePassword.rejected, (state, { payload }) => {
                state.isError = true
                alert("La password non è stata cambiata.")
            })
            /*******************************************************************
             * 
             * @dev getUserInfo.
             * 
             */
            .addCase(getUserInfo.fulfilled, (state, { payload }) => {
                state.isFetching = false
                state.isSuccess = true

                const response = payload.gmapi.response.data[0]

                state.userName = response.user;
                state.company = response.company;
                state.userNameFirstLetter = response.user.charAt(0).toUpperCase();
                state.userNameFirstLetter = (state.userName || '').length > 0 ? state.userName.charAt(0).toUpperCase() : '';
                // state.isAdmin = response.super_admin;
                state.type = (response?.type !== undefined) ? response.type : 'Normale';
                state.role = (response?.role !== undefined) ? response.role : 'Normale';
                state.adminUser = (response?.admin_user !== undefined) ? (response.admin_user || '') : '';
                state.adminMode = (response?.admin_mode !== undefined) ? response.admin_mode : false;
                state.userManutenzione = (state.role === 'Manutenzione' && !state.adminMode);
                state.companyName = (response?.company_name !== undefined) ? response.company_name : state.userName;
                state.companyFirstLetter = (state.companyName || '').length > 0 ? state.companyName.charAt(0).toUpperCase() : '';
            })
            .addCase(getUserInfo.pending, (state) => {
                state.isFetching = true
            })
            .addCase(getUserInfo.rejected, (state, { payload }) => {
                state.isError = true
                alert(`"getUserInfo": Recupero dei dati utente fallito!`)
            })

        /********************************************************************
         *
         * @dev logoutUser.
         *
         */
        /*
        .addCase(logoutUser.fulfilled,(state,{ payload }) => {
            // Clean up.
            state.isFetching = false
            state.isSuccess = true
            state.token = ""
            state.userName = ""
            state.userNameFirstLetter = ""
        })
        .addCase(logoutUser.pending,(state) => {
            state.isFetching = true
        })
        .addCase(logoutUser.rejected,(state,{ payload }) => {
            state.isFetching = false
            state.isError = true
            // Clean up.
            state.token = ""
            state.userName = ""
        })
        */


    },
})

// Export the reducer.
export const { clearState } = userSlice.actions

// Export the Slice by name.
export const userSelector = (state) => state.user

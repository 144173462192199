// React.
import { useEffect } from "react"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// SearchSlice.
import { searchData, searchSelector } from "../features/search/SearchSlice"
// Icons.
import { Search } from "./Icons"

/**
 *
 * @dev SearchBox Component.
 * 
 * @function SearchBox
 *
 * @param { string } placeholder
 *
 * @returns JSX.Element
 *
 */
//TODO: FIXME: check the state.
export default function SearchBox(props) {
    const dispatch = useDispatch()

    // searchSlice State.
    const {
        //isLoading,
        //isError,
        searchQuery,
    } = useSelector(searchSelector)

    const handleSubmit = (event) => {
        event.preventDefualt()
    }

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {}, [searchQuery])

    /**
     *
     * Component render.
     *
     */
    return (
        <div className="flex">
            <form onSubmit={handleSubmit}>
                <label className="relative block">
                    <span className="sr-only">Search</span>
                    <input
                        className="
                            block 
                            bg-afm-box-light-gray 
                            w-full border 
                            border-slate-300 
                            rounded-md py-2 
                            pl-9 pr-3 
                            shadow-sm 
                            focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1
                            text-sm sm:text-sm
                            placeholder:italic placeholder:text-afm-box-dark-gray placeholder:opacity-50"
                        type="text"
                        name="search"
                        onChange={(event) => {
                            event.target.value
                                ? dispatch(searchData(event.target.value))
                                : dispatch(searchData(" "))
                        }}
                        value={searchQuery ? null : searchQuery}
                        //value={searchQuery ?  "" : searchQuery}
                        placeholder={props.placeholder ? props.placeholder : "Cerca"}
                        readOnly={false}
                    />
                    <span className="absolute m-2 inset-y-0 right-0 flex items-center justify-center text-afm-box-light-gray-text">
                        <Search />
                    </span>
                </label>
            </form>
        </div>
    )
}

/**
 *
 * @notice VisuraSlice to handle state of Visura globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 */

// Redux.
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit"
// Axios.
import axios from "axios"
// gmapi.
import {
    API_KEY,
    listaVisureAPI,
    downloadVisuraAPI,
    eliminaVisuraAPI
} from "../../../../common/gmApi"
// cookie.
import { tkbox,getCookie } from "../../../../common/cookie"
// util.
import {
    downloadDocument,
    showDocument,
} from "../../../../common/util"

/********************************************
 *                                          *
 * @notice AsyncThunk API(s) Implementation *
 *                                          *
 ********************************************/
/**
 *
 *  @dev Get Visura data.
 *
 */
export const getVisura = createAsyncThunk(
    // Action route.
    "visura/getVisura",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                listaVisureAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                    },
                }
            )

            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    //console.error('Error "getVisura":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "getVisura":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "getVisura":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Download Visura document.
 *
 */
export const downloadVisuraDoc = createAsyncThunk(
    // Action route.
    "visura/downloadVisuraDoc",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { Object } obj
     * @param { string } obj.idBoxVisure
     * @param { string } obj.docName
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async ({ idBoxVisure,docName },thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                downloadVisuraAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_visure: idBoxVisure,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    // Build a custom response with extradata.

                    return response
                } else {
                    //console.error('Error "downloadVisuraDoc":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "downloadVisuraDoc":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "downloadVisuraDoc":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Show Visura document.
 *
 */
export const showVisuraDoc = createAsyncThunk(
    // Action route.
    "visura/showVisuraDoc",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { string } idBoxVisure
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (idBoxVisure,thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                downloadVisuraAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_visure: idBoxVisure,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    // Build a custom response with extradata.

                    return {
                        data: response.data,
                        eventSender: idBoxVisure,
                    }
                } else {
                    //console.error('Error "showVisuraDoc":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "showVisuraDoc":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "showVisuraDoc":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Delete Visura item.
 *
 */
export const deleteVisura = createAsyncThunk(
    // Action route.
    "visura/deleteVisura",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { string } idBoxVisure
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (idBoxVisure,thunkAPI) => {
        try {
            const response = await axios.delete(
                // url.
                eliminaVisuraAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_visure: idBoxVisure,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    // Build a custom response with extradata.

                    return {
                        data: response.data,
                        eventSender: idBoxVisure,
                    }
                } else {
                    //console.error('Error "deleteVisura":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "deleteVisura":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "deleteVisura":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/***************************************
 *                                     *
 * @notice createSlice implementation. *
 *                                     *
 * @dev Create a visuraSlice feature.  *
 * @dev UI views state controller.     *
 *                                     *
 ***************************************/
export const visuraSlice = createSlice({
    // Name, used in action types.
    name: "visura",
    // The initial state for the reducer.
    initialState: {
        visuraItems: [],       // All visura items.
        //needsReload: false,
        isFetching: false,     // AsyncThunk is calling an API.
        isSuccess: false,      // AsyncThunk is success from API call.
        isError: false,        // AsyncThunk is fail from API call.
        errorMsg: "",          // Error message container.
        visuraDoc: [],         // Visura document.
        visuraDocName: "",     // Visura document name.
        visuraDocSenderId: "", // Visura document sender id.
        isFetchingDoc: false,
        isSuccessDoc: false,
        isErrorDoc: false,
    },
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {
        /**
         * @action clearVisuraDocSenderId
         */
        clearVisuraDocSenderId: (state) => {
            state.visuraDocSenderId = ""

            return state
        },
    },
    // AsyncThunk Reducers.
    // A "builder callback" function used to add more reducers, or
    // an additional object of "case reducers", where the keys should be other
    // action types.
    extraReducers: (builder) => {
        builder
            /*******************************************************************
             *
             * @dev getVisura.
             *
             */
            .addCase(getVisura.fulfilled,(state,action) => {
                state.isFetching = false
                state.isSuccess = true
                //state.needsReload = false

                // Update duvriItems sort by `data_ora_inserimento` key.
                const visuraItems = action.payload.gmapi.response.data
                state.visuraItems = visuraItems.sort((firstEl,secondEl) => (
                    firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                ))
            })
            .addCase(getVisura.pending,(state) => {
                state.isFetching = true
            })
            .addCase(getVisura.rejected,(state,action) => {
                state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
            })
            /*******************************************************************
             *
             * @dev downloadVisuraDoc.
             *
             */
            .addCase(downloadVisuraDoc.fulfilled,(state,action) => {
                state.isFetchingDoc = false
                state.isSuccessDoc = true

                // Update the state with the file name from action payload.
                state.visuraDoc = action.payload.data.gmapi.response.data
                // Update the state with the file name.

                let visuraDocName = action.meta.arg.docName
                let splitted = visuraDocName.split('_');
                if(splitted.length > 1 && /^\d+$/.test(splitted[0]))
                {
                    splitted.shift();
                    visuraDocName = splitted.join('_');
                }

                state.visuraDocName = visuraDocName

                // Document download trigger.
                downloadDocument(state.visuraDoc,state.visuraDocName)
            })
            .addCase(downloadVisuraDoc.pending,(state,action) => {
                state.isFetchingDoc = true
                // Update the state with doc id.
                state.visuraDocSenderId = action.meta.arg.idBoxVisure
            })
            .addCase(downloadVisuraDoc.rejected,(state,action) => {
                state.isFetchingDoc = false
                state.isErrorDoc = true
                //state.errorMsgDoc = action.payload;
            })
            /*******************************************************************
             *
             * @dev showVisuraDoc.
             *
             */
            .addCase(showVisuraDoc.fulfilled,(state,action) => {
                //state.isFetchingDoc = false;
                //state.isSuccessDoc = true;
                state.visuraDoc = action.payload.data.gmapi.response.data

                showDocument(state.visuraDoc)
            })
            .addCase(showVisuraDoc.pending,(state,action) => {
                //state.isFetchingDoc = true;

                // Update the state with doc id.
                state.visuraDocSenderId = action.meta.arg.idBoxVisure
            })
            .addCase(showVisuraDoc.rejected,(state,action) => {
                //state.isFetchingDoc = false;
                //state.isErrorDoc = true;
                //state.errorMsgDoc = action.payload;
            })
            /*******************************************************************
             *
             * @dev deleteVisura.
             *
             */
            .addCase(deleteVisura.fulfilled,(state,action) => {
                //state.isFetching = false
                //state.isSuccess = true

                // Get the eventSender from the action payload.
                const idBoxVisure = action.payload.eventSender
                // Find the object wich corresponding id.
                const visuraToRemove = state.visuraItems.findIndex((obj) => obj.id_box_visure === idBoxVisure)

                // If found then remove it from the list.
                if (visuraToRemove > -1) {
                    state.visuraItems.splice(visuraToRemove,1) // Remove the item selected.
                }
            })
            .addCase(deleteVisura.pending,(state) => {
                //state.isFetching = true
            })
            .addCase(deleteVisura.rejected,(state,action) => {
                //state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
                //state.needsReload = true
            })

    },
})

// Export the reducer.
export const { clearVisuraDocSenderId } = visuraSlice.actions

// Export the Slice by name.
export const visuraSelector = (state) => state.visura

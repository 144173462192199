// Router.
import { Outlet } from "react-router-dom"

/**
 * 
 * @dev Main Component of the application.
 *
 * @function App
 * 
 * @returns JSX.Element
 * 
 */
function App() {
    return (
        <main className="w-full">
            <Outlet />
        </main>
    )
}
export default App

// React.
// React.
import { 
    useEffect, 
    //useRef 
} from "react"
// Router.
import { useParams } from "react-router-dom"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// ComunicazioniSlice.
import {
    comunicazioniSelector,
    getNoticeById,
    clearNoticeDocSenderId,
    avvisoLetto,
    downloadNoticeDoc,
    showNoticeDoc,
} from "../../features/comunicazioni/ComunicazioniSlice"
// UI.
import HSeparator from "../../base/HSeparator"
import Button from "../../base/Button"
// Icons.
import { OvalLoader } from "../../base/Icons"
// Util.
import { formatDateTimeIT } from "../../../common/util"
// UI.
import ToggleSwitch from "../../base/ToggleSwitch"
import PdfViewer from "../../base/PdfViewer"

/**
 *
 * @dev ComunicazioniDetail UI Component.
 * @dev Visualize the detail view of a single comunication.
 *
 * @function ComunicazioniDetail
 *
 * @returns JSX.Element
 *
 */
export default function ComunicazioniDetail() {
    // Create a ref with on the first div of ComunicazioniDetail Compoennt.
    //const ref = useRef(null)

    // dipatcher.
    const dispatch = useDispatch()

    // Get the id param from url.
    const { id } = useParams()

    // comunicazioniSlice State.
    const {
        noticesItems,
        noticeSelected,
        //noticehasBeenRed,
        isFetchingNoticeSelected,
        isSuccessNoticeSelected,
        isErrorNoticeSelected,
        noticeDocSenderId,
        isFetchingNoticeDoc,
    } = useSelector(comunicazioniSelector)

    /**
     *
     * Component side effects.
     *
     */
    /*
    useEffect(() => {
        if (noticesItems.length < 1) {
            dispatch(getComunicazioni())
        }
    }, [dispatch, noticesItems])
    */

    useEffect(() => {
        if (id && noticesItems.length > 0) {
            dispatch(getNoticeById({ id }))
        }
        
    }, [
        dispatch, 
        noticesItems,
        id, 
    ])

    //useEffect(() => {}, [isFetchingNoticeDoc, noticeDocSenderId, noticeSelected])

    //useEffect(() => {}, [isFetchingNoticeSelected, isSuccessNoticeSelected, isErrorNoticeSelected])

    useEffect(() => {},[        
        isFetchingNoticeSelected, 
        isSuccessNoticeSelected, 
        isErrorNoticeSelected, 
        noticeSelected
    ])

    // Format the date.
    const date =
        noticeSelected.data_ora_ultima_modifica &&
        formatDateTimeIT(noticeSelected.data_dal)

    /**
     *
     * Component render.
     *
     */
    return (
        <div 
            //ref={ref} 
            id={"ComunicazioniDetail"}
            className="
                sticky 
                top-6
                shadow-lg
                scrollbar-thin scrollbar-thumb-afm-box-light-gray-text"
        >
            {/* Skeleton. */}
            {isFetchingNoticeSelected && <ComunicazioniDetailSkeleton />}

            {
                !isFetchingNoticeSelected && 
                !isErrorNoticeSelected && 
                isSuccessNoticeSelected && (
                    <div className="text-afm-box-dark-gray bg-gray-100">
                        <div className="sticky top-0 bg-gray-100 px-8 pt-8">
                            <p className="flex-grow font-bold mb-5">
                                {noticeSelected.titolo}
                            </p>
                            <p className="text-afm-box-light-gray-text mb-5">
                                {date}
                            </p>

                            <div className="mb-5">
                                <ToggleSwitch
                                    hasMessage={true}
                                    toggle={noticeSelected.letto}
                                    action={avvisoLetto(
                                                {
                                                    idBoxAvvisi: id, 
                                                    letto: !noticeSelected.letto
                                                }
                                            )}
                                />
                            </div>

                            <div className="mb-2 mt-1">
                                <HSeparator />
                            </div>
                        </div>
                        <div className="p-8 overflow-y-scroll max-h-96">
                            <div 
                                className="text-sm"
                                dangerouslySetInnerHTML={{ __html: `${noticeSelected.avviso}` }}
                            ></div>

                            <div className="my-10 font-semibold">
                                {noticeSelected.allegato ?
                                        <PdfViewer action={showNoticeDoc(noticeSelected.id_box_avvisi)}/>
                                    :
                                        null
                                }
                            </div>

                            {/* If isFetchingDoc and visuraDocSenderId State 
                                correspond to Button id pressed, than show the OvalLoader.
                            */}
                            {noticeSelected.allegato ?
                                <Button
                                    text="Scarica allegato"
                                    id={noticeSelected.id_box_avvisi}
                                    icon={
                                        isFetchingNoticeDoc &&
                                        noticeDocSenderId === noticeSelected.id_box_avvisi && (
                                            <OvalLoader strokeColor="#aaa" />
                                        )
                                    }
                                    twTextColor="text-white"
                                    twBackground="bg-afm-box-blue"
                                    twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                    actions={[
                                        clearNoticeDocSenderId(),
                                        downloadNoticeDoc(
                                            {
                                                idBoxAvvisi: noticeSelected.id_box_avvisi, 
                                                docName: noticeSelected.allegato
                                            }
                                        ),
                                    ]}
                                />
                                :
                                null
                            }
                        </div>
                    </div>
            )}

            {isErrorNoticeSelected && !isSuccessNoticeSelected && (
                <div className="flex items-center justify-center my-14">
                    <p className="text-xl">
                        ...Recupero dei dati fallito
                    </p>
                </div>
            )}
        </div>
    )
}

/**
 *
 * @function ComunicazioniDetail
 *
 * @dev
 *
 * @returns JSX.Element
 *
 */
function ComunicazioniDetailSkeleton() {
    return (
        <div className="animate-pulse">
            <div className="bg-neutral-100 p-10">
                <div className="flex flex-col space-y-6 mb-10">
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                    <div className="h-6 w-20 bg-neutral-300 rounded"></div>
                </div>

                <div className="flex flex-col space-y-4 mb-5">
                    <div className="h-4 w-full bg-neutral-300 rounded"></div>
                    <div className="h-4 w-full bg-neutral-300 rounded"></div>
                    <div className="h-4 w-full bg-neutral-300 rounded"></div>
                    <div className="h-4 w-full bg-neutral-300 rounded"></div>
                    <div className="h-4 w-full bg-neutral-300 rounded"></div>
                    <div className="h-4 w-full bg-neutral-300 rounded"></div>
                    <div className="h-4 w-full bg-neutral-300 rounded"></div>
                    <div className="h-4 w-full bg-neutral-300 rounded"></div>
                </div>

                <div className="flex flex-col">
                    <div className="h-4 w-1/2 bg-neutral-300 rounded my-5"></div>
                    <div className="h-8 w-2/6 bg-neutral-300 rounded mt-2"></div>
                </div>
            </div>
        </div>
    )
}

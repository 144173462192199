/**
 *
 * @notice VisuraModalSlice to handle state of VisuraModal globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 */

// Redux.
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
// Axios.
import axios from "axios"
// gmapi.
import { API_KEY, caircaDurcAPI, caircaVisuraAPI } from "../../../../common/gmApi"
// cookie.
import { tkbox, getCookie } from "../../../../common/cookie"

/**
 *
 *  @dev Insert a new company certificate.
 *
 */
export const uploadDURC = createAsyncThunk(
    // Action route.
    "durc/uploadDURC",
    //
    // Async Function with axios http req.
    //
    // First param:
    //  {
    //      data: string,
    //      scadenza: string,
    //      allegato: any
    //  }
    //
    // Second param: "thunkAPI" callback function that should return a promise
    // handle with extraReducers to update the State.
    // It takes two parameter too: first is the value of the dispatched action,
    // and the second is the Thunk API config.
    //
    async ({ data, scadenza, allegato }, thunkAPI) => {
        try {
            const bodyFormData = new FormData()
            bodyFormData.append('allegato', allegato, (allegato.name ? allegato.name : 'file'))
            const response = await axios.post(
                // url.
                caircaDurcAPI,
                // body.
                bodyFormData,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        data: data,
                        scadenza: scadenza,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    // else reject gmapi response error.
                    //console.error('Error "uploadDURC":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "uploadDURC":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            // If something get wrong then reject the error.
            console.error('Error "uploadDURC":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Create "durcModalSlice" feature.
 *
 */
export const durcModalSlice = createSlice({
    // Name, used in action types.
    name: "durcModal",
    // The initial state for the reducer.
    initialState: {
        modalIsOpen: false,     // Modal is open or not.
        modalIsFetching: false, // AsyncThunk is calling an API.
        modalIsSuccess: false,  // AsyncThunk is success from API call.
        modalIsError: false,    // AsyncThunk is fail from API call.
        modalErrorMsg: "",      // Error message container.
    },
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {
        clearState: (state) => {
            state.modalIsError = false
            state.modalIsSuccess = false
            state.modalIsFetching = false

            return state
        },
        openModal: (state) => {
            state.modalIsOpen = true
        },
        closeModal: (state) => {
            state.modalIsOpen = false
        },
    },
    // AsyncThunk Reducers.
    // A "builder callback" function used to add more reducers, or
    // an additional object of "case reducers", where the keys should be other
    // action types.
    extraReducers: (builder) => {
        builder
            /**
             *
             * uploadDURC.
             *
             */
            .addCase(uploadDURC.fulfilled, (state) => {
                state.modalIsFetching = false
                state.modalIsSuccess = true
            })
            .addCase(uploadDURC.pending, (state) => {
                state.modalIsFetching = true
            })
            .addCase(uploadDURC.rejected, (state, action) => {
                state.modalIsFetching = false
                state.modalIsError = true
                state.modalErrorMsg = action.payload
            })
    },
})

// Export the reducer.
export const { clearState, openModal, closeModal } = durcModalSlice.actions

// Export the Slice by name.
export const durcModalSelector = (state) => state.durcModal

// React.
import { useEffect } from "react"
// Redux.
import { useLocation, useParams } from "react-router-dom"
// Router.
import { Link } from "react-router-dom"
import { organicoSelector, getEmployeeInfoById } from "../features/generale/organico/OrganicoSlice"
import { insediamentiSelector,findSettlementById } from "../features/insediamenti/InsediamentiSlice"
// UI.
import Button from "./Button"
// Util.
import { buildBreadCrumbItems, buildBreadCrumbItemsForEmployees, buildBreadCrumbItemsForMachine, buildBreadCrumbItemsForSettlements } from "../../common/util"
import { manutenzioniSelector } from "../features/manutenzioni/ManutenzioniSlice"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Tooltip from "./Tooltip"

// Icons.
import { 
    InformationCircle
} from "./Icons"

/**
 *
 * @dev SubHeader Component.
 * 
 * @function SubHeader
 *
 * @param { string } subHeaderTitle
 * 
 * @param { bool } firstBtnVisibile
 * @param { string } firstBtnText
 * @param { JSX.Element } firstBtnIcon
 * @param { string } firstBtnTwBg
 * @param { string } firstBtnTwFocusBg
 * @param { string } firstBtnTwTextColor
 * @param { any[] } firstBtnActions
 * 
 * @param { bool } secondBtnVisibile
 * @param { string } secondBtnText
 * @param { JSX.Element } secondBtnIcon
 * @param { string } secondBtnTwBg
 * @param { string } secondBtnTwFocusBg
 * @param { string } secondBtnTwTextColor
 * @param { any[] } secondBtnActions
 * @param { bool } needFirstBreadCrumb
 * 
 * @returns JSX.Element
 *
 */
export default function SubHeader(props) {
    const [breadCrumbItems, setBreadCrumbItems] = useState([])

    const location = useLocation() // Get current location.

    const dispatch = useDispatch()

    // Get the url parameter.
    const { id } = useParams()

    const { employeeInfo } = useSelector(organicoSelector)
    const { settlementInfo } = useSelector(insediamentiSelector)
    const { machineInfo } = useSelector(manutenzioniSelector)

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        if (id !== undefined) {
            dispatch(getEmployeeInfoById(id))
            dispatch(findSettlementById(id))
        }
    }, [dispatch, id])

    useEffect(() => {
        if (
            location.pathname.includes("lavoratori") &&
            props.subHeaderTitle === "Lavoratori" &&
            id !== undefined
        ) {
            setBreadCrumbItems(
                buildBreadCrumbItemsForEmployees(
                    location,
                    props.needFirstBreadCrumb,
                    id,
                    employeeInfo,
                    1
                )
            )
        } else if (
            location.pathname.includes("manutenzioni") &&
            props.subHeaderTitle === "Manutenzioni" &&
            id !== undefined
        ) {
            setBreadCrumbItems(
                buildBreadCrumbItemsForMachine(
                    location,
                    props.needFirstBreadCrumb,
                    id,
                    machineInfo,
                    1
                )
            )
        } else if (
            location.pathname.includes("generale/organico") &&
            props.subHeaderTitle === "Organico Aziendale" &&
            id !== undefined
        ) {
            setBreadCrumbItems(
                buildBreadCrumbItemsForEmployees(
                    location,
                    props.needFirstBreadCrumb,
                    id,
                    employeeInfo,
                    2
                )
            )
        // TODO: FIXME: build the breadcrumb with the name of the settlement.
    
        } else if (
            location.pathname.includes("insediamenti") &&
            props.subHeaderTitle === "Insediamenti" &&
            id !== undefined
        ){            
            setBreadCrumbItems(
                buildBreadCrumbItemsForSettlements(
                    location,
                    1,
                    id,
                    settlementInfo,
                    1
                )
            )
        } else if (
            location.pathname.includes("organigramma") &&
            props.subHeaderTitle === "organigramma" &&
            id !== undefined
        ){            
            setBreadCrumbItems(
                buildBreadCrumbItemsForSettlements(
                    location,
                    1,
                    id,
                    settlementInfo,
                    1
                )
            )
        } else if (
            location.pathname.includes("privacy/moduli")
        ){            
            const items = buildBreadCrumbItems(location, props.needFirstBreadCrumb);
            items[items.length - 1].text = "Documenti Master Privacy";
            setBreadCrumbItems(items)
        } else {
            setBreadCrumbItems(buildBreadCrumbItems(location, props.needFirstBreadCrumb))
        }
    }, [
        props.needFirstBreadCrumb, 
        props.subHeaderTitle, 
        id, 
        location, 
        employeeInfo,
        machineInfo,
        settlementInfo
    ])

    /**
     *
     * Component render.
     *
     */
    return (
        <div id="sub-header" className="flex h-20 py-6 bg-afm-box-green">
            <nav className="bg-inherit self-center rounded-md w-full">
                <ol className="flex pl-14">
                    {breadCrumbItems.map((item, id) => {
                        if (id < breadCrumbItems.length - 1) {
                            return (
                                <div key={id} className="flex">
                                    <li>
                                        <Link
                                            to={item.path}
                                            className={`
                                                ${item.text === 'organigramma' ? 'flex items-center' : 'block'}
                                                ${item.text === 'visura' ? 'flex items-center' : 'block'}
                                                ${item.text === 'doma' ? 'flex items-center' : 'block'}
                                                ${item.text === 'durc' ? 'flex items-center' : 'block'}
                                                ${item.text === 'durf' ? 'flex items-center' : 'block'}
                                                ${item.text === 'organico' ? 'flex items-center' : 'block'}
                                                flex-1 
                                                py-6 
                                                text-lg text-left text-white 
                                                hover:text-afm-box-light-gray 
                                                uppercase`}
                                        >{item.text}
                                            {item.text === 'organico' &&
                                                <div className="ml-2">
                                                    <Tooltip 
                                                        message="L’organico aziendale è la composizione e l'ordinamento del personale di un’impresa. Inserire i nominativi delle maestranze presenti." 
                                                        twBackground="bg-afm-box-blue" 
                                                    >
                                                        <InformationCircle />
                                                    </Tooltip>
                                                </div>
                                            }
                                        </Link>
                                    </li>
                                    <li className="flex items-center">
                                        <span
                                            className="
                                                block
                                                text-white 
                                                mx-2 
                                                align-middle"
                                        >
                                            {">"}
                                        </span>
                                    </li>
                                </div>
                            )
                        } else
                            return (
                                <div key={id} className="flex">
                                    <li>
                                        <Link
                                            to={item.path}
                                            className={`
                                                ${item.text === 'organigramma' ? 'flex items-center' : 'block'}
                                                ${item.text === 'visura' ? 'flex items-center' : 'block'}
                                                ${item.text === 'doma' ? 'flex items-center' : 'block'}
                                                ${item.text === 'durc' ? 'flex items-center' : 'block'}
                                                ${item.text === 'durf' ? 'flex items-center' : 'block'}
                                                ${item.text === 'organico' ? 'flex items-center' : 'block'}
                                                flex-1 
                                                text-lg font-medium text-left 
                                                py-6 
                                                ${item.paddingLeft}
                                                text-white
                                                uppercase`}
                                        >
                                            {item.text}

                                            {item.text === 'visura' &&
                                                <div className="ml-2">
                                                    <Tooltip
                                                        message="La visura camerale è il documento che fornisce informazioni su qualunque impresa italiana, individuale o collettiva, iscritta al registro delle imprese tenuto dalle Camere di Commercio, dell’Industria, Artigianato ed Agricoltura."
                                                        twBackground="bg-afm-box-blue"
                                                        translateTooltip={true}
                                                    >
                                                        <InformationCircle />
                                                    </Tooltip>
                                                </div>
                                            }

                                            {item.text === 'doma' &&
                                                <div className="ml-2">
                                                    <Tooltip
                                                        message={"Dichiarazione dell’organico medio annuo (riferito all’anno precedente al rilascio della dichiarazione)."}
                                                        twBackground="bg-afm-box-blue"
                                                        translateTooltip={true}
                                                    >
                                                        <InformationCircle />
                                                    </Tooltip>
                                                </div>
                                            }

                                            {item.text === 'durc' &&
                                                <div className="ml-2">
                                                    <Tooltip
                                                        message={"Il Documento unico di regolarità contributiva (DURC) è l'attestazione della regolarità contributiva dell'azienda verso Inps, Inail e Cassa Edile."}
                                                        twBackground="bg-afm-box-blue"
                                                        translateTooltip={true}
                                                    >
                                                        <InformationCircle />
                                                    </Tooltip>
                                                </div>
                                            }
                                            {item.text === 'durf' &&
                                                <div className="ml-2">
                                                    <Tooltip
                                                        message={"Il Documento unico di regolarità fiscale (DURF) è un documento emesso dall'Agenzia delle Entrate italiana che attesta la regolarità fiscale di un'impresa."}
                                                        twBackground="bg-afm-box-blue"
                                                        translateTooltip={true}
                                                    >
                                                        <InformationCircle />
                                                    </Tooltip>
                                                </div>
                                            }


                                            {item.text === 'organico' &&
                                                <div className="ml-2">
                                                    <Tooltip 
                                                        message="L’organico aziendale è la composizione e l'ordinamento del personale di un’impresa. Inserire i nominativi delle maestranze presenti." 
                                                        twBackground="bg-afm-box-blue" 
                                                    >
                                                        <InformationCircle />
                                                    </Tooltip>
                                                </div>
                                            }
                                        </Link>
                                    </li>
                                </div>
                            )
                    })}
                </ol>
            </nav>
            <div className="flex items-center justify-end space-x-8 mr-8">
                {props.firstBtnVisibile && (
                    <Button
                        twBackground={props.firstBtnTwBg}
                        twFocusBg={props.firstBtnTwFocusBg}
                        twTextColor={props.firstBtnTwTextColor}
                        icon={props.firstBtnIcon}
                        text={props.firstBtnText}
                        actions={props.firstBtnActions}
                    />
                )}

                {props.secondBtnVisibile && (
                    <Button
                        twBackground={props.secondBtnTwBg}
                        twFocusBg={props.secondBtnTwFocusBg}
                        twTextColor={props.secondBtnTwTextColor}
                        icon={props.secondBtnIcon}
                        text={props.secondBtnText}
                        actions={props.secondBtnActions}
                    />
                )}
            </div>
        </div>
    )
}

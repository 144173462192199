// React.
import {
    useEffect, useState,
    forwardRef,
    //useState
} from "react"
// Hook Form.
import { useForm } from "react-hook-form"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// organicoModalSlice.
import {
    organicoModalSelector,
    closeModal,
    clearState,
    addOrganico,
    updateOrganico
} from "../../features/generale/organico/OrganicoModalSlice"
// UI.
import Modal from "../../base/Modal"
import ListBox from "../../base/ListBox"

import SearchSelect from '../../base/SearchSelect';
import Select from 'react-select';

// Icons.
import { OvalLoader } from "../../base/Icons"
import DocUploader from "../../base/DocUploader"

const dayPlaceholder = 'giorno',
    monthPlaceholder = 'mese',
    yearPlaceholder = 'anno';

const daysOptions = (props) => {
    if (!props.numberDays) return
    const options = []
    for (let i = 1; i <= props.numberDays; i++) {
        options.push({ value: i.toString(), label: i.toString() })
    }
    // options.unshift({ value: '', label: dayPlaceholder, disabled: true })
    return options
}

const monthsOptions = (props) => {
    if (!props.numberMonths) return
    const options = []
    for (let i = 1; i <= props.numberMonths; i++) {
        options.push({ value: i.toString(), label: i.toString() })
    }
    // options.unshift({ value: '', label: monthPlaceholder, disabled: true })
    return options
}

const yearsOptions = (props) => {
    if (!props.numberYears) return

    const offset = props.offset ? -1 : 1

    const options = []
    for (let i = 0; i <= props.numberYears; i++) {
        options.push({ value: (new Date().getFullYear() + i * offset).toString(), label: (new Date().getFullYear() + i * offset).toString() })
    }
    // options.unshift({ value: '', label: yearPlaceholder, disabled: true })
    return options
}

const employeeTypes = [
    { label: "Datore di lavoro", value: 'DATORE DI LAVORO' },
    { label: "Socio lavoratore", value: 'SOCIO LAVORATORE' },
    { label: "Lavoratore", value: 'DIPENDENTE' },
    { label: "Interinale", value: 'INTERINALE' }
];

const sameNumber = (a, b) => {
    return parseFloat(a) === parseFloat(b)
}

const mapSameNumber = (option, value) => {
    return sameNumber(option.value, value) ? { ...option, selected: true } : option
}

/**
 * 
 * @dev OrganicoModal Component.
 *
 * @function OrganicoModal
 *
 * @returns JSX.Element
 *
 */
export default function OrganicoModal(props) {
    const dispatch = useDispatch();
    const defaultData = props?.data || {};
    const editMode = props?.editMode === true ? true : false;
    const modalTitle = editMode ? "Modifica lavoratore" : "Nuovo lavoratore";

    const defaultValues = Object.assign({
        id: '',
        employeeName: '',
        employeeSurname: '',
        employeeJob: '',
        employeeTitle: '',
        employeeCf: '',
        employeeType: '',
        employeeCartaIdentita: '',
        employeeTesseraSanitaria: '',
        employeeContrattoAssunzione: '',
        employeeBirth: '',
        employeeBirthDay: '',
        employeeBirthMonth: '',
        employeeBirthYear: '',
        employeeBirthPlace: '',
        employeeBirthPlaceProvince: '',
        employeeHiringDay: '',
        employeeHiringMonth: '',
        employeeHiringYear: '',
        employeeNationality: '',
    }, defaultData);

    /**
     *  Initialize a React hook form State using UseForm Hook.
     * @see https://react-hook-form.com/get-started
     */
    const {
        register,
        handleSubmit,
        //setError,
        //clearErrors,
        formState,
        setValue,
        getValues,
        //formState: { errors } // subscription.
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues
    }) // UseForm Hook.
    const { isDirty, isValid } = formState // Get the form state.
    const [isCartaIdentitaUploaded, setIsCartaIdentitaUploaded] = useState(false)
    const [isTesseraSanitariaUploaded, setIsTesseraSanitariaUploaded] = useState(false)
    const [isContrattoAssunzioneUploaded, setIsContrattoAssunzioneUploaded] = useState(false)
    // visuraModalSlice State.
    const { modalIsFetching, modalIsSuccess, modalIsError, modalErrorMsg } =
        useSelector(organicoModalSelector)
    /**
     *  onSubmit Form handler.
     *  Post data to insert a new document.
     */
    const onSubmit = (data) => {
        const lavoratore = {
            name: data.employeeName,
            cognome: data.employeeSurname,
            mansione: data.employeeJob,
            qualifica: data.employeeTitle,
            tipo_dipendente: data.employeeType,
            codice_fiscale: data.employeeCf,
            carta_identita: data.employeeCartaIdentita,
            tessera_sanitaria: data.employeeTesseraSanitaria,
            contratto_assunzione: data.employeeContrattoAssunzione,
            data_nascita: `${data.employeeBirthYear}-${String(data.employeeBirthMonth).padStart(2, '0')}-${String(data.employeeBirthDay).padStart(2, '0')}`,
            data_assunzione: `${data.employeeHiringYear}-${String(data.employeeHiringMonth).padStart(2, '0')}-${String(data.employeeHiringDay).padStart(2, '0')}`,
            nazionalita: data.employeeNationality,
            luogo_nascita: data.employeeBirthPlace,
            provincia_nascita: data.employeeBirthPlaceProvince
        }

        if (!isValid) { return };
        if (editMode) {
            lavoratore.id = defaultData?.id;
            dispatch(updateOrganico(lavoratore));
        } else {
            dispatch(addOrganico(lavoratore));
        }
    }
    /**
     * Component side effects.
     */
    useEffect(() => {
        if (modalIsSuccess) {
            dispatch(closeModal()) // Close Modal.
            dispatch(clearState()) // Clean up the UserSlice State.
            window.location.reload();
        }
    }, [
        dispatch,
        modalIsFetching,
        modalIsSuccess,
        modalIsError,
        modalErrorMsg,
        formState
    ])

    const employeeCartaIdentita = register('employeeCartaIdentita', { required: !editMode });
    const employeeTesseraSanitaria = register('employeeTesseraSanitaria', { required: !editMode });
    const employeeContrattoAssunzione = register('employeeContrattoAssunzione', { required: false });

    // const DocUploaderRef = forwardRef(({ onChange, onBlur, name, label }, ref) => (
    //     <DocUploader name={name} ref={ref} onChange={onChange} onBlur={onBlur} />
    // ));

    const days = daysOptions({ numberDays: 31 })
    const months = monthsOptions({ numberMonths: 12 })
    const years = yearsOptions({ numberYears: 99, offset: true })
    const yearsAssunzione = yearsOptions({ numberYears: 99, offset: true })

    const employeeBirthDay = register('employeeBirthDay', { required: true })
    const employeeBirthMonth = register('employeeBirthMonth', { required: true })
    const employeeBirthYear = register('employeeBirthYear', { required: true })

    const employeeHiringDay = register('employeeHiringDay', { required: true })
    const employeeHiringMonth = register('employeeHiringMonth', { required: true })
    const employeeHiringYear = register('employeeHiringYear', { required: true })

    /**
     * Component render.
     */
    return (
        <Modal title={modalTitle}>
            <form action="" className="p-4" onSubmit={handleSubmit(onSubmit)} method="POST" encType="multipart/form-data">
                <div className="flex flex-row space-x-10">
                    {/* Nome field */}
                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="employeeName"
                            className="
                                mb-2 
                                pl-2 pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Nome<top className="text-red-500 ml-1">*</top>
                        </label>
                        <input
                            type="text"
                            name="employeeName"
                            placeholder="Nome Lavoratore"
                            className="
                                w-96 
                                px-3 py-2 
                                bg-afm-box-light-gray
                                text-afm-box-dark-gray
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md 
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("employeeName", {
                                required: true,
                                //validate: value => (value !== '')
                            })}
                        />
                    </div>
                    {/* Cognome field */}
                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="employeeSurname"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Cognome<top className="text-red-500 ml-1">*</top>
                        </label>

                        <input
                            type="text"
                            name="employeeSurname"
                            placeholder="Cognome Lavoratore"
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md 
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("employeeSurname", {
                                required: true,
                                //validate: value => (value !== '')
                            })}
                        />
                    </div>
                </div>
                <div className="flex flex-row space-x-10">
                    {/* Codice Fiscale field */}
                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="employeeCf"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Codice Fiscale<top className="text-red-500 ml-1">*</top>
                        </label>
                        <input
                            type="text"
                            name="employeeCf"
                            placeholder="Codice fiscale"
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md  
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("employeeCf", {
                                required: true,
                                //validate: value => (value !== '')
                            })}
                        />
                    </div>
                    {/* Data di nascita fields */}
                    <div className="flex flex-row space-x-10">
                        {/* Data di nascita field */}
                        <div className="flex flex-col justify-center mt-6 mb-2">
                            <label
                                className="
                                    mb-2 
                                    pl-2 
                                    pt-1 
                                    text-left text-base text-afm-box-dark-gray"
                            >
                                Data di nascita<top className="text-red-500 ml-1">*</top>
                            </label>
                            <div className="inline-flex space-x-6">
                                <Select
                                    {...employeeBirthDay}
                                    options={days || []}
                                    defaultValue={days.find((option) => sameNumber(option.value, defaultData?.employeeBirthDay))}
                                    onChange={(selected) => {
                                        setValue("employeeBirthDay", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                                    }}
                                    placeholder={dayPlaceholder}
                                    className="relative w-28"
                                // name="employeeBirthDay"
                                />
                                <Select
                                    {...employeeBirthMonth}
                                    options={months || []}
                                    defaultValue={months.find((option) => sameNumber(option.value, defaultData?.employeeBirthMonth))}
                                    onChange={(selected) => {
                                        setValue("employeeBirthMonth", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                                    }}
                                    placeholder={monthPlaceholder}
                                    className="relative w-28"
                                    name="employeeBirthMonth"
                                />
                                <Select
                                    {...employeeBirthYear}
                                    options={years || []}
                                    defaultValue={years.find((option) => sameNumber(option.value, defaultData?.employeeBirthYear))}
                                    onChange={(selected) => {
                                        setValue("employeeBirthYear", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                                    }}
                                    placeholder={yearPlaceholder}
                                    className="relative w-28"
                                    name="employeeBirthYear"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row space-x-10">
                    {/* Luogo di nascita field */}
                    <div className="flex flex-col justify-center mt-6 mb-2 w-96">
                        <label
                            htmlFor="employeeBirthPlace"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Luogo di nascita<top className="text-red-500 ml-1">*</top>
                        </label>
                        <input
                            type="text"
                            name="employeeBirthPlace"
                            placeholder="Luogo di nascita"
                            className="
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md 
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("employeeBirthPlace", {
                                required: true,
                                //validate: value => (value !== '')
                            })}
                        />
                    </div>
                    <div className="flex flex-row space-x-5 justify-center w-96">
                        {/* Luogo di nascita provincia field */}
                        <div className="flex flex-col justify-center mt-6 mb-2 w-2/6">
                            <label
                                htmlFor="employeeBirthPlaceProvince"
                                className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                            >
                                Provincia<top className="text-red-500 ml-1">*</top>
                            </label>
                            <input
                                type="text"
                                name="employeeBirthPlaceProvince"
                                placeholder="Provincia"
                                className="
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md 
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                {...register("employeeBirthPlaceProvince", {
                                    required: true,
                                    //validate: value => (value !== '')
                                })}
                            />
                        </div>
                        {/* Nazionalità field */}
                        <div className="flex flex-col justify-center mt-6 mb-2 w-full">
                            <label
                                htmlFor="employeeNationality"
                                className="
                                mb-2 
                                pl-2 pt-1 
                                text-left text-base text-afm-box-dark-gray"
                            >
                                Nazionalità<top className="text-red-500 ml-1">*</top>
                            </label>
                            <input
                                type="text"
                                name="employeeNationality"
                                placeholder="Nazionalità"
                                className="
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md  
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                {...register("employeeNationality", {
                                    required: true,
                                    //validate: value => (value !== '')
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row space-x-10">
                    {/* Data di assunzione field */}
                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left 
                                text-base 
                                text-afm-box-dark-gray"
                        >
                            Data di assunzione<top className="text-red-500 ml-1">*</top>
                        </label>
                        <div className="inline-flex space-x-6">
                            <Select
                                {...employeeHiringDay}
                                options={days || []}
                                defaultValue={days.find((option) => sameNumber(option.value, defaultData?.employeeHiringDay))}
                                onChange={(selected) => {
                                    setValue("employeeHiringDay", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                                }}
                                placeholder={dayPlaceholder}
                                className="relative w-28"
                                name="employeeHiringDay"
                            />
                            <Select
                                {...employeeHiringMonth}
                                options={months || []}
                                defaultValue={months.find((option) => sameNumber(option.value, defaultData?.employeeHiringMonth))}
                                onChange={(selected) => {
                                    setValue("employeeHiringMonth", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                                }}
                                placeholder={monthPlaceholder}
                                className="relative w-28"
                                name="employeeHiringMonth"
                            />
                            <Select
                                {...employeeHiringYear}
                                options={years || []}
                                defaultValue={years.find((option) => sameNumber(option.value, defaultData?.employeeHiringYear))}
                                onChange={(selected) => {
                                    setValue("employeeHiringYear", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                                }}
                                placeholder={yearPlaceholder}
                                className="relative w-28"
                                name="employeeHiringYear"
                            />
                        </div>
                    </div>
                    {/* Tipologia dipendente dropdown */}
                    <div className="flex flex-col justify-center mt-6 mb-2 w-full">
                        <label
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left 
                                text-base 
                                text-afm-box-dark-gray"
                        >
                            Tipologia dipendente<top className="text-red-500 ml-1">*</top>
                        </label>
                        <Select
                            {...register("employeeType")}
                            options={employeeTypes || []}
                            defaultValue={(employeeTypes || []).find(
                                (option) => (option?.value || '').toLowerCase().trim() == (defaultData?.employeeType || '').toLowerCase().trim()
                            )}
                            onChange={(selected) => {
                                setValue("employeeType", selected?.value || '');
                            }}
                            placeholder={'Seleziona tipologia'}
                            className="relative w-full"
                            name="employeeType"
                        />
                    </div>
                </div>
                <div className="flex flex-row space-x-10">
                    {/* Qualifica field */}
                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="employeeTitle"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Qualifica<top className="text-red-500 ml-1">*</top>
                        </label>
                        <input
                            type="text"
                            name="employeeTitle"
                            placeholder="Qualifica"
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md  
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("employeeTitle", {
                                required: true,
                                //validate: value => (value !== '')
                            })}
                        />
                    </div>
                    {/* Mansione field */}
                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="employeeJob"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Mansione<top className="text-red-500 ml-1">*</top>
                        </label>
                        <input
                            type="text"
                            name="employeeJob"
                            placeholder="Mansione"
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("employeeJob", {
                                required: true,
                                //validate: value => (value !== '')
                            })}
                        />
                    </div>
                </div>
                <div className="flex flex-row space-x-10">
                    {/* Carta d'identità field */}
                    <div className="flex-col text-left mt-6 mb-2">
                        <label>Carta d'identità{(!editMode) && <top className="text-red-500 ml-1">*</top>}</label>
                        <div className="block">
                            <DocUploader name="employeeCartaIdentita"
                                onChange={(file, fileURL) => {
                                    setIsCartaIdentitaUploaded(!!file);
                                    setValue('employeeCartaIdentita', file, { shouldDirty: true, shouldValidate: true });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row space-x-10">
                    {/* Tessera sanitaria field */}
                    <div className="flex-col text-left mt-6 mb-2">
                        <label>Tessera sanitaria{(!editMode) && <top className="text-red-500 ml-1">*</top>}</label>
                        <div className="block">
                            <DocUploader name={'employeeTesseraSanitaria'}
                                onChange={(file, fileURL) => {
                                    setIsTesseraSanitariaUploaded(!!file);
                                    setValue('employeeTesseraSanitaria', file, { shouldDirty: true, shouldValidate: true });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row space-x-10">
                    {/* Contratto d'assunzione field */}
                    <div className="flex-col text-left mt-6 mb-2">
                        <label>Contratto d'assunzione</label>
                        <div className="block">
                            <DocUploader name={'employeeContrattoAssunzione'}
                                onChange={(file, fileURL) => {
                                    setIsContrattoAssunzioneUploaded(!!file);
                                    setValue('employeeContrattoAssunzione', file, { shouldDirty: true, shouldValidate: true });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex mt-10">
                    {/* Button Annulla */}
                    <div className="flex-1">
                        <button
                            type="button"
                            className="
                                flex 
                                w-auto 
                                px-7 
                                h-10 
                                gap-1 
                                items-center justify-center 
                                uppercase whitespace-nowrap font-semibold
                                text-white 
                                bg-afm-box-dark-gray 
                                hover:opacity-80
                                focus:outline-none"
                            onClick={() => {
                                dispatch(closeModal())
                            }}
                        >
                            annulla
                        </button>
                    </div>
                    {/* Button Procedi */}
                    <button
                        type="submit"
                        value="Submit"
                        disabled={!isDirty || !isValid}
                        className="
                            disabled:opacity-50 
                            flex 
                            w-auto 
                            px-7 
                            h-10 
                            gap-1 
                            items-center justify-center 
                            uppercase whitespace-nowrap font-semibold 
                            text-white 
                            bg-afm-box-blue 
                            hover:opacity-80 
                            focus:outline-none"
                    >
                        {modalIsFetching && <OvalLoader />} procedi
                    </button>
                </div>
                {/* Modal Error */}
                {modalIsError && (
                    <div className="h-8 inline-flex items-center justify-items-center 
                        mt-2 px-20 bg-afm-box-error-red/10 text-afm-box-error-red 
                        border border-afm-box-error-red rounded-md">
                        <p>{modalErrorMsg}</p>
                    </div>
                )}
            </form>
        </Modal>
    )
}

// React.
import {
    useState,
    useEffect,
    //Fragment
} from "react"
// Headless UI.
import { Switch } from "@headlessui/react"
import { useDispatch } from "react-redux"

/**
 *
 * @function ToggleSwitch
 *
 * @param { bool } hasMessage
 * @param { string | bool } toggle
 * @param { any } action
 *
 * @returns JSX.Element
 *
 */
export default function ToggleSwitch(props) {
    
    // ToggleSwitch enabled or not.
    const [
        enabled, 
        setEnabled
    ] = useState(() => {return props.toggle !== undefined ? props.toggle : false} )
    // ToggleSwitch message label.
    //const [message, setMessage] = useState("")

    const dispatch = useDispatch()

    /**
     *
     * Component update effects.
     *
     */
    useEffect(() => {}, [enabled])

    /**
     *
     * Component render.
     *
     */
    return (
        <div className="flex space-x-4 items-center">
            <Switch
                checked={enabled}
                onChange={() => enabled === true ? setEnabled(true) : dispatch(props.action)}
                //as={Fragment}
                className={`
                    ${enabled ? "bg-afm-box-green" : "bg-gray-200"} 
                    relative 
                    inline-flex 
                    h-6 
                    w-11
                    items-center
                    rounded-full`}
            >
                <span className="sr-only">Enable notifications</span>
                <span
                    className={`
                        ${enabled ? "translate-x-6" : "translate-x-1"} 
                        inline-block 
                        h-4 
                        w-4 
                        transform 
                        rounded-full 
                        bg-white
                        transition`}
                />
            </Switch>

            {props.hasMessage && <p className="text-sm">{enabled ? "Già letto" : "Da leggere"}</p>}
        </div>
    )
}

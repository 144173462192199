/**
 *
 * @function Input
 *
 * @param { string } labelHtmlFor
 * @param { string } labelTitle
 * @param { string } inputType
 * @param { string } inputName
 * @param { string } placeholder
 * @param { bool } inputRequired
 * @param { any } inputValue
 * @param { bool } readOnly
 *
 * @returns JSX.Element
 *
 */
export default function Input(props) {
    return (
        <div className="mb-6">
            {props.labelTitle && (
                <label
                    htmlFor={props.labelHtmlFor}
                    className="block mb-2 text-left text-sm text-gray-800 font-medium"
                >
                    {props.labelTitle}
                </label>
            )}

            <input
                type={props.inputType}
                name={props.inputName}
                placeholder={props.placeholder}
                required={props.inputRequired}
                defaultValue={props?.inputValue ? props.inputValue : ""}
                //value={props?.inputValue ? props.inputValue : ''}
                readOnly={props.readOnly}
                className="
                    w-full 
                    px-3 
                    py-2 
                    bg-afm-box-light-gray 
                    text-afm-box-dark-gray 
                    placeholder-afm-box-dark-gray/[.50]
                    border 
                    border-gray-300 
                    rounded-md 
                    focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
            />
        </div>
    )
}

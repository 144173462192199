/**
 *
 * @dev OrganicoModalSlice to handle state of OrganicoModal globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 */

// Redux.
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
// Axios.
import axios from "axios"
// gmapi.
import {
    API_KEY,
    updateLavoratoreAPI,
    nuovoLavoratoreAPI,
    caircaVisitaAPI
} from "../../../../common/gmApi"
// cookie.
import { tkbox, getCookie } from "../../../../common/cookie"
/**
 *
 *  @dev Insert a new organico.
 *
 */
export const addOrganico = createAsyncThunk(
    // Action route.
    "organico/addOrganico",
    async (newLavoratore, thunkAPI) => {
        var bodyFormData = new FormData();
        // bodyFormData.append('carta_identita', newLavoratore.carta_identita, newLavoratore.carta_identita.name);
        // bodyFormData.append('tessera_sanitaria', newLavoratore.tessera_sanitaria, newLavoratore.tessera_sanitaria.name);
        // bodyFormData.append('contratto_assunzione', newLavoratore.contratto_assunzione, newLavoratore.contratto_assunzione.name);
        if (newLavoratore.carta_identita) {
            bodyFormData.append('carta_identita', newLavoratore.carta_identita, newLavoratore.carta_identita.name);
        }
        if (newLavoratore.tessera_sanitaria) {
            bodyFormData.append('tessera_sanitaria', newLavoratore.tessera_sanitaria, newLavoratore.tessera_sanitaria.name);
        }
        if (newLavoratore.contratto_assunzion) {
            bodyFormData.append('contratto_assunzione', newLavoratore.contratto_assunzione, newLavoratore.contratto_assunzione.name);
        }
        try {
            const response = await axios.post(
                // url.
                nuovoLavoratoreAPI,
                // body.
                bodyFormData,
                // Queryparams.
                {
                    headers: { "Content-Type": "multipart/form-data" },
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        nome: newLavoratore.name,
                        cognome: newLavoratore.cognome,
                        mansione: newLavoratore.mansione,
                        qualifica: newLavoratore.qualifica,
                        tipo_dipendente: newLavoratore.tipo_dipendente,
                        codice_fiscale: newLavoratore.codice_fiscale,
                        data_nascita: newLavoratore.data_nascita,
                        data_assunzione: newLavoratore.data_assunzione,
                        nazionalita: newLavoratore.nazionalita,
                        luogo_nascita: newLavoratore.luogo_nascita,
                        provincia_nascita: newLavoratore.provincia_nascita
                    },
                }
            )

            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    // else reject gmapi response error.
                    console.error('Error "addOrganico":', response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                console.error('Error "addOrganico":', response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            // If something get wrong then reject the error.
            console.error('Error "addOrganico":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)
/**
 *
 *  @dev Update existing organico.
 *
 */
export const updateOrganico = createAsyncThunk(
    // Action route.
    "organico/updateOrganico",
    async (lavoratore, thunkAPI) => {
        var bodyFormData = new FormData();
        if (lavoratore.carta_identita) {
            bodyFormData.append('carta_identita', lavoratore.carta_identita, lavoratore.carta_identita.name)
        }
        if (lavoratore.tessera_sanitaria) {
            bodyFormData.append('tessera_sanitaria', lavoratore.tessera_sanitaria, lavoratore.tessera_sanitaria.name)
        }
        if (lavoratore.contratto_assunzion) {
            bodyFormData.append('contratto_assunzione', lavoratore.contratto_assunzione, lavoratore.contratto_assunzione.name)
        }
        try {
            const response = await axios.post(
                // url.
                updateLavoratoreAPI,
                // body.
                bodyFormData,
                // Queryparams.
                {
                    headers: { "Content-Type": "multipart/form-data" },
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_lavoratori: lavoratore.id,
                        nome: lavoratore.name,
                        cognome: lavoratore.cognome,
                        mansione: lavoratore.mansione,
                        qualifica: lavoratore.qualifica,
                        tipo_dipendente: lavoratore.tipo_dipendente,
                        codice_fiscale: lavoratore.codice_fiscale,
                        data_nascita: lavoratore.data_nascita,
                        data_assunzione: lavoratore.data_assunzione,
                        nazionalita: lavoratore.nazionalita,
                        luogo_nascita: lavoratore.luogo_nascita,
                        provincia_nascita: lavoratore.provincia_nascita
                    },
                }
            )

            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    // else reject gmapi response error.
                    console.error('Error "updateOrganico":', response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                console.error('Error "updateOrganico":', response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            // If something get wrong then reject the error.
            console.error('Error "updateOrganico":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)
/**
 *
 * @dev Insert a new employee.
 *
 */
export const uploadOrganico = createAsyncThunk(
    // Action route.
    "organico/uploadOrganico",
    //
    // Async Function with axios http req.
    //
    // First param: 
    //  {
    //   data: string,
    //   scadenza: string
    //   allegato: any 
    //  }
    //
    // Second param: "thunkAPI" callback function that should return a promise
    // handle with extraReducers to update the State.
    // It takes two parameter too: first is the value of the dispatched action,
    // and the second is the Thunk API config.
    //
    async ({ data, scadenza, allegato }, thunkAPI) => {
        try {
            const response = await axios.post(
                // url.
                //nuovoLavoratoreAPI,
                // body.
                {
                    allegato: allegato,
                },
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        data: data,
                        scadenza: scadenza,
                    },
                }
            )

            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    //return console.log(response.data);
                    return response.data
                } else {
                    // else reject gmapi response error.
                    //console.error('Error "uploadOrganico":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "uploadOrganico":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            // If something get wrong then reject the error.
            console.error('Error "uploadOrganico":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)
/**
 *
 *  @dev Insert a new company certificate.
 *
 */
export const uploadVisita = createAsyncThunk(
    // Action route.
    "organico/uploadVisita",
    //
    // Async Function with axios http req.
    //
    // First param:
    //  {
    //      data: string,
    //  }
    //
    // Second param: "thunkAPI" callback function that should return a promise
    // handle with extraReducers to update the State.
    // It takes two parameter too: first is the value of the dispatched action,
    // and the second is the Thunk API config.
    //
    async ({ allegato }, thunkAPI) => {
        try {
            const response = await axios.post(
                // url.
                caircaVisitaAPI,
                // body.
                {
                    allegato: allegato,
                },
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    // else reject gmapi response error.
                    //console.error('Error "uploadVisita":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "uploadVisita":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            // If something get wrong then reject the error.
            console.error('Error "uploadVisita":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)
/**
 *
 *  @∂ev Create "organicoModalSlice" feature.
 *
 */
export const organicoModalSlice = createSlice({
    // Name, used in action types.
    name: "organicoModal",
    // The initial state for the reducer.
    initialState: {
        modalIsOpen: false,     // Modal is open or not.
        modalIsFetching: false, // AsyncThunk is calling an API.
        modalIsSuccess: false,  // AsyncThunk is success from API call.
        modalIsError: false,    // AsyncThunk is fail from API call.
        modalErrorMsg: "",      // Error message container.
    },
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {
        clearState: (state) => {
            state.modalIsError = false
            state.modalIsSuccess = false
            state.modalIsFetching = false

            return state
        },
        openModal: (state) => {
            console.log('open organico modal');
            state.modalIsOpen = true;
        },
        closeModal: (state) => {
            state.modalIsOpen = false;
        },
    },
    // AsyncThunk Reducers.
    // A "builder callback" function used to add more reducers, or
    // an additional object of "case reducers", where the keys should be other
    // action types.
    extraReducers: (builder) => {
        builder
            /**
             *
             * uploadOrganico.
             *
             */
            .addCase(uploadOrganico.fulfilled, (state) => {
                state.modalIsFetching = false
                state.modalIsSuccess = true
            })
            .addCase(uploadOrganico.pending, (state) => {
                state.modalIsFetching = true
            })
            .addCase(uploadOrganico.rejected, (state, action) => {
                state.modalIsFetching = false
                state.modalIsError = true
                state.modalErrorMsg = action.payload
            })
            /**
             *
             * uploadVisita.
             *
             */
            .addCase(uploadVisita.fulfilled, (state) => {
                state.modalIsFetching = false
                state.modalIsSuccess = true
            })
            .addCase(uploadVisita.pending, (state) => {
                state.modalIsFetching = true
            })
            .addCase(uploadVisita.rejected, (state, action) => {
                state.modalIsFetching = false
                state.modalIsError = true
                state.modalErrorMsg = action.payload
            })
            /**
             *
             * addOrganico.
             *
             */
            .addCase(addOrganico.fulfilled, (state) => {
                state.modalIsFetching = false
                state.modalIsSuccess = true
            })
            .addCase(addOrganico.pending, (state) => {
                state.modalIsFetching = true
            })
            .addCase(addOrganico.rejected, (state, action) => {
                state.modalIsFetching = false
                state.modalIsError = true
                state.modalErrorMsg = action.payload
                console.log('add organico error', state, action)
            })
            /**
             *
             * updateOrganico.
             *
             */
            .addCase(updateOrganico.fulfilled, (state) => {
                state.modalIsFetching = false
                state.modalIsSuccess = true
            })
            .addCase(updateOrganico.pending, (state) => {
                state.modalIsFetching = true
            })
            .addCase(updateOrganico.rejected, (state, action) => {
                state.modalIsFetching = false
                state.modalIsError = true
                state.modalErrorMsg = action.payload
                console.log('update organico error', state, action)
            })
    },
})
// Export the reducer.
export const { clearState, openModal, closeModal } = organicoModalSlice.actions

// Export Slice by name.
export const organicoModalSelector = (state) => state.organicoModal

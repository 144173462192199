// React.
import { Fragment } from "react"
// Redux.
import { useDispatch } from "react-redux"
// Icons.
import { PaperClip } from "./Icons"

/**
 *
 * @dev PdfViewer Component as a link to pdf file.
 * 
 * @function PdfViewer
 *
 * @param { any } action
 *
 * @returns JSX.Element
 *
 */
export default function PdfViewer(props) {
    const dispatch = useDispatch()

    if (props.action !== undefined) {
        return (
            <button
                className="flex underline text-afm-box-dark-gray"
                onClick={() => dispatch(props.action)}
            >
                <PaperClip />
                PDF
            </button>
        )
    } else {
        return <Fragment>action undefined</Fragment>
    }
    
}

// React.
import { useState, useEffect, Fragment } from "react"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// PrivacySlice.
import {
    getPrivacyDocs,
    privacySelector,
    clearPrivacyDocSenderId,
    downloadPrivacyDocById,
    showPrivacyDocById,
} from "../../features/privacy/PrivacySlice"
// UI.
import SubHeader from "../../base/SubHeader"
import LayoutDetailContainer from "../LayoutDetailContainer"
import Tabs from "../../base/Tabs"
import Grid from "../../base/Grid"
import SearchBox from "../../base/SearchBox"
import Button from "../../base/Button"
import PdfViewer from "../../base/PdfViewer"
// Icons.
import { LockClosed, OvalLoader } from "../../base/Icons"
import { emptyString } from "../../../common/util"
// SearchSlice.
import { searchSelector, setSearchList, reset } from "../../features/search/SearchSlice"
import {DataScadenzaSort} from "../../../common/util"

/**
 * 
 * @dev PrivacyMod UI Component.
 * @dev Privacy subsection
 *
 * @function PrivacyMod
 *
 * @returns JSX.Element
 *
 */
export default function PrivacyMod() {
    const dispatch = useDispatch();

    // Tab `Altri Documenti` grid items.
    const [otherDocsGridItems, setOtherDocsGridItems] = useState([])
    // Tab `Altri Documenti` grid header items.
    const [otherDocsGridHeaderTitleItems, setOtherDocsGridHeaderTitleItems] = useState([])

    //
    // PrivacySlice State.
    //
    const {
        otherMods,
        isFetching,
        isSuccess,
        isError,
        //errorMsg,
        privacyDocSenderId,
        isFetchingDoc,
    } = useSelector(privacySelector)

    /**
     *
     *  Component side effects.
     *
     */
    useEffect(() => {
        dispatch(getPrivacyDocs())
    }, [dispatch]);

    // searchSlice State.
    const { searchResults, searchList, searchQuery } = useSelector(searchSelector)

    useEffect(() => {
        //
        // Build Grid items for "Altri Documenti" Category.
        //
        const otherDocsItems = [];
        const emptySearch = (searchQuery || '').trim() === "";
        const searchQueryResults = emptySearch ? searchList : searchResults;

        // if (!otherMods) { return }
        if (searchQueryResults.length === 0) {
            setOtherDocsGridItems(searchQueryResults);
            return
        };

        searchQueryResults
            .map(item => item)
            .sort(DataScadenzaSort)
            .map((doc) => {
                const otherDocsGridItem = [
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "Documento",
                        additionalText: "",
                        twColSpan: "col-span-4",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Caricamento",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Scadenza",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    // {
                    //     text: "",
                    //     twColSpan: "col-span-1",
                    //     twAdditionalClass: "",
                    //     component: {},
                    // },
                    {
                        text: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass:
                            "inline-flex gap-2 justify-self-end items-center justify-items-center",
                        component: ""
                    },
                ]
                //
                // Item 0.
                otherDocsGridItem[0].component = (
                    <div
                        className="
                        flex 
                        space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                        justify-self-start 
                        text-afm-box-light-gray-text"
                    >
                        <LockClosed />
                        <span className="border-l border-afm-box-light-gray-text"></span>
                    </div>
                )
                // Item 1.
                otherDocsGridItem[1].text = doc.nome
                // Item 2.
                otherDocsGridItem[2].text = doc.data_ora_inserimento
                // Item 3.
                otherDocsGridItem[3].text = doc.data_scadenza
                // Item 4.
                // otherDocsGridItem[4].component = <PdfViewer action={showPrivacyDocById(doc.id_box_privacy)} />
                // Item 5.
                otherDocsGridItem[4].component = (
                    <div className="flex items-center space-x-2">
                        {/* If isFetchingDoc and privacyDocSenderId State correspond to Button id pressed, than show the OvalLoader. */}
                        {isFetchingDoc && privacyDocSenderId === doc.id_box_privacy && (
                            <OvalLoader strokeColor="#aaa" />
                        )}
                        {(!emptyString(doc.allegato)) &&
                            <Button
                                text="DOWNLOAD"
                                id={doc.id_box_privacy}
                                twTextColor="text-white"
                                twBackground="bg-afm-box-blue"
                                twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                actions={[
                                    clearPrivacyDocSenderId(),
                                    downloadPrivacyDocById(doc.id_box_privacy),
                                ]}
                            />
                        }
                    </div>
                )

                otherDocsItems.push(otherDocsGridItem)

                return setOtherDocsGridItems(otherDocsItems)
            })

        setOtherDocsGridHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Documento",
                twColSpan: "col-span-4",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data Caricamento",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data scadenza",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            // {
            //     title: "Allegato",
            //     twColSpan: "col-span-1",
            //     twAdditionalClass: "",
            //     component: "",
            // },
            {
                title: "",
                twColSpan: "col-span-3",
                twAdditionalClass: "justify-self-end",
                component: <SearchBox placeholder={"Cerca documento"} />,
            },
        ])
    }, [
        searchList,
        searchQuery,
        searchResults,
        searchList,
        isFetchingDoc,
        privacyDocSenderId
    ])
    /**
     *
     *  if can get visura items list correctly
     *  then is possible to init and configure the search feature.
     *
     */
    useEffect(() => {
        if (isSuccess) {
            // if organicoItems are been fetched then call setSearchList giving:
            // list, search key and an additional list of search key.
            dispatch(
                setSearchList({
                    dataItems: otherMods,
                    searchIndex: 'nome',
                    additionalIndex: []
                })
            )
        }
    }, [
        otherMods,
        isFetching,
        isSuccess,
        isError
    ]);
    useEffect(() => {
        dispatch(reset());
    }, []);
    /**
     *
     *  Component render.
     *
     */
    return (
        <Fragment>
            <SubHeader
                subHeaderTitle="Documenti Master Privacy"
                firstBtnVisibile={false}
                secondBtnVisibile={false}
            />
            <LayoutDetailContainer>
                {/* Skeleton. */}
                {isFetching && <PrivacyModSkeleton />}

                {!isFetching && !isError && isSuccess && <Grid
                    twGridTemplateColumns="grid-cols-12"
                    headerTitleItems={otherDocsGridHeaderTitleItems}
                    items={otherDocsGridItems}
                />}

                {isError && !isSuccess && (
                    <div className="flex items-center justify-center my-14">
                        <p className="text-xl">...Recupero dei dati fallito</p>
                    </div>
                )}
            </LayoutDetailContainer>
        </Fragment>
    )
}

/**
 * 
 * @dev PrivacyModSkeleton skeleton component.
 *
 * @function PrivacyModSkeleton
 *
 * @returns JSX.Element
 *
 */
function PrivacyModSkeleton() {
    return (
        <div className="animate-pulse px-10">
            <div className="flex flex-col">
                <header
                    className="
                        h-20 
                        flex 
                        space-x-4 
                        items-center justify-items-start font-semibold"
                >
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                </header>
                <span className="flex-grow mb-2 border-t border-gray-400"></span>

                <div
                    className="
                        flex space-x-4  
                        my-1 
                        mb-4 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-14 w-full bg-neutral-300 rounded"></div>
                </div>

                <div
                    className="
                        flex space-x-4 
                        my-1 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex space-x-4 
                        my-1 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex space-x-4 
                        my-1 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex space-x-4 
                        my-1 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex space-x-4 
                        my-1 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
            </div>
        </div>
    )
}

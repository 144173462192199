// React.
import { Fragment } from "react"
// Redux.
import { useDispatch } from "react-redux"
// Headless UI.
import { Menu, Transition } from "@headlessui/react"
// Util.
import { classNames } from "../../common/util"
// Icons.
import { ChevronDown } from "./Icons"

/**
 *
 * @dev Handle image uploading process.
 * 
 * @function DropDown
 * 
 * @params {
 *  buttonItems: {
 *      type: string
 *      method: function
 *  }
 * } props
 *
 * @returns JSX.Element
 *
 */
export default function DropDown(props) {
    // Actions dispatcher.
    const dispatch = useDispatch()

    //
    // Type property.
    // To know how kind of item view in the dropdown (inside <Menu.Items/> container)
    //
    const types = props.buttonItems !== undefined ? props.buttonItems : [{type: "deafult", method: undefined}]

    /**
     * 
     * @function MenuItem
     * 
     * @params {
     * type: string
     * method: function
     * } props
     * 
     * @returns JSX.Element
     * 
     */
    function MenuItem(props){
        if (props.type !== undefined) {

            switch (props.type) {
                case "delete":
                    return(
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    type="button"
                                    className={classNames(
                                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                        "block w-full text-left px-4 py-2 text-sm"
                                    )}
                                    onClick={
                                        () => props.method !== undefined ?
                                        dispatch(props.method) 
                                        : 
                                        null
                                    }
                                >
                                    {props.type === "delete" ? "Elimina" : "delete"}
                                </button>
                            )}
                        </Menu.Item>
                    )
                default:
                    break
            }
            
        } else {
            return
        }
    }

    /**
     * 
     * @function menuItemType
     * 
     * @returns JSX.Element
     * 
     */
    function MenuItemType() {
        return types.map((type, id) => 
                <MenuItem key={id} type={type.type} method={type.method}/> 
            )
    }

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className="
                        inline-flex justify-center 
                        w-full 
                        shadow-sm 
                        px-4 
                        py-2 
                        text-sm 
                        font-medium text-white
                        bg-afm-box-blue
                        focus:bg-afm-box-blue/[0.7] 
                        hover:opacity-80 
                        focus:outline-none"
                >
                    <ChevronDown />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="
                        z-10 
                        origin-top-right 
                        absolute 
                        right-0 
                        mt-2 
                        w-56 
                        rounded-md shadow-lg 
                        bg-white 
                        ring-1
                        ring-black 
                        ring-opacity-5
                        focus:outline-none"
                >
                    <div className="py-1">
                        <form method="POST" action="#">
                            <MenuItemType/>
                        </form>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

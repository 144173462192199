// Icons.
import { PluSm } from "./Icons"

/**
 *
 * @dev BtnAddField Component
 * 
 * @function BtnAddField
 *
 * @param { any } onClick
 * @param { string } text
 *
 * @returns JSX.Element
 *
 */
export default function BtnAddField(props) {
    return (
        <button
            className="
                flex 
                items-center justify-center 
                py-1 
                mr-1 my-1 
                background-transparent 
                text-afm-box-green font-bold text-xs underline 
                outline-none 
                focus:outline-none 
                ease-linear 
                transition-all 
                duration-150"
            type="button"
            onClick={props.onClick}
        >
            <PluSm /> {props.text}
        </button>
    )
}

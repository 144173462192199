// react
import { useEffect, useState, Fragment } from "react"
// Redux.
import { useSelector, useDispatch } from "react-redux"
// ManutenzioniModalSlice.
import {
    manutenzioniModalSelector,
    closeModal,
    clearState,
    addMachine,
} from "../../features/manutenzioni/ManutenzioniModalSlice"
// Hook Form.
import { useForm } from "react-hook-form"
// UI.
import Modal from "../../base/Modal"
import Tooltip from "../../base/Tooltip"

// Icons.
import { OvalLoader, Check, InformationCircle } from "../../base/Icons"

/**
 *
 * @dev ManutenzioniModal UI Component.
 * @dev Allow insertion of new machinery.
 * 
 * @function ManutenzioniModal
 *
 * @returns JSX.Element
 *
 */
export default function ManutenzioniModal() {
    const dispatch = useDispatch()

    // Init doc state.
    const [doc, setDoc] = useState("")

    // Init isUploaded state.
    const [isUploaded, setIsUploaded] = useState(false)

    /**
     *
     *  Initialize a React hook form State using UseForm Hook.
     *
     * @see https://react-hook-form.com/get-started
     *
     */
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        formState,
        formState: { errors }, // subscription.
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {
            machineName: "",
        },
    }) // UseForm Hook.

    const { isDirty, isValid } = formState // Get the form state.

    // manutenzioniModalSlice State.
    const { modalIsFetching, modalIsSuccess, modalIsError, modalErrorMsg } =
        useSelector(manutenzioniModalSelector)

    /**
     *
     *  onSubmit Form handler.
     *  Post data to insert a new Machine.
     */

    // FIXME: Procedure incomplete.
    const onSubmit = (data) => {
        // console.log(data.allegato);
        // console.log(errors);

        if (!doc) {
            setError("allegato", {
                type: "custom",
                message: "Immagine Macchina obbligatoria",
            })
            return
        }

        if (data.machineName === "" || data.machineName === " ") {
            setError("machineName", {
                type: "custom",
                message: "Nome Macchina Obbligatorio",
            })
            return
        }

        const newMachine = {
            name: data.machineName,
            reparto: data.machineUnit,
            matricola: data.machineNumber,
            matricola_interna: data.machineInternalNumber,
            anno: data.machineYear,
            marcatura_ce: data.machineCE,
            assistenza_tecnica: data.machineAssistenzaTecnica,
            nome_referente: data.machineNomeReferente,
            numero_assistenza: data.machineNumeroAssistenza,
            email_assistenza: data.machineEmailAssistenza,
            allegato: doc
        }

        if (isValid) {
            dispatch(addMachine(newMachine))
        }
    }

    /**
     *
     * Component side effects.
     *
     */

    useEffect(() => {
        if (modalIsSuccess) {
            dispatch(closeModal()) // Close Modal.
            dispatch(clearState()) // Clean up the UserSlice State.
        }
    }, [
        dispatch,
        modalIsFetching,
        modalIsSuccess,
        modalIsError,
        modalErrorMsg,
        formState
    ])

    /**
     * DOC handler
     */
    const accept = 'image/*,.pdf';

    const Uploader = (props) => {
        const name = 'allegato';

        function onChangeHandler(event) {
            const file = event.target.files[0]
            const fileURL = URL.createObjectURL(file) // Create a blob url with uploaded image.

            if (file) {
                setDoc(file)
                setIsUploaded(true)
            }

            if (props.onChange && file) {
                props.onChange(file)
            }
        }

        return (
            <div className="inline-flex align-middle opacity-100 text-xs">
                <div
                    className="
                        relative 
                        w-32 
                        overflow-hidden 
                        outline outline-1 
                        my-4 
                        p-2 
                        text-black"
                >
                    <input
                        type="file"
                        name={name}
                        accept={accept}
                        {...register("allegato", {
                            // required: "Allegato Obbligatorio",
                        })}
                        onChange={(e) => {
                            onChangeHandler(e)
                        }}
                        className="absolute top-0 right-0 cursor-pointer opacity-0 w-full h-full"
                    />

                    <p className="text-base">Scegli file...</p>
                </div>

                <div className="flex items-center justify-center ml-10">
                    {isUploaded ? (
                        <Fragment>
                            <p className="text-base">File caricato con successo</p>
                            <span
                                className="
                                    ml-2 
                                    rounded-full 
                                    h-6 
                                    w-6 
                                    bg-afm-box-success-green 
                                    text-white"
                            >
                                <Check />
                            </span>
                        </Fragment>
                    ) : (
                        <p className="text-base">Nessun file caricato</p>
                    )}
                </div>
            </div>
        )
    }

    /**
     *
     * Component render.
     *
     */
    return (
        <Modal title="Nuova Macchina">
            <form action="" className="p-4" onSubmit={handleSubmit(onSubmit)} method="POST">
                <div className="flex flex-row space-x-10">
                    <div className="flex flex-col">
                        <label
                            htmlFor="allegato"
                            className="
                                mb-2 
                                pr-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Immagine Macchina<top className="text-red-500 ml-1">*</top>
                        </label>

                        <div className="flex items-center">
                            <Uploader 
                                onChange={(e) => {
                                    // console.log("onChange")
                                }} 
                            />
                        </div>

                        {errors.allegato && (
                            <div className="flex justify-items-start text-afm-box-error-red text-xs">
                                <p>{errors.allegato.message}</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex flex-row space-x-10">
                    <div className="flex flex-col">
                        <div
                            className="
                                flex 
                                flex-row 
                                items-center 
                                justify-start 
                                mt-6 
                                mb-2"
                        >

                            <label
                                htmlFor="machineName"
                                className="
                                    mb-2 
                                    pr-2 
                                    pt-1 
                                    text-left text-base text-afm-box-dark-gray"
                            >
                                Nome Macchina<top className="text-red-500 ml-1">*</top>
                            </label>

                            <span
                                className="
                                    h-6 
                                    w-6 
                                    rounded-full 
                                    bg-afm-box-light-gray 
                                    text-afm-box-dark-gray"
                            >
                                <Tooltip
                                    message="Nome della macchina che si desidera inserire"
                                    twBackground="bg-afm-box-blue"
                                >
                                    <InformationCircle />
                                </Tooltip>
                            </span>
                        </div>

                        <input
                            type="text"
                            name="machineName"
                            placeholder="Nome macchina"
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("machineName", {
                                required: true,
                                //validate: value => (value !== '')
                            })}
                        />

                        {errors.machineName && (
                            <div className="flex justify-items-start text-afm-box-error-red text-xs">
                                <p>{errors.machineName.message}</p>
                            </div>
                        )}
                    </div>

                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="machineUnit"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Reparto
                        </label>

                        <input
                            type="text"
                            name="machineUnit"
                            placeholder="Reparto"
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md 
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("machineUnit", {
                                //required: true,
                                //validate: value => (value !== '')
                            })}
                        />
                    </div>
                </div>

                <div className="flex flex-row space-x-10">
                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="machineNumber"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Matricola
                        </label>

                        <input
                            type="text"
                            name="machineNumber"
                            placeholder="Matricola"
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md 
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("machineNumber", {
                                //required: true,
                                //validate: value => (value !== '')
                            })}
                        />
                    </div>

                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="machineInternalNumber"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Matricola interna
                        </label>

                        <input
                            type="text"
                            name="machineInternalNumber"
                            placeholder="Matricola interna"
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("machineInternalNumber", {
                                //required: true,
                                //validate: value => (value !== '')
                            })}
                        />
                    </div>
                </div>

                <div className="flex flex-row space-x-10">
                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="machineYear"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Anno<top className="text-red-500 ml-1">*</top>
                        </label>

                        <input
                            type="text"
                            name="machineYear"
                            placeholder="Anno"
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("machineYear", {
                                required: true,
                                min: 2010,
                                max: 3000,
                                //validate: value => (value >= 2010) && (value <= 3000)
                            })}
                        />
                    </div>

                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="machineCE"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Marcatura CE
                        </label>

                        <input
                            type="text"
                            name="machineCE"
                            placeholder="Marcatura CE"
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("machineCE", {
                                //required: true,
                                //validate: value => (value !== '')
                            })}
                        />
                    </div>
                </div>

                <div className="flex flex-row space-x-10">
                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="machineAssistenzaTecnica"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Assistenza tecnica
                        </label>

                        <input
                            type="text"
                            name="machineAssistenzaTecnica"
                            placeholder=""
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("machineAssistenzaTecnica", {
                                //required: true,
                                //validate: value => (value >= 2010) && (value <= 3000)
                            })}
                        />
                    </div>

                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="machineNomeReferente"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Nome referente
                        </label>

                        <input
                            type="text"
                            name="machineNomeReferente"
                            placeholder=""
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("machineNomeReferente", {
                                //required: true,
                                //validate: value => (value !== '')
                            })}
                        />
                    </div>
                </div>

                <div className="flex flex-row space-x-10">
                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="machineNumeroAssistenza"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Numero di telefono
                        </label>

                        <input
                            type="text"
                            name="machineNumeroAssistenza"
                            placeholder=""
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("machineNumeroAssistenza", {
                                //required: true,
                                //validate: value => (value >= 2010) && (value <= 3000)
                            })}
                        />
                    </div>

                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="machineEmailAssistenza"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Email
                        </label>

                        <input
                            type="text"
                            name="machineEmailAssistenza"
                            placeholder=""
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("machineEmailAssistenza", {
                                //required: true,
                                //validate: value => (value !== '')
                            })}
                        />
                    </div>
                </div>

                <div className="flex mt-10">
                    <div className="flex-1">
                        <button
                            type="button"
                            className="
                                flex 
                                w-auto 
                                px-7 
                                h-10 
                                gap-1 
                                items-center justify-center 
                                uppercase whitespace-nowrap font-semibold 
                                text-white 
                                bg-afm-box-dark-gray 
                                hover:opacity-80
                                focus:outline-none"
                            onClick={() => {
                                dispatch(closeModal())
                            }}
                        >
                            annulla
                        </button>
                    </div>

                    <button
                        type="submit"
                        value="Submit"
                        disabled={!isDirty || !isValid}
                        className="
                            disabled:opacity-50 
                            flex 
                            w-auto 
                            px-7 
                            h-10 
                            gap-1 
                            items-center justify-center 
                            uppercase whitespace-nowrap font-semibold 
                            text-white 
                            bg-afm-box-blue 
                            hover:opacity-80 
                            focus:outline-none"
                    >
                        {modalIsFetching && <OvalLoader />} procedi
                    </button>
                </div>

                {modalIsError && (
                    <div
                        className="
                            inline-flex 
                            items-center 
                            justify-items-center 
                            h-8 
                            mt-2 
                            px-20 
                            bg-afm-box-error-red/10 
                            text-afm-box-error-red 
                            border border-afm-box-error-red rounded-md"
                    >
                        <p>{modalErrorMsg}</p>
                    </div>
                )}
            </form>
        </Modal>
    )
}

/**
 *
 * @function Pillow
 * 
 * @param { string } twBackground
 * @param { string } twTextColor
 * @param { string } text
 *
 * @returns JSX.Element
 *
 */
export default function Pillow(props) {
    return (
        <div
            className={`
                ${props.twTextColor ? props.twTextColor : "text-inherit"} 
                ${props.twBackground}
                flex items-center justify-center
                h-10 
                w-auto 
                px-2
                mr-2 
                rounded-full
                text-xs`}
        >
            {props.text}
        </div>
    )
}

// React.
//import { useState } from 'react';
// Util.
import { classNames } from "../../common/util"
// Headless UI.
import { Tab } from "@headlessui/react"

/**
 *
 * @dev Primitive from headless UI.
 * 
 * @function Tabs
 *
 * @param { any[] } categories
 * @param { number } tabDefaultIndex
 *
 * @returns JSX.Element
 *
 */
export default function Tabs(props) {
    /*
    let [categories] = useState({
        Recent: [
            {
                id: 1,
                title: 'Does drinking coffee make you smarter?',
                date: '5h ago',
                commentCount: 5,
                shareCount: 2,
            },
            {
                id: 2,
                title: "So you've bought coffee... now what?",
                date: '2h ago',
                commentCount: 3,
                shareCount: 2,
            },
        ],
        Popular: [
            {
                id: 1,
                title: 'Is tech making coffee better or worse?',
                date: 'Jan 7',
                commentCount: 29,
                shareCount: 16,
            },
            {
                id: 2,
                title: 'The most innovative things happening in coffee',
                date: 'Mar 19',
                commentCount: 24,
                shareCount: 12,
            },
        ],
        Trending: [
            {
                id: 1,
                title: 'Ask Me Anything: 10 answers to your questions about coffee',
                date: '2d ago',
                commentCount: 9,
                shareCount: 5,
            },
            {
                id: 2,
                title: "The worst advice we've ever heard about coffee",
                date: '4d ago',
                commentCount: 1,
                shareCount: 2,
            },
        ],
    });
    */

    /**
     *
     * Component render.
     *
     */
    return (
        <div className="w-full px-2">
            <Tab.Group defaultIndex={props.tabDefaultIndex !== undefined ? props.tabDefaultIndex : 0}
                onChange={(index) => {
                    if (props.onChange) {
                        props.onChange(index);
                    }
                }}>
                <Tab.List className="flex bg-inherit p-1">
                    {Object.keys(props.categories).map((category) => (
                        <Tab
                            key={category}
                            className={({ selected }) =>
                                classNames(
                                    "w-full py-2.5 text-base font-medium leading-5",
                                    "border-b border-afm-box-light-gray",
                                    selected
                                        ? "bg-white border-afm-box-green text-afm-box-green outline-none"
                                        : "text-afm-box-dark-gray hover:bg-white/[0.12] hover:text-afm-box-light-gray-text"
                                )
                            }
                        >
                            {category}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels className="mt-2">
                    {Object.values(props.categories).map((category, idx) => (
                        <Tab.Panel key={idx} className="rounded-sm bg-white p-3">
                            {category.map((item) => (
                                <div key={item.id}>{item.component}</div>
                            ))}
                        </Tab.Panel>
                    ))}

                    {/*
                    {Object.values(props.categories).map((posts,idx) => (
                        <Tab.Panel
                            key={idx}
                            className={classNames(
                                'rounded-xl bg-white p-3',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                            )}
                        >
                            <ul>
                                {posts.map((post) => (
                                    <li
                                        key={post.id}
                                        className="relative rounded-md p-3 hover:bg-gray-100"
                                    >
                                        <h3 className="text-sm font-medium leading-5">
                                            {post.title}
                                        </h3>

                                        <ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-500">
                                            <li>{post.date}</li>
                                            <li>&middot;</li>
                                            <li>{post.commentCount} comments</li>
                                            <li>&middot;</li>
                                            <li>{post.shareCount} shares</li>
                                        </ul>

                                        <a
                                            href="#"
                                            className={classNames(
                                                'absolute inset-0 rounded-md',
                                                'ring-blue-400 focus:z-10 focus:outline-none focus:ring-2'
                                            )}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </Tab.Panel>
                    ))}
                    */}
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}

/**
 *
 * @dev Index.
 * @dev Mount/render your main react component (App).
 *
 */
// React
import { StrictMode } from "react"
import ReactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"

// Store provider.
import { Provider } from "react-redux"
import store from "./store"

// Router.
import { BrowserRouter } from "react-router-dom"
import { Routes, Route } from "react-router-dom"

// Global styles.
import "./index.css"

/******************************************************************************/

// Main.
import App from "./App"

// Special routes.
import NoMatchingRoute from "./components/NoMatchingRoute"
import ProtectedRoutes from "./components/ProtectedRoutes"
import AdminRoutes from "./components/AdminRoutes"
// Dashboard.
import Dashboard from "./components/Dashboard"
// Login.
import Login from "./components/Login"
// Admin.
import Admin from "./components/Admin"
// Register.
//import Register from './components/features/user/Register';
// "user-settings" section.
import UserSettings from "./components/detail/user/UserSettings"

// "Panoramica" section.
import Panoramica from "./components/detail/panoramica/Panoramica"

// "Generale" section.
import Generale from "./components/detail/generale/Generale"
// "Azienda" subsection.
import Azienda from "./components/detail/generale/Azienda"
// "Visura" subsection.
import Visura from "./components/detail/generale/Visura"
// "Doma" subsection.
import Doma from "./components/detail/generale/Doma"
// "Doma" subsection.
import Durc from "./components/detail/generale/Durc"
// "Doma" subsection.
import Durf from "./components/detail/generale/Durf"
// "Organigramma" subsection.
import Organigramma from "./components/detail/generale/Organigramma"
// "organigramma/dettaglio" subsection.
import OrganigrammaDetail from "./components/detail/generale/OrganigrammaDetail"
// "Organico" subsection.
import Organico from "./components/detail/generale/Organico"
import OrganicoDetail from "./components/detail/generale/OrganicoDetail"

// "Insediamenti" section.
import Insediamenti from "./components/detail/insediamenti/Insediamenti"
import InsediamentiDetail from "./components/detail/insediamenti/InsediamentiDetail"

// "Lavoratori" section.
import Lavoratori from "./components/detail/lavoratori/Lavoratori"
import LavoratoriDetail from "./components/detail/lavoratori/LavoratoriDetail"

// "Privacy" section.
import Privacy from "./components/detail/privacy/Privacy"
// "PrivacyDoc" subsection.
import PrivacyDoc from "./components/detail/privacy/PrivacyDoc"
// "PrivacyMod" subsection.
import PrivacyMod from "./components/detail/privacy/PrivacyMod"

// "Lavori in Cantiere" section.
//import LiCantiere from "./components/detail/cantiere/LiCantiere"
//import LiCantiereDetail from "./components/detail/cantiere/LiCantiereDetail"

// "Lavori in Azienda" section.
//import LiAzienda from "./components/detail/azienda/LiAzienda"
//import LiAziendaDetail from "./components/detail/azienda/LiAziendaDetail"

// "Manutenzioni" section.
import Manutenzioni from "./components/detail/manutenzioni/Manutenzioni"
import ManutenzioniDetail from "./components/detail/manutenzioni/ManutenzioniDetail"

// "AFM Premium" section.
//import AfmPremium from "./components/detail/afm-premium/AfmPremium"

// "Documenti Master" section.
import DocumentiMaster from "./components/detail/documenti-master/DocumentiMaster"

// "Duvri" section.
//import Duvri from "./components/detail/duvri/Duvri"
//import DuvriDetail from "./components/detail/duvri/DuvriDetail"

// "Comunicazioni" section.
import Comunicazioni from "./components/detail/comunicazioni/Comunicazioni"
import ComunicazioniDetail from "./components/detail/comunicazioni/ComunicazioniDetail"

// "Scadenze" section.
import Scadenze from "./components/detail/scadenze/Scadenze"

//
//
//
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    {/* App route. */}
                    <Route path="/" element={<App />}>
                        {/* Login route. */}
                        <Route path="login" element={<Login />} />
                        <Route index element={<Login />} />

                        {/* Register route. */}
                        {/*<Route path='register' element={<Register />} />*/}

                        {/* No matching routes (404).  */}
                        <Route path="*" element={<NoMatchingRoute />} />

                        <Route element={<AdminRoutes />}>
                            <Route path="admin/dashboard" element={<Admin />} />
                        </Route>

                        {/* PRIVATE routes. */}
                        <Route element={<ProtectedRoutes />}>
                            {/* Dashboard route nested. */}
                            <Route path="dashboard" element={<Dashboard />}>
                                {/* Panoramica. Dashboard default route. */}
                                <Route index element={<Panoramica />} />

                                {/* Panoramica route. */}
                                <Route path="user-settings" element={<UserSettings />} />

                                {/* Panoramica route. */}
                                <Route path="panoramica" element={<Panoramica />} />

                                {/* Generale route nested. */}
                                <Route path="generale" element={<Generale />}>
                                    <Route path="azienda" element={<Azienda />} />
                                    <Route path="visura" element={<Visura />} />
                                    <Route path="doma" element={<Doma />} />
                                    <Route path="durc" element={<Durc />} />
                                    <Route path="durf" element={<Durf />} />
                                    <Route path="organigramma" element={<Organigramma />} />
                                    <Route
                                        path="organigramma/dettaglio-organigramma-:id"
                                        element={<OrganigrammaDetail />}
                                    />
                                    <Route path="organico" element={<Organico />} />
                                    <Route
                                        path="organico/dettaglio-lavoratore-:id"
                                        element={<OrganicoDetail />}
                                    />
                                </Route>

                                {/* Insediamenti route. */}
                                <Route path="insediamenti" element={<Insediamenti />} />
                                {/* TODO: Activate the route when API's and data are available.*/}

                                <Route
                                    path="insediamenti/dettaglio-insediamento-:id"
                                    element={<InsediamentiDetail />}
                                />


                                {/* Lavoratori route. */}
                                <Route path="lavoratori" element={<Lavoratori />} />
                                {/*<Route index element={<Lavoratori />} />*/}
                                <Route
                                    path="lavoratori/dettaglio-lavoratore-:id"
                                    element={<LavoratoriDetail />}
                                />

                                {/* Privacy route nested. */}
                                <Route path="privacy" element={<Privacy />}>
                                    <Route path="documenti" element={<PrivacyDoc />} />
                                    <Route path="moduli" element={<PrivacyMod />} />
                                </Route>

                                {/* Cantiere route. */}
                                {/*
                                <Route path="cantiere" element={<LiCantiere />} />
                                <Route
                                    path="cantiere/dettaglio-cantiere-:id"
                                    element={<LiCantiereDetail />}
                                />
                                */}

                                {/* Azienda route. */}
                                {/*
                                <Route path="azienda" element={<LiAzienda />} />
                                <Route path="azienda/dettaglio" element={<LiAziendaDetail />} />
                                */}

                                {/* Manutenzioni route. */}
                                <Route path="manutenzioni" element={<Manutenzioni />} />
                                {/* TODO FIXME: The endpoint API does not return data.*/}
                                <Route
                                    path="manutenzioni/dettaglio-macchina-:id"
                                    element={<ManutenzioniDetail />}
                                />


                                {/* Afm Premium route. */}
                                {/* TODO: Activate the route when API's and data are available.*/}
                                {/*
                                <Route path="afm-premium" element={<AfmPremium />} />
                                */}

                                {/* Documenti Master route. */}
                                <Route path="documenti-master" element={<DocumentiMaster />} />

                                {/* Duvri route. */}
                                {/*<Route path="duvri" element={<Duvri />} />*/}
                                {/* TODO: Activate the route when API's and data are available.*/}
                                {/*
                                <Route
                                    path="duvri/dettaglio-azienda-:id"
                                    element={<DuvriDetail />}
                                />
                                */}

                                {/* Comunicazioni route. */}
                                <Route path="comunicazioni" element={<Comunicazioni />}>
                                    <Route
                                        path="dettaglio-comunicazione-:id"
                                        element={<ComunicazioniDetail />}
                                    />
                                </Route>

                                {/* Scadenze route. */}
                                <Route path="scadenze" element={<Scadenze />} />
                            </Route>
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </Provider>
    </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// React.
import { useState, useEffect, Fragment } from "react"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// OrganigrammaSlice.
import { getOrganigrammaInsediamenti, organigrammaSelector, downloadOrganigrammaById } from "../../features/organigramma/OrganigrammaSlice"
// UI.
import Grid from "../../base/Grid"
//import PrototypeGrid from "../../base/PrototypeGrid";
import SubHeader from "../../base/SubHeader"
import LayoutDetailContainer from "../LayoutDetailContainer"
// import SearchBox from "../../base/SearchBox"
import Button from "../../base/Button"
// Icons.
import { DocumentText, Printer } from "../../base/Icons"

/**
 *
 * @dev Generale Subsection.
 * 
 * @function Organigramma
 *
 * @returns JSX.Element
 *
 */
export default function Organigramma() {
    const dispatch = useDispatch()

    // gridItems State.
    // gridHeaderTitleItems State.
    const [gridHeaderTitleItems, setGridHeaderTitleItems] = useState([])
    const [gridItems, setGridItems] = useState([])

    // organigrammalice State.
    const { organigrammaItems, isSuccess, isError, isFetching } = useSelector(organigrammaSelector)

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        dispatch(getOrganigrammaInsediamenti())
    }, [dispatch])

    useEffect(() => { }, [isSuccess, isError, isFetching])

    useEffect(() => {
        const items = []
        if(!organigrammaItems || !Array.isArray(organigrammaItems)) return

        organigrammaItems.map((item) => {
            const gridItem = [
                {
                    text: "",
                    twColSpan: "col-span-1",
                    twAdditionalClass: "justify-self-start text-afm-box-light-gray-text",
                    component: {},
                },
                {
                    text: "Nome insediamento Lorem Ipsum",
                    twColSpan: "col-span-7",
                    twAdditionalClass: "",
                    component: "",
                },
                {
                    text: "",
                    twColSpan: "col-span-4 justify-self-end flex gap-3",
                    twAdditionalClass: "",
                    component: {},
                },
            ]

            gridItem[0].component = (
                <div
                    className="
                        flex 
                        space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                        justify-self-start 
                        text-afm-box-light-gray-text"
                >
                    <DocumentText />
                    <span className="border-l h-6 border-afm-box-light-gray-text"></span>
                </div>
            )

            gridItem[1].text = item.nome
            gridItem[2].component = (
                <Fragment>
                    <Button
                        text="dettaglio"
                        twTextColor="text-white"
                        twBackground="bg-afm-box-blue"
                        twFocusBG="focus:bg-afm-box-blue/[0.5]"
                        navigateTo={`./dettaglio-organigramma-${item.id_box_insediamenti}`}
                    />
                    <Button
                        icon={<Printer />}
                        id={item.id_box_insediamenti}
                        twTextColor="text-white"
                        twBackground="bg-afm-box-dark-gray px-3"
                        twFocusBG="focus:bg-afm-box-gray/[0.7]"
                        actions={[downloadOrganigrammaById({ id: item.id_box_insediamenti, fileName: "organigrammainsediamento_" + item.nome + ".pdf" })]}
                    />
                </Fragment>
            )
            // console.log(item);
            items.push(gridItem)

            return setGridItems(items)
        })

        // Set grid header titles.
        setGridHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Insediamento",
                twColSpan: "col-span-7",
                twAdditionalClass: "",
                component: "",
            },
            // {
            //     title: "",
            //     twColSpan: "col-span-4",
            //     twAdditionalClass: "justify-self-end",
            //     component: <SearchBox />,
            // },
        ])
    }, [organigrammaItems])



    /**
     *
     * Component render.
     *
     */
    return (
        <Fragment>
            <SubHeader
                subHeaderTitle={"Organigramma sicurezza"}
                firstBtnVisibile={false}
                secondBtnVisibile={false}
            />
            <LayoutDetailContainer>
                {/* Skeleton. */}
                {isFetching && <OrganigrammaSkeleton />}

                {!isFetching && !isError && isSuccess && (
                    <Grid
                        twGridTemplateColumns="grid-cols-12"
                        headerTitleItems={gridHeaderTitleItems}
                        items={gridItems}
                    />
                )}

                {/*
                <PrototypeGrid
                    twGridTemplateColumns='grid-cols-6'
                    headerTitleItems={[
                        {
                            title: '',
                            twColSpan: 'col-span-1',
                            twAdditionalClass: '',
                            component: ''
                        },
                        {
                            title: 'Insediamento',
                            twColSpan: 'col-span-4',
                            twAdditionalClass: '',
                            component: ''
                        },
                        {
                            title: '',
                            twColSpan: 'col-span-1',
                            twAdditionalClass: 'justify-self-end',
                            component: <SearchBox />
                        },
                    ]}
                    items={[
                        {
                            text: '',
                            twColSpan: 'col-span-1',
                            twAdditionalClass: 'flex justify-self-end mr-20 text-afm-box-light-gray-text',
                            component:
                                <Fragment>
                                    <DocumentText />
                                    <span className="flex-grow ml-11 border-l border-afm-box-light-gray-text"></span>
                                </Fragment>
                        },
                        {
                            text: 'Nome insediamento Lorem Ipsum',
                            twColSpan: 'col-span-3',
                            twAdditionalClass: '',
                            component: ''
                        },
                        {
                            text: '',
                            twColSpan: 'col-span-2',
                            twAdditionalClass: 'inline-flex space-x-2 justify-self-end',
                            component:
                                <Button
                                    text='dettaglio'
                                    twTextColor='text-white'
                                    twBackground='bg-afm-box-blue'
                                    twFocusBG='focus:bg-afm-box-blue/[0.5]'
                                    navigateTo='./dettaglio'
                                />
                        },

                    ]}
                />
                */}
            </LayoutDetailContainer>
        </Fragment>
    )
}

/**
 * 
 * @dev Organigramma skeleton Component.
 *
 * @function OrganigrammaSkeleton
 *
 * @returns JSX.Element
 *
 */
function OrganigrammaSkeleton() {
    return (
        <div className="animate-pulse">
            <div className="flex flex-col">
                <header
                    className="
                        h-20 
                        grid grid-cols-12 
                        gap-10 
                        items-center justify-items-start 
                        font-semibold"
                >
                    <div className="col-span-1 h-10 w-full bg-inherit rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-inherit rounded"></div>
                    <div className="col-span-2 h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-inherit rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-inherit rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-inherit rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-inherit rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-inherit rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-inherit rounded"></div>
                    <div className="col-span-2 h-10 w-full bg-neutral-300 rounded"></div>
                </header>
                <span className="flex-grow mb-2 border-t border-gray-400"></span>

                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
            </div>
        </div>
    )
}

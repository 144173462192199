// React.
import { useEffect } from "react"
// Hook Form.
import { useForm } from "react-hook-form"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// ScadenzeModalSlice.
import {
    scadenzeModalSelector,
    closeModal,
    clearState,
    addScadenza,
} from "../../features/scadenze/ScadenzeModalSlice"
// UI.
import Modal from "../../base/Modal"
// Icons.
import { OvalLoader } from "../../base/Icons"

/**
 * 
 * @dev ScadenzeModal UI Component.
 *
 * @function ScadenzeModal
 *
 * @returns JSX.Element
 *
 */
export default function ScadenzeModal() {
    const dispatch = useDispatch()

    /**
     *
     *  Initialize a React hook form State using UseForm Hook.
     *
     * @see https://react-hook-form.com/get-started
     *
     */
    const {
        register,
        handleSubmit,
        //setError,
        //clearErrors,
        formState,
        //formState: { errors } // subscription.
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {
            deadlineName: "",
            deadlineDescr: "",
            deadlineDay: "",
            deadlineMonth: "",
            deadlineYear: "",
        },
    }) // UseForm Hook.

    const { isDirty, isValid } = formState // Get the form state.

    // visuraModalSlice State.
    const { modalIsFetching, modalIsSuccess, modalIsError, modalErrorMsg } =
        useSelector(scadenzeModalSelector)

    /**
     *
     *  onSubmit Form handler.
     *  Post data to insert a new deadline.
     */

    // FIXME: Procedure incomplete check fields.
    const onSubmit = (data) => {
        const newDeadline = {
            date: `${data.deadlineYear}-${data.deadlineMonth}-${data.deadlineDay}`,
            title: data.deadlineName,
            closed: "No",
            notes: data.deadlineDescr,
        }

        //console.log(newDeadline)
        //console.log(isValid)

        if (isValid) {
            dispatch(addScadenza(newDeadline))
        }
    }

    /**
     *
     * Component update effects.
     *
     */
    useEffect(() => {
        if (modalIsSuccess) {
            dispatch(closeModal()) // Close Modal.
            dispatch(clearState()) // Clean up the UserSlice State.
        }

    }, [
        dispatch, 
        modalIsFetching, 
        modalIsSuccess, 
        modalIsError, 
        modalErrorMsg, 
        formState
    ])

    /**
     *
     * Component render.
     *
     */
    return (
        <Modal title="Nuova Scadenza">
            <form action="" className="p-4" onSubmit={handleSubmit(onSubmit)} method="POST">
                <div className="flex flex-col">
                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="deadlineName"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Titolo Scadenza
                        </label>

                        <input
                            type="text"
                            name="deadlineName"
                            placeholder="Titolo"
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md 
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("deadlineName", {
                                //required: true,
                                //validate: value => (value !== '')
                            })}
                        />
                    </div>

                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="deadlineDescr"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Descrizione Scadenza
                        </label>

                        <input
                            type="text"
                            name="deadlineDescr"
                            placeholder="Descrizione"
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("deadlineDescr", {
                                //required: true,
                                //validate: value => (value !== '')
                            })}
                        />
                    </div>

                    <div className="flex flex-row space-x-10">
                        <div className="flex flex-col justify-center mt-6 mb-2">
                            <label
                                className="
                                    mb-2 
                                    pl-2 
                                    pt-1 
                                    text-left text-base text-afm-box-dark-gray"
                            >
                                Data scadenza
                            </label>

                            <div className="inline-flex space-x-6">
                                <input
                                    type="number"
                                    name="deadlineDay"
                                    placeholder="giorno"
                                    className="
                                        w-28 
                                        px-3 
                                        py-2 
                                        bg-afm-box-light-gray 
                                        text-afm-box-dark-gray 
                                        placeholder-afm-box-dark-gray/[.50] 
                                        border border-gray-300 rounded-md 
                                        focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                    {...register("deadlineDay", {
                                        //required: true,
                                        min: 1,
                                        max: 31,
                                        //validate: value => (value >= 1) && (value <= 31)
                                    })}
                                />

                                <input
                                    type="number"
                                    name="deadlineMonth"
                                    placeholder="mese"
                                    className="
                                        w-28 
                                        px-3 
                                        py-2 
                                        bg-afm-box-light-gray 
                                        text-afm-box-dark-gray 
                                        placeholder-afm-box-dark-gray/[.50] 
                                        border border-gray-300 rounded-md 
                                        focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                    {...register("deadlineMonth", {
                                        //required: true,
                                        min: 1,
                                        max: 12,
                                        //validate: value => (value >= 1) && (value <= 12)
                                    })}
                                />

                                <input
                                    type="number"
                                    name="deadlineYear"
                                    placeholder="anno"
                                    className="
                                        w-28 
                                        px-3 
                                        py-2 
                                        bg-afm-box-light-gray 
                                        text-afm-box-dark-gray 
                                        placeholder-afm-box-dark-gray/[.50] 
                                        border border-gray-300 rounded-md 
                                        focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                    {...register("deadlineYear", {
                                        //required: true,
                                        min: 2010,
                                        max: 3000,
                                        //validate: value => (value >= 2010) && (value <= 3000)
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex mt-10">
                    <div className="flex-1">
                        <button
                            type="button"
                            className="
                                w-auto 
                                px-7 
                                h-10 
                                gap-1 
                                items-center justify-center 
                                uppercase whitespace-nowrap font-semibold 
                                text-white flex 
                                bg-afm-box-dark-gray 
                                hover:opacity-80 
                                focus:outline-none"
                            onClick={() => {
                                dispatch(closeModal())
                            }}
                        >
                            annulla
                        </button>
                    </div>

                    <button
                        type="submit"
                        value="Submit"
                        disabled={!isDirty || !isValid}
                        className="
                            disabled:opacity-50 
                            flex 
                            w-auto 
                            px-7 
                            h-10 
                            gap-1 
                            items-center justify-center 
                            uppercase whitespace-nowrap font-semibold 
                            text-white 
                            bg-afm-box-blue 
                            hover:opacity-80 
                            focus:outline-none"
                    >
                        {modalIsFetching && <OvalLoader />} procedi
                    </button>
                </div>

                {modalIsError && (
                    <div
                        className="
                            inline-flex 
                            items-center justify-items-center 
                            h-8 
                            mt-2 
                            px-20 
                            bg-afm-box-error-red/10 
                            text-afm-box-error-red 
                            border border-afm-box-error-red rounded-md"
                    >
                        <p>{modalErrorMsg}</p>
                    </div>
                )}
            </form>
        </Modal>
    )
}

// Redux.
import { useDispatch } from "react-redux"
// Router.
import { useNavigate } from "react-router-dom"

/**
 *
 * @function Button
 *
 * @param { bool } disabled
 * @param { string } text
 * @param { string } twTextColor
 * @param { JSX.Element } icon
 * @param { string } twBackground
 * @param { string } twFocusBg
 * @param { string } navigateTo
 * @param { Function[] } actions
 *
 * @returns JSX.Element
 *
 */
export default function Button(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Protection in case the property is not set.
    const disabled = (props?.disabled === true) ? true : false

    const handleClick = (route) => {
        if (route) {
            navigate(route)
        }
    }

    if (props.navigateTo && disabled === false) {
        return (
            <button
                id={props.id}
                className={`
                    ${props.twBackground} ${props.twFocusBg} ${props.twTextColor} 
                    flex 
                    gap-1 
                    w-auto 
                    h-10 
                    px-7 
                    items-center justify-center 
                    uppercase whitespace-nowrap 
                    font-semibold 
                    hover:opacity-80 
                    focus:outline-none`}
                onClick={() => {
                    handleClick(props.navigateTo)
                }}
            >
                {props.icon} {props.text}
            </button>
        )
    } else if (props.actions && disabled === false) {
        return (
            <button
                id={props.id}
                className={`
                    ${props.twBackground} ${props.twFocusBg} ${props.twTextColor} 
                    flex 
                    gap-1 
                    w-auto 
                    h-10 
                    px-7 
                    items-center justify-center 
                    uppercase whitespace-nowrap 
                    font-semibold 
                    hover:opacity-80 
                    focus:outline-none`}
                onClick={() => {
                    props.actions.map((action) => dispatch(action)) // Process and dispatch an action list.
                }}
            >
                {props.icon} {props.text}
            </button>
        )
    } else if (props.onClick && disabled === false) {
        return (
            <button
                id={props.id}
                className={`
                    ${props.twBackground} ${props.twFocusBg} ${props.twTextColor} 
                    flex 
                    gap-1 
                    w-auto 
                    h-10 
                    px-7 
                    items-center justify-center 
                    uppercase whitespace-nowrap 
                    font-semibold 
                    hover:opacity-80 
                    focus:outline-none`}
                onClick={props.onClick}
            >
                {props.icon} {props.text}
            </button>
        )
    } else if (disabled === true) {
        return (
            <button
                className={`
                    ${props.twBackground} ${props.twFocusBg} ${props.twTextColor} 
                    flex 
                    gap-1 
                    w-auto 
                    h-10 
                    px-7 
                    items-center justify-center 
                    uppercase whitespace-nowrap 
                    font-semibold 
                    opacity-50 
                    focus:outline-none`}
                disabled
            >
                {props.icon} {props.text}
            </button>
        )
    }
}

// React.
import { Fragment, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
// Router.
import { useParams } from "react-router";
// Hook Form.
import { useForm } from "react-hook-form"
import CreatableSelect from 'react-select/creatable';
// UI.
import ListBox from "../../base/ListBox"
import SubHeader from "../../base/SubHeader"
import BtnAddField from "../../base/BtnAddField"
import BtnRemoveField from "../../base/BtnRemoveField"
import LayoutDetailContainer from "../LayoutDetailContainer"
import Button from "../../base/Button"
import Tooltip from "../../base/Tooltip"

import { InformationCircle, Printer } from "../../base/Icons"

import SearchSelect from '../../base/SearchSelect';
import Select from 'react-select';

import {
    getOrganico,
    organicoSelector,
    downloadOrganico,
} from "../../features/generale/organico/OrganicoSlice"

import {
    getOrganigramma,
    organigrammaSelector,
    downloadOrganigrammaById, salvaOrganigramma
} from "../../features/organigramma/OrganigrammaSlice"
import { data } from "autoprefixer"

const listaLavoratori = [
    { value: "", label: "" }
    // { value: "tipo 1", label: "tipo 1" }
];

/**
 *
 * @dev OrganigrammaDetail Component.
 * 
 * @function OrganigrammaDetail
 *
 * @returns JSX.Element
 *
 */
export default function OrganigrammaDetail() {
    const dispatch = useDispatch()
    // TODO: Implement parameterized view.
    const { id } = useParams();
    /**
     *
     *  Initialize a React hook form State using UseForm Hook.
     *
     * @see https://react-hook-form.com/get-started
     *
     */
    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState,
        setValue,
        getValues,
        formState: { errors }, // subscription.
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {
            datori_lavoro: [],
            rspp: [],
            addetti_primo_soccorso: [],
            formErrors: "",
        },
    }) // UseForm Hook.

    //
    //  Init datoreDiLavoroList state array.
    //  Handle listBox.
    //
    const [datoreDiLavoroList, setDatorediLavoroList] = useState([])
    const [datoreDiLavoroOptions, setDatorediLavoroOptions] = useState([])
    const [RSPPList, setRSPPList] = useState([])
    const [RSPPOptions, setRSPPOptions] = useState([])
    const [medicoCompetenteOptions, setMedicoCompetenteOptions] = useState([])
    const [responsabileEmergenzeOptions, setResponsabileEmergenzeOptions] = useState([])
    const [sostitutoResponsabileEmergenzeOptions, setSostitutoResponsabileEmergenzeOptions] = useState([])
    const [RLSOptions, setRLSOptions] = useState([])
    const [addettiAntincendioOptions, setAddettiAntincendioOptions] = useState([])
    const [addettiPrimoSoccorsoOptions, setAddettiPrimoSoccorsoOptions] = useState([])
    const [prepostoOptions, setPrepostoOptions] = useState([])
    const [dirigentiOptions, setDirigentiOptions] = useState([])
    const [rolesOptions, setRolesOptions] = useState({})
    // organicoSlice State.
    const {
        organigrammaItems,
        organigrammaPDFItems,
        organigrammaPDFName,
        isFetching,
        isSuccess,
        isError,
        isFetchingPDF,
        isSuccessPDF,
        isErrorPDF
        //errorMsg
    } = useSelector(organigrammaSelector)
    const [listItems, setListItems] = useState([{ name: '' }])

    const registerDatoriLavoro = register('datori_lavoro', { required: false })
    const registerRspp = register('rspp', { required: false })
    const registerMedicoCompetente = register('medico_competente', { required: false })
    const registerResponsabileEmergenze = register('responsabile_emergenze', { required: false })
    const registerSostitutoResponsabileEmergenze = register('sostituto_responsabile_emergenze', { required: false })
    const registerRLS = register('rls', { required: false })
    const registerAddettiAntincendio = register('addetti_antincendio', { required: false })
    const registerPrimoSoccorso = register('addetti_primo_soccorso', { required: false })
    const registerPreposto = register('preposto', { required: false })
    const registerDirigenti = register('dirigenti', { required: false })

    const saveOrganigramma = () => {
        let values = getValues()
        values['id'] = id;
        dispatch(salvaOrganigramma(values))
        console.log('saved');
        // window.location.reload();
    }

    const canAddToList = (role, list) => {
        const roleOptions = getRoleOptions(role)
        if (!roleOptions || !list) {
            return false
        }
        if (!roleOptions.length || !list.length) {
            return false
        }
        return (roleOptions.length - 1) > list.length
    }

    useEffect(() => {
        dispatch(getOrganigramma(id))
    }, [dispatch]);

    useEffect(() => {
        const options = {}
        Object.keys(organigrammaItems).map((role) => {
            if (!organigrammaItems[role].options && !organigrammaItems[role].value) {
                options[role] = []
                return null;
            }
            if (!organigrammaItems[role].options) {
                options[role] = [];
                return null;
            }
            if (typeof organigrammaItems[role].options === 'object' && !Array.isArray(organigrammaItems[role].options)) {
                options[role] = [Object.values(organigrammaItems[role].options)[0]]
            }
            else if (Array.isArray(organigrammaItems[role].options)) {
                options[role] = organigrammaItems[role].options.map((option) => {
                    return Object.assign({}, option, { selected: false })
                })
            }



        })
        setRolesOptions(options)
    }, [organigrammaItems])

    const getRoleOptions = (role = '', index = 0) => {
        if (!organigrammaItems[role]) {
            return [{ value: '', label: 'Non assegnato', selected: '' }]
        }
        // console.log(role, organigrammaItems[role])
        let currentValue = ''

        if (organigrammaItems[role].value) {
            currentValue = (typeof organigrammaItems[role].value == 'object') ? organigrammaItems[role].value[index] : organigrammaItems[role].value
        }
        if (!rolesOptions[role]) {
            return [{ value: '', label: 'Non assegnato', selected: currentValue == '' }]
        }
        if (role === 'rspp'|| role === 'medico_competente') {
            return [{ value: '', label: 'Non assegnato', selected: currentValue == '' }].concat(organigrammaItems[role].options.map((option) => {
                return Object.assign({}, { label: option, value: option }, { selected: currentValue == option.value })
            }));
        }
        return [{ value: '', label: 'Non assegnato', selected: currentValue == '' }].concat(rolesOptions[role].map(options => {
            return Object.assign({}, options, { selected: currentValue == options.value })
        }))
    }

    // datoreDiLavoro list box.
    const datoreDiLavoroListBox = (
        <CreatableSelect
            formatCreateLabel={function (inputText) { return 'Aggiungi: ' + inputText.toUpperCase(); }}
            {...registerDatoriLavoro}
            options={getRoleOptions('datori_lavoro') || []}
            onChange={(selected) => {
                setValue("datori_lavoro", selected?.value || '', { shouldDirty: true, shouldValidate: true });
            }}
            placeholder={'Datori di lavoro'}
            className="relative w-full"
            name="datori_lavoro"
        />
    )

    const RSPPListBox = (
        <CreatableSelect
            formatCreateLabel={(inputText) => `Aggiungi: ${inputText}`}
            {...registerRspp}
            options={getRoleOptions('rspp') || []}
            onChange={(selected) => {
                setValue("rspp", selected?.value || '', { shouldDirty: true, shouldValidate: true });
            }}
            placeholder={'rspp'}
            className="relative w-full"
            name="rspp"
        />
    )

    const MedicoCompetenteListBox = (
        <CreatableSelect
            formatCreateLabel={(inputText) => `Aggiungi: ${inputText}`}
            {...registerMedicoCompetente}
            options={getRoleOptions('medico_competente') || []}
            onChange={(selected) => {
                setValue("medico_competente", selected?.value || '', { shouldDirty: true, shouldValidate: true });
            }}
            placeholder={'Medico competente'}
            className="relative w-full"
            name="medico_competente"
        />
    )

    const ResponsabileEmergenzeListBox = (
        <CreatableSelect
            formatCreateLabel={(inputText) => `Aggiungi: ${inputText}`}
            {...registerResponsabileEmergenze}
            options={getRoleOptions('responsabile_emergenze') || []}
            onChange={(selected) => {
                setValue("responsabile_emergenze", selected?.value || '', { shouldDirty: true, shouldValidate: true });
            }}
            placeholder={'Responsabile emergenze'}
            className="relative w-full"
            name="responsabile_emergenze"
        />
    )
    const SostitutoResponsabileEmergenzeListBox = (
        <CreatableSelect
            formatCreateLabel={(inputText) => `Aggiungi: ${inputText}`}
            {...registerSostitutoResponsabileEmergenze}
            options={getRoleOptions('sostituto_responsabile_emergenze') || []}
            onChange={(selected) => {
                setValue("sostituto_responsabile_emergenze", selected?.value || '', { shouldDirty: true, shouldValidate: true });
            }}
            placeholder={'Sostituto responsabile emergenze'}
            className="relative w-full"
            name="sostituto_responsabile_emergenze"
        />
    )




    // Add a list box to the datoreDiLavoroList array.
    function addDatoreDiLavoroList() {
        let copy = datoreDiLavoroList
        if (copy.length >= 2) {
            alert('Non è possibile aggiungere altri elementi all\'elenco');
            return;
        }

        copy.push(<div className="mt-4">{datoreDiLavoroListBox}</div>)

        // Get the old state and update it.
        setDatorediLavoroList((prevDatoreDiLavoroList) => [...prevDatoreDiLavoroList])
    }

    // Remove an list box to the datoreDiLavoroList array.
    function removeDatoreDiLavoroList() {
        let copy = datoreDiLavoroList

        copy.pop(datoreDiLavoroListBox)

        // Get the old state and update it.
        setDatorediLavoroList((prevDatoreDiLavoroList) => [...prevDatoreDiLavoroList])
    }

    function addRSPPList() {
        let copy = RSPPList
        if (copy.length >= 2) {
            alert('Non è possibile aggiungere altri elementi all\'elenco');
            return;
        }

        copy.push(<div className="mt-4">{RSPPListBox}</div>)

        // Get the old state and update it.
        setRSPPList((prevRSPPList) => [...prevRSPPList])
    }

    function removeRSPPList() {
        let copy = RSPPList

        copy.pop(RSPPListBox)

        // Get the old state and update it.
        setRSPPList((prevRSPPList) => [...prevRSPPList])
    }

    //
    //  Init rlsList state array.
    //  Handle listBox.
    //
    const [rlsList, setRlsList] = useState([])

    // rls list box.

    function rlsListBox(selectIndex) {
        return <CreatableSelect
            formatCreateLabel={(inputText) => `Aggiungi: ${inputText}`}
            {...registerRLS}
            options={organigrammaItems?.rls?.options || []}
            onChange={(selected) => {
                let valuesKey = 'rls'
                let values = manageValueInList(valuesKey, selected, selectIndex)
                // console.log(values);
            }}
            placeholder={'RLS'}
            className="relative w-full"
            name="rls"
        />
    }

    // Add a list box to the rlsList array.
    function addRlsList() {
        let copy = rlsList
        if (copy.length >= 2) {
            alert('Non è possibile aggiungere altri elementi all\'elenco');
            return;
        }

        copy.push(<div className="mt-4">{rlsListBox(rlsList.length + 1)}</div>)
        // copy.push(<div className="mt-4">{rlsListBox}</div>)

        // Get the old state and update it.
        setRlsList((prevRlsList) => [...prevRlsList])
    }

    // Remove an list box to the rlsList array.
    function removeRlsList() {
        let copy = rlsList
        if (copy.length == 0)
            return;
        copy.splice(copy.length - 1, 1)
        reduceSelected(copy, 'rls')

        // Get the old state and update it.
        setRlsList((prevRlsList) => [...prevRlsList,])
    }

    //
    //  Init addettiAntincendioList state array.
    //  Handle listBox.
    //
    const [addettiAntincendioList, setAddettiAntincendioList] = useState([])

    // addettiAntincendio list box.
    function addettiAntincendioListBox(selectIndex) {
        return <CreatableSelect
            formatCreateLabel={(inputText) => `Aggiungi: ${inputText}`}
            {...registerRLS}
            options={organigrammaItems?.addetti_antincendio?.options || []}
            onChange={(selected) => {
                let valuesKey = 'addetti_antincendio'
                let values = manageValueInList(valuesKey, selected, selectIndex)
                // console.log(values);
            }}
            placeholder={'Addetti antincendio'}
            className="relative w-full"
            name="rls"
        />
    }

    // Add a list box to the addettiAntincendioList array.
    function addAddettiAntincendioList() {
        let copy = addettiAntincendioList
        if (copy.length >= 2) {
            alert('Non è possibile aggiungere altri elementi all\'elenco');
            return;
        }

        copy.push(<div className="mt-4">{addettiAntincendioListBox(addettiAntincendioList.length + 1)}</div>)

        // Get the old state and update it.
        setAddettiAntincendioList((prevAddettiAntincendioList) => [...prevAddettiAntincendioList])
    }

    // Remove an list box to the addettiAntincendioList array.
    function removeAddettiAntincendioList() {
        let copy = addettiAntincendioList

        if (copy.length == 0)
            return;
        copy.splice(copy.length - 1, 1)

        // copy.pop(addettiPrimoSoccorsoListBox)
        reduceSelected(copy, 'addetti_antincendio')

        // Get the old state and update it.
        setAddettiAntincendioList((prevAddettiAntincendioList) => [...prevAddettiAntincendioList,])
    }

    //
    //  Init addettiPrimoSoccorsoList state array.
    //  Handle listBox.
    //
    const [addettiPrimoSoccorsoList, setAddettiPrimoSoccorsoList] = useState([])

    // addettiPrimoSoccorso list box.
    function addettiPrimoSoccorsoListBox(selectIndex) {
        // <SearchSelect
        //     inputClasses="w-full h-full px-3 py-2 bg-afm-box-light-gray text-afm-box-dark-gray placeholder-afm-box-dark-gray/[.50] border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
        //     options={organigrammaItems?.addetti_primo_soccorso?.options}
        // />
        return <CreatableSelect
            formatCreateLabel={(inputText) => `Aggiungi: ${inputText}`}
            {...registerPrimoSoccorso}
            options={organigrammaItems?.addetti_primo_soccorso?.options || []}
            onChange={(selected) => {
                let valuesKey = 'addetti_primo_soccorso'
                let values = manageValueInList(valuesKey, selected, selectIndex)
                // console.log(values);
            }}
            placeholder={'Addetti Primo Soccorso'}
            className="relative w-full"
            name="addetti_primo_soccorso"
        />
    }

    // Add a list box to the addettiPrimoSoccorsoList array.
    function addAddettiPrimoSoccorsoList() {
        let copy = addettiPrimoSoccorsoList
        if (copy.length >= 2) {
            alert('Non è possibile aggiungere altri elementi all\'elenco');
            return;
        }

        copy.push(<div className="mt-4">{addettiPrimoSoccorsoListBox(addettiPrimoSoccorsoList.length + 1)}</div>)

        // Get the old state and update it.
        setAddettiPrimoSoccorsoList((prevAddettiPrimoSoccorsoList) => [
            ...prevAddettiPrimoSoccorsoList,
        ])
    }

    function reduceSelected(copy, valueKey) {
        if (copy.length < getValues(valueKey).length) {
            let values = getValues(valueKey)
            values.pop()
            setValue(valueKey, values, { shouldDirty: true, shouldValidate: true })
        }
    }

    function manageValueInList(valuesKey, selected, selectIndex) {
        let values = getValues(valuesKey) ? getValues(valuesKey) : []
        if (selected.value) {
            if (values[selectIndex])
                values[selectIndex] = selected.value
            else
                values.push(selected.value)
        }
        setValue(valuesKey, values, { shouldDirty: true, shouldValidate: true })
        return values
    }

    // Remove an list box to the addettiPrimoSoccorsoList array.
    function removePrimoSoccorsoList() {
        let copy = addettiPrimoSoccorsoList
        if (copy.length == 0)
            return;
        copy.splice(copy.length - 1, 1)
        // copy.pop(addettiPrimoSoccorsoListBox)
        reduceSelected(copy, 'addetti_primo_soccorso')

        // Get the old state and update it.
        setAddettiPrimoSoccorsoList((prevAddettiPrimoSoccorsoList) => [
            ...prevAddettiPrimoSoccorsoList,
        ])
    }

    /**
     *  Init prepostoList state array.
     *  Handle listBox.
     */
    const [prepostoList, setPrepostoList] = useState([])

    // preposto list box.
    function prepostoListBox(selectIndex) {
        return <CreatableSelect
            formatCreateLabel={(inputText) => `Aggiungi: ${inputText}`}
            {...registerPreposto}
            options={organigrammaItems?.preposto?.options || []}
            onChange={(selected) => {
                let valuesKey = 'preposto'
                let values = manageValueInList(valuesKey, selected, selectIndex)
                // console.log(values);
            }}
            placeholder={'Preposto'}
            className="relative w-full"
            name="preposto"
        />
    }

    // Add a list box to the prepostoList array.
    function addPrepostoList() {
        let copy = prepostoList
        if (copy.length >= 2) {
            alert('Non è possibile aggiungere altri elementi all\'elenco');
            return;
        }

        copy.push(<div className="mt-4">{prepostoListBox(prepostoList.length + 1)}</div>)

        // Get the old state and update it.
        setPrepostoList((prevPrepostoList) => [...prevPrepostoList])
    }

    // Remove an list box to the prepostoList array.
    function removePrepostoList() {

        let copy = prepostoList
        if (copy.length == 0)
            return;
        copy.splice(copy.length - 1, 1)
        reduceSelected(copy, 'preposto')
        setPrepostoList((prevPrepostoList) => [...prevPrepostoList,])
    }

    /**
     *  Init dirigentiList state array.
     *  Handle listBox.
     */
    const [dirigentiList, setDirigentiList] = useState([])

    // dirigenti list box.
    function dirigentiListBox(selectIndex) {

        return <CreatableSelect
            formatCreateLabel={(inputText) => `Aggiungi: ${inputText}`}
            {...registerDirigenti}
            options={organigrammaItems?.dirigenti?.options || []}
            onChange={(selected) => {
                let valuesKey = 'dirigenti'
                let values = manageValueInList(valuesKey, selected, selectIndex)
                // console.log(values);
            }}
            placeholder={'Dirigenti'}
            className="relative w-full"
            name="dirigenti"
        />
    }

    function getCurrentValuesLabel(role) {
        let values = organigrammaItems[role]
        let labels = [];
        if (values && values.value) {
            for (let value in values.value)
                labels.push(values.value[value].persona);
        }

        return labels.length > 0 ? labels.join(', ') : 'nessuno';
    }


    // Add a list box to the dirigentiList array.
    function addDirigentiList() {
        let copy = dirigentiList
        if (copy.length >= 2) {
            alert('Non è possibile aggiungere altri elementi all\'elenco');
            return;
        }

        copy.push(<div className="mt-4">{dirigentiListBox(dirigentiList.length + 1)}</div>)

        // Get the old state and update it.
        setDirigentiList((prevDirigentiList) => [...prevDirigentiList])
    }

    // Remove an list box to the dirigentiList array.
    function removeDirigentiList() {
        let copy = dirigentiList
        if (copy.length == 0)
            return;
        copy.splice(copy.length - 1, 1)
        reduceSelected(copy, 'dirigenti')

        // Get the old state and update it.
        setDirigentiList((prevDirigentiList) => [...prevDirigentiList])
    }

    /**
     *
     * Component render.
     *
     */
    return (

        <Fragment>
            {/* <SubHeader
                subHeaderTitle="organigramma"
                firstBtnVisibile={false}
                secondBtnVisibile={false}
                secondBtnText="GENERA PDF"
                secondBtnTwBg="bg-afm-box-dark-gray"
                secondBtnTwFocusBg="focus:bg-afm-box-dark-gray/[0.7]"
                secondBtnTwTextColor="text-white"
            /> */}

            <LayoutDetailContainer>
                <div className="flex gap-8 mb-3">
                    <div className="w-96">
                        <div className="w-96 py-1 flex gap-3">
                            <label
                                className="mb-2 pt-1 text-left text-sm text-afm-box-dark-gray"
                            >
                                Datore di lavoro
                            </label>

                            <span
                                className="
                        h-6 
                        w-6 
                        rounded-full 
                        bg-afm-box-light-gray 
                        text-afm-box-dark-gray"
                            >
                                <Tooltip
                                    message="È il soggetto titolare del rapporto di lavoro con il lavoratore, o comunque, il soggetto che, secondo il tipo e l’assetto dell’organizzazione nel cui ambito il lavoratore presta la propria attività, ha la responsabilità dell’organizzazione stessa o dell’unità produttiva in quanto esercita i poteri decisionali e di spesa."
                                    twBackground="bg-afm-box-blue"
                                    translateTooltip={true}
                                >
                                    <InformationCircle />
                                </Tooltip>
                            </span>

                        </div>

                        {datoreDiLavoroListBox}

                        {datoreDiLavoroList.map((item, id) => {
                            return (
                                <div key={id} className="flex gap-2">
                                    <div className="flex-1">
                                        {item}
                                    </div>
                                    <div className="self-center pt-6">
                                        <BtnRemoveField onClick={removeDatoreDiLavoroList} />
                                    </div>
                                </div>
                            )
                        })}
                        {(canAddToList("datori_lavoro", addDatoreDiLavoroList)) &&
                            <BtnAddField text="Aggiungi persona" onClick={addDatoreDiLavoroList} />
                        }
                        <div>
                            <small>Valore
                                attuale: <strong>{getCurrentValuesLabel("datori_lavoro")}</strong></small>
                        </div>
                    </div>

                    <div className="w-96">
                        <div className="w-96 py-1 flex gap-3">
                            <label
                                className="mb-2 pt-1 text-left text-sm text-afm-box-dark-gray"
                            >
                                RSPP
                            </label>

                            <span
                                className="
                                    h-6 
                                    w-6 
                                    rounded-full 
                                    bg-afm-box-light-gray 
                                    text-afm-box-dark-gray"
                            >
                                <Tooltip
                                    message="Persona in possesso delle capacità e dei requisiti professionali di cui all’articolo 32 designata dal datore di lavoro, a cui risponde, per coordinare il servizio di prevenzione e protezione dai rischi."
                                    twBackground="bg-afm-box-blue"
                                    translateTooltip={true}
                                >
                                    <InformationCircle />
                                </Tooltip>
                            </span>

                        </div>
                        {RSPPListBox}

                        {RSPPList.map((item, id) => {
                            return (
                                <div key={id} className="flex gap-2">
                                    <div className="flex-1">
                                        {item}
                                    </div>
                                    <div className="self-center pt-6">
                                        <BtnRemoveField onClick={removeRSPPList} />
                                    </div>
                                </div>
                            )
                        })}
                        {(canAddToList("rspp", addRSPPList)) &&
                            <BtnAddField text="Aggiungi" onClick={addRSPPList} />
                        }
                        <div>
                            <small>Valore
                                attuale: <strong>{getCurrentValuesLabel("rspp")}</strong></small>
                        </div>
                    </div>
                </div>
                <div className="flex gap-8 mb-3">
                    <div>
                        <div className="w-96 py-1 flex gap-3">
                            <label
                                className="mb-2 pt-1 text-left text-sm text-afm-box-dark-gray"
                            >
                                Medico Competente
                            </label>
                            <span
                                className="
                                    h-6 
                                    w-6 
                                    rounded-full 
                                    bg-afm-box-light-gray 
                                    text-afm-box-dark-gray"
                            >
                                <Tooltip
                                    message="Il medico competente in base a quanto stabilito dal D. Lgs. 81/08, è una figura professionale che, avendone titolo e requisiti professionali, si occupa di svolgere attività di sorveglianza e visite mediche per assicurarsi che i lavoratori godano di buona salute.
                                    È solitamente un libero professionista o dipendente di struttura sanitaria, che ha conseguito una specializzazione in medicina del lavoro oppure in igiene e medicina preventiva o in medicina legale, che deve risultare iscritto ad un elenco nazionale dei medici competenti, reperibile da ogni datore di lavoro sul sito del ministero ed iscritto all'ordine dei medici nazionale.
                                    Il medico in possesso di uno dei titoli e dei requisiti formativi e professionali di cui all’articolo
                                    38, che collabora, secondo quanto previsto all’articolo 29, comma 1, con il datore di lavoro ai fini della valutazione dei rischi ed è nominato dallo stesso per effettuare la sorveglianza sanitaria e per tutti gli altri compiti di cui al presente decreto."
                                    twBackground="bg-afm-box-blue"
                                    translateTooltip={true}
                                >
                                    <InformationCircle />
                                </Tooltip>
                            </span>
                        </div>

                        {/* <SearchSelect
                            inputClasses="w-full h-full px-3 py-2 bg-afm-box-light-gray text-afm-box-dark-gray placeholder-afm-box-dark-gray/[.50] border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            options={listaLavoratori}
                        /> */}
                        {MedicoCompetenteListBox}
                        <div>
                            <small>Valore attuale: <strong>{getCurrentValuesLabel("medico_competente")}</strong></small>
                        </div>
                    </div>
                    <div>
                        <div className="w-96">
                            <div className="w-96 py-1 flex gap-3">
                                <label className="mb-2 pt-1 text-left text-sm text-afm-box-dark-gray">RLS</label>
                                <span
                                    className="
                                        h-6 
                                        w-6 
                                        rounded-full 
                                        bg-afm-box-light-gray 
                                        text-afm-box-dark-gray"
                                >
                                    <Tooltip
                                        message="L'RLS è una persona eletta o designata per rappresentare i lavoratori per quanto concerne gli aspetti della salute e della sicurezza durante il lavoro."
                                        twBackground="bg-afm-box-blue"
                                    >
                                        <InformationCircle />
                                    </Tooltip>
                                </span>
                                <span>
                                </span>
                            </div>
                            {rlsListBox(0)}

                            {rlsList.map((item, id) => {
                                return (
                                    <div key={id} className="flex gap-2">
                                        <div className="flex-1">{item}</div>
                                        <div className="self-center pt-6">
                                            <BtnRemoveField onClick={removeRlsList} />
                                        </div>
                                    </div>
                                )
                            })}

                            <BtnAddField text="Aggiungi Persona" onClick={addRlsList} />
                            <div>
                                <small>Valore attuale: <strong>{getCurrentValuesLabel("rls")}</strong></small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-8 mb-3">
                    <div className="w-96">
                        <div className="w-96 py-1 flex gap-3">
                            <label className="mb-2 pt-1 text-left text-sm text-afm-box-dark-gray">
                                Addetti Antincendio
                            </label>
                            <span className="
                                h-6 
                                w-6 
                                rounded-full 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray"
                            >
                                <Tooltip
                                    message="L'Addetto Antincendio o, meglio, l'Addetto alla Lotta Antincendio è il lavoratore incaricato di attuare le misure di prevenzione incendi, della lotta antincendio e della gestione delle emergenze che da essa possono scaturire."
                                    twBackground="bg-afm-box-blue"
                                    translateTooltip={true}
                                >
                                    <InformationCircle />
                                </Tooltip>
                            </span>
                        </div>
                        {addettiAntincendioListBox(0)}

                        {addettiAntincendioList.map((item, id) => {
                            return (
                                <div key={id} className="flex gap-2">
                                    <div className="flex-1">{item}</div>
                                    <div className="self-center pt-6">
                                        <BtnRemoveField onClick={removeAddettiAntincendioList} />
                                    </div>
                                </div>
                            )
                        })}

                        <BtnAddField text="Aggiungi Persona" onClick={addAddettiAntincendioList} />

                        <div>
                            <small>Valore
                                attuale: <strong>{getCurrentValuesLabel("addetti_antincendio")}</strong></small>
                        </div>
                    </div>
                    <div className="w-96">
                        <div className="w-96 py-1 flex gap-3">
                            <label
                                className="mb-2 pt-1 text-left text-sm text-afm-box-dark-gray"
                            >
                                Addetti Primo Soccorso
                            </label>

                            <span
                                className="
                                    h-6 
                                    w-6 
                                    rounded-full 
                                    bg-afm-box-light-gray 
                                    text-afm-box-dark-gray"
                            >
                                <Tooltip
                                    message="L’Addetto al Primo Soccorso può essere definito come il lavoratore preventivamente designato a compiere un insieme di azioni e interventi (pur non avendo qualifica medica) che hanno il fine di preservare la vita dell'infortunato, in attesa dell'arrivo di personale più qualificato."
                                    twBackground="bg-afm-box-blue"
                                    translateTooltip={true}
                                >
                                    <InformationCircle />
                                </Tooltip>
                            </span>
                        </div>
                        {addettiPrimoSoccorsoListBox(0)}

                        {addettiPrimoSoccorsoList.map((item, id) => {
                            return (
                                <div key={id} className="flex gap-2">
                                    <div className="flex-1">{item}</div>
                                    <div className="self-center pt-6">
                                        <BtnRemoveField onClick={removePrimoSoccorsoList} />
                                    </div>
                                </div>
                            )
                        })}

                        <BtnAddField
                            text="Aggiungi Persona"
                            onClick={addAddettiPrimoSoccorsoList}
                        />
                        <div>
                            <small>Valore
                                attuale: <strong>{getCurrentValuesLabel("addetti_primo_soccorso")}</strong></small>
                        </div>
                    </div>
                </div>
                <div className="flex gap-8 mb-3">
                    <div>
                        <div className="w-96 py-1 flex gap-3">
                            <label
                                className="mb-2 pt-1 text-left text-sm text-afm-box-dark-gray"
                            >
                                Responsabile emergenze
                            </label>
                            <span
                                className="
                                    h-6
                                    w-6
                                    rounded-full
                                    bg-afm-box-light-gray
                                    text-afm-box-dark-gray"
                            >
                                <Tooltip
                                    message="Il responsabile emergenze è la persona che valuta la natura e la gravità dell'emergenza in atto e assume la gestione e la direzione delle operazioni di emergenza."
                                    twBackground="bg-afm-box-blue"
                                    translateTooltip={true}
                                >
                                    <InformationCircle />
                                </Tooltip>
                            </span>
                        </div>

                        {ResponsabileEmergenzeListBox}
                        <div>
                            <small>Valore attuale: <strong>{getCurrentValuesLabel("responsabile_emergenze")}</strong></small>
                        </div>
                    </div>
                    <div>
                        <div className="w-96 py-1 flex gap-3">
                            <label
                                className="mb-2 pt-1 text-left text-sm text-afm-box-dark-gray"
                            >
                                Sostituto responsabile emergenze
                            </label>
                            <span
                                className="
                                    h-6
                                    w-6
                                    rounded-full
                                    bg-afm-box-light-gray
                                    text-afm-box-dark-gray"
                            >
                                <Tooltip
                                    message="Il sostituto responsabile emergenze è la persona che, in mancanza del responsabile emergenze, valuta la natura e la gravità dell'emergenza in atto e assume la gestione e la direzione delle operazioni di emergenza."
                                    twBackground="bg-afm-box-blue"
                                    translateTooltip={true}
                                >
                                    <InformationCircle />
                                </Tooltip>
                            </span>
                        </div>

                        {SostitutoResponsabileEmergenzeListBox}
                        <div>
                            <small>Valore attuale: <strong>{getCurrentValuesLabel("sostituto_responsabile_emergenze")}</strong></small>
                        </div>
                    </div>
                </div>
                <div className="flex gap-8 mb-8">
                    <div className="w-96">
                        <div className="w-96 py-1 flex gap-3">
                            <label
                                className="mb-2 pt-1 text-left text-sm text-afm-box-dark-gray"
                            >
                                Preposto
                            </label>

                            <span
                                className="
                        h-6 
                        w-6 
                        rounded-full 
                        bg-afm-box-light-gray 
                        text-afm-box-dark-gray"
                            >
                                <Tooltip
                                    message="È la persona che, in ragione delle competenze professionali e di poteri gerarchici e funzionali adeguati alla natura dell’incarico conferitogli, sovraintende all’attività lavorativa e garantisce l’attuazione delle direttive ricevute, controllandone la corretta esecuzione da parte dei lavoratori ed esercitando un funzionale potere di iniziativa."
                                    twBackground="bg-afm-box-blue"
                                    translateTooltip={true}
                                >
                                    <InformationCircle />
                                </Tooltip>
                            </span>
                        </div>
                        {prepostoListBox(0)}

                        {prepostoList.map((item, id) => {
                            return (
                                <div key={id} className="flex gap-2">
                                    <div className="flex-1">{item}</div>
                                    <div className="self-center pt-6">
                                        <BtnRemoveField onClick={removePrepostoList} />
                                    </div>
                                </div>
                            )
                        })}

                        <BtnAddField text="Aggiungi Persona" onClick={addPrepostoList} />

                        <div>
                            <small>Valore attuale: <strong>{getCurrentValuesLabel("preposto")}</strong></small>
                        </div>
                    </div>
                    <div className="w-96">
                        <div className="w-96 py-1 flex gap-3">
                            <label
                                className="mb-2 pt-1 text-left text-sm text-afm-box-dark-gray"
                            >
                                Dirigenti
                            </label>

                            <span
                                className="
                        h-6 
                        w-6 
                        rounded-full 
                        bg-afm-box-light-gray 
                        text-afm-box-dark-gray"
                            >
                                <Tooltip
                                    message="È la persona che, in ragione delle competenze professionali e di poteri gerarchici e funzionali adeguati alla natura dell’incarico conferitogli, attua le direttive del datore di lavoro organizzando l’attività lavorativa e vigilando su di essa."
                                    twBackground="bg-afm-box-blue"
                                >
                                    <InformationCircle />
                                </Tooltip>
                            </span>
                        </div>
                        {dirigentiListBox(0)}

                        {dirigentiList.map((item, id) => {
                            return (
                                <div key={id} className="flex gap-2">
                                    <div className="flex-1">{item}</div>
                                    <div className="self-center pt-6">
                                        <BtnRemoveField onClick={removeDirigentiList} />
                                    </div>
                                </div>
                            )
                        })}

                        <BtnAddField text="Aggiungi Persona" onClick={addDirigentiList} />

                        <div>
                            <small>Valore attuale: <strong>{getCurrentValuesLabel("dirigenti")}</strong></small>
                        </div>
                    </div>
                </div>
                <Button
                    text="Salva modifiche"
                    twTextColor="text-white"
                    twBackground="bg-afm-box-blue  float-left mr-3"
                    twFocusBG="focus:bg-afm-box-blue/[0.5]"
                    actions={[saveOrganigramma]}
                />
                <Button
                    text="Stampa"
                    twTextColor="text-white"
                    twBackground="bg-afm-box-dark-gray px-3 float-left"
                    twFocusBG="focus:bg-afm-box-gray/[0.7]"
                    actions={[downloadOrganigrammaById({ id: id, fileName: "organigrammainsediamento_" + organigrammaItems.citta + ".pdf" })]}
                />
            </LayoutDetailContainer>
        </Fragment>
    )
}

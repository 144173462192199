import { Fragment } from "react";

/**
 * 
 * @dev Container layout inside the LayoutDetail view.
 *
 * @function LayoutDetailContainer
 *
 * @param { JSX.Element } children
 * 
 * @returns JSX.Element
 *
 */
export default function LayoutDetailContainer({ children }) {
    return (
        <Fragment>
            {/*<section className="px-10 py-10">*/}
            <section
                className="
                    px-2 sm:px-5 xl:px-10  
                    py-10 
                    grow 
                    w-full"
            >
                {children}
            </section>
        </Fragment>
    )
}

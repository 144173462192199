/**
 *
 * @notice OrganigrammaSlice to handle state of Organigramma globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 */

// Redux.
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
// Axios.
import axios from "axios"
// gmapi.
import { API_KEY, listaInsediamentiAPI, downloadOrganigrammaAPI, listaOrganigrammaAPI, salvaOrganigrammaAPI } from "../../../common/gmApi"
// cookie.
import { tkbox, getCookie } from "../../../common/cookie"
// util.
import { downloadDocument, showDocument } from "../../../common/util"

/********************************************
 *                                          *
 * @notice AsyncThunk API(s) Implementation *
 *                                          *
 ********************************************/
/**
 *
 *  @dev Get Organigramma data.
 *
 */

export const getOrganigramma = createAsyncThunk(
    // Action route.
    'organigramma/getOrganigramma',
    //
    // Async Function with axios http req.
    //
    // First param: null.
    //
    // Second param: "thunkAPI" callback function that should return a promise 
    // handle with extraReducers to update the State.
    // It takes two parameter too: first is the value of the dispatched action, 
    // and the second is the Thunk API config.
    //
    async (id, thunkAPI) => {
        // console.log('getOrganigramma: ', id);
        try {
            const response = await axios.get(
                // url.
                listaOrganigrammaAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id: id
                    }
                }
            );

            if ((response.status === 200) && (response.data.gmapi.response.status === 'ok')) {
                return response.data;
            } else {
                return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0]);
            };

        } catch (error) {
            console.error('Error "getOrganigramma":', error.message);
            return thunkAPI.rejectWithValue(error.message);
        }
    }

);


export const salvaOrganigramma = createAsyncThunk(
    'organigramma/saveOrganigramma',

    async (body , thunkAPI) => {
        try {
            let params = {
                apikey: API_KEY,
                tkbox: getCookie(tkbox),
            }
            for(let key in body)
                params[key] = body[key] ? (Array.isArray(body[key]) ? body[key].join('|') : body[key]) : '';

            // console.log('params: ',params);

            const response = await axios.post(
                salvaOrganigrammaAPI,
                { "a": "b"},
                {
                    params: params
                }
            );

            if ((response.status === 200) && (response.data.gmapi.response.status === 'ok')) {
                return response.data;
            } else {
                return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0]);
            };

        } catch (error) {
            console.error('Error "saveOrganigramma":', error.message);
            return thunkAPI.rejectWithValue(error.message);
        }
    }

);


/**
 *
 *  @dev Get Insediamenti data.
 *
 */
export const getOrganigrammaInsediamenti = createAsyncThunk(
    // Action route.
    "organigramma/getOrganigrammaInsediamenti",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                listaInsediamentiAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    //console.error('Error "getOrganigrammaInsediamenti":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "getOrganigrammaInsediamenti":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "getOrganigrammaInsediamenti":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)
/**
 *
 *  @dev Get Employee img by id.
 *
 */
export const downloadOrganigrammaById = createAsyncThunk(
    // Action route.
    "organico/downloadOrganigrammaById",
    async ({ id, fileName }, thunkAPI) => {
        // console.log({ downloadOrganigrammaById: id })
        try {
            const response = await axios.get(
                // url.
                downloadOrganigrammaAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id: id
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    //console.error('Error "downloadOrganigrammaById":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "downloadOrganigrammaById":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "downloadOrganigrammaById":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)
/**
 *
 *  @dev Create "organigrammaSlice" feature.
 *
 */
/********************************************
 *                                          *
 * @notice createSlice implementation.      *
 *                                          *
 * @dev Create "organigrammaSlice" feature. *
 * @dev UI views state controller.          *
 *                                          *
 ********************************************/
export const organigrammaSlice = createSlice({
    // Name, used in action types.
    name: "organigramma",
    // The initial state for the reducer.
    initialState: {
        organigrammaItems: [], // All organigramma items.
        isFetching: false,     // AsyncThunk is calling an API.
        isSuccess: false,      // AsyncThunk is success from API call.
        isError: false,        // AsyncThunk is fail from API call.
        errorMsg: "",          // Error message container.
        organigrammaPDFItems: [], // All organigramma items.
        organigrammaPDFName: "",
        isFetchingPDF: false,     // AsyncThunk is calling an API.
        isSuccessPDF: false,      // AsyncThunk is success from API call.
        isErrorPDF: false,        // AsyncThunk is fail from API call.
    },
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {},
    // AsyncThunk Reducers.
    // A "builder callback" function used to add more reducers, or
    // an additional object of "case reducers", where the keys should be other
    // action types.
    extraReducers: (builder) => {
        builder
            /*******************************************************************
             *
             * @dev getOrganigramma
             *
             */
            .addCase(getOrganigramma.fulfilled, (state, action) => {
                state.isFetching = false
                state.isSuccess = true

                // Update organigrammaItems sort by `data_ora_inserimento` key.
                const organigrammaItems = action.payload.gmapi.response.data
                // console.log('GET ORGANIGRAMMA ITEMS FROM SLICE 2', organigrammaItems);
                state.organigrammaItems = Object.assign({}, organigrammaItems)
            })
            .addCase(getOrganigramma.pending, (state) => {
                state.isFetching = true
            })
            .addCase(getOrganigramma.rejected, (state, action) => {
                state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
            })
            /*******************************************************************
             *
             * @dev getOrganigrammaInsediamenti.
             *
             */
            .addCase(getOrganigrammaInsediamenti.fulfilled, (state, action) => {
                state.isFetching = false
                state.isSuccess = true

                // Update organigrammaItems sort by `data_ora_inserimento` key.
                const organigrammaItems = action.payload.gmapi.response.data
                // console.log('GET ORGANIGRAMMA ITEMS FROM SLICE fulfilled', organigrammaItems);
                state.organigrammaItems = organigrammaItems.sort((firstEl, secondEl) => (
                    firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                ))

            })
            .addCase(getOrganigrammaInsediamenti.pending, (state) => {
                state.isFetching = true
            })
            .addCase(getOrganigrammaInsediamenti.rejected, (state, action) => {
                state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
            })
            /*******************************************************************
             * 
             * @dev downloadOrganigrammaById.
             * 
             */
            .addCase(downloadOrganigrammaById.fulfilled, (state, action) => {
                state.isFetchingPDF = false
                state.isSuccessPDF = true

                // Update the state with the file name from action payload.
                state.organigrammaPDFItems = action.payload.gmapi.response.data
                // // Update the state with the file name.
                state.organigrammaPDFName = action.meta.arg.fileName

                // Document download trigger.
                downloadDocument(state.organigrammaPDFItems, state.organigrammaPDFName)
            })
            .addCase(downloadOrganigrammaById.pending, (state, action) => {
                state.isFetchingPDF = true
                // Update the state with doc id.
                // state.employeeListSenderId = action.meta.arg.idBoxVisitaLavoratore
            })
            .addCase(downloadOrganigrammaById.rejected, (state, action) => {
                state.isFetchingPDF = false
                state.isErrorPDF = true
                //state.errorMsgList = action.payload;
            })
            .addCase(salvaOrganigramma.fulfilled, (state, action) => {
                state.isFetching = false
                state.isSuccess = true
                // console.log(action.payload.gmapi.response.data);
                const organigrammaItems = action.payload.gmapi.response.data;
                state.organigrammaItems = organigrammaItems;
                // console.log(organigrammaItems);
                // state.organigrammaItems = organigrammaItems.sort((firstEl, secondEl) => (
                //     firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                // ))
            })
            
    },
})

// Export the reducer.
export const { clearState } = organigrammaSlice.actions

// Export the Slice by name.
export const organigrammaSelector = (state) => state.organigramma

// React
import { useEffect } from "react"
// Hook Form.
import { useForm } from "react-hook-form"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// DocumentoSlice.
import {
    documentoModalSelector,
    closeModal,
    uploadDocumento,
    clearState,
} from "../../features/generale/organico/DocumentoModalSlice"
// UI.
import Modal from "../../base/Modal"
import DocUploader from "../../base/DocUploader"
import Tooltip from "../../base/Tooltip"
// Icons.
import { OvalLoader, InformationCircle } from "../../base/Icons"

import SearchSelect from '../../base/SearchSelect';
import Select from 'react-select';

const dayPlaceholder = 'giorno',
    monthPlaceholder = 'mese',
    yearPlaceholder = 'anno';

const daysOptions = (props) => {
    if (!props.numberDays) return
    const options = []
    for (let i = 1; i <= props.numberDays; i++) {
        options.push({ value: i.toString(), label: i.toString() })
    }
    // options.unshift( { value: '', label: dayPlaceholder, disabled: true } )
    return options
}

const monthsOptions = (props) => {
    if (!props.numberMonths) return
    const options = []
    for (let i = 1; i <= props.numberMonths; i++) {
        options.push({ value: i.toString(), label: i.toString() })
    }
    // options.unshift( { value: '', label: monthPlaceholder, disabled: true } )
    return options
}

const yearsOptions = (props) => {
    if (!props.numberYears) return

    const offset = props.offset ? -1 : 1

    const options = []
    for (let i = 0; i <= props.numberYears; i++) {
        options.push({ value: (new Date().getFullYear() + i * offset).toString(), label: (new Date().getFullYear() + i * offset).toString() })
    }
    // options.unshift( { value: '', label: yearPlaceholder, disabled: true } )
    return options
}

/**
 * 
 * @dev DocumentoModal UI Component.
 *
 * @function DocumentoModal
 *
 * @returns JSX.Element
 *
 */
export default function DocumentoModal(props) {
    const dispatch = useDispatch()

    // Boolean State to know if the form has Error.
    //const [formhasErrors,setFormhasErrors] = useState(false);

    /**
     *
     *  Initialize a React hook form State using UseForm Hook.
     *
     * @see https://react-hook-form.com/get-started
     *
     */
    const {
        register,
        handleSubmit,
        //setError,
        //clearErrors,
        formState,
        setValue,
        //formState: { errors } // subscription.
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {
            documentoOrganicoId: props.id || '0',
            documentoDay: "",
            documentoMonth: "",
            documentoYear: "",
            scadenzaDocumentoDay: "",
            scadenzaDocumentoMonth: "",
            scadenzaDocumentoYear: "",
            tipoDocumento: "",
            noteDocumento: "",
            allegato: "",
            formErrors: "",
        },
    }) // UseForm Hook.

    const { isDirty, isValid } = formState // Get the form state.

    // documentoModalSlice State.
    const { modalIsFetching, modalIsSuccess, modalIsError, modalErrorMsg } =
        useSelector(documentoModalSelector)

    /**
     *
     *  onSubmit Form handler.
     *  Post data to insert a new document.
     */
    // FIXME: Procedure incomplete.
    const onSubmit = (data) => {
        const documentoToUpload = {
            organicoId: data.documentoOrganicoId,
            data: `${data.documentoYear}-${data.documentoMonth}-${data.documentoDay}`,
            scadenza: `${data.scadenzaDocumentoYear}-${data.scadenzaDocumentoMonth}-${data.scadenzaDocumentoDay}`,
            tipoDocumento: data.tipoDocumento,
            note: data.noteDocumento,
            allegato: data.allegato,
        }

        if (isValid) {
            dispatch(uploadDocumento(documentoToUpload))
        }
    }

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        if (modalIsSuccess) {
            dispatch(closeModal()) // Close Modal.

            dispatch(clearState()) // Clean up the UserSlice State.
        }
    }, [dispatch, modalIsFetching, modalIsSuccess, modalIsError, modalErrorMsg, formState])

    const days = daysOptions({ numberDays: 31 })
    const months = monthsOptions({ numberMonths: 12 })
    const years = yearsOptions({ numberYears: 5, offset: true })
    const yearsAfter = yearsOptions({ numberYears: 100, offset: false })

    const documentoDay = register('documentoDay', { required: true })
    const documentoMonth = register('documentoMonth', { required: true })
    const documentoYear = register('documentoYear', { required: true })

    const scadenzaDocumentoDay = register('scadenzaDocumentoDay', { required: true })
    const scadenzaDocumentoMonth = register('scadenzaDocumentoMonth', { required: true })
    const scadenzaDocumentoYear = register('scadenzaDocumentoYear', { required: true })

    /**
     *
     * Component render.
     *
     */
    return (
        <Modal title="Nuovo Documento">
            <form action="" className="p-4" onSubmit={handleSubmit(onSubmit)} method="POST" encType="multipart/form-data">
                <input type="hidden" {...register("documentoOrganicoId", {
                    //required: true,
                })} />
                <div className="flex gap-3">
                    <div
                        className="
                            flex flex-row 
                            text-left 
                            items-center justify-center 
                            mt-6 mb-2"
                    >
                        <label
                            htmlFor="documentoDay"
                            className="
                                mb-2 
                                pr-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Data Documento<top className="text-red-500 ml-1">*</top>
                        </label>

                    </div>
                </div>

                <div className="inline-flex space-x-4 w-full">
                   
                    <Select
                        {...register("documentoDay")}
                        options={days || []}
                        onChange={(selected) => {
                            setValue("documentoDay", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={dayPlaceholder}
                        className="relative w-full"
                        name="documentoDay"
                    />
                    <Select
                        {...register("documentoMonth")}
                        options={months || []}
                        onChange={(selected) => {
                            setValue("documentoMonth", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={monthPlaceholder}
                        className="relative w-full"
                        name="documentoMonth"
                    />
                    <Select
                        {...register("documentoYear")}
                        options={years || []}
                        onChange={(selected) => {
                            setValue("documentoYear", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={yearPlaceholder}
                        className="relative w-full"
                        name="documentoYear"
                    />
                </div>

                <div className="flex gap-3">
                    <div
                        className="
                            flex flex-row 
                            text-left items-center justify-center 
                            mt-6 mb-2"
                    >
                        <label
                            htmlFor="documentoDay"
                            className="
                                mb-2 
                                pr-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Data Scadenza<top className="text-red-500 ml-1">*</top>
                        </label>


                    </div>
                </div>
                <div className="inline-flex space-x-4 w-full">
                      <Select
                        {...register("scadenzaDocumentoDay")}
                        options={days || []}
                        onChange={(selected) => {
                            setValue("scadenzaDocumentoDay", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={dayPlaceholder}
                        className="relative w-full"
                        name="scadenzaDocumentoDay"
                    />
                    <Select
                        {...register("scadenzaDocumentoMonth")}
                        options={months || []}
                        onChange={(selected) => {
                            setValue("scadenzaDocumentoMonth", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={monthPlaceholder}
                        className="relative w-full"
                        name="scadenzaDocumentoMonth"
                    />
                    <Select
                        {...register("scadenzaDocumentoYear")}
                        options={yearsAfter || []}
                        onChange={(selected) => {
                            setValue("scadenzaDocumentoYear", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={yearPlaceholder}
                        className="relative w-full"
                        name="scadenzaDocumentoYear"
                    />
                </div>

                <div className="block mt-6 mb-2">
                    <div className="flex-col text-left mt-6 mb-2">
                        <label htmlFor="tipoDocumento">Tipologia del documento<top className="text-red-500 ml-1">*</top></label>
                    </div>
                    {/* <select
                        type="text"
                        name="tipoDocumento"
                        className="
                            w-full 
                            px-3 
                            py-2 
                            bg-afm-box-light-gray 
                            text-afm-box-dark-gray 
                            placeholder-afm-box-dark-gray/[.50] 
                            border border-gray-300 rounded-md  
                            focus:outline-none 
                            focus:ring 
                            focus:ring-indigo-100 
                            focus:border-indigo-300"
                        {...register("tipoDocumento", {
                            //required: true,
                        })}
                    >
                        <option value="CARTA D'IDENTITÀ">CARTA D’IDENTITÀ</option>
                        <option value="UNI LAV">UNI LAV</option>
                        <option value="TESSERA SANITARIA">TESSERA SANITARIA</option>
                        <option value="PASSAPORTO">PASSAPORTO</option>
                        <option value="PERMESSO DI SOGGIORNO">PERMESSO DI SOGGIORNO</option>
                        <option value="CONSEGNA DPI">CONSEGNA DPI</option>
                        <option value="SCHEDA INSERIMENTO PERSONALE">SCHEDA INSERIMENTO PERSONALE</option>
                        <option value="CONTRATTO DI LAVORO">CONTRATTO DI LAVORO</option>
                        <option value="REGOLAMENTO AZIENDALE">REGOLAMENTO AZIENDALE</option>
                    </select> */}
                    <Select
                        {...register("tipoDocumento")}
                        options={[
                            { value: 'CARTA D’IDENTITÀ', label: 'CARTA D’IDENTITÀ' },
                            { value: 'UNI LAV', label: 'UNI LAV' },
                            { value: 'TESSERA SANITARIA', label: 'TESSERA SANITARIA' },
                            { value: 'PASSAPORTO', label: 'PASSAPORTO' },
                            { value: 'PERMESSO DI SOGGIORNO', label: 'PERMESSO DI SOGGIORNO' },
                            { value: 'CONSEGNA DPI', label: 'CONSEGNA DPI' },
                            { value: 'SCHEDA INSERIMENTO PERSONALE', label: 'SCHEDA INSERIMENTO PERSONALE' },
                            { value: 'CONTRATTO DI LAVORO', label: 'CONTRATTO DI LAVORO' },
                            { value: 'REGOLAMENTO AZIENDALE', label: 'REGOLAMENTO AZIENDALE' }
                        ] || []}
                        onChange={(selected) => {
                            setValue("tipoDocumento", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={'Seleziona tipologia'}
                        className="relative w-full"
                        name="tipoDocumento"
                    />
                </div>

                <div className="block mt-6 mb-2">
                    <div className="flex-col text-left mt-6 mb-2">
                        <label htmlFor="noteDocumento">Note</label>
                    </div>
                    <input
                        type="text"
                        name="noteDocumento"
                        placeholder="Inserisci qui note del documento"
                        className="
                            w-full 
                            px-3 
                            py-2 
                            bg-afm-box-light-gray 
                            text-afm-box-dark-gray 
                            placeholder-afm-box-dark-gray/[.50] 
                            border border-gray-300 rounded-md  
                            focus:outline-none 
                            focus:ring 
                            focus:ring-indigo-100 
                            focus:border-indigo-300"
                        {...register("noteDocumento", {
                            //required: true,
                        })}
                    />
                </div>

                <div className="flex-col text-left mt-6 mb-0">
                    <label>Carica Documento<top className="text-red-500 ml-1">*</top></label>
                </div>
                <div className="block">
                    <DocUploader name="allegato"
                        onChange={(file) => {
                            setValue('allegato', file);
                        }}
                    />
                </div>

                <div className="flex mt-10">
                    <div className="flex-1">
                        <button
                            type="button"
                            className="
                                flex 
                                gap-1 
                                w-auto 
                                px-7 
                                h-10 
                                items-center justify-center
                                uppercase whitespace-nowrap font-semibold 
                                text-white 
                                bg-afm-box-dark-gray
                                hover:opacity-80 
                                focus:outline-none"
                            onClick={() => {
                                dispatch(closeModal())
                            }}
                        >
                            annulla
                        </button>
                    </div>
                    <button
                        type="submit"
                        value="Submit"
                        disabled={!isDirty || !isValid}
                        className="
                            flex 
                            w-auto 
                            px-7 
                            h-10 
                            gap-1 
                            items-center justify-center 
                            uppercase whitespace-nowrap font-semibold 
                            text-white 
                            bg-afm-box-blue 
                            hover:opacity-80 
                            focus:outline-none"
                    >
                        {modalIsFetching && <OvalLoader />} procedi
                    </button>
                </div>

                {modalIsError && (
                    <div
                        className="
                            h-8 
                            inline-flex 
                            items-center justify-items-center 
                            mt-2 
                            px-20 
                            bg-afm-box-error-red/10 
                            text-afm-box-error-red 
                            border border-afm-box-error-red rounded-md"
                    >
                        <p>{modalErrorMsg}</p>
                    </div>
                )}
            </form>
        </Modal>
    )
}

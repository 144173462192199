/**
 *
 * @notice CorsoModalSlice to handle state of CorsoModal globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 */

// Redux.
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
// Axios.
import axios from "axios"
// gmapi.
import { API_KEY, caricaCorsoAPI } from "../../../../common/gmApi"
// cookie.
import { tkbox, getCookie } from "../../../../common/cookie"

/********************************************
 *                                          *
 * @notice AsyncThunk API(s) Implementation *
 *                                          *
 ********************************************/

/**
 *
 *  @dev Upload a new corso.
 *
 */
export const uploadCorso = createAsyncThunk(
    "corso/uploadCorso",
    async ({ organicoId, data, allegato, tipologiaCorso }, thunkAPI) => {
        try {
            var bodyFormData = new FormData()
            bodyFormData.append('allegato', allegato, allegato.name)

            const response = await axios.post(
                // url.
                caricaCorsoAPI,
                // body.
                bodyFormData,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        organicoId: organicoId,
                        data: data,
                        tipologiaCorso: tipologiaCorso,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    //return console.log(response.data);
                    return response.data
                } else {
                    // else reject gmapi response error.
                    //console.error('Error "showEmployeeCourseDocById":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "uploadCorso":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            // If something get wrong then reject the error.
            console.error('Error "uploadCorso":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/*******************************************
 *                                         *
 * @notice createSlice implementation.     *
 *                                         *
 * @dev Create a "corsoModalSlice" feature. *
 * @dev UI views state controller.         *
 *                                         *
 *******************************************/
export const corsoModalSlice = createSlice({
    name: "corsoModal",
    initialState: {
        modalIsOpen: false,     // Modal is open or not.
        modalIsFetching: false, // AsyncThunk is calling an API.
        modalIsSuccess: false,  // AsyncThunk is success from API call.
        modalIsError: false,    // AsyncThunk is fail from API call.
        modalErrorMsg: "",      // Error message container.
    },
    reducers: {
        clearState: (state) => {
            state.modalIsError = false
            state.modalIsSuccess = false
            state.modalIsFetching = false

            return state
        },
        openModal: (state) => {
            state.modalIsOpen = true
        },
        closeModal: (state) => {
            state.modalIsOpen = false
        },
    },
    extraReducers: (builder) => {
        builder
            /**
             * @dev uploadCorso.
             */
            .addCase(uploadCorso.fulfilled, (state) => {
                state.modalIsFetching = false
                state.modalIsSuccess = true
            })
            .addCase(uploadCorso.pending, (state) => {
                state.modalIsFetching = true
            })
            .addCase(uploadCorso.rejected, (state, action) => {
                state.modalIsFetching = false
                state.modalIsError = true
                state.modalErrorMsg = action.payload
            })
    },
})

export const { clearState, openModal, closeModal } = corsoModalSlice.actions
export const corsoModalSelector = (state) => state.corsoModal
// React.
import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { Camera } from "./Icons"

/**
 *
 * @dev Handle img uploading process.
 * 
 * @function ImgUploader
 * 
 * @param { Base64 } img
 * @param { string } id
 * @param { * } action
 * 
 * 
 * 
 * @returns JSX.Element
 *
 */
export default function ImgUploader(props) {

    // Get the ref of the file input html element to trigger it's click event.
    const fileInput = useRef(null)

    // Init imgIsChanged flag.
    // State to know if render the file uploader component.
    const [imgIsChanged, setImgIsChanged] = useState(false)   
    // State to know if render the file uploader component.
    const [imageProfile, setImageProfile] = useState(null)

    /**
     *
     * @dev Uploader UI Component.
     * 
     * @function Uploader
     * @returns JSX.Element
     *
     */
    function Uploader(props) {
        const dispatch = useDispatch()

        /**
         * 
         * @dev upload an image.
         * 
         * @function uploadImg
         * 
         * @param { SyntheticBaseEvent } event  
         * 
         * @returns void
         * 
         */
        function uploadImg(event) {

            // Create file variable.
            let file
            // File size limit in bytes.
            const fileSizeLimitInBytes = 1048576
            // Width and height size limit in pixels.
            const widthSizeLimit = 1000
            const heightSizeLimit = 1000

            // Create an image instance.
            let img = new Image()

            // Get the files from the target event.
            const files = event.target.files;

            // If files is empty alert, else get a file.
            if (files.length === 0) {
                alert("Please choose an image file…")
                console.error("Please choose an image file…")
            } else {
                file = event.target.files[0]
            }

            // Allowed file types.
            const allowedFileTypes = ["image/png", "image/jpeg"]
            // Allows only PNG, and JPEG images.
            const fileIsAnImage = Array.from(files).every((file) =>
                allowedFileTypes.includes(file.type)
            )
            
            // Create a blob url with uploaded image.
            // Assign to the `img.src` property the uploaded file.
            img.src = window.URL.createObjectURL(file)
            
            img.onload = () => {
                // File checks.
                if(
                    (img.width <= widthSizeLimit || img.height <= heightSizeLimit) && // images size are 1000x1000 or less.
                    (fileIsAnImage) &&                                                // Are images
                    file.size < fileSizeLimitInBytes                                  // 1MB Limit. 1MB = 1048576 bytes.
                  ){
                        //alert(`The Image is the right size. It can be uploaded!`)
                        
                        //
                        // Upload logic.
                        //
                        props.setNewImg(img.src) // Fire `setNewImg` to update parent state.
                        props.setImgIsChanged(false) // Fire `setImgIsChanged` to update parent state.
                        // Update factory image calling API.
                        // If props.id is not populated then fire the action with one parameter, else with an object.
                        if (props.id === undefined) {
                            dispatch(props.action(file))
                        } else {
                            dispatch(props.action({
                                newLogo: file,
                                id: props.id
                            }))
                        }
                   } else {
                        alert(`The file is not an image or the image doesn't look like the size as we wanted. 
                        It's ${img.width}x${img.height}px, but we require 1000x1000px or less.
                        It's ${(file.size / fileSizeLimitInBytes).toFixed(2)} MB, but we require max 1MB.`)
                }
             }
        }

        return (
            <div
                className="
                    z-10 
                    h-40 
                    w-40 
                    absolute 
                    text-center 
                    bg-white opacity-90"
            >
                <div
                    className="
                        z-20 
                        h-full 
                        py-10 
                        inline-block 
                        align-middle 
                        opacity-100 text-xs"
                >
                    <p className="text-black">Carica Logo</p>
                    <div
                        className="
                            relative 
                            overflow-hidden 
                            outline 
                            outline-1 
                            my-4 
                            p-2 
                            text-black"
                    >
                        <input
                            type="file"
                            name="picture"
                            accept=".jpg, .JPG, .jpeg, .png, .PNG"
                            ref={fileInput}
                            //size=""
                            onChange={(event) => uploadImg(event)}
                            className="absolute top-0 right-0 cursor-pointer opacity-0 w-full h-full"
                        />
                        
                        <button onClick={ (event) => fileInput.current && fileInput.current.click() }>
                            Scegli file...
                        </button>
                    </div>

                    <p className="whitespace-normal text-afm-box-dark-gray">
                        Formato: .jpg, .png <br />
                        Dimensioni: 1000x1000 px - 1MB max
                    </p>
                </div>
            </div>
        )
    }

    /**
     *
     * Component side effects.
     *
     */
     useEffect(() => { },[imageProfile])


    /**
     *
     * Component render.
     *
     */
    return (
        <div className="flex flex-col items-center">
            <div
                className="
                    h-40 
                    w-40 
                    flex 
                    items-center justify-center 
                    border border-dashed border-afm-box-light-gray-text 
                    text-afm-box-light-gray-text"
                //onMouseOver={ () => setIsHovering(true) }
                //onMouseOut={ () => setIsHovering(false) }
            >
                {imgIsChanged && 
                    <Uploader 
                        setNewImg={setImageProfile} 
                        setImgIsChanged={setImgIsChanged}
                        id={props.id}
                        action={props.action}
                    />
                }

                {(props.img.length) || (imageProfile !== null) ?
                    <img src={imageProfile ? imageProfile : props.img} alt="avatar" className="bg-cover" />
                    :
                    <Camera/>
                }

            </div>
            <p 
                className="mt-6 text-xs underline cursor-pointer hover:text-afm-box-green"
                as="button"
                onClick={() => setImgIsChanged(!imgIsChanged)}
            >
                {(props.img.length) || (imageProfile) ? ('Cambia ' + (props.label ? props.label : 'logo azienda')) : 'Carica ' + (props.label ? props.label : 'logo azienda')}
            </p>
        </div>
    )
}

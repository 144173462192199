/**
 *
 * @notice SearchSlice to handle state of the Search globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 * @dev It use js search library to perform a search.
 *
 */

// Redux.
import { createSlice } from "@reduxjs/toolkit"
// js-search.
import * as JsSearch from "js-search"

const initialState = {
    // returned: []
    searchList: [],    // Pure list.
    search: [],        // JsSearch list.
    searchResults: [], // Search result.
    isLoading: false,  // Search js is loading.
    isError: false,    // Search js is error.
    searchQuery: "",   // Search keyword.
};


/**
 *
 * @dev Create "searchSlice" feature.
 *
 */
export const searchSlice = createSlice({
    // Name, used in action types.
    name: "search",
    // The initial state for the reducer.
    // initialState: {
    //     searchList: [],    // Pure list.
    //     search: [],        // JsSearch list.
    //     searchResults: [], // Search result.
    //     isLoading: false,  // Search js is loading.
    //     isError: false,    // Search js is error.
    //     searchQuery: "",   // Search keyword.
    // },
    initialState,
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {
        /**
         *
         * js-search Library initialization.
         *
         *
         */
        setSearchList: (state, action) => {
            // Set data to search.
            const dataToSearch = new JsSearch.Search(action.payload.searchIndex)

            /**
             *
             * Defines an indexing strategy for the data.
             * @see https://github.com/bvaughn/js-search#configuring-the-index-strategy
             *
             */
            // default.
            dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
            //dataToSearch.indexStrategy = new JsSearch.ExactWordIndexStrategy(); // this index strategy is built for exact word matches.

            /**
             *
             * Defines the sanitizer for the search
             * to prevent some of the words from being excluded.
             *
             */
            dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()

            /**
             *
             * Defines the search index.
             * @see https://github.com/bvaughn/js-search#configuring-the-search-index
             *
             */
            dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex(action.payload.searchIndex)

            dataToSearch.addIndex(action.payload.searchIndex) // Sets the index attribute for the data.

            // Only one additional index.
            //dataToSearch.addIndex(action.payload.additionalIndex[1]); // Sets the index attribute for the data.

            // More additional index.
            if (action.payload.additionalIndex.length > 0) {
                // For each additionalIndex sets the index attribute for the data.
                action.payload.additionalIndex.map((index) => dataToSearch.addIndex(index))
            }

            dataToSearch.addDocuments(action.payload.dataItems) // Adds the data to be searched.

            // Then return state.
            return {
                ...state,
                searchList: action.payload.dataItems,
                search: dataToSearch,
                isLoading: false,
            }
        },
        /**
         *
         * Handles the input change and perform a search with js-search
         * in which the results will be added to the state.
         *
         */
        searchData: (state, action) => {
            //const queryResult = state.search.search(action.payload)
            const queryResult = state.search.search(action.payload)

            // Then return state.
            return {
                ...state,
                searchQuery: action.payload,
                searchResults: queryResult,
            }
        },
        reset() {
            return {
                ...initialState
            }
        }
    },
    // AsyncThunk Reducers.
    //extrareducers: {}
})

// Export the reducer.
export const { setSearchList, searchData, reset } = searchSlice.actions

// Export the Slice by name.
export const searchSelector = (state) => state.search

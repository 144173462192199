// React.
import { useState, useEffect, useContext, Fragment } from "react"
// Router.
import { NavLink, useNavigate } from "react-router-dom"
// Context.
import { SideBarMenuContext } from "./SideBarMenu"
// UI.
import SideBarMenuTooltip from "./SideBarMenuTooltip"
// Icons.
import { ChevronDown, ChevronUp, Nbsp } from "../base/Icons"

/**
 *
 * @function SectionBtn
 * 
 * @param { string } text
 * @param { JSX.Element } icon
 * @param { JSX.Element } arrowIndicator
 * @param { string } route
 * @param { string } tooltipMessage
 * @param { bool } showNotificationBadge
 * @param { JSX.Element } children
 * 
 * @returns JSX.Element
 * 
 */
export default function SectionBtn(props) {
    const navigate = useNavigate()
    const notificationNumber = props?.notificationNumber === undefined ? 0 : props.notificationNumber;

    // Use Context to know if the sideBarMenu is open or not.
    const sideBarIsOpen = useContext(SideBarMenuContext)

    // Flag to know how chevron icon render.
    const [toggleIsOpen, setToggleIsOpen] = useState(false)

    // Flag to handle ToolTip custom visibility.
    const [showToolTip, setShowToolTip] = useState(false)

    /**
     *
     * Functoin sectionToggleCollapse.
     *
     * Expand menu sections.
     *
     * @param route string
     * @returns void
     *
     */
    function sectionToggleCollapse(route) {
        toggleIsOpen ? setToggleIsOpen(false) : setToggleIsOpen(true) // Toggle SideBar.

        if (route && !toggleIsOpen) {
            navigate(route) // Set a default route.
        }
    }

    // Expand menu sections toggle via className.
    const toggleSection = toggleIsOpen ? "block text-white" : "hidden"

    /**
     *
     * Function hasArrow.
     *
     * Check if has an arrow.
     *
     */
    function hasArrow() {
        if (props.arrowIndicator) {
            return toggleIsOpen ? (
                <ChevronUp onClick={sectionToggleCollapse} />
            ) : (
                <ChevronDown onClick={sectionToggleCollapse} />
            )
        } else if (notificationNumber > 0) {
            return (
                <div
                    className="
                            flex items-center justify-center 
                            w-7 h-7 
                            rounded-full 
                            bg-afm-box-dark-gray-opacity-80 
                            text-afm-box-green font-semibold"
                >
                    <p>{notificationNumber}</p>
                </div>
            )
        } else {
            return <Nbsp />
        }
    }

    /* NavLink active className. */
    const activeClassName = "bg-afm-box-dark-gray-opacity-90 flex items-center h-14"

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => { }, [toggleIsOpen, showToolTip]) // Update on every state changes.

    if (props.children === undefined) {
        /**
         *
         * If section menu has not a subsection, then render NavLink component with "to" prop.
         *
         */
        return (
            <li className="border-none outline-none text-white">
                <NavLink
                    className={({ isActive }) =>
                        isActive
                            ? activeClassName
                            : "bg-afm-box-dark-gray hover:bg-afm-box-dark-gray-opacity-90 flex items-center h-14"
                    }
                    to={props.route}
                    children={({ isActive }) => {
                        const activeStyle = isActive ? "bg-afm-box-green" : "bg-transparent"
                        return (
                            <Fragment>
                                <div className="inline-flex space-x-4">
                                    <span className={`${activeStyle} h-14 w-1`}></span>
                                    <div className="inline-flex items-center space-x-6">
                                        <div>
                                            {/* 
                                                Show SideBarMenuTooltip showToolTip flag is true and
                                                the SideBarMenu is not Open. 
                                            */}
                                            {showToolTip && !sideBarIsOpen && (
                                                <SideBarMenuTooltip
                                                    message={props.tooltipMessage}
                                                    twBackground="bg-black"
                                                ></SideBarMenuTooltip>
                                            )}
                                            <div
                                                as="button"
                                                onMouseEnter={() => {
                                                    showToolTip
                                                        ? setShowToolTip(false)
                                                        : setShowToolTip(true)
                                                }}
                                                onMouseLeave={() => {
                                                    showToolTip
                                                        ? setShowToolTip(false)
                                                        : setShowToolTip(true)
                                                }}
                                            >
                                                {props.icon}
                                            </div>
                                        </div>

                                        {sideBarIsOpen && (
                                            <div className="inline-flex space-x-8">
                                                <span className="w-[111px] whitespace-nowrap">
                                                    {props.text}
                                                </span>
                                                <span>{hasArrow()}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Fragment>
                        )
                    }}
                />
            </li>
        )
    } else {
        /**
         *
         * If section menu has a subsection, then render a button with onClick event.
         *
         */
        return (
            <li className="border-none outline-none">
                <div
                    as="button"
                    className={`
                        ${toggleIsOpen ? "text-afm-box-green" : "text-white"} 
                        p-5 
                        h-14 
                        w-full
                        inline-flex 
                        text-left 
                        cursor-pointer 
                        border-none 
                        outline-none 
                        bg-afm-box-dark-gray 
                        hover:bg-afm-box-dark-gray-opacity-90`}
                    onClick={() => sectionToggleCollapse(props.route)}
                >
                    <div className="inline-flex items-center space-x-6">
                        <div>
                            {/* 
                                Show SideBarMenuTooltip showToolTip flag is true and
                                the SideBarMenu is not Open. 
                            */}
                            {showToolTip && !sideBarIsOpen && (
                                <SideBarMenuTooltip
                                    message={props.tooltipMessage}
                                    twBackground="bg-black"
                                ></SideBarMenuTooltip>
                            )}
                            <div
                                as="button"
                                onMouseEnter={() => {
                                    showToolTip ? setShowToolTip(false) : setShowToolTip(true)
                                }}
                                onMouseLeave={() => {
                                    showToolTip ? setShowToolTip(false) : setShowToolTip(true)
                                }}
                            >
                                {props.icon}
                            </div>
                        </div>

                        {sideBarIsOpen && (
                            <div className="inline-flex">
                                <span className="w-[144px] whitespace-nowrap">{props.text}</span>
                                <span>{hasArrow()}</span>
                            </div>
                        )}
                    </div>
                </div>
                <ul className={`${toggleSection}`}>{props.children}</ul>
            </li>
        )
    }
}

/**
 *
 * @notice ScadenzeSlice to handle state of Scadenze globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 */

// Redux.
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit"
// Axios.
import axios from "axios"
// gmapi.
import { API_KEY,listaScadenzeAPI } from "../../../common/gmApi"
// cookie.
import { tkbox,getCookie } from "../../../common/cookie"

/********************************************
 *                                          *
 * @notice AsyncThunk API(s) Implementation *
 *                                          *
 ********************************************/

/**
 *
 *  @dev Get Scadenze data.
 *
 */
export const getScadenze = createAsyncThunk(
    // Action route.
    "scadenze/getScadenze",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                listaScadenzeAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    //console.error('Error "getScadenze":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "getScadenze":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "getScadenze":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/****************************************
 *                                      *
 * @notice createSlice implementation.  *
 *                                      *
 * @dev Create "scadenzeSlice" feature. *
 * @dev UI views state controller.      *
 *                                      *
 ****************************************/
export const scadenzeSlice = createSlice({
    // Name, used in action types.
    name: "scadenze",
    // The initial state for the reducer.
    initialState: {
        deadlinesItems: [], // All deadlines items.
        isFetching: false,  // AsyncThunk is calling an API.
        isSuccess: false,   // AsyncThunk is success from API call.
        isError: false,     // AsyncThunk is fail from API call.
        errorMsg: "",       // Error message container.
    },
    // Reducers.
    reducers: {},
    // AsyncThunk Reducers.
    // A "builder callback" function used to add more reducers, or
    // an additional object of "case reducers", where the keys should be other
    // action types.
    extraReducers: (builder) => {
        builder
            /*******************************************************************
             *
             * @dev getScadenze.
             *
             */
            .addCase(getScadenze.fulfilled,(state,action) => {
                state.isFetching = false
                state.isSuccess = true

                // Update deadlinesItems sort by `data_ora_inserimento` key.
                const deadlinesItems = action.payload.gmapi.response.data;
                state.deadlinesItems = deadlinesItems;
                // state.deadlinesItems = deadlinesItems.sort((firstEl,secondEl) => (
                //     firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                // ))
            })
            .addCase(getScadenze.pending,(state) => {
                state.isFetching = true
            })
            .addCase(getScadenze.rejected,(state,action) => {
                state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
            })
    },
})

// Export the reducer.
//export const { clearState } = scadenzeSlice.actions

// Export the Slice by name.
export const scadenzeSelector = (state) => state.scadenze

import axios from "axios";
import { getCookie, setCookie, tkbox } from "../common/cookie";
import { API_KEY } from "../common/gmApi";

export const buildThunkAPIGetRequest = async (thunkAPI, url, queryparams = {}) => {
    if (!queryparams) {
        queryparams = {}
    }
    if (!queryparams.params) {
        queryparams.params = {}
    }
    queryparams.params.apikey = queryparams?.params?.apikey || API_KEY;
    queryparams.params.tkbox = queryparams?.params?.tkbox || getCookie(tkbox);
    const response = await axios.get(url, queryparams);
    if (response.status !== 200 || response?.data?.gmapi?.auth !== "ok") {
        return thunkAPI.rejectWithValue(response.data.gmapi.error)
    };
    if (response?.data?.gmapi?.response?.status !== "ok") {
        return thunkAPI.rejectWithValue(response?.data?.gmapi?.response?.error[0])
    };
    return response.data
}

export const buildThunkAPIPostRequest = async (thunkAPI, url, body = null, queryparams = {}) => {
    if (!queryparams) {
        queryparams = {}
    }
    if (!queryparams.params) {
        queryparams.params = {}
    }
    queryparams.params.apikey = queryparams?.params?.apikey || API_KEY;
    queryparams.params.tkbox = queryparams?.params?.tkbox || getCookie(tkbox);
    const response = await axios.post(url, body, queryparams);
    if (response.status !== 200 || response?.data?.gmapi?.auth !== "ok") {
        return thunkAPI.rejectWithValue(response.data.gmapi.error)
    };
    if (response?.data?.gmapi?.response?.status !== "ok") {
        return thunkAPI.rejectWithValue(response?.data?.gmapi?.response?.error[0])
    };
    return response.data
}

export function forceUser(newToken, newExpiration) {
    if (typeof newToken !== 'string' || typeof newExpiration !== 'string') { return false }
    if (newToken.length === 0 || newExpiration.length === 0) { return false }
    setCookie(
        tkbox,
        newToken,
        newExpiration
    );
    return true
}
// React.
import { useContext, useEffect } from "react"
import { SideBarMenuContext } from "./SideBarMenu"
// UI.
import SectionBtn from "./SectionBtn"
// Icons.
import {
    ViewGrid,
    ViewList,
    User,
    LockClosed,
    //Truck,
    //OfficeBuilding,
    //Star,
    //BookOpen,
    //DocumentText,
    Logout,
    Chat,
    Exclamation,
    LocationMarker,
    Nbsp,
    Screwdriver,
} from "../base/Icons"
// Redux.
import { useSelector } from "react-redux"
// Slice.
import { comunicazioniSelector } from "../features/comunicazioni/ComunicazioniSlice"
import { scadenzeSelector } from "../features/scadenze/ScadenzeSlice"

/**
 *
 * @dev Sidebar buttons list container.
 * 
 * @function Navigator.
 *
 * @returns JSX.Element
 *
 */
export default function Navigator() {
    // Use Context to know if the sideBarMenu is open or not.
    const sideBarIsOpen = useContext(SideBarMenuContext);

    const { noticesItems } = useSelector(comunicazioniSelector);
    const { deadlinesItems } = useSelector(scadenzeSelector);
    const unreadNotices = noticesItems ? noticesItems.filter((item) => item.letto === false) : [];
    const unreadDeadlines = deadlinesItems ? deadlinesItems.filter((item) => item.letto === false) : [];

    const userManutenzione = useSelector((state) => state.user.userManutenzione);

    return (
        <div
            //max-h-[800px]
            className={`
                ${sideBarIsOpen}
                h-[100vh]
                bg-afm-box-dark-gray`}
        >
            <nav>
                <ul>
                    <SectionBtn
                        text="Panoramica"
                        icon={<ViewGrid />}
                        tooltipMessage="Panoramica"
                        route="panoramica"
                    />
                    <SectionBtn
                        text="Dati Generali"
                        icon={<ViewList />}
                        arrowIndicator={true}
                        tooltipMessage="Dati Generali"
                        route="generale/azienda"
                    >
                        <ul>
                            <SectionBtn
                                text="Dati Azienda"
                                icon={<Nbsp />}
                                tooltipMessage="azienda"
                                route="generale/azienda"
                            />
                            {(!userManutenzione) &&
                                <>
                                    <SectionBtn
                                        text="Visura"
                                        icon={<Nbsp />}
                                        tooltipMessage="visura"
                                        route="generale/visura"
                                    />
                                    <SectionBtn
                                        text="Doma"
                                        icon={<Nbsp />}
                                        tooltipMessage="doma"
                                        route="generale/doma"
                                    />
                                    <SectionBtn
                                        text="DURC"
                                        icon={<Nbsp />}
                                        tooltipMessage="DURC"
                                        route="generale/durc"
                                    />
                                    <SectionBtn
                                        text="DURF"
                                        icon={<Nbsp />}
                                        tooltipMessage="DURF"
                                        route="generale/durf"
                                    />
                                    <SectionBtn
                                        text="Organigramma"
                                        icon={<Nbsp />}
                                        tooltipMessage="organigramma"
                                        route="generale/organigramma"
                                    />
                                    {/* <SectionBtn
                                        text="Organico"
                                        icon={<Nbsp />}
                                        tooltipMessage="organico"
                                        route="generale/organico"
                                    /> */}
                                </>
                            }
                        </ul>
                    </SectionBtn>
                    {(!userManutenzione) &&
                        <>
                            <SectionBtn
                                text="Insediamenti"
                                icon={<LocationMarker />}
                                tooltipMessage="Insediamenti"
                                route="insediamenti"
                            />
                            <SectionBtn
                                text="Lavoratori"
                                icon={<User />}
                                tooltipMessage="Lavoratori"
                                route="lavoratori"
                            />
                            <SectionBtn
                                text="Privacy"
                                icon={<LockClosed />}
                                arrowIndicator={true}
                                tooltipMessage="Privacy"
                                route="privacy/documenti"
                            >
                                <ul>
                                    <SectionBtn
                                        text="Documenti Privacy"
                                        icon={<Nbsp />}
                                        tooltipMessage="documenti"
                                        route="privacy/documenti"
                                    />
                                    <SectionBtn
                                        text="Documenti Master Privacy"
                                        icon={<Nbsp />}
                                        tooltipMessage="Documenti master"
                                        route="privacy/moduli"
                                    />
                                </ul>
                            </SectionBtn>
                            {/*
                            <SectionBtn
                                text='Lavori in Cantiere'
                                icon={<OfficeBuilding />}
                                tooltipMessage='Cantieri'
                                route='cantiere'
                            />
                            */}
                            {/*
                            <SectionBtn
                                text='Lavori in Azienda'
                                icon={<DocumentText />}
                                tooltipMessage='Azienda'
                                route='azienda'
                            />
                            */}
                        </>
                    }
                    <SectionBtn
                        text="Manutenzioni"
                        icon={<Screwdriver />}
                        tooltipMessage="Manutenzioni"
                        route="manutenzioni"
                    />
                    {(!userManutenzione) &&
                        <>
                            {/*
                            <SectionBtn
                                text="AFM Premium"
                                icon={<BookOpen />}
                                tooltipMessage="AFM Premium"
                                route="afm-premium"
                            />
                            */}
                            <SectionBtn
                                text="Documenti Master"
                                icon={<Logout />}
                                tooltipMessage="Documenti Master"
                                route="documenti-master"
                            />
                            {/*
                            <SectionBtn
                                text="Duvri"
                                icon={<Truck />}
                                tooltipMessage="Duvri"
                                route="duvri"
                            />
                            */}
                            <SectionBtn
                                text="Comunicazioni"
                                icon={<Chat />}
                                tooltipMessage="Comunicazioni"
                                notificationNumber={unreadNotices.length}
                                route="comunicazioni"
                            />
                            <SectionBtn
                                text="Scadenze"
                                icon={<Exclamation />}
                                tooltipMessage="Scadenze"
                                notificationNumber={unreadDeadlines.length}
                                route="scadenze"
                            />
                        </>
                    }
                </ul>
            </nav>
        </div>
    )
}

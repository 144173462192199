// Router.
import { Outlet } from "react-router-dom"
// UI.
import LayoutDetail from "../LayoutDetail"

/**
 *
 * @dev Visualize the first component available under Generale route.
 * 
 * @function Generale
 *
 * @returns JSX.Element
 *
 */
export default function Generale() {
    return (
        <LayoutDetail title={"Dati Generali"}>
            <Outlet />
        </LayoutDetail>
    )
}

// UI.
import HSeparator from "./HSeparator"

import Tooltip from "./Tooltip"

import { InformationCircle } from "./Icons"

/**
 * 
 * @dev Configurable afm-box Grid.
 *
 * @function Grid.
 * 
 * @param { string } twGridTemplateColumns
 * @param { object } headerTitleItems
 * @dev headerTitleItems : {[
 * @dev      {
 * @dev         title,
 * @dev         twColSpan,
 * @dev         twAdditionalClass,
 * @dev         component,
 * @dev      }
 * @dev ]}
 * @param { object } items
 * @dev items : {[
 * @dev      {
 * @dev         text,
 * @dev         additionalText,
 * @dev         twColSpan,
 * @dev         twAdditionalClass,
 * @dev         component,
 * @dev      }
 * @dev ]}
 *
 * @returns JSX.Element
 *
 */
export default function Grid(props) {
    // Grid Columns TailwindCSS token.
    const twGridTemplateColumns = props.twGridTemplateColumns

    /**
     *
     * Component render.
     *
     */
    return (
        <div className="flex flex-col">
            <header
                className={`
                    grid ${twGridTemplateColumns} 
                    h-20
                    items-center justify-items-start
                    font-semibold 
                    sticky top-0
                    bg-white
                    z-10`}
            >
                {props.headerTitleItems === undefined ? (
                    <div className="w-full flex items-center justify-center mt-10">
                        <p className="text-lg font-semibold">
                            Ops! La lista è vuota o la proprietà "headerTitleItems" non è stata
                            assegnata
                        </p>
                    </div>
                ) : (
                    props.headerTitleItems.map((item, id) => {
                        // If component exist than assign it to the variable.
                        const component = item?.component,
                            tooltipMessage = item?.tooltipMessage ? item.tooltipMessage : null

                        if (component) {
                            return (
                                <div
                                    key={id}
                                    className={`${item.twColSpan} ${item.twAdditionalClass}`}
                                >
                                    {component}
                                </div>
                            )
                        } else {
                            return (
                                <div key={id} className={`flex items-center gap-x-2 pr-3 ${item.twColSpan}`}>
                                    <h1>{item.title}</h1>

                                    {tooltipMessage && (
                                        <Tooltip
                                            message={tooltipMessage}
                                            twBackground="bg-afm-box-blue"
                                        >
                                            <InformationCircle />
                                        </Tooltip>
                                    )}
                                </div>
                            )
                        }
                    })
                )}
            </header>

            <HSeparator />

            {props?.isFetching ? (
                <>
                    {props.items === undefined && (
                        <div className="w-full flex items-center justify-center mt-10">
                            <div className="h-10 w-full bg-neutral-300 rounded animate-pulse"></div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    {(props.items === undefined || props?.items?.length === 0) ? (
                        <div className="w-full flex items-center justify-center mt-10">
                            <p className="text-lg font-semibold">
                                {props.emptyMessage || 'Nessun risultato trovato.' /*'Ops! La lista è vuota o la proprietà "items" non è stata assegnata'*/}
                            </p>
                        </div>
                    ) : (
                        props.items.map((item, id) => (
                            <div
                                key={id}
                                className={`
                            grid ${twGridTemplateColumns} 
                            group 
                            p-2 
                            rounded-md 
                            items-center justify-center justify-items-start 
                            text-center text-black
                            text-sm xl:text-sm
                            hover:bg-gray-100 
                            border-b`}
                            >
                                {item.map((gridItem, id) => {
                                    const component = gridItem?.component
                                    if (component) {
                                        return (
                                            <div
                                                key={id}
                                                className={`${gridItem.twColSpan} ${gridItem.twAdditionalClass}`}
                                            >
                                                {component}
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div
                                                key={id}
                                                className={`${gridItem.twColSpan} text-left font-normal`}
                                            >
                                                <p className="font-medium pr-4">{gridItem.text}</p>
                                                <p>{gridItem.additionalText}</p>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        ))
                    )}
                </>
            )}

        </div>
    )
}

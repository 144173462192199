// React.
import { useEffect, useState, Fragment } from "react"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// InsediamentiSlice.
import { getSettlements, insediamentiSelector } from "../../features/insediamenti/InsediamentiSlice"
// UI.
import Grid from "../../base/Grid"
import SearchBox from "../../base/SearchBox"
import LayoutDetail from "../LayoutDetail"
import LayoutDetailContainer from "../LayoutDetailContainer"
import Button from "../../base/Button"
// Icons. 
import { DocumentText } from "../../base/Icons"
// SearchSlice.
import { searchSelector, setSearchList, reset } from "../../features/search/SearchSlice"

/**
 *  
 * @dev Insediamenti UI Component.
 * 
 * @function Insediamenti
 * 
 * @returns JSX.Element
 * 
 */
export default function Insediamenti() {
    const dispatch = useDispatch()

    // gridItems State.
    const [gridItems, setGridItems] = useState([])
    // gridHeaderTitleItems State.
    const [gridHeaderTitleItems, setGridHeaderTitleItems] = useState([])

    // insediamentiSlice State.
    const {
        settlementsItems,
        isFetching,
        isSuccess,
        isError,
        //errorMsg
    } = useSelector(insediamentiSelector)

    // Status Class enums can be grouped as static members of a class.
    class Status {
        // Create new instances of the same class as static attributes.

        // active namespace.
        static active = new Status("active", "Attivo")

        // nonActive namespace.
        static nonActive = new Status("non-active", "Non attivo")

        // waiting namespace.
        static waiting = new Status("waiting", "In attesa di verifica")

        constructor(value, message) {
            this.value = value
            this.message = message
        }
    }

    // Enums can be accesss by using namespaced assignments.
    // this makes it semantically clear that "compliant" is a "Status"
    const active = Status.active
    const nonActive = Status.nonActive
    //const waiting = Status.waiting;

    /**
     *
     * Badge component.
     *
     */
    const Badge = (props) => {
        /**
         * getStatus function.
         * It return the TailwindCSS class token as the corresponding status.
         */
        const getStatus = (status) => {
            switch (status) {
                case "active":
                    return "bg-afm-box-success-green"

                case "non-active":
                    return "bg-afm-box-error-red"

                case "waiting":
                    return "bg-afm-box-waiting-yellow"

                default:
                    return "bg-afm-box-light-gray"
            }
        }

        return (
            <div
                className={`${getStatus(
                    props.status
                )} rounded-full w-auto px-4 mx-2 h-12 flex items-center justify-center text-inherit`}
            >
                {props.message}
            </div>
        )
    }

    // searchSlice State.
    const { searchResults, searchList, searchQuery } = useSelector(searchSelector);

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        dispatch(getSettlements())
    }, [dispatch])

    useEffect(() => {
        const items = [];
        const emptySearch = (searchQuery || '').trim() === "";
        const searchQueryResults = emptySearch ? searchList : searchResults

        if (searchQueryResults.length < 1) {
            return setGridItems(items)
        } else {
            searchQueryResults.map((insediamento) => {
                const gridItem = [
                    {
                        text: "",
                        additionalText: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "Insediamento",
                        additionalText: "",
                        twColSpan: "col-span-5",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Stato",
                        additionalText: "",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "text-white",
                        component: {},
                    },
                    {
                        text: "",
                        additionalText: "",
                        twColSpan: "col-span-4",
                        twAdditionalClass: "justify-self-end",
                        component: {},
                    },
                ]

                // Item 0.
                gridItem[0].component = (
                    <div
                        className="
                            flex 
                            space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                            justify-self-start 
                            text-afm-box-light-gray-text"
                    >
                        <DocumentText />
                        <span className="border-l border-afm-box-light-gray-text"></span>
                    </div>
                )
                // Item 1.
                gridItem[1].text = insediamento.nome
                // Item 2.
                gridItem[2].component = (
                    <Badge
                        status={insediamento.attivo === "Si" ? active.value : nonActive.value}
                        message={insediamento.attivo === "Si" ? active.message : nonActive.message}
                    />
                )
                // Item 3.
                gridItem[3].component = (
                    <Fragment>
                        <Button
                            text="dettaglio"
                            twTextColor="text-white"
                            twBackground="bg-afm-box-blue"
                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                            navigateTo={`./dettaglio-insediamento-${insediamento.id_box_insediamenti}`}
                        />
                    </Fragment>
                )

                items.push(gridItem)

                return setGridItems(items)
            })
        }

        setGridHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Insediamento",
                twColSpan: "col-span-5",
                twAdditionalClass: "",
                component: "",
                tooltipMessage: "Di seguito vengono riportati tutti gli insediamenti attivi dell’azienda."
            },
            {
                title: "Stato",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "",
                twColSpan: "col-span-4 justify-self-end",
                twAdditionalClass: "",
                component: <SearchBox />,
            },
        ])
    }, [
        // settlementsItems, 
        searchList,
        searchQuery,
        searchResults,
        active.value, nonActive.value, active.message, nonActive.message]);


    useEffect(() => {
        if (isSuccess) {
            // if dataItems are been fetched then call setSearchList giving:
            // list, search key and an additional list of search key.
            dispatch(
                setSearchList({
                    dataItems: settlementsItems,
                    searchIndex: Object.keys(settlementsItems.length ? settlementsItems[0] : [''])[0],
                    additionalIndex: [
                        'nome',
                        'indirizzo',
                        'citta',
                        'cap'
                    ],
                })
            )
        }
    }, [
        settlementsItems
    ]);

    useEffect(() => {
        dispatch(reset());
    }, []);

    /**
     *
     * Component render.
     *
     */
    return (
        <LayoutDetail title="Insediamenti">
            <LayoutDetailContainer>
                {/* Skeleton. */}
                {isFetching && <InsediamentiSkeleton />}

                {!isFetching && !isError && isSuccess && (
                    <Grid
                        twGridTemplateColumns="grid-cols-12"
                        headerTitleItems={gridHeaderTitleItems}
                        items={gridItems}
                    />
                )}

                {isError && !isSuccess && (
                    <div className="flex items-center justify-center my-14">
                        <p className="text-xl">...Recupero dei dati fallito</p>
                    </div>
                )}
            </LayoutDetailContainer>
        </LayoutDetail>
    )
}

/**
 * 
 * @dev Insediamenti skeleton Component.
 *
 * @function InsediamentiSkeleton
 *
 * @returns JSX.Element
 *
 */
function InsediamentiSkeleton() {
    return (
        <div className="animate-pulse">
            <div className="flex flex-col">
                <header className="h-20 grid grid-cols-12 gap-10 items-center justify-items-start font-semibold">
                    <div className="col-span-1 h-10 w-full bg-inherit rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-inherit rounded"></div>
                    <div className="col-span-3 h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="col-span-2 h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="col-span-5 h-10 w-full bg-neutral-300 rounded"></div>
                </header>
                <span className="flex-grow mb-2 border-t border-gray-400"></span>

                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
            </div>
        </div>
    )
}

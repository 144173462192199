/**
 *
 * @notice Admin Component.
 *
 * @dev Component as allow the user to get in the app or not.
 *
 */
import { Fragment, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Select from 'react-select'
import AfmBoxLogoNeg from "../assets/afmbox_logo_neg.svg"
import { getListaAziende, listaAziendeSelector, switchUser, switchUserSelector, switchUserClearState } from "../components/features/generale/admin/AdminSlice"
import { forceUser } from "./../common/utilAPI"
import { Logout, OvalLoader } from "./base/Icons"
import { clearState, logoutUser, userSelector } from "./features/user/UserSlice"

/**
 *
 * @function Login
 *
 * @returns JSX.Element
 *
 */
export default function Admin() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formIsFetching, setFormIsFetching] = useState(false);
    const [formhasErrors, setFormhasErrors] = useState(false);
    const { isFetching, isSuccess, isError, errorMsg } = useSelector(userSelector);
    const { handleSubmit, setValue, register, setError, clearErrors, formState, formState: { errors, isValid } } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {
            userview: "",
            formErrors: "",
        },
    });
    const listaAziende = useSelector(listaAziendeSelector);
    const switchUserData = useSelector(switchUserSelector);

    useEffect(() => {
        dispatch(getListaAziende());
        return () => {
            dispatch(clearState())
        }
    }, []);
    useEffect(() => {
        const { data } = switchUserData;
        if (!data) { return };
        if (Object.keys(data).length === 0) { return };
        const success = forceUser(data?.token, data?.scadenza);
        if (success === true) {
            dispatch(switchUserClearState());
            navigate("/dashboard/panoramica", { replace: true }) // Then navigate the the main component.
        }
    }, [
        switchUserData.data
    ]);
    useEffect(() => {
        // if (isSuccess) {
        //     setFormIsFetching(false) // Stop showing Oval indicator.

        //     navigate("/dashboard/panoramica", { replace: true }) // Then navigate the the main component.
        // }
        // if (isError) {
        //     setFormIsFetching(false) // Stop showing Oval indicator.
        //     setFormhasErrors(true) // Set form errors.
        //     dispatch(clearState()) // Clean up the UserSlice State.
        // }
        // if (isFetching) {
        //     setFormIsFetching(true) // Showing Oval indicator.
        // }
    }, [dispatch, navigate, formState, isSuccess, isError, isFetching, errorMsg]);
    const onSubmit = (data) => {
        if (data.formErrors.length > 0 && errorMsg) {
            setError("formErrors", {
                type: "server side",
                message: errorMsg,
            });
            return
        }
        setFormhasErrors(false);
        clearErrors("formErrors");
        dispatch(switchUser(data))
    };
    const logOut = async () => {
        try {
            const response = await dispatch(logoutUser()).unwrap()
            if (response) {
                dispatch(clearState())
                navigate("/login", { replace: true })
            } else {
                console.log("UserBtn: does tkbox still exist?", response)
            }
        } catch (error) {
            console.error(error)
        }
    };
    /**
     *
     * Component render.
     *
     */
    return (
        <Fragment>
            <div className="min-h-screen flex flex-col justify-center py-12 px-6 sm:px-6 lg:px-8 bg-afm-box-dark-gray text-white/50">
                <div className="mx-auto sm:mx-auto  sm:w-full sm:max-w-md  text-center">
                    <img src={AfmBoxLogoNeg} alt="AFMBOX logo" className="h-24 w-full p-1" />
                    <p className="text-white mt-6">Hai eseguito l'accesso Amministratore</p>
                    <p className="mt-2">Inserisci il nome dell'azienda che stai cercando</p>
                </div>
                <div className="flex justify-center items-center mt-8 sm:mx-auto w-auto sm:w-auto">
                    <div className="bg-inherit pb-8 px-4 sm:px-10 admin-search-content">
                        <form className="space-y-10" onSubmit={handleSubmit(onSubmit)} method="POST">
                            <div className="mb-10 group text-black">
                                <Select {...register("userview")}
                                    onChange={(selected) => {
                                        setValue("userview", selected?.value || '');
                                    }}
                                    options={listaAziende?.data || []} />
                            </div>
                            <div className="mb-10 mx-auto">
                                <button className="
                                        w-64 
                                        h-10 
                                        px-7 
                                        flex 
                                        mx-auto 
                                        gap-1 
                                        items-center justify-center 
                                        uppercase whitespace-nowrap font-semibold 
                                        text-white text-lg
                                        bg-afm-box-green
                                        hover:opacity-80 
                                        focus:outline-none"
                                    type="submit"
                                    value="Submit">
                                    {formIsFetching && <OvalLoader className="mr-3" />}Accedi
                                </button>
                            </div>
                            <div className="mb-10 mx-auto">
                                {formhasErrors && (
                                    <div className="
                                        h-8 
                                        text-center 
                                        bg-afm-box-error-red/10 
                                        text-afm-box-error-red 
                                        border border-afm-box-error-red rounded-md">
                                        <p>{errorMsg}</p>
                                    </div>
                                )}
                            </div>
                        </form>
                        <div className="mb-10 mx-auto flex justify-center">
                            <button className="flex items-center
                                    py-2
                                    px-7
                                    rounded-sm 
                                    transition duration-150 ease-in-out 
                                    focus:outline-nones"
                                onClick={async () => {
                                    logOut()
                                }}
                            >
                                <div className="flexshrink-0 items-center justify-center text-gray">
                                    <Logout aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm font-medium">Disconnetti</p>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

// React
import { useEffect, useState, Fragment } from "react"
// Hook Form.
import { useForm } from "react-hook-form"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// VisitaSlice.
import {
    visitaModalSelector,
    closeModal,
    uploadVisita,
    clearState,
} from "../../features/generale/organico/VisitaModalSlice"
// UI.
import Modal from "../../base/Modal"
import DocUploader from "../../base/DocUploader"
import Tooltip from "../../base/Tooltip"
// Icons.
import { OvalLoader, InformationCircle, Selector, Check } from "../../base/Icons"

import { Listbox, Transition } from "@headlessui/react"

import SearchSelect from '../../base/SearchSelect';
import Select from 'react-select';

const dayPlaceholder = 'giorno',
    monthPlaceholder = 'mese',
    yearPlaceholder = 'anno';

const daysOptions = (props) => {
    if (!props.numberDays) return
    const options = []
    for (let i = 1; i <= props.numberDays; i++) {
        options.push({ value: i.toString(), label: i.toString() })
    }
    // options.unshift({ value: '', label: dayPlaceholder, disabled: true })
    return options
}

const monthsOptions = (props) => {
    if (!props.numberMonths) return
    const options = []
    for (let i = 1; i <= props.numberMonths; i++) {
        options.push({ value: i.toString(), label: i.toString() })
    }
    // options.unshift({ value: '', label: monthPlaceholder, disabled: true })
    return options
}

const yearsOptions = (props) => {
    if (!props.numberYears) return

    const offset = props.offset ? -1 : 1

    const options = []
    for (let i = 0; i <= props.numberYears; i++) {
        options.push({ value: (new Date().getFullYear() + i * offset).toString(), label: (new Date().getFullYear() + i * offset).toString() })
    }
    // options.unshift({ value: '', label: yearPlaceholder, disabled: true })
    return options
}

/**
 * 
 * @dev VisitaModal UI Component.
 *
 * @function VisitaModal
 *
 * @returns JSX.Element
 *
 */
const visiteMediche = [
    { label: "VISITA MEDICA GENERALE", value: "VISMED" },
    { label: "VISITA MEDICA GENERALE ANNUALE", value: "VISMED1" },
    { label: "VISITA MEDICA GENERALE BIENNALE", value: "VISMED2" },
    { label: "VISITA MEDICA GENERALE TRIENNALE", value: "VISMED3" },
    { label: "VISITA MEDICA GENERALE QUINQUENNALE", value: "VISMED5" },
]

export default function VisitaModal(props) {
    const [selected, setSelected] = useState(visiteMediche[0])

    const dispatch = useDispatch()

    // Boolean State to know if the form has Error.
    //const [formhasErrors,setFormhasErrors] = useState(false);

    /**
     *
     *  Initialize a React hook form State using UseForm Hook.
     *
     * @see https://react-hook-form.com/get-started
     *
     */
    const {
        register,
        handleSubmit,
        //setError,
        //clearErrors,
        formState,
        setValue,
        //formState: { errors } // subscription.
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {
            visitaOrganicoId: props.id || '0',
            visitaDay: "",
            visitaMonth: "",
            visitaYear: "",
            // scadenzaVisitaDay: "",
            // scadenzaVisitaMonth: "",
            // scadenzaVisitaYear: "",
            tipologiaVisita: "",
            allegato: "",
            formErrors: "",
        },
    }) // UseForm Hook.

    const { isDirty, isValid } = formState // Get the form state.

    // visitaModalSlice State.
    const { modalIsFetching, modalIsSuccess, modalIsError, modalErrorMsg } =
        useSelector(visitaModalSelector)

    /**
     *
     *  onSubmit Form handler.
     *  Post data to insert a new document.
     */
    // FIXME: Procedure incomplete.
    const onSubmit = (data) => {
        const visitaToUpload = {
            organicoId: data.visitaOrganicoId,
            data: `${data.visitaYear}-${data.visitaMonth}-${data.visitaDay}`,
            // scadenza: `${data.scadenzaVisitaYear}-${data.scadenzaVisitaMonth}-${data.scadenzaVisitaDay}`,
            // titolo: selected.value,
            // titolo: data.titoloVisita,
            tipologiaVisita: data.tipologiaVisita,
            allegato: data.allegato,
        }

        if (isValid) {
            dispatch(uploadVisita(visitaToUpload))
        }
    }

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        if (modalIsSuccess) {
            dispatch(closeModal()) // Close Modal.

            dispatch(clearState()) // Clean up the UserSlice State.
        }
    }, [dispatch, modalIsFetching, modalIsSuccess, modalIsError, modalErrorMsg, formState])

    const days = daysOptions({ numberDays: 31 })
    const months = monthsOptions({ numberMonths: 12 })
    const years = yearsOptions({ numberYears: 4, offset: true })

    const visitaDay = register('visitaDay', { required: true })
    const visitaMonth = register('visitaMonth', { required: true })
    const visitaYear = register('visitaYear', { required: true })

    /**
     *
     * Component render.
     *
     */
    return (
        <Modal title="Nuova Visita">
            <form action="" className="p-4" onSubmit={handleSubmit(onSubmit)} method="POST" encType="multipart/form-data">
                <input type="hidden" {...register("visitaOrganicoId", {
                    //required: true,
                })} />
                <div className="flex gap-3">
                    <div
                        className="
                            flex flex-row 
                            text-left 
                            items-center justify-center 
                            mt-6 mb-2"
                    >
                        <label
                            htmlFor="visitaDay"
                            className="
                                mb-2 
                                pr-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Data Visita<top className="text-red-500 ml-1">*</top>
                        </label>
                    </div>
                </div>

                <div className="inline-flex space-x-4 w-full">
                        <Select
                        {...register("visitaDay")}
                        options={days || []}
                        onChange={(selected) => {
                            setValue("visitaDay", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={dayPlaceholder}
                        className="relative w-full"
                        name="visitaDay"
                    />
                    <Select
                        {...register("visitaMonth")}
                        options={months || []}
                        onChange={(selected) => {
                            setValue("visitaMonth", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={monthPlaceholder}
                        className="relative w-full"
                        name="visitaMonth"
                    />
                    <Select
                        {...register("visitaYear")}
                        options={years || []}
                        onChange={(selected) => {
                            setValue("visitaYear", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={yearPlaceholder}
                        className="relative w-full"
                        name="visitaYear"
                    />
                </div>
                <div className="block mt-6 mb-2">
                    <div className="flex-col text-left mt-6 mb-2">
                        <label htmlFor="tipologiaVisita">Tipologia<top className="text-red-500 ml-1">*</top></label>
                    </div>

                    <Select
                        {...register("tipologiaVisita")}
                        options={visiteMediche || []}
                        onChange={(selected) => {
                            setValue("tipologiaVisita", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={'Seleziona tipologia'}
                        className="relative w-full"
                    />
                   
                </div>

                <div className="flex-col text-left mt-6 mb-0">
                    <label>Carica Visita<top className="text-red-500 ml-1">*</top></label>
                </div>
                <div className="block">
                    <DocUploader name="allegato"
                        onChange={(file) => {
                            setValue('allegato', file);
                        }}
                    />
                </div>

                <div className="flex mt-10">
                    <div className="flex-1">
                        <button
                            type="button"
                            className="
                                flex 
                                gap-1 
                                w-auto 
                                px-7 
                                h-10 
                                items-center justify-center
                                uppercase whitespace-nowrap font-semibold 
                                text-white 
                                bg-afm-box-dark-gray
                                hover:opacity-80 
                                focus:outline-none"
                            onClick={() => {
                                dispatch(closeModal())
                            }}
                        >
                            annulla
                        </button>
                    </div>
                    <button
                        type="submit"
                        value="Submit"
                        disabled={!isDirty || !isValid}
                        className="
                            flex 
                            w-auto 
                            px-7 
                            h-10 
                            gap-1 
                            items-center justify-center 
                            uppercase whitespace-nowrap font-semibold 
                            text-white 
                            bg-afm-box-blue 
                            hover:opacity-80 
                            focus:outline-none"
                    >
                        {modalIsFetching && <OvalLoader />} procedi
                    </button>
                </div>

                {modalIsError && (
                    <div
                        className="
                            h-8 
                            inline-flex 
                            items-center justify-items-center 
                            mt-2 
                            px-20 
                            bg-afm-box-error-red/10 
                            text-afm-box-error-red 
                            border border-afm-box-error-red rounded-md"
                    >
                        <p>{modalErrorMsg}</p>
                    </div>
                )}
            </form>
        </Modal>
    )
}

/**
 *
 * @function Modal
 *
 * @dev base modal window.
 * 
 * @param { string } title
 * @param { JSX.Element } children
 *
 * @returns JSX.Element
 *
 */
export default function Modal(props) {
    return (
        <aside
            className="
                fixed 
                top-0 
                left-0 
                w-full 
                h-full 
                z-20 
                flex 
                items-center 
                justify-center 
                bg-black/70"
                
        >
            <div className="text-center h-full overflow-y-auto modal-content">
                <header className="items-center text-center text-lg font-bold px-16 py-5 bg-gray-100">
                    {props.title}
                </header>
                <div className="bg-white w-auto p-8 text-center">
                    {props.children}
                </div>
            </div>
        </aside>
    )
}

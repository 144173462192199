/**
 *
 * @dev cookie.
 * @dev Contains general purpose utilities to interact with cookies.
 *
 */

// TokenBox.
export const tkbox = "tkbox"

/*******************************************************************************
 *
 * @dev Set Cookie by name, value.
 * 
 * @function setCookie
 * @param { string } cname
 * @param { string } cvalue
 * @param { string } expiration
 * 
 * @returns void
 *
 */
export function setCookie (cname,cvalue,expiration) {

    let expires

    if (expiration) {
        expires = `expires=${new Date(expiration).toUTCString()}`
    } else {
        expires = `expires=${new Date(Date.now() + 86400 * 1000).toUTCString()}` // 1 Day.
    }

    document.cookie = `${cname}=${cvalue};${expires};path=/`
}

/*
export const setCookie = (cname,cvalue,exdays) => {
   const d = new Date(); 
   d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
   let expires = "expires=" + d.toUTCString();
   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};
*/

/*******************************************************************************
 *
 * @dev Get Cookie by name.
 * 
 * @function getCookie
 * @param { string } cname
 * @returns { string }
 *
 */
export function getCookie (cname) {
    const name = `${cname}=`

    const ca = document.cookie.split(";")

    for (let i = 0; i < ca.length; i++) {
        let cookie = ca[i]

        while (cookie.charAt(0) === " ") {
            cookie = cookie.substring(1)
        }

        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length,cookie.length)
        }
    }
    return ""
}

/*******************************************************************************
 *
 * @dev Check if Cookie exist.
 * 
 * @function checkCookie
 * @param { string } cname
 * @returns { bool }
 *
 */
export function checkCookie (cname) {
    const user = getCookie(cname)

    if (user !== "") {
        return true
    } else {
        return false
    }
}

/*******************************************************************************
 *
 * @dev Delete Cookie.
 * 
 * @function deleteCookie
 * @param { string } cname
 * @returns void
 *
 */
export function deleteCookie (cname) {
    const hasBeenFound = checkCookie(cname)

    if (hasBeenFound) {
        document.cookie = cname + "=; Max-Age=-99999999; path=/;"
    }
}

/**
 *
 * @notice dataSlice to handle state of Durf globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 */

// Redux.
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
// Axios.
import axios from "axios"
// gmapi.
import {
    API_KEY,
    listaDurfAPI,
    downloadDurfAPI,
    eliminaDurfAPI
} from "../../../../common/gmApi"
// cookie.
import { tkbox, getCookie } from "../../../../common/cookie"
// util.
import {
    downloadDocument,
    showDocument,
} from "../../../../common/util"

/********************************************
 *                                          *
 * @notice AsyncThunk API(s) Implementation *
 *                                          *
 ********************************************/
/**
 *
 *  @dev Get Doc data.
 *
 */
export const getDoc = createAsyncThunk(
    // Action route.
    "durf/getDURF",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                listaDurfAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                    },
                }
            )

            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    //console.error('Error "getDURF":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "getDURF":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "getDURF":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Download DURF document.
 *
 */
export const downloadDoc = createAsyncThunk(
    // Action route.
    "durf/downloadDurfDoc",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { Object } obj
     * @param { string } obj.docId
     * @param { string } obj.docName
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async ({ docId, docName }, thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                downloadDurfAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        doc_id: docId,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    // Build a custom response with extradata.
                    return response
                } else {
                    //console.error('Error "downloadDurfDoc":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "downloadDurfDoc":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "downloadDurfDoc":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Show document.
 *
 */
export const showDoc = createAsyncThunk(
    // Action route.
    "durf/showDurfDoc",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { string } docId
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (docId, thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                downloadDurfAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        doc_id: docId,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    // Build a custom response with extradata.
                    return {
                        data: response.data,
                        eventSender: docId,
                    }
                } else {
                    //console.error('Error "showDurfDoc":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "showDurfDoc":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "showDurfDoc":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Delete DURF item.
 *
 */
export const deleteDoc = createAsyncThunk(
    // Action route.
    "durf/deleteDURF",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { string } docId
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (docId, thunkAPI) => {
        try {
            const response = await axios.delete(
                // url.
                eliminaDurfAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        doc_id: docId,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    // Build a custom response with extradata.
                    return {
                        data: response.data,
                        eventSender: docId,
                    }
                } else {
                    //console.error('Error "deleteDURF":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "deleteDURF":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "deleteDURF":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/***************************************
 *                                     *
 * @notice createSlice implementation. *
 *                                     *
 * @dev Create a dataSlice feature.  *
 * @dev UI views state controller.     *
 *                                     *
 ***************************************/
export const dataSlice = createSlice({
    // Name, used in action types.
    name: "durf",
    // The initial state for the reducer.
    initialState: {
        dataItems: [],       // All items.
        //needsReload: false,
        isFetching: false,     // AsyncThunk is calling an API.
        isSuccess: false,      // AsyncThunk is success from API call.
        isError: false,        // AsyncThunk is fail from API call.
        errorMsg: "",          // Error message container.
        doc: [],         // document.
        docName: "",     // document name.
        docSenderId: "", // document sender id.
        isFetchingDoc: false,
        isSuccessDoc: false,
        isErrorDoc: false,
    },
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {
        /**
         * @action clearDocSenderId
         */
        clearDocSenderId: (state) => {
            state.docSenderId = ""

            return state
        },
    },
    // AsyncThunk Reducers.
    // A "builder callback" function used to add more reducers, or
    // an additional object of "case reducers", where the keys should be other
    // action types.
    extraReducers: (builder) => {
        builder
            /*******************************************************************
             *
             * @dev getDoc.
             *
             */
            .addCase(getDoc.fulfilled, (state, action) => {
                state.isFetching = false
                state.isSuccess = true
                //state.needsReload = false

                // Update duvriItems sort by `data_ora_inserimento` key.
                const dataItems = action.payload.gmapi.response.data;
                if (!dataItems) {
                    return
                };
                state.dataItems = dataItems.sort((firstEl, secondEl) => (
                    firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                ))
            })
            .addCase(getDoc.pending, (state) => {
                state.isFetching = true
            })
            .addCase(getDoc.rejected, (state, action) => {
                state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
            })
            /*******************************************************************
             *
             * @dev downloadDoc.
             *
             */
            .addCase(downloadDoc.fulfilled, (state, action) => {
                state.isFetchingDoc = false
                state.isSuccessDoc = true

                // Update the state with the file name from action payload.
                state.doc = action.payload.data.gmapi.response.data
                // Update the state with the file name.

                let docName = action.meta.arg.docName
                let splitted = docName.split('_');
                if (splitted.length > 1 && /^\d+$/.test(splitted[0])) {
                    splitted.shift();
                    docName = splitted.join('_');
                }

                state.docName = docName

                // Document download trigger.
                downloadDocument(state.doc, state.docName)
            })
            .addCase(downloadDoc.pending, (state, action) => {
                state.isFetchingDoc = true
                // Update the state with doc id.
                state.docSenderId = action.meta.arg.docId
            })
            .addCase(downloadDoc.rejected, (state, action) => {
                state.isFetchingDoc = false
                state.isErrorDoc = true
                //state.errorMsgDoc = action.payload;
            })
            /*******************************************************************
             *
             * @dev showDoc.
             *
             */
            .addCase(showDoc.fulfilled, (state, action) => {
                //state.isFetchingDoc = false;
                //state.isSuccessDoc = true;
                state.doc = action.payload.data.gmapi.response.data

                showDocument(state.doc)
            })
            .addCase(showDoc.pending, (state, action) => {
                //state.isFetchingDoc = true;

                // Update the state with doc id.
                state.docSenderId = action.meta.arg.docId
            })
            .addCase(showDoc.rejected, (state, action) => {
                //state.isFetchingDoc = false;
                //state.isErrorDoc = true;
                //state.errorMsgDoc = action.payload;
            })
            /*******************************************************************
             *
             * @dev deleteDoc.
             *
             */
            .addCase(deleteDoc.fulfilled, (state, action) => {
                //state.isFetching = false
                //state.isSuccess = true

                // Get the eventSender from the action payload.
                const idBoxDoc = action.payload.eventSender
                const docToRemove = state.dataItems.findIndex((obj) => obj.id_box_durf === idBoxDoc)

                // If found then remove it from the list.
                if (docToRemove > -1) {
                    state.dataItems.splice(docToRemove, 1) // Remove the item selected.
                }
            })
            .addCase(deleteDoc.pending, (state) => {
                //state.isFetching = true
            })
            .addCase(deleteDoc.rejected, (state, action) => {
                //state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
                //state.needsReload = true
            })
    },
})

// Export the reducer.
export const { clearDocSenderId: clearDocSenderId } = dataSlice.actions

// Export the Slice by name.
export const dataSelector = (state) => state.durf

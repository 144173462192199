// react
import { useEffect, useState, Fragment } from "react"
// Redux.
import { useSelector, useDispatch } from "react-redux"
// ManutenzioniModalSlice.
import {
    fileManutenzioniModalSelector,
    closeModal,
    clearState,
    addFile,
} from "../../features/manutenzioni/FileManutenzioniModalSlice"
// Hook Form.
import { useForm } from "react-hook-form"
// UI.
import Modal from "../../base/Modal"
import Tooltip from "../../base/Tooltip"

// Icons.
import { OvalLoader, Check, InformationCircle } from "../../base/Icons"
import Select from "react-select"
import DocUploader from "../../base/DocUploader"

const dayPlaceholder = 'giorno',
    monthPlaceholder = 'mese',
    yearPlaceholder = 'anno';

const daysOptions = (props) => {
    if (!props.numberDays) return
    const options = []
    for (let i = 1; i <= props.numberDays; i++) {
        options.push({ value: i.toString(), label: i.toString() })
    }
    // options.unshift({ value: '', label: dayPlaceholder, disabled: true })
    return options
}

const monthsOptions = (props) => {
    if (!props.numberMonths) return
    const options = []
    for (let i = 1; i <= props.numberMonths; i++) {
        options.push({ value: i.toString(), label: i.toString() })
    }
    // options.unshift({ value: '', label: monthPlaceholder, disabled: true })
    return options
}

const yearsOptions = (props) => {
    if (!props.numberYears) return

    const offset = props.offset ? -1 : 1

    const options = []
    for (let i = 0; i <= props.numberYears; i++) {
        options.push({ value: (new Date().getFullYear() + i * offset).toString(), label: (new Date().getFullYear() + i * offset).toString() })
    }
    // options.unshift({ value: '', label: yearPlaceholder, disabled: true })
    return options
}


export default function FileManutenzioniModal(props) {
    const dispatch = useDispatch()

    // Init doc state.
    const [doc, setDoc] = useState("")

    // Init isUploaded state.
    const [isUploaded, setIsUploaded] = useState(false)

    /**
     *
     *  Initialize a React hook form State using UseForm Hook.
     *
     * @see https://react-hook-form.com/get-started
     *
     */
    const {
        register,
        handleSubmit,
        setError,
        formState,
        setValue,
        formState: { errors }, // subscription.
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {
            macchinarioId: props.id || '0',
            tipo: props.tipo || 'CNTR',
            loadDay: "",
            loadMonth: "",
            loadYear: "",
            expireDay: "",
            expireMonth: "",
            expireYear: "",
            description: "",
            allegato: "",
        },
    })

    const { isDirty, isValid } = formState // Get the form state.

    const { modalIsFetching, modalIsSuccess, modalIsError, modalErrorMsg } =
        useSelector(fileManutenzioniModalSelector)

    const onSubmit = (data) => {
        // console.log(errors);

        const newFile = {
            idBoxMacchinari: data.macchinarioId,
            descrizione: data.description,
            dataCaricamento: data.loadYear + '-' + data.loadMonth + '-' + data.loadDay,
            dataScadenza: data.expireYear + '-' + data.expireMonth + '-' + data.expireDay,
            allegato: data.allegato,
            tipo: data.tipo
        }

        if (isValid) {
            dispatch(addFile(newFile))
        }
    }


    useEffect(() => {
        if (modalIsSuccess) {
            dispatch(closeModal()) // Close Modal.
            dispatch(clearState()) // Clean up the UserSlice State.
        }
    }, [
        dispatch,
        modalIsFetching,
        modalIsSuccess,
        modalIsError,
        modalErrorMsg,
        formState
    ])

    const days = daysOptions({ numberDays: 31 })
    const months = monthsOptions({ numberMonths: 12 })
    const years = yearsOptions({ numberYears: 12, offset: true })
    const yearsAfter = yearsOptions({ numberYears: 12, offset: false })

    /**
     * DOC handler
     */
    const accept = 'image/*,.pdf';


    /**
     *
     * Component render.
     *
     */
    return (
        <Modal title="Nuovo documento">
            <form action="" className="p-4" onSubmit={handleSubmit(onSubmit)} method="POST">
                <div className="flex-col text-left mt-6 mb-0">
                    <label>Carica documento<top className="text-red-500 ml-1">*</top></label>
                </div>
                <div className="block">
                    <DocUploader name="allegato"
                        onChange={(file) => {
                            setValue('allegato', file);
                        }}
                    />
                </div>


                <div className="flex flex-row space-x-10">
                    <div className="flex flex-col justify-center mt-6 mb-2">
                        <label
                            htmlFor="machineNumber"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Descrizione
                        </label>

                        <input
                            type="text"
                            name="machineNumber"
                            placeholder="Descrizione"
                            className="
                                w-96 
                                px-3 
                                py-2 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray 
                                placeholder-afm-box-dark-gray/[.50] 
                                border border-gray-300 rounded-md 
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("description", {})}
                        />
                    </div>
                </div>

                {/*DATA CARICAMENTO*/}
                <div className="flex gap-3">
                    <div
                        className="
                            flex flex-row
                            text-left
                            items-center justify-center
                            mt-6 mb-2"
                    >
                        <label
                            htmlFor="loadDay"
                            className="
                                mb-2
                                pr-2
                                pt-1
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Data Caricamento<top className="text-red-500 ml-1">*</top>
                        </label>
                    </div>
                </div>
                <div className="inline-flex space-x-4 w-full">
                    <Select
                        {...register("loadDay", { required: true })}
                        options={days || []}
                        onChange={(selected) => {
                            setValue("loadDay", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={dayPlaceholder}
                        className="relative w-full"
                        name="loadDay"
                    />
                    <Select
                        {...register("loadMonth", { required: true })}
                        options={months || []}
                        onChange={(selected) => {
                            setValue("loadMonth", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={monthPlaceholder}
                        className="relative w-full"
                        name="loadMonth"
                    />
                    <Select
                        {...register("loadYear", { required: true })}
                        options={years || []}
                        onChange={(selected) => {
                            setValue("loadYear", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={yearPlaceholder}
                        className="relative w-full"
                        name="loadYear"
                    />
                </div>
                {/*DATA SCADENZA*/}
                <div className="flex gap-3">
                    <div
                        className="
                            flex flex-row
                            text-left
                            items-center justify-center
                            mt-6 mb-2"
                    >
                        <label
                            htmlFor="loadDay"
                            className="
                                mb-2
                                pr-2
                                pt-1
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Data Scadenza<top className="text-red-500 ml-1">*</top>
                        </label>
                    </div>
                </div>
                <div className="inline-flex space-x-4 w-full">
                    <Select
                        {...register("expireDay")}
                        options={days || []}
                        onChange={(selected) => {
                            setValue("expireDay", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={dayPlaceholder}
                        className="relative w-full"
                        name="expireDay"
                    />
                    <Select
                        {...register("expireMonth")}
                        options={months || []}
                        onChange={(selected) => {
                            setValue("expireMonth", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={monthPlaceholder}
                        className="relative w-full"
                        name="expireMonth"
                    />
                    <Select
                        {...register("expireYear")}
                        options={yearsAfter || []}
                        onChange={(selected) => {
                            setValue("expireYear", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={yearPlaceholder}
                        className="relative w-full"
                        name="expireYear"
                    />
                </div>


                <div className="flex mt-10">
                    <div className="flex-1">
                        <button
                            type="button"
                            className="
                                flex 
                                w-auto 
                                px-7 
                                h-10 
                                gap-1 
                                items-center justify-center 
                                uppercase whitespace-nowrap font-semibold 
                                text-white 
                                bg-afm-box-dark-gray 
                                hover:opacity-80
                                focus:outline-none"
                            onClick={() => {
                                dispatch(closeModal())
                            }}
                        >
                            annulla
                        </button>
                    </div>

                    <button
                        type="submit"
                        value="Submit"
                        disabled={!isDirty || !isValid}
                        className="
                            disabled:opacity-50 
                            flex 
                            w-auto 
                            px-7 
                            h-10 
                            gap-1 
                            items-center justify-center 
                            uppercase whitespace-nowrap font-semibold 
                            text-white 
                            bg-afm-box-blue 
                            hover:opacity-80 
                            focus:outline-none"
                    >
                        {modalIsFetching && <OvalLoader />} procedi
                    </button>
                </div>


                {modalIsError && (
                    <div
                        className="
                            inline-flex 
                            items-center 
                            justify-items-center 
                            h-8 
                            mt-2 
                            px-20 
                            bg-afm-box-error-red/10 
                            text-afm-box-error-red 
                            border border-afm-box-error-red rounded-md"
                    >
                        <p>{modalErrorMsg}</p>
                    </div>
                )}
            </form>
        </Modal>
    )
}

import { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
// OrganicoSlice.
import {
    cleanUpEmployeeCourses, cleanUpEmployeeDocs,
    cleanUpEmployeeExams, cleanUpEmployeeInfo,
    getOrganico, organicoSelector
} from "../../features/generale/organico/OrganicoSlice"
// OrganicoModalSlice.
import { openModal, organicoModalSelector } from "../../features/generale/organico/OrganicoModalSlice"
// UI.
import Button from "../../base/Button"
import Grid from "../../base/Grid"
import Pillow from "../../base/Pillow"
// import SearchBox from "../../base/SearchBox"
import SubHeader from "../../base/SubHeader"
import LayoutDetailContainer from "../LayoutDetailContainer"
import OrganicoModal from "./OrganicoModal"
// Icons.
import CompliantStatus from "../../../common/CompliantStatus"
import Circle from "../../base/Circle"
import { PluSm } from "../../base/Icons"
import OrganicoSkeleton from "./OrganicoSkeleton"

const organicoGridHeader = [
    {
        title: "",
        twColSpan: "col-span-1",
        twAdditionalClass: "",
        component: "",
    },
    {
        title: "Nome e C.F.",
        twColSpan: "col-span-2",
        twAdditionalClass: "",
        component: "",
    },
    {
        title: "Mansione",
        twColSpan: "col-span-3",
        twAdditionalClass: "",
        component: "",
    },
    {
        title: "Tipologia dipendente",
        twColSpan: "col-span-2",
        twAdditionalClass: "",
        component: "",
    },
    // {
    //     title: "",
    //     twColSpan: "col-span-2 justify-self-end",
    //     twAdditionalClass: "",
    //     component: <SearchBox />,
    // },
];

const getOrganicoGridItem = (item) => {
    // Enums can be accesss by using namespaced assignments.
    // this makes it semantically clear that "compliant" is a "Status"
    const compliant = CompliantStatus.compliant
    const nonCompliant = CompliantStatus.nonCompliant
    return [
        // Attivo
        {
            text: "",
            additionalText: "",
            twColSpan: "col-span-1",
            twAdditionalClass: "place-self-center",
            component: (
                <Circle
                    status={item.attivo === "Si" ? compliant.value : nonCompliant.value}
                    message={
                        item.attivo === "Si" ? compliant.message : nonCompliant.message
                    }
                />
            )
        },
        // Nome e cognome.
        {
            text: `${item.cognome} ${item.nome}`,
            twColSpan: "col-span-2"
        },
        // Mansione
        {
            text: "Mansione",
            additionalText: item.codice_fiscale,
            twColSpan: "col-span-3",
            component: (
                <Pillow text={item.mansione} twBackground="bg-afm-box-light-gray" />
            )
        },
        // Tipo dipendente
        {
            twColSpan: "col-span-2",
            twAdditionalClass: "text-white",
            component: (
                <Fragment>
                    {item.tipo_dipendente ? (
                        <Pillow
                            text={item.tipo_dipendente}
                            twBackground="bg-afm-box-dark-gray"
                        />
                    ) : null}
                </Fragment>
            )
        },
        // Actions
        {
            twColSpan: "col-span-2",
            twAdditionalClass: "justify-self-end",
            component: (
                <Fragment>
                    <Button
                        text="dettaglio"
                        twTextColor="text-white"
                        twBackground="bg-afm-box-blue"
                        twFocusBG="focus:bg-afm-box-blue/[0.5]"
                        navigateTo={`dettaglio-lavoratore-${item.id_box_lavoratori}`}
                    />
                </Fragment>
            )
        },
    ]
}

/**
 *
 * @dev View the first component available under "Generale" route.
 * 
 * @function Organico
 *
 * @returns JSX.Element
 *
 */
export default function Organico() {
    const dispatch = useDispatch()
    /**
     * organicoSlice State.
     */
    const {
        organicoItems,
        isFetching,
        isSuccess,
        isError
    } = useSelector(organicoSelector)
    /**
     * visuraModalSlice State.
     */
    const { modalIsOpen, modalIsSucces } = useSelector(organicoModalSelector)
    /**
     * Component side effects.
     */
    useEffect(() => {
        dispatch(getOrganico())
        dispatch(cleanUpEmployeeInfo())
        dispatch(cleanUpEmployeeCourses())
        dispatch(cleanUpEmployeeExams())
        dispatch(cleanUpEmployeeDocs())
    }, [dispatch, modalIsSucces])
    useEffect(() => { }, [isFetching, isSuccess, isError])
    /**
     * Component render.
     */
    return (
        <Fragment>
            <SubHeader
                subHeaderTitle="Organico Aziendale"
                needFirstBreadCrumb={false}
                firstBtnVisibile={false}
                secondBtnVisibile={true}
                secondBtnText="Aggiungi lavoratore"
                secondBtnIcon={<PluSm />}
                secondBtnTwBg="bg-afm-box-dark-gray"
                secondBtnTwFocusBg="focus:bg-afm-box-dark-gray/[0.7]"
                secondBtnTwTextColor="text-white"
                secondBtnActions={[openModal()]}
            />
            <LayoutDetailContainer>
                {/* Skeleton. */}
                {isFetching && <OrganicoSkeleton />}
                {/* Grid if not fetching. */}
                {!isFetching && !isError && isSuccess && (
                    <Grid
                        twGridTemplateColumns="grid-cols-10"
                        headerTitleItems={organicoGridHeader}
                        items={organicoItems.map((organico) => getOrganicoGridItem(organico))}
                    />
                )}
                {/* If Modal window is open, then render. */}
                {modalIsOpen && <OrganicoModal />}
                {/* Error message if data call failed. */}
                {isError && !isSuccess && (
                    <div className="flex items-center justify-center my-14">
                        <p className="text-xl">...Recupero dei dati fallito</p>
                    </div>
                )}
            </LayoutDetailContainer>
        </Fragment>
    )
}
/**
 *
 * @notice ScadenzeModalSlice to handle state of Scadenze globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 */

// Redux.
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit"
// Axios.
import axios from "axios"
// gmapi.
import { API_KEY,aggiungiScadenzaAPI } from "../../../common/gmApi"
// cookie.
import { tkbox,getCookie } from "../../../common/cookie"

/**
 *
 *  @dev Insert a new deadline.
 *
 */
export const addScadenza = createAsyncThunk(
    // Action route.
    "scadenze/addScadenza",
    //
    // Async Function with axios http req.
    //
    // First param: Object as contain "date", "title", "closed", "notes".
    //  {
    //      date: string,
    //      title: string,
    //      closed: bool,
    //      notes: string
    //  }
    //
    // Second param: "thunkAPI" callback function that should return a promise
    // handle with extraReducers to update the State.
    // It takes two parameter too: first is the value of the dispatched action,
    // and the second is the Thunk API config.
    //
    async ({ date,title,closed,notes },thunkAPI) => {
        try {
            const response = await axios.post(
                // url.
                aggiungiScadenzaAPI,
                // body.
                null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        data_scadenza: date,
                        titolo: title,
                        chiusa: closed,
                        note: notes,
                    },
                }
            )

            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    // else reject gmapi response error.
                    //console.error('Error "addScadenza":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "addScadenza":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            // If something get wrong then reject the error.
            console.error('Error "addScadenza":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Create "scadenzeModalSlice" feature.
 *
 */
export const scadenzeModalSlice = createSlice({
    // Name, used in action types.
    name: "scadenzeModal",
    // The initial state for the reducer.
    initialState: {
        modalIsOpen: false,     // Modal is open or not.
        modalIsFetching: false, // AsyncThunk is calling an API.
        modalIsSuccess: false,  // AsyncThunk is success from API call.
        modalIsError: false,    // AsyncThunk is fail from API call.
        modalErrorMsg: "",      // Error message container.
    },
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {
        clearState: (state) => {
            state.modalIsError = false
            state.modalIsSuccess = false
            state.modalIsFetching = false

            return state
        },
        openModal: (state) => {
            state.modalIsOpen = true
        },
        closeModal: (state) => {
            state.modalIsOpen = false
        },
    },
    // AsyncThunk Reducers.
    // A "builder callback" function used to add more reducers, or
    // an additional object of "case reducers", where the keys should be other
    // action types.
    extraReducers: (builder) => {
        builder
            /**
             *
             * addScadenza.
             *
             */
            .addCase(addScadenza.fulfilled,(state) => {
                state.modalIsFetching = false
                state.modalIsSuccess = true
            })
            .addCase(addScadenza.pending,(state) => {
                state.modalIsFetching = true
            })
            .addCase(addScadenza.rejected,(state,action) => {
                state.modalIsFetching = false
                state.modalIsError = true
                state.modalErrorMsg = action.payload
            })
    },
})

// Export the reducer.
export const { clearState,openModal,closeModal } = scadenzeModalSlice.actions

// Export the Slice by name.
export const scadenzeModalSelector = (state) => state.scadenzeModal

/**
 *
 * @nnotice DuvriSlice to handle state of Insediamenti globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 */
// Redux.
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit"
// Axios.
import axios from "axios"
// gmapi.
import { API_KEY,eliminaAziendeDuvriAPI,listaDuvriAPI } from "../../../common/gmApi"
// cookie.
import { tkbox,getCookie } from "../../../common/cookie"

/**
 *
 *  @dev Get Duvri data.
 *
 */
export const getDuvri = createAsyncThunk(
    // Action route.
    "duvri/getDuvri",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                listaDuvriAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    //console.error('Error "Error "getOrganico":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "Error "getOrganico":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "getOrganico":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Delete duvri item.
 *
 */
export const deleteDuvri = createAsyncThunk(
    // Action route.
    "duvri/deleteDuvri",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { string } idBoxDuvri
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (idBoxDuvri,thunkAPI) => {
        try {
            const response = await axios.delete(
                // url.
                eliminaAziendeDuvriAPI,
                // body.
                //null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_duvri: idBoxDuvri,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return {
                        data: response.data,
                        eventSender: idBoxDuvri
                    }
                } else {
                    //console.error('Error "deleteDuvri":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "deleteDuvri":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "deleteDuvri":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 * @dev Create a duvriSlice feature.
 *
 */
export const duvriSlice = createSlice({
    // Name, used in action types.
    name: "duvri",
    // The initial state for the reducer.
    initialState: {
        duvriItems: [],      // All duvri items.
        //needsReload: false,  // Does Component view needs reload (fire useEffects).
        defaultTabIndex: 0,  // Default tab index of "Tabs" child component.
        isFetching: false,   // AsyncThunk is calling an API.
        isSuccess: false,    // AsyncThunk is success from API call.
        isError: false,      // AsyncThunk is fail from API call.
        errorMsg: "",        // Error message container.
    },
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {},
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    extraReducers: (builder) => {
        builder
            /*************************************************+*****************
             *
             * @dev getDuvri.
             *
             */
            .addCase(getDuvri.fulfilled,(state,action) => {
                state.isFetching = false
                state.isSuccess = true
                //state.needsReload = false // Component view doesn't needs refresh.

                // Update duvriItems sort by `data_ora_inserimento` key.
                const duvriItems = action.payload.gmapi.response.data
                state.duvriItems = duvriItems.sort((firstEl,secondEl) => (
                    firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                ))
            })
            .addCase(getDuvri.pending,(state) => {
                state.isFetching = true
            })
            .addCase(getDuvri,(state,action) => {
                state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
            })
            /*************************************************+*****************
             *
             * @dev deleteDuvri.
             *
             */
            .addCase(deleteDuvri.fulfilled,(state,action) => {
                //state.isFetching = false
                //state.isSuccess = true
                //state.needsReload = true // Component view needs refresh.

                // Get the eventSender from the action payload.
                const idBoxDuvri = action.payload.eventSender
                // Find the object wich corresponding id.
                const duvriToRemove = state.duvriItems.findIndex((obj) => obj.id_box_duvri === idBoxDuvri)

                // If found then remove it from the list.
                if (duvriToRemove > -1) {
                    state.duvriItems.splice(duvriToRemove,1) // Remove the item selected.
                }

            })
            .addCase(deleteDuvri.pending,(state) => {
                //state.isFetching = true
            })
            .addCase(deleteDuvri.rejected,(state,action) => {
                //state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
                //state.needsReload = true // Component view needs refresh.
            })
    },
})

// Export the reducer.
//export const { clearState } = duvriSlice.actions

// Export the Slice by name.
export const duvriSelector = (state) => state.duvri

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { listaAziendeAPI, switchUserViewAPI } from "../../../../common/gmApi";
import { buildThunkAPIGetRequest, buildThunkAPIPostRequest } from '../../../../common/utilAPI';

/**
 * @thunk getListaAziende
 */
export const getListaAziende = createAsyncThunk(
    "admin/getListaAziende",
    async (thunkAPI) => await buildThunkAPIGetRequest(thunkAPI, listaAziendeAPI)
);
/**
 * @thunk switchUser
 */
export const switchUser = createAsyncThunk(
    "admin/switchUser",
    async ({ userview }, thunkAPI) => await buildThunkAPIPostRequest(thunkAPI, switchUserViewAPI, null, { params: { userview: userview ? userview : '' } })
);
/**
 * @slice listaAziendeSlice
 */
export const listaAziendeSlice = createSlice({
    name: 'adminListaAziende',
    initialState: {
        data: [],
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMsg: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListaAziende.fulfilled, (state, action) => {
                state.isFetching = false;
                state.isSuccess = true;
                state.data = action?.payload?.gmapi?.response?.data || [];
                state.errorMsg = '';
            })
            .addCase(getListaAziende.pending, (state) => {
                state.isFetching = true;
                state.errorMsg = '';
            })
            .addCase(getListaAziende.rejected, (state, action) => {
                state.isFetching = false;
                state.isError = true;
                state.errorMsg = action?.payload || ''
            })
    }
});
// Export the actions and reducer
export const listaAziendeSelector = (state) => state.adminListaAziende;
export const { setData, setIsFetching, setIsSuccess, setIsError, setErrorMsg } = listaAziendeSlice.actions;
export default listaAziendeSlice.reducer;
/**
 * @slice switchUserSlice
 */
export const switchUserSlice = createSlice({
    name: 'adminSwitchUser',
    initialState: {
        data: {},
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMsg: null,
    },
    reducers: {
        switchUserClearState(data) {
            data.data = {};
            data.isFetching = false;
            data.isSuccess = false;
            data.isError = false;
            data.errorMsg = null
            return data
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(switchUser.fulfilled, (state, action) => {
                state.isFetching = false;
                state.isSuccess = true;
                state.errorMsg = '';
                if (Array.isArray(action?.payload?.gmapi?.response?.data)) {
                    state.data = action.payload.gmapi.response.data[0] || {};
                    return
                }
                state.data = action.payload.gmapi.response.data || {}
            })
            .addCase(switchUser.pending, (state) => {
                state.isFetching = true;
                state.errorMsg = '';
            })
            .addCase(switchUser.rejected, (state, action) => {
                state.isFetching = false;
                state.isError = true;
                state.errorMsg = action?.payload || ''
            })
    }
});
// Export the actions and reducer
export const switchUserSelector = (state) => state.adminSwitchUser;
export const { switchUserClearState } = switchUserSlice.actions
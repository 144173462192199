/**
 *
 * @dev Generic Horizontal Separator.
 * 
 * @function HSeparator
 *
 * @returns JSX.Element
 *
 */
export default function HSeparator() {
    return <span className="flex flex-grow mb-6 border-t border-gray-400"></span>
}

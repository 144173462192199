/**
 *
 * @notice DocumentiMasterSlice to handle state of DocumentiMaster globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 */

// Redux.
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit"
// Axios.
import axios from "axios"
// gmapi.
import { API_KEY,downloadDocumentiMasterAPI,listaDocumentiMasterAPI } from "../../../common/gmApi"
// cookie.
import { tkbox,getCookie } from "../../../common/cookie"
import { downloadDocument } from "../../../common/util"

/**
 *
 *  @dev Get Master docs data.
 *
 */
export const getMasterDocs = createAsyncThunk(
    // Action route.
    "documentiMaster/getMasterDocs",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                listaDocumentiMasterAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    //console.error('Error "getMasterDocs":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "getMasterDocs":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "getMasterDocs":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Download Master doc.
 *
 */
export const downloadMasterDocById = createAsyncThunk(
    // Action route.
    "documentiMaster/downloadMasterDocById",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { string } idBoxDocumentiMaster
     * 
     * @param thunkAPI: <AsyncThunkConfig/> 
     * @dev Callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AxiosResponse<any, any>
     * 
     */
    async (idBoxDocumentiMaster,thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                downloadDocumentiMasterAPI,
                // body.
                //null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_documenti_master: idBoxDocumentiMaster,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    // Build a custom response with extradata.
                    return response
                } else {
                    // else reject gmapi response error.
                    console.error('Error "downloadMasterDocById":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                console.error('Error "downloadMasterDocById":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            // If something get wrong then reject the error.
            console.error('Error "downloadMasterDocById":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)


/**************************************************
 *                                                *
 * @notice createSlice implementation.            *
 *                                                *
 * @dev Create a "documentiMasterSlice" feature.  *
 * @dev UI views state controller.                *
 *                                                *
 **************************************************/
export const documentiMasterSlice = createSlice({
    // Name, used in action types.
    name: "documentiMaster",
    // The initial state for the reducer.
    initialState: {
        masterDocsItems: [],        // All Master doc items.
        isFetching: false,          // Is fetching Master doc items.
        isSuccess: false,           // Is success Master doc items.
        isError: false,             // Is errror Master doc items.
        errorMsg: "",               // Errror Message Master doc items.
        masterDocName: "",          // Master Doc Name.
        masterDocSenderId: "",      // Master Doc Name id.
        isFetchingMasterDoc: false, // Is fetching Master Doc Base64.
        isSuccessMasterDoc: false,  // Is success Master Doc Base64.
        isErrorMasterDoc: false,    // Is error Master Doc Base64.
        //errorMsgMasterDoc: "",      // Error message Master Doc Base64.
    },
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {
        /**
         * @action clearPrivacyDocSenderId
         */
        clearMasterDocSenderId: (state) => {
            state.masterDocSenderId = ""

            return state
        },
    },
    // AsyncThunk Reducers.
    // A "builder callback" function used to add more reducers, or
    // an additional object of "case reducers", where the keys should be other
    // action types.
    extraReducers: (builder) => {
        builder
            /*******************************************************************
             *
             * @dev getMasterDocs.
             *
             */
            .addCase(getMasterDocs.fulfilled,(state,action) => {
                state.isFetching = false
                state.isSuccess = true
                state.masterDocsItems = action.payload.gmapi.response.data
            })
            .addCase(getMasterDocs.pending,(state) => {
                state.isFetching = true
            })
            .addCase(getMasterDocs.rejected,(state,action) => {
                state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
            })
            /*******************************************************************
             *
             * @dev downloadMasterDocById.
             *
             */
            .addCase(downloadMasterDocById.fulfilled,(state,action) => {
                state.isFetchingMasterDoc = false
                state.isSuccessMasterDoc = true

                // Update the state with the file name from action payload.
                const masterDoc = action.payload.data.gmapi.response.data

                for (let index = 0; index < state.masterDocsItems.length; index++) {
                    const element = state.masterDocsItems[index]
                    if (element.id_box_documenti_master === state.masterDocSenderId) {
                        state.masterDocName = element.allegato
                    }
                }
                // Document download trigger.
                downloadDocument(masterDoc,state.masterDocName)
            })
            .addCase(downloadMasterDocById.pending,(state,action) => {
                state.isFetchingMasterDoc = true
                state.masterDocSenderId = action.meta.arg // Get the sender id (id_box_documenti_master) which trigger the download.
            })
            .addCase(downloadMasterDocById.rejected,(state,action) => {
                state.isFetchingMasterDoc = false
                state.isErrorMasterDoc = true
                //state.errorMsgMasterDoc = action.payload
            })
    },
})

// Export the reducer.
export const { clearMasterDocSenderId } = documentiMasterSlice.actions

// Export the Slice by name.
export const documentiMasterSelector = (state) => state.documentiMaster

/**
 *
 * @notice AziendaSlice to handle state of Azienda globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 */

// Redux.
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
// axios.
import axios from "axios"
// gmapi.
import {
    API_KEY,
    caricaAziendaLogoAPI,
    datiAziendaAPI,
    downloadAziendaLogoAPI,
    downloadDatiAziendaAPI
} from "../../../../common/gmApi"
// cookie.
import { tkbox, getCookie } from "../../../../common/cookie"
import { downloadDocument } from "../../../../common/util"

/********************************************
 *                                          *
 * @notice AsyncThunk API(s) Implementation *
 *                                          *
 ********************************************/

/**
 *
 *  @dev Get Factory data.
 *
 */
export const getFactory = createAsyncThunk(
    // Action route.
    "azienda/getFactory",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                datiAziendaAPI,
                // body.
                //null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                    },
                }
            )
            //
            //
            //
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    //console.error('Error "getFactory":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "getFactory":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "getFactory":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Download Factory logo.
 *
 */
export const downloadFactoryLogo = createAsyncThunk(
    // Action route.
    "azienda/downloadFactoryLogo",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                downloadAziendaLogoAPI,
                // body.
                // null
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                    },
                }
            )
            //
            //
            //
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response
                } else {
                    console.error('Error "downloadFactoryLogo":', response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                console.error('Error "downloadFactoryLogo":', response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "downloadFactoryLogo":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Upload Factory logo.
 *
 */
export const uploadFactoryLogo = createAsyncThunk(
    // Action route.
    "azienda/uploadFactoryLogo",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { any } newLogo
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (newLogo, thunkAPI) => {
        try {
            var bodyFormData = new FormData()
            bodyFormData.append('logo', newLogo, newLogo.name)

            const response = await axios.post(
                // url.
                caricaAziendaLogoAPI,
                // body.
                bodyFormData,
                // Queryparams.
                {
                    headers: { "Content-Type": "multipart/form-data" },
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                    },
                },
            )
            //
            //
            //
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response
                } else {
                    console.error('Error "uploadFactoryLogo":', response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                console.error('Error "uploadFactoryLogo":', response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "uploadFactoryLogo":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/*****************************************
 *                                       *
 * @notice createSlice implementation.   *
 *                                       *
 * @dev Create a "aziendaSlice" feature. *
 * @dev UI views state controller.       *
 *                                       *
 *****************************************/
export const aziendaSlice = createSlice({
    // Name, used in action types.
    name: "azienda",
    // The initial state for the reducer.
    initialState: {
        factoryItems: [],  // All Factory items.
        factoryLogo: "",   // Factory logo in Base64.
        isFetching: false, // AsyncThunk is calling an API.
        isSuccess: false,  // AsyncThunk is success from API call.
        isError: false,    // AsyncThunk is fail from API call.
        errorMsg: "",      // Error message container.
    },
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {},
    // AsyncThunk Reducers.
    // A "builder callback" function used to add more reducers, or
    // an additional object of "case reducers", where the keys should be other
    // action types.
    extraReducers: (builder) => {
        builder
            /*******************************************************************
             *
             * @dev getFactory.
             *
             */
            .addCase(getFactory.fulfilled, (state, action) => {
                state.isFetching = false
                state.isSuccess = true

                state.factoryItems = action.payload.gmapi.response.data[0]
            })
            .addCase(getFactory.pending, (state) => {
                state.isFetching = true
            })
            .addCase(getFactory.rejected, (state, action) => {
                state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
            })
            /*******************************************************************
             *
             * @dev downloadFactoryLogo.
             *
             */
            .addCase(downloadFactoryLogo.fulfilled, (state, action) => {
                state.isFetching = false
                state.isSuccess = true
                state.factoryLogo = action.payload.data.gmapi.response.data
            })
            .addCase(downloadFactoryLogo.pending, (state) => {
                state.isFetching = true
            })
            .addCase(downloadFactoryLogo.rejected, (state, action) => {
                state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
            })
            /*******************************************************************
             *
             * @dev uploadFactoryLogo.
             *
             */
            .addCase(uploadFactoryLogo.fulfilled, (state, action) => {
                state.factoryLogo = action.payload.data.gmapi.response.data
            })
            .addCase(uploadFactoryLogo.pending, (state) => {
            })
            .addCase(uploadFactoryLogo.rejected, (state, action) => {
                state.isError = true
                state.errorMsg = action.payload
                console.error(`"uploadFactoryLogo": ${action.payload}`)
            })
            .addCase(downloadDatiAziendaById.fulfilled, (state, action) => {
                state.isFetching = false
                state.isSuccess = true
                let elements = document.querySelectorAll("input[name='company-name']");
                let companyName = elements.length > 0 ? elements[0].value.toLowerCase() : 'azienda';
                companyName = companyName.replaceAll('.', '').replaceAll(' ', '_');
                downloadDocument(action.payload.gmapi.response.data, ('datigenerali_' + companyName + '.pdf'));
            })
    },
})

// Export the reducer.
//export const { clearState } = aziendaSlice.actions

// Export the Slice by name.
export const aziendaSelector = (state) => state.azienda

export const downloadDatiAziendaById = createAsyncThunk(
    // Action route.
    "azienda/downloadDatiAziendaById",
    async ({ id }, thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                downloadDatiAziendaAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id: id
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    //console.error('Error "downloadOrganigrammaById":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "downloadOrganigrammaById":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "downloadOrganigrammaById":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)
/**
 *
 * @notice DomaSlice to handle state of Doma globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 */

// Redux.
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit"
// axios.
import axios from "axios"
// gmapi.
import { API_KEY,listaDomaAPI,downloadDomaAPI,eliminaDomaAPI } from "../../../../common/gmApi"
// cookie.
import { tkbox,getCookie } from "../../../../common/cookie"
// util.
import { downloadDocument,showDocument } from "../../../../common/util"

/********************************************
 *                                          *
 * @notice AsyncThunk API(s) Implementation *
 *                                          *
 ********************************************/
/**
 *
 *  @dev Get Doama data.
 *
 */
export const getDoma = createAsyncThunk(
    // Action route.
    "doma/getDoma",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                listaDomaAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                    },
                }
            )

            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    //console.error('Error "getDoma":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "getDoma":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "getDoma":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Download Doma document.
 *
 */
export const downloadDomaDoc = createAsyncThunk(
    // Action route.
    "doma/downloadDomaDoc",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { Object } obj
     * @param { string } obj.idBoxDoma
     * @param { string } obj.docName
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async ({ idBoxDoma,docName },thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                downloadDomaAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_doma: idBoxDoma,
                    },
                }
            )

            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    // Build a custom response withx extradata.
                    return {
                        data: response.data,
                        eventSender: idBoxDoma,
                    }
                } else {
                    //console.error('Error "downloadDomaDoc":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "downloadDomaDoc":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "downloadDomaDoc":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Show Doma document.
 *
 */
export const showDomaDoc = createAsyncThunk(
    // Action route.
    "doma/showDomaDoc",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { string } idBoxDoma
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (idBoxDoma,thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                downloadDomaAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_doma: idBoxDoma,
                    },
                }
            )

            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    // Build a custom response with extradata.
                    return {
                        data: response.data,
                        eventSender: idBoxDoma,
                    }
                } else {
                    //console.error('Error "showDomaDoc":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "showDomaDoc":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "showDomaDoc":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 * 
 * @dev Delete Doma item.
 * 
 */
export const deleteDoma = createAsyncThunk(
    // Action route-
    "doma/deleteDoma",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { string } idBoxDoma
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (idBoxDoma,thunkAPI) => {
        try {
            const response = await axios.delete(
                // url.
                eliminaDomaAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_doma: idBoxDoma
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    // Build a custom response with extradata.
                    return {
                        data: response.data,
                        eventSender: idBoxDoma,
                    }
                } else {
                    //console.error('Error "deleteDoma":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "deleteDoma":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "deleteDoma":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/***************************************
 *                                     *
 * @notice createSlice implementation. *
 *                                     *
 * @dev Create "domaSlice" feature.    *
 * @dev UI views state controller.     *
 *                                     *
 ***************************************/
export const domaSlice = createSlice({
    // Name, used in action types.
    name: "doma",
    // The initial state for the reducer.
    initialState: {
        domaItems: [],        // All doma items.
        isFetching: false,    // AsyncThunk is calling an API.
        isSuccess: false,     // AsyncThunk is success from API call.
        isError: false,       // AsyncThunk is fail from API call.
        errorMsg: "",         // Error message container.
        domaDoc: [],          // All doma document.
        domaDocName: "[]",    // Doma document name.
        domaDocSenderId: "",  // Doma document sender id.
        isFetchingDoc: false, // AsyncThunk is calling an API.
        isSuccessDoc: false,  // AsyncThunk is success from API call.
        isErrorDoc: false,    // AsyncThunk is fail from API call.
    },
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {
        /**
         * @action clearDomaDocSenderId
         */
        clearDomaDocSenderId: (state) => {
            state.domaDocSenderId = ""

            return state
        },
    },
    // AsyncThunk Reducers.
    // A "builder callback" function used to add more reducers, or
    // an additional object of "case reducers", where the keys should be other
    // action types.
    extraReducers: (builder) => {
        builder
            /*******************************************************************
             * 
             * @dev getDoma.
             * 
             */
            .addCase(getDoma.fulfilled,(state,action) => {
                state.isFetching = false
                state.isSuccess = true

                // Update domaItems sort by `data_ora_inserimento` key.
                const domaItems = action.payload.gmapi.response.data
                state.domaItems = domaItems.sort((firstEl,secondEl) => (
                    firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                ))
            })
            .addCase(getDoma.pending,(state) => {
                state.isFetching = true
            })
            .addCase(getDoma.rejected,(state,action) => {
                state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
            })
            /*******************************************************************
             * 
             * @dev downloadDomaDoc.
             * 
             */
            .addCase(downloadDomaDoc.fulfilled,(state,action) => {
                state.isFetchingDoc = false
                state.isSuccessDoc = true
                // Update the state with the file name from action payload.
                state.domaDoc = action.payload.data.gmapi.response.data
                // Update the state with the file name.
                let domaDocName = action.meta.arg.docName
                let splitted = domaDocName.split('_');
                if(splitted.length > 1 && /^\d+$/.test(splitted[0]))
                {
                    splitted.shift();
                    domaDocName = splitted.join('_');
                }

                state.domaDocName = domaDocName
                // Document download trigger.
                downloadDocument(state.domaDoc, (state.domaDocName ? state.domaDocName : ""))
            })
            .addCase(downloadDomaDoc.pending,(state,action) => {
                state.isFetchingDoc = true
                // Update the state with doc id.
                state.domaDocSenderId = action.meta.arg.idBoxDoma
            })
            .addCase(downloadDomaDoc.rejected,(state,action) => {
                state.isFetchingDoc = false
                state.isErrorDoc = true
                //state.errorMsgDoc = action.payload;
            })
            /*******************************************************************
             *
             * @dev showDomaDoc.
             *
             */
            .addCase(showDomaDoc.fulfilled,(state,action) => {
                // Needless.
                //state.isFetchingDoc = false;
                //state.isSuccessDoc = true;

                // get the base64 string.
                state.domaDoc = action.payload.data.gmapi.response.data
                // Get the file name.
                //state.domaDocName = action.meta.arg

                showDocument(state.domaDoc)
            })
            .addCase(showDomaDoc.pending,(state,action) => {
                //state.isFetchingDoc = true;
                state.domaDocSenderId = action.meta.arg; // Access to thunk argument passed in "downloadVisuraDoc" AsyncThunk.
            })
            .addCase(showDomaDoc.rejected,(state,action) => {
                //state.isFetchingDoc = false;
                //state.isErrorDoc = true;
                //state.errorMsgDoc = action.payload;
            })
            /*******************************************************************
             *
             * @dev deleteDoma.
             *
             */
            .addCase(deleteDoma.fulfilled,(state,action) => {
                //state.isFetching = false
                //state.isSuccess = true

                // Get the eventSender from the action payload.
                const idBoxDoma = action.payload.eventSender
                // Find the object wich corresponding id.
                const domaToRemove = state.domaItems.findIndex((obj) => obj.id_box_doma === idBoxDoma)

                // If found then remove it from the list.
                if (domaToRemove > -1) {
                    state.domaItems.splice(domaToRemove,1) // Remove the item selected.
                }
            })
            .addCase(deleteDoma.pending,(state,action) => {
                //state.isFetching = true
            })
            .addCase(deleteDoma.rejected,(state,action) => {
                //state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
                //state.needsReload = true
            })

    },
})

// Export the reducer.
export const { clearDomaDocSenderId } = domaSlice.actions

// Export the Slice by name.
export const domaSelector = (state) => state.doma

/**
 *
 * @notice User Settings view.
 *
 * @dev Component as allow the user change its settings.
 *
 */

// React.
import { useEffect } from "react"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// react-hook-form.
import { useForm } from "react-hook-form"
// UserSlice.
import { changePassword, userSelector } from "../../features/user/UserSlice"
// UI.
import LayoutDetail from "../LayoutDetail"
import LayoutDetailContainer from "../LayoutDetailContainer"
import { OvalLoader } from "../../base/Icons"

/**
 *
 * @dev User Settings UI view.
 * 
 * @function UserSettings
 *
 * @returns JSX.Element
 *
 */
export default function UserSettings() {

    const dispatch = useDispatch()

    // Get the UserSlice selector.
    const {
        isSuccess,
        isFetching,
        isError
    } = useSelector(userSelector)

    /**
     *
     *  Initialize a React hook form State using UseForm Hook.
     *
     * @see https://react-hook-form.com/get-started
     *
     */
    const {
        register,
        handleSubmit,
        //setError,
        //clearErrors,
        formState,
        //formState: { errors } // subscription.
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {
            newPassword: "",
            confirmPassword: ""
        }
    }) // useForm Hook.

    const { isDirty, isValid } = formState // Get the form state.

    /**
     * 
     * @dev onSubmit Form handler.
     * @dev Post data to insert a new document.
     * 
     * @const onSubmit
     * @param { * } data
     * @returns void
     * 
     */
    const onSubmit = (data) => {
        const { 
            newPassword,
            confirmPassword
         } = data

        if (isValid && data && newPassword === confirmPassword) {
            dispatch(changePassword(newPassword))
        } else {
            alert("Le password inserite sono diverse")
        }
    }

    useEffect(() => {}, [
        isSuccess,
        isFetching,
        isError
    ])

    return(
        <LayoutDetail title={"Impostazioni utente"}>
            <LayoutDetailContainer>
                <div className="flex gap-10">
                <form 
                    className="w-full pr-6"
                    action="" 
                    onSubmit={handleSubmit(onSubmit)} 
                    method="POST"
                >
                    <div className="flex flex-col mb-10 space-y-4">
                        <div className="flex flex-col">
                            <label
                                htmlFor="newPassword"
                                className="
                                    mb-2 
                                    pt-1 
                                    text-left 
                                    text-base 
                                    text-afm-box-dark-gray"
                            >
                                Nuova Password
                            </label>
                            <input
                                type="password"
                                name="newPassword"
                                placeholder="**************"
                                className="
                                    w-full 
                                    px-3 
                                    py-2 
                                    bg-afm-box-light-gray 
                                    text-afm-box-dark-gray 
                                    placeholder-afm-box-dark-gray/[.50] 
                                    border border-gray-300 rounded-md 
                                    focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                {...register("newPassword", {
                                    required: true,
                                    validate: value => (value !== "") && (value !== undefined)
                                })}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label
                                htmlFor="confirmPassword"
                                className="
                                    mb-2 
                                    pt-1 
                                    text-left 
                                    text-base 
                                    text-afm-box-dark-gray"
                            >
                                Conferma Password
                            </label>
                            <input
                                type="password"
                                name="confirmPassword"
                                placeholder="**************"
                                className="
                                    w-full 
                                    px-3 
                                    py-2 
                                    bg-afm-box-light-gray 
                                    text-afm-box-dark-gray 
                                    placeholder-afm-box-dark-gray/[.50] 
                                    border border-gray-300 rounded-md 
                                    focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                {...register("confirmPassword", {
                                    required: true,
                                    validate: value => (value !== "") && (value !== undefined)
                                })}
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        value="Submit"
                        disabled={!isDirty || !isValid}
                        className={`
                            ${!isDirty || !isValid ? "disabled:opacity-50" : null}
                            flex 
                            w-auto 
                            px-7 
                            h-10 
                            gap-1 
                            items-center justify-center 
                            uppercase whitespace-nowrap font-semibold 
                            text-white 
                            bg-afm-box-blue 
                            hover:opacity-80 
                            focus:outline-none`}
                        >
                            {isFetching && <OvalLoader />} Salva Modifiche
                    </button>
                </form>
                </div>
            </LayoutDetailContainer>
        </LayoutDetail>
    )
}
// Redux.
import { configureStore } from "@reduxjs/toolkit"
// UserSlice.
import { userSlice } from "./components/features/user/UserSlice"
// AziendaSlice.
import { aziendaSlice } from "./components/features/generale/azienda/AziendaSlice"
// VisuraSlice.
import { visuraSlice } from "./components/features/generale/visura/VisuraSlice"
import { visuraModalSlice } from "./components/features/generale/visura/VisuraModalSlice"
// DomaSlice.
import { domaSlice } from "./components/features/generale/doma/DomaSlice"
import { domaModalSlice } from "./components/features/generale/doma/DomaModalSlice"
// DurcSlice.
import { dataSlice as durcSlice } from "./components/features/generale/durc/DurcSlice"
import { durcModalSlice } from "./components/features/generale/durc/DurcModalSlice"
// DurfSlice.
import { dataSlice as durfSlice } from "./components/features/generale/durf/DurfSlice"
import { durfModalSlice } from "./components/features/generale/durf/DurfModalSlice"
// OrganigrammaSlice.
import { organigrammaSlice } from "./components/features/organigramma/OrganigrammaSlice"
// OrganicoSlice.
import { organicoSlice } from "./components/features/generale/organico/OrganicoSlice"
import { organicoModalSlice } from "./components/features/generale/organico/OrganicoModalSlice"
import { corsoModalSlice } from "./components/features/generale/organico/CorsoModalSlice"
import { documentoModalSlice } from "./components/features/generale/organico/DocumentoModalSlice"
import { visitaModalSlice } from "./components/features/generale/organico/VisitaModalSlice"
// InsediamentiSlice.
import { insediamentiSlice } from "./components/features/insediamenti/InsediamentiSlice"
// PrivacySlice.
import { privacySlice } from "./components/features/privacy/PrivacySlice"
// CantiereSlice.
import { cantiereSlice } from "./components/features/cantiere/CantiereSlice"
// ManutenzioniSlice.
import { manutenzioniSlice } from "./components/features/manutenzioni/ManutenzioniSlice"
import { manutenzioniModalSlice } from "./components/features/manutenzioni/ManutenzioniModalSlice"
// DocumentiMasterSlice.
import { documentiMasterSlice } from "./components/features/documenti-master/DocumentiMasterSlice"
// DuvriSlice.
import { duvriSlice } from "./components/features/duvri/DuvriSlice"
import { duvriModalSlice } from "./components/features/duvri/DuvriModalSlice"
// ComunicazioniSlice.
import { comunicazioniSlice } from "./components/features/comunicazioni/ComunicazioniSlice"
// ScadenzeSlice.
import { scadenzeSlice } from "./components/features/scadenze/ScadenzeSlice"
import { scadenzeModalSlice } from "./components/features/scadenze/ScadenzeModalSlice"
// SearchSlice.
import { searchSlice } from "./components/features/search/SearchSlice"
// Admin
import { listaAziendeSlice, switchUserSlice } from "./components/features/generale/admin/AdminSlice";
import { fileManutenzioniModalSlice } from "./components/features/manutenzioni/FileManutenzioniModalSlice"
import { filePrivacyModalSlice } from "./components/features/privacy/PrivacyModalSlice"

/**
 * 
 * @notice Redux Toolkit Store configuration.
 * @dev Globally store configuration.
 * 
 */
export default configureStore({
    reducer: {
        // User.
        user: userSlice.reducer,
        // Generale Azienda.
        azienda: aziendaSlice.reducer,
        // Generale Visura.
        visura: visuraSlice.reducer,
        visuraModal: visuraModalSlice.reducer,
        // Generale Doma.
        doma: domaSlice.reducer,
        domaModal: domaModalSlice.reducer,
        // Generale Durc.
        durc: durcSlice.reducer,
        durcModal: durcModalSlice.reducer,
        // Generale Durf.
        durf: durfSlice.reducer,
        durfModal: durfModalSlice.reducer,
        // Generale Organigramma.
        organigramma: organigrammaSlice.reducer,
        // Generale Organico.
        organico: organicoSlice.reducer,
        organicoModal: organicoModalSlice.reducer,
        corsoModal: corsoModalSlice.reducer,
        documentoModal: documentoModalSlice.reducer,
        visitaModal: visitaModalSlice.reducer,
        // Insediamenti.
        insediamenti: insediamentiSlice.reducer,
        // Privacy.
        privacy: privacySlice.reducer,
        filePrivacyModal: filePrivacyModalSlice.reducer,
        // Lavori in Cantiere.
        cantiere: cantiereSlice.reducer,
        // Manutenzioni.
        manutenzioni: manutenzioniSlice.reducer,
        manutenzioniModal: manutenzioniModalSlice.reducer,
        fileMenutenzioniModal: fileManutenzioniModalSlice.reducer,
        // Documenti Master.
        documentiMaster: documentiMasterSlice.reducer,
        // Duvri.
        duvri: duvriSlice.reducer,
        duvriModal: duvriModalSlice.reducer,
        // Comunicazioni.
        comunicazioni: comunicazioniSlice.reducer,
        // Scadenze.
        scadenze: scadenzeSlice.reducer,
        scadenzeModal: scadenzeModalSlice.reducer,
        // Search feature.
        search: searchSlice.reducer,
        adminListaAziende: listaAziendeSlice.reducer,
        adminSwitchUser: switchUserSlice.reducer,
    },
    /**
     *
     * Middleware configuration.
     *
     */
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types.
                ignoredActions: [
                    // manutenzioni.
                    'manutenzioni/addMachine/pending',
                    'manutenzioni/addMachine/fulfilled',
                    'manutenzioni/addMachine/rejected',
                    // search.
                    "search/searchData",
                    // comunicazioni.
                    "comunicazioni/downloadNoticeDoc/fulfilled",
                    "comunicazioni/downloadNoticeDoc/pending",
                    "comunicazioni/downloadNoticeDoc/rejected",
                    "comunicazioni/showNoticeDoc/fulfilled",
                    "comunicazioni/showNoticeDoc/pending",
                    "comunicazioni/showNoticeDoc/rejected",
                    // privacy.
                    "privacy/showPrivacyDocById/fulfilled",
                    "privacy/showPrivacyDocById/fulfilled",
                    "privacy/showPrivacyDocById/fulfilled",
                    "privacy/downloadPrivacyDocById/fulfilled",
                    // visura.
                    "visura/downloadVisuraDoc/fulfilled",
                    "visura/downloadVisuraDoc/pending",
                    "visura/downloadVisuraDoc/rejected",
                    "visura/showVisuraDoc/fulfilled",
                    "visura/showVisuraDoc/pending",
                    "visura/showVisuraDoc/rejected",
                    // organico.
                    "organico/downloadEmployeeDocById/fulfilled",
                    "organico/downloadEmployeeDocById/pending",
                    "organico/downloadEmployeeDocById/rejected",
                    "organico/showEmployeeDocById/fulfilled",
                    "organico/showEmployeeDocById/pending",
                    "organico/showEmployeeDocById/rejected",
                    "organico/uploadEmployeeLogo/fulfilled",
                    "organico/uploadEmployeeLogo/pending",
                    "organico/uploadEmployeeLogo/rejected",
                    // corso
                    "corso/uploadCorso/fulfilled",
                    "corso/uploadCorso/pending",
                    "corso/uploadCorso/rejected",
                    // documentiMaster.
                    "documentiMaster/downloadMasterDoc/fulfilled",
                    "documentiMaster/downloadMasterDoc/pending",
                    "documentiMaster/downloadMasterDoc/rejectedx",
                    // azienda.
                    "azienda/uploadFactoryLogo/fulfilled",
                    "azienda/uploadFactoryLogo/pending",
                    "azienda/uploadFactoryLogo/rejected",
                    "azienda/downloadFactoryLogo/fulfilled",
                    "azienda/downloadFactoryLogo/pending",
                    "azienda/downloadFactoryLogo/rejected"
                ],
                ignoredActionPaths: ["search.search"],
                ignoredPaths: ["search.search"],
            },
        }),
})

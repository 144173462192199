import CompliantStatus from './CompliantStatus';
import Tooltip from "./Tooltip"

/**
 *
 * @const Circle
 *
 * @dev Circle UI component.
 *
 * @param { string } message
 *
 * @returns JSX.Element
 *
 */
export default function CompliantCircle(props) {
    const { status } = props;
    /**
     * getStatus function.
     * It return the TailwindCSS class token as the corresponding status.
     */
    const getStatus = (status) => {
        switch (status) {
            case "compliant":
                return "bg-afm-box-success-green"

            case "non-compliant":
                return "bg-afm-box-error-red"

            case "waiting":
                return "bg-afm-box-waiting-yellow"

            default:
                return "bg-afm-box-light-gray"
        }
    }

    // Enums can be accesss by using namespaced assignments.
    // this makes it semantically clear that "compliant" is a "CompliantStatus"
    const compliant = CompliantStatus.compliant;
    const nonCompliant = CompliantStatus.nonCompliant;

    const message = status === true ? compliant.message : nonCompliant.message;
    const statusClassName = status === true ? compliant.value : nonCompliant.value;

    return (
        <Tooltip message={message} twBackground="bg-afm-box-blue">
            <div
                className={`
                    rounded-full 
                    h-3 
                    w-3 
                    ${getStatus(statusClassName)}`}
            ></div>
        </Tooltip>
    )
}

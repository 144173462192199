import { Fragment, useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
// OrganicoSlice.
import {
    clearEmployeeCourseDocSenderId, deleteEmployeeCourseDocById,
    downloadEmployeeCourseDocById, getEmployeeCoursesById,
    clearEmployeeExamSenderId, deleteEmployeeExamById,
    downloadEmployeeExamById, getEmployeeExamsById,
    deleteEmployeeDocById, downloadEmployeeDocById, getEmployeeDocsById,
    getEmployeeImgById, getEmployeeInfoById,
    organicoSelector,
    showEmployeeCourseDocById,
    showEmployeeDocById,
    uploadEmployeeLogo,
} from "../../features/generale/organico/OrganicoSlice"
// OrganicoModalSlice.

// UI.
import Button from "../../base/Button"
import DropDown from "../../base/DropDown"
import Grid from "../../base/Grid"
import ImgUploader from "../../base/ImgUploader"
import Input from "../../base/Input"
import Pillow from "../../base/Pillow"
// import SearchBox from "../../base/SearchBox"
import SubHeader from "../../base/SubHeader"
import Tabs from "../../base/Tabs"
import LayoutDetailContainer from "../LayoutDetailContainer"
// import VisitaModal from "./VisitaModal"
import { corsoModalSelector, openModal as openCorsoModal } from "../../features/generale/organico/CorsoModalSlice"
import { documentoModalSelector, openModal as openDocumentoModal } from "../../features/generale/organico/DocumentoModalSlice"
import { visitaModalSelector, openModal as openVisitaModal } from "../../features/generale/organico/VisitaModalSlice"
import CorsoModal from "./CorsoModal"
import DocumentoModal from "./DocumentoModal"
import VisitaModal from "./VisitaModal"
import PdfViewer from "../../base/PdfViewer"
import Tooltip from "../../base/Tooltip"
// Icons.
import {
    //Printer, 
    DocumentText,
    InformationCircle,
    OvalLoader,
    PluSm
} from "../../base/Icons"
import OgranicoDetailSkeleton from "./../organico/OgranicoDetailSkeleton"
/**
 *
 * @dev Organico detail view.
 * 
 * @function OrganicoDetail
 *
 * @returns JSX.Element
 *
 */
export default function OrganicoDetail() {
    const dispatch = useDispatch()
    // screen modals
    const corsoModalStatus = useSelector(corsoModalSelector)
    const documentoModalStatus = useSelector(documentoModalSelector)
    const visitaModalStatus = useSelector(visitaModalSelector)
    // Tab categories.
    const [categories, setCategories] = useState([])
    // Corsi grid header title.
    const [corsiGridHeaderTitleItems, setCorsiGridHeaderTitleItems] = useState([])
    // Corsi grid items.
    const [corsiGridItems, setCorsiGridItems] = useState([])
    // Visite grid header title.
    const [visiteGridHeaderTitleItems, setVisiteGridHeaderTitleItems] = useState([])
    // Visite grid items.
    const [visiteGridItems, setVisiteGridItems] = useState([])
    // Documenti grid header title.
    const [documentiGridHeaderTitleItems, setDocumentiGridHeaderTitleItems] = useState([])
    // Documenti grid items.
    const [documentiGridItems, setDocumentiGridItems] = useState([])
    const [modalComponent, setModalComponent] = useState(null);
    // organicoSlice State.
    const {
        employeeImg,
        employeeInfo,
        employeeCourses,
        employeeExams,
        employeeDocs,
        isFetching,
        isSuccess,
        isError,
        employeeCourseDoc,
        employeeCourseDocSenderId,
        isFetchingCourseDoc,
        isSuccessCourseDoc,
        defaultTabIndex,
        // employeeExam,
        employeeExamSenderId,
        isFetchingExam,
        // isSuccessExam,
    } = useSelector(organicoSelector)

    // Get the url parameter.
    const { id } = useParams()

    /**
     * Component side effects.
     */
    useEffect(() => {
        dispatch(getEmployeeImgById(id))
        dispatch(getEmployeeInfoById(id))
        dispatch(getEmployeeCoursesById(id))
        dispatch(getEmployeeExamsById(id))
        dispatch(getEmployeeDocsById(id))
    }, [dispatch, id])

    useEffect(() => {
        /**
         * Build "Corsi" category grid items.
         */
        const corsiItems = []
        employeeCourses.map((course) => {
            const corsiGridItem = [
                {
                    text: "",
                    twColSpan: "col-span-1",
                    twAdditionalClass: "justify-self-start text-afm-box-light-gray-text",
                    component: {},
                },
                {
                    text: "Corso",
                    additionalText: "",
                    twColSpan: "col-span-3",
                    twAdditionalClass: "",
                    component: "",
                },
                {
                    text: "Data Attestato",
                    twColSpan: "col-span-1",
                    twAdditionalClass: "",
                    component: "",
                },
                {
                    text: "Data Scadenza",
                    twColSpan: "col-span-1",
                    twAdditionalClass: "",
                    component: "",
                },
                {
                    text: "Tipo",
                    twColSpan: "col-span-2",
                    twAdditionalClass: "text-white",
                    component: {},
                },
                {
                    text: "Allegato",
                    twColSpan: "col-span-2",
                    twAdditionalClass: "",
                    component: {},
                },
                {
                    text: "",
                    twColSpan: "col-span-2",
                    twAdditionalClass: "justify-self-end",
                    component: {},
                },
            ]
            // Item 0.
            corsiGridItem[0].component = (
                <div
                    className="
                        flex 
                        space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                        justify-self-start 
                        text-afm-box-light-gray-text"
                >
                    <DocumentText />
                    <span className="border-l border-afm-box-light-gray-text"></span>
                </div>
            )
            // Item 1.
            corsiGridItem[1].text = course.titolo
            // Item 2.
            corsiGridItem[2].text = course.data_attestato
            // Item 3.
            corsiGridItem[3].text = course.data_scadenza
            // Item 4.
            corsiGridItem[4].component = <Pillow text={course.tipo} twBackground="bg-afm-box-green" />
            // Item 5.
            corsiGridItem[5].component = (
                <PdfViewer action={showEmployeeCourseDocById(course.id_box_corsi_lavoratore)} />
            )
            // Item 6.
            corsiGridItem[6].component = (
                <div className="flex items-center space-x-2">
                    {/* If isFetchingCourseDoc and employeeCourseDocSenderId State correspond to Button id pressed, than show the OvalLoader. */}
                    {isFetchingCourseDoc &&
                        employeeCourseDocSenderId === course.id_box_corsi_lavoratore && (
                            <OvalLoader strokeColor="#aaa" />
                        )}
                    <Button
                        text="DOWNLOAD"
                        id={course.id_box_corsi_lavoratore}
                        twTextColor="text-white"
                        twBackground="bg-afm-box-blue"
                        twFocusBG="focus:bg-afm-box-blue/[0.5]"
                        actions={[
                            clearEmployeeCourseDocSenderId(),
                            downloadEmployeeCourseDocById(
                                {
                                    idBoxCorsiLavoratore: course.id_box_corsi_lavoratore,
                                    docName: course.allegato
                                }
                            ),
                        ]}
                    />
                    {/* Only Admin can remove docs. */}
                    {employeeInfo.id_origine === "0" &&
                        <DropDown
                            buttonItems={[
                                {
                                    type: "delete",
                                    method: deleteEmployeeCourseDocById({
                                        idBoxLavoratori: id,
                                        idBoxCorsiLavoratore: course.id_box_corsi_lavoratore
                                    })
                                }
                            ]}
                        />
                    }
                </div>
            )
            corsiItems.push(corsiGridItem)
            return setCorsiGridItems(corsiItems)
        })
        setCorsiGridHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Corso",
                twColSpan: "col-span-3",
                twAdditionalClass: "",
                component: "",
                tooltipMessage: "Durante l’inserimento dei corsi possibilità di scegliere il corso attraverso un menù a tendine con l’elenco dei corsi."
            },
            {
                title: "Data attestato",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data scadenza",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
                tooltipMessage: "È possibile inserire la data di scadenza secondo le periodicità consigliate nel file PERIODICITA’ SCADENZE CORSI"
            },
            {
                title: "Tipo",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Allegato",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            // {
            //     title: "",
            //     twColSpan: "col-span-2",
            //     twAdditionalClass: "justify-self-end",
            //     component: <SearchBox />,
            // },
        ])
        /**
         * Build "Visite" category grid items.
         */
        const visiteItems = []
        employeeExams.map((exam) => {
            const visiteGridItem = [
                {
                    text: "",
                    twColSpan: "col-span-1",
                    twAdditionalClass: "justify-self-start text-afm-box-light-gray-text",
                    component: {},
                },
                {
                    text: "Codice visita",
                    additionalText: "",
                    twColSpan: "col-span-4",
                    twAdditionalClass: "",
                    component: "",
                },
                {
                    text: "Data visita",
                    twColSpan: "col-span-2",
                    twAdditionalClass: "",
                    component: "",
                },
                {
                    text: "Data scadenza",
                    twColSpan: "col-span-2",
                    twAdditionalClass: "",
                    component: "",
                },
                {
                    text: "",
                    twColSpan: "col-span-3",
                    twAdditionalClass: "inline-flex gap-2 justify-self-end",
                    component: {},
                },
            ]
            // Item 0.
            visiteGridItem[0].component = (
                <div
                    className="
                        flex 
                        space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                        justify-self-start 
                        text-afm-box-light-gray-text"
                >
                    <DocumentText />
                    <span className="border-l border-afm-box-light-gray-text"></span>
                </div>
            )
            // Item 1.
            visiteGridItem[1].text = exam.codice_visita
            // Item 2.
            visiteGridItem[2].text = exam.data_visita
            // Item 3.
            visiteGridItem[3].text = exam.data_scadenza
            /* Create a Blob with pdf.  */
            /*
            const binaryData = []
            binaryData.push(exam.allegato)
            const pdf = window.URL.createObjectURL(
                new Blob(binaryData, { type: "application/pdf" })
            )
            */
            // Item 4.
            // visiteGridItem[4].component = <Pdf pdf={pdf} />
            // Item 4.
            visiteGridItem[4].component = (
                <div className="flex space-x-2">
                    {exam.allegato && (
                        <>
                            {/* If isFetchingExam and employeeExamSenderId State correspond to Button id pressed, than show the OvalLoader. */}
                            {isFetchingExam && employeeExamSenderId === exam.id_box_visite_lavoratore && (
                                <OvalLoader strokeColor="#aaa" />
                            )}
                            <Button
                                text="DOWNLOAD"
                                id={exam.id_box_visite_lavoratore}
                                twTextColor="text-white"
                                twBackground="bg-afm-box-blue"
                                twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                actions={[
                                    clearEmployeeExamSenderId(),
                                    downloadEmployeeExamById(
                                        {
                                            idBoxVisitaLavoratore: exam.id_box_visite_lavoratore,
                                            docName: exam.allegato
                                        }
                                    ),
                                ]}
                            />
                            {/* Only Admin can remove docs. */}
                            {employeeInfo.id_origine === "0" &&
                                <DropDown
                                    buttonItems={[
                                        {
                                            type: "delete",
                                            method: deleteEmployeeExamById({
                                                idBoxLavoratori: id,
                                                idBoxVisitaLavoratore: exam.id_box_visite_lavoratore
                                            })
                                        }
                                    ]}
                                />
                            }
                        </>
                    )}
                </div>
            )
            visiteItems.push(visiteGridItem)
            return setVisiteGridItems(visiteItems)
        })
        setVisiteGridHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Codice visita",
                twColSpan: "col-span-4",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data visita",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data scadenza",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
                tooltipMessage: "È possibile inserire la data di scadenza secondo le periodicità stabilita dal medico competente presente nel giudizio di idoneità."
            },
            // {
            //     title: "",
            //     twColSpan: "col-span-3",
            //     twAdditionalClass: "justify-self-end",
            //     component: <SearchBox />,
            // },
        ])
        /**
         * Build Grid items for Documenti category. 
         */
        const documentiItems = []
        employeeDocs.map((doc) => {
            const documentiGridItem = [
                {
                    text: "",
                    twColSpan: "col-span-1",
                    twAdditionalClass: "justify-self-start text-afm-box-light-gray-text",
                    component: {},
                },
                {
                    text: "Documento",
                    additionalText: "",
                    twColSpan: "col-span-2",
                    twAdditionalClass: "",
                    component: "",
                },
                {
                    text: "Data rilascio",
                    twColSpan: "col-span-2",
                    twAdditionalClass: "",
                    component: "",
                },
                {
                    text: "Data Scadenza",
                    twColSpan: "col-span-2",
                    twAdditionalClass: "",
                    component: "",
                },
                {
                    text: "Tipo",
                    twColSpan: "col-span-2",
                    twAdditionalClass: "text-white",
                    component: {},
                },
                {
                    text: "Allegato",
                    twColSpan: "col-span-1",
                    twAdditionalClass: "",
                    component: {},
                },
                {
                    text: "",
                    twColSpan: "col-span-2",
                    twAdditionalClass: "justify-self-end",
                    component: {},
                },
            ]
            // Item 0.
            documentiGridItem[0].component = (
                <div
                    className="
                        flex 
                        space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                        justify-self-start 
                        text-afm-box-light-gray-text"
                >
                    <DocumentText />
                    <span className="border-l border-afm-box-light-gray-text"></span>
                </div>
            )
            // Item 1.
            documentiGridItem[1].text = doc.tipo
            // Item 2.
            documentiGridItem[2].text = doc.data_documento
            // Item 3.
            documentiGridItem[3].text = doc.data_scadenza
            // Item 4.
            documentiGridItem[4].component = (
                <Pillow text={doc.tipo} twBackground="bg-afm-box-green" />
            )
            // Item 5.
            documentiGridItem[5].component = <PdfViewer action={showEmployeeDocById(doc.id_box_documenti_lavoratore)} />
            // Item 6.
            documentiGridItem[6].component = (
                <div className="flex space-x-2">
                    <Button
                        text="download"
                        twTextColor="text-white"
                        twBackground="bg-afm-box-blue"
                        twFocusBG="focus:bg-afm-box-blue/[0.5]"
                        actions={[
                            clearEmployeeCourseDocSenderId(),
                            downloadEmployeeDocById(
                                {
                                    idBoxCorsiLavoratore: doc.id_box_corsi_lavoratore,
                                    docName: doc.allegato
                                }
                            ),
                        ]}
                    />
                    {/* Only Admin can remove docs. */}
                    {employeeInfo.id_origine === "0" &&
                        <DropDown
                            buttonItems={[
                                {
                                    type: "delete",
                                    method: deleteEmployeeDocById({
                                        idBoxLavoratori: id,
                                        idBoxDocumentiLavoratore: doc.id_box_documenti_lavoratore
                                    })
                                }
                            ]}
                        />
                    }
                </div>
            )
            documentiItems.push(documentiGridItem)
            return setDocumentiGridItems(documentiItems)
        })
        setDocumentiGridHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Documento",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data rilascio",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data scadenza",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
                tooltipMessage: "È possibile inserire la data di scadenza secondo le periodicità stabilita."
            },
            {
                title: "Tipo",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Allegato",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            // {
            //     title: "",
            //     twColSpan: "col-span-2",
            //     twAdditionalClass: "justify-self-end",
            //     component: <SearchBox />,
            // },
        ])
    }, [
        employeeInfo.id_origine,
        employeeImg,
        employeeCourses,
        employeeExams,
        employeeDocs,
        employeeCourseDoc,
        employeeCourseDocSenderId,
        isFetchingCourseDoc,
        isSuccessCourseDoc,
        id
    ])
    useEffect(() => {
        /**
         *
         *  Build Tab categories.
         *
         */
        const categoryItems = {
            Informazioni: [
                {
                    id: 1,
                    component: (
                        <Fragment>
                            <div
                                className="
                                    h-16 
                                    items-center 
                                    flex 
                                    space-x-8 
                                    p-3 
                                    rounded-sm 
                                    bg-gray-100"
                            >
                                <h1 className="text-left text-lg font-bold">
                                    Informazioni
                                </h1>
                            </div>
                            <div className="flex gap-10 pt-10">
                                <div className="relative">
                                    <ImgUploader
                                        img={employeeImg}
                                        action={uploadEmployeeLogo}
                                        id={employeeInfo.id_box_lavoratori}
                                    />
                                </div>
                                <form action="" method="POST" className="w-full pr-6">
                                    <div className="flex gap-10">
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="employee-name"
                                                labelTitle="Nome"
                                                inputType="text"
                                                inputName="employee-name"
                                                placeholder="Lorem ipsum"
                                                required={true}
                                                inputValue={employeeInfo.nome}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="employee-surname"
                                                labelTitle="Cognome"
                                                inputType="text"
                                                inputName="employee-surname"
                                                placeholder="Lorem ipsum"
                                                required={true}
                                                inputValue={employeeInfo.cognome}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-10">
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="employee-cf"
                                                labelTitle="Codice Fiscale"
                                                inputType="text"
                                                inputName="employee-cf"
                                                placeholder="RSS MRA 70A41 F205Z"
                                                required={true}
                                                inputValue={employeeInfo.codice_fiscale}
                                            />
                                        </div>
                                        {/*
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="employee-birth-date"
                                                labelTitle="Data di nascita"
                                                inputType="text"
                                                inputName="employee-birth-date"
                                                placeholder="05 / 11 / 1993"
                                                required={true}
                                                //inputValue={employeeInfo.data_di_nascita}
                                            />
                                        </div>
                                        */}
                                    </div>
                                    <div className="flex gap-10">
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="employee-birth-place"
                                                labelTitle="Luogo di nascita"
                                                inputType="text"
                                                inputName="employee-birth-place"
                                                placeholder={"Bergamo"}
                                                required={true}
                                            //inputValue={employeeInfo.luogo_di_nascita}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="employee-nationality"
                                                labelTitle="Nazionalità"
                                                inputType="text"
                                                inputName="employee-nationality"
                                                placeholder={"Italiana"}
                                                required={true}
                                            //inputValue={employeeInfo.nazionalità}
                                            />
                                        </div>
                                    </div>
                                    <div className="my-10">
                                        <div className="flex gap-10">
                                            <div className="flex-1">
                                                <Input
                                                    labelHtmlFor="employee-hire-date"
                                                    labelTitle="Data di Assunzione"
                                                    inputType="text"
                                                    inputName="employee-hire-date"
                                                    placeholder="05 / 11 / 2021"
                                                    required={true}
                                                //inputValue={employeeInfo.data_assunzione}
                                                />
                                            </div>
                                            <div className="flex-1">
                                                <Input
                                                    labelHtmlFor="employee-type"
                                                    labelTitle="Tipologia Dipendente"
                                                    inputType="text"
                                                    inputName="employee-type"
                                                    placeholder="Lorem Ipsum"
                                                    required={true}
                                                    inputValue={employeeInfo.tipo_dipendente}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex gap-10">
                                            <div className="flex-1">
                                                <Input
                                                    labelHtmlFor="employee-title"
                                                    labelTitle="Qualifica"
                                                    inputType="text"
                                                    inputName="employee-title"
                                                    required={true}
                                                    inputValue={employeeInfo.qualifica}
                                                />
                                            </div>
                                            <div className="flex-1">
                                                <Input
                                                    labelHtmlFor="employee-job"
                                                    labelTitle="Mansione"
                                                    inputType="text"
                                                    inputName="employee-job"
                                                    placeholder="Lorem Ipsum"
                                                    required={true}
                                                    inputValue={employeeInfo.mansione}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex space-x-10">
                                        <Button
                                            disabled={true}
                                            text="Salva modifiche"
                                            twTextColor="text-white"
                                            twBackground="bg-afm-box-blue"
                                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                        />
                                        {/* TODO: Wait API implementation.*/}
                                        {/*
                                        <Button
                                            disabled={true}
                                            text="Crea tesserino"
                                            twTextColor="text-white"
                                            twBackground="bg-afm-box-dark-gray"
                                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                        />
                                        */}
                                    </div>
                                </form>
                            </div>
                        </Fragment>
                    ),
                },
            ],
            Corsi: [
                {
                    id: 1,
                    component: (
                        <Fragment>
                            <div
                                className="
                                    h-16 
                                    items-center 
                                    flex 
                                    space-x-8 
                                    p-3 
                                    rounded-sm 
                                    bg-gray-100"
                            >
                                <h1 className="text-left text-lg font-bold flex">
                                    Corsi
                                    <div className="ml-2">
                                        <Tooltip
                                            message="È consentito inserire i corsi svolti in materia di salute e sicurezza previsti dalla normativa vigente."
                                            twBackground="bg-afm-box-blue"
                                            translateTooltip={true}
                                        >
                                            <InformationCircle />
                                        </Tooltip>
                                    </div>
                                </h1>
                                <div className="flex flex-1 justify-end space-x-5">
                                    {/*
                                    <Button
                                        text='Scarica tutto'
                                        icon={
                                                (isFetchingCourseDoc) &&
                                            <OvalLoader strokeColor='#aaa' />
                                        }
                                        twTextColor='text-black'
                                        twBackground='bg-white'
                                        twFocusBG='focus:bg-white/[0.7]'
                                        actions={[downloadEmployeeCourseDocById(id)]}
                                    />
                                    */}
                                    {/* <Button
                                        disabled={true}
                                        text="Scarica Tutto"
                                        twTextColor="text-black"
                                        twBackground="bg-white"
                                        twFocusBG="focus:bg-white/[0.7]"
                                    //icon={ }
                                    /> */}
                                    <Button
                                        text="Aggiungi corso"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        onClick={() => {
                                            // console.log('aggiungi corso')
                                            // setModalComponent(<CorsoModal id={id} />);
                                            // dispatch(openModal())
                                            dispatch(openCorsoModal());
                                        }}
                                        icon={<PluSm />}
                                    />
                                </div>
                            </div>
                            {corsiGridItems.length < 1 ? (
                                <div className="flex items-center justify-center my-14">
                                    <p className="text-xl">... Non ci sono corsi ...</p>
                                </div>
                            ) : (
                                <Grid
                                    twGridTemplateColumns="grid-cols-12"
                                    headerTitleItems={corsiGridHeaderTitleItems}
                                    items={corsiGridItems}
                                />
                            )}
                        </Fragment>
                    ),
                },
            ],
            Visite: [
                {
                    id: 1,
                    component: (
                        <Fragment>
                            <div
                                className="
                                    h-16 
                                    items-center 
                                    flex 
                                    space-x-8 
                                    p-3 
                                    rounded-sm
                                    bg-gray-100"
                            >
                                <h1 className="text-left text-lg font-bold">
                                    Visite
                                </h1>
                                <Tooltip
                                    message="È consentito inserire le visite mediche preventive e periodiche svolte dal medico competente."
                                    twBackground="bg-afm-box-blue"
                                >
                                    <InformationCircle />
                                </Tooltip>
                                <div className="flex flex-1 justify-end space-x-5">
                                    {/* <Button
                                        disabled={true}
                                        text="Scarica tutto"
                                        twTextColor="text-black"
                                        twBackground="bg-white"
                                        twFocusBG="focus:bg-white/[0.7]"
                                    /> */}
                                    <Button
                                        text="Aggiungi visita"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        onClick={() => {
                                            // console.log('aggiungi visita');
                                            dispatch(openVisitaModal())
                                        }}
                                        icon={<PluSm />}
                                    />
                                </div>
                            </div>
                            {visiteGridItems.length < 1 ? (
                                <div className="flex items-center justify-center my-14">
                                    <p className="text-xl">... Non ci sono visite ...</p>
                                </div>
                            ) : (
                                <Grid
                                    twGridTemplateColumns="grid-cols-12"
                                    headerTitleItems={visiteGridHeaderTitleItems}
                                    items={visiteGridItems}
                                />
                            )}
                            {/* If Modal window is open, then render. */}
                            {/* {modalIsOpen && <VisitaModal />} */}
                            {/* <VisitaModal /> */}

                        </Fragment>
                    ),
                },
            ],
            Documenti: [
                {
                    id: 1,
                    component: (
                        <Fragment>
                            <div
                                className="
                                    h-16 
                                    items-center 
                                    flex 
                                    space-x-8 
                                    p-3 
                                    rounded-sm
                                    bg-gray-100"
                            >
                                <h1 className="text-left text-lg font-bold">
                                    Documenti
                                </h1>
                                <Tooltip
                                    message="È consentito inserire i documenti relativi all’assunzione e lo svolgimento dell’attività."
                                    twBackground="bg-afm-box-blue"
                                >
                                    <InformationCircle />
                                </Tooltip>
                                <div className="flex flex-1 justify-end space-x-5">
                                    {/* <Button
                                        disabled={true}
                                        text="Scarica tutto"
                                        twTextColor="text-black"
                                        twBackground="bg-white"
                                        twFocusBG="focus:bg-white/[0.7]"
                                    /> */}
                                    {/* <Button
                                        disabled={false}
                                        text="Aggiungi doc"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        icon={<PluSm />}
                                    /> */}
                                    <Button
                                        text="Aggiungi documento"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        onClick={() => {
                                            console.log('aggiungi documento')
                                            // setModalComponent(<DocumentoModal id={id} />);
                                            dispatch(openDocumentoModal())
                                        }}
                                        icon={<PluSm />}
                                    />
                                </div>
                            </div>
                            {documentiGridItems.length < 1 ? (
                                <div className="flex items-center justify-center my-14">
                                    <p className="text-xl">
                                        Non ci sono documenti...
                                    </p>
                                </div>
                            ) : (
                                <Grid
                                    twGridTemplateColumns="grid-cols-12"
                                    headerTitleItems={documentiGridHeaderTitleItems}
                                    items={documentiGridItems}
                                />
                            )}
                        </Fragment>
                    ),
                },
            ],
        }
        return setCategories(categoryItems)
    }, [
        employeeImg,
        employeeInfo.id_box_lavoratori,
        employeeInfo.codice_fiscale,
        employeeInfo.cognome,
        employeeInfo.mansione,
        employeeInfo.tipo_dipendente,
        employeeInfo.nome,
        employeeInfo.qualifica,
        corsiGridHeaderTitleItems,
        corsiGridItems,
        visiteGridHeaderTitleItems,
        visiteGridItems,
        documentiGridHeaderTitleItems,
        documentiGridItems,
        isFetching,
        isSuccess,
        isError,
    ])
    /**
     * Component render.
     */
    return (
        <Fragment>
            <SubHeader
                subHeaderTitle="Organico Aziendale"
                firstBtnVisibile={false}
                //firstBtnText="stampa"
                //firstBtnIcon={<Printer />}
                //firstBtnTwBg="bg-white"
                //firstBtnTwFocusBg="focus:bg-white/[0.7]"
                //firstBtnTwTextColor="text-black"
                secondBtnVisibile={false}
                //secondBtnText="Aggiungi lavoratore"
                //secondBtnIcon={<PluSm />}
                //secondBtnTwBg="bg-afm-box-dark-gray"
                //secondBtnTwFocusBg="focus:bg-afm-box-dark-gray/[0.7]"
                //secondBtnTwTextColor="text-white"
                //secondBtnActions={[openModal()]}
            />
            <LayoutDetailContainer>
                {/* Skeleton. */}
                {isFetching && <OgranicoDetailSkeleton />}
                {/* Tabs if not fetching. */}
                {!isFetching && !isError && isSuccess &&
                    <Tabs
                        categories={categories}
                        tabDefaultIndex={defaultTabIndex}
                    />
                }
                {/* If Modal window is open, then render. */}
                {/* {(corsoModalStatus.modalIsOpen && modalComponent != null) && <>
                    {modalComponent}
                </>} */}
                {corsoModalStatus.modalIsOpen && <CorsoModal id={id} />}
                {documentoModalStatus.modalIsOpen && <DocumentoModal id={id} />}
                {visitaModalStatus.modalIsOpen && <VisitaModal id={id} />}
                {/* Error message if data call failed. */}
                {isError && !isSuccess && (
                    <div className="flex items-center justify-center my-14">
                        <p className="text-xl">...Recupero dei dati fallito</p>
                    </div>
                )}
            </LayoutDetailContainer>
        </Fragment>
    )
}
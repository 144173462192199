/**
 *
 * @function OrganicoSkeleton
 *
 * @dev Organico skeleton Component.
 *
 * @returns JSX.Element
 *
 */
export default function OrganicoSkeleton() {
    return (
        <div className="animate-pulse">
            <div className="flex flex-col">
                <header
                    className="
                        h-20 
                        flex 
                        space-x-4 
                        items-center 
                        justify-items-start 
                        font-semibold"
                >
                    <div className="h-10 w-full bg-inherit rounded"></div>
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="h-10 w-full bg-inherit rounded"></div>
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="h-10 w-full bg-inherit rounded"></div>
                    <div className="h-10 w-full bg-inherit rounded"></div>
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="h-10 w-full bg-inherit rounded"></div>
                    <div className="h-10 w-full bg-inherit rounded"></div>
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </header>
                <span className="flex-grow mb-2 border-t border-gray-400"></span>
                <div
                    className="
                        flex 
                        space-x-4 
                        group my-1 p-1 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group my-1 p-1 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group my-1 p-1 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group my-1 p-1 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group my-1 p-1 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group my-1 p-1 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
            </div>
        </div>
    )
}
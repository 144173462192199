// React.
import { useEffect, useState, Fragment } from "react"
// Router.
import { Outlet } from "react-router-dom"
// UI.
import SideBarMenu from "./master/SideBarMenu"
import Modal from "./base/Modal"

/**
 *
 * @dev Main view.
 * 
 * @function Dashboard
 *
 * @returns JSX.Element
 *
 */
export default function Dashboard() {
    // Modal window is open or not boolean state.
    const [isOpen, setIsOpen] = useState(false)

    // Check screen size.
    const checkSize = () => {
        const windowWidth = window.innerWidth

        const xl = 1280 // desktop.
        const xxl = 1536 // desktop.

        if (windowWidth >= xl || windowWidth >= xxl) {
            setIsOpen(false) // true.
        } else {
            setIsOpen(true)
        }
    }

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        /**
         *
         * Every render triggered by isOpen,
         * attach an event to the window and fire checkSize callback function.
         *
         */
        if (window.attachEvent) {
            window.attachEvent("onresize", checkSize)
        } else if (window.addEventListener) {
            window.addEventListener("resize", checkSize, true)
        }
        /*
        else {
            //The browser does not support Javascript event binding.
        }
        */
    }, [isOpen])

    return (
        <Fragment>
            <div className="flex">
                <SideBarMenu />
                <Outlet />
            </div>

            {isOpen && (
                <Modal title="Suggerimento">
                    <div className="flex flex-col space-y-14 text-lg">
                        <p>E' consigliabile l'utilizzo massimizzato su desktop</p>

                        <button
                            type="button"
                            className="
                                bg-afm-box-blue text-white 
                                flex items-center justify-center 
                                w-auto h-10 
                                mx-20 
                                uppercase whitespace-nowrap font-semibold 
                                hover:opacity-80 focus:outline-none"
                            onClick={() => setIsOpen(false)}
                        >
                            Ok, Ho capito!
                        </button>
                    </div>
                </Modal>
            )}
        </Fragment>
    )
}

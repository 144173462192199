// Icons.
import { X } from "./Icons"

/**
 *
 * @dev BtnRemoveField Component
 * 
 * @function BtnRemoveField
 *
 * @param { any } onClick
 *
 * @returns JSX.Element
 *
 */
export default function BtnRemoveField(props) {
    return (
        <button type="button" className="text-afm-box-light-gray-text" onClick={props.onClick}>
            <X />
        </button>
    )
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
// Axios.
import axios from "axios"
// gmapi.
import {
    API_KEY,
    caricaFileMacchinariAPI, caricaPrivacyDocAPI,
    downloadCorsoLavoratoreAPI,
    downloadFileMacchinariAPI
} from "../../../common/gmApi"
// cookie.
import { tkbox, getCookie } from "../../../common/cookie"
import { downloadDocument, showDocument } from "../../../common/util"
import { downloadEmployeeCourseDocById, showEmployeeCourseDocById } from "../generale/organico/OrganicoSlice"
import { useState } from "react"

export const addFile = createAsyncThunk(
    // Action route.
    "privacy/addFile",
    async ({  nome, tipologia, tipo, dataScadenza, allegato }, thunkAPI) => {
        try {

            var bodyFormData = new FormData()
            bodyFormData.append('allegato', allegato, allegato.name)

            const response = await axios.post(
                // url.
                caricaPrivacyDocAPI,
                // body.
                bodyFormData,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        nome: nome,
                        tipologia: tipologia,
                        tipo: tipo,
                        data_scadenza: dataScadenza
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok")
                    return response.data
                else
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
            }
            else
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
        } catch (error) {
            console.error('Error "uploadDocPrivacy":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)



export const filePrivacyModalSlice = createSlice({
    // Name, used in action types.
    name: "privacyModal",
    // The initial state for the reducer.
    initialState: {
        modalIsOpen: false,     // Modal is open or not.
        modalIsFetching: false, // AsyncThunk is calling an API.
        modalIsSuccess: false,  // AsyncThunk is success from API call.
        modalIsError: false,    // AsyncThunk is fail from API call.
        modalErrorMsg: "",      // Error message container.
        files:[],
        file: [],
        type: '',
    },
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {
        clearState: (state) => {
            state.modalIsError = false
            state.modalIsSuccess = false
            state.modalIsFetching = false
            return state
        },
        openModal: (state, action) => {
            state.type = action.payload;
            state.modalIsOpen = true
        },
        closeModal: (state) => {
            state.modalIsOpen = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addFile.fulfilled, (state, action) => {
                state.modalIsFetching = false
                state.modalIsSuccess = true
                state.files =  action.payload.gmapi.response.data
            })
            .addCase(addFile.pending, (state) => {
                state.modalIsFetching = true
            })
            .addCase(addFile.rejected, (state, action) => {
                state.modalIsFetching = false
                state.modalIsError = true
                state.modalErrorMsg = action.payload
            })
    },
})

// Export the reducer.
export const { clearState, openModal, closeModal, setType, cleanUpFiles } = filePrivacyModalSlice.actions

// Export the Slice by name.
export const filePrivacyModalSelector = (state) => state.filePrivacyModal

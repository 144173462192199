/**
 *
 * @dev Global utilities.
 *
 */

import { Base64 } from "js-base64"


export const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export const PDF_BASE64_PREFIX = "data:application/pdf;base64,"
export const PNG_BASE64_PREFIX = "data:image/png;base64,"
export const JPEG_BASE64_PREFIX = "data:image/jpeg;base64,"
export const TEXT_BASE64_PREFIX = "data:text/plain;base64,"
export const JSON_BASE64_PREFIX = "data:application/json;base64,"
export const PDF_FILE_EXTENSION = ".pdf"
export const PNG_FILE_EXTENSION = ".png"
export const TEXT_FILE_EXTENSION = ".txt"
export const JSON_FILE_EXTENSION = ".json"


/*******************************************************************************
 *
 * @function formatDateTimeIT
 * 
 * @param { string } dateTime
 * @returns { string } itDateString
 * 
 * @dev Given dateTime : "2021-09-25 09:44:29"
 * @dev Returns italian formatted Date : "25 set 2021"
 *
 */
export function formatDateTimeIT(dateTime) {
    const splittedDate = dateTime.split(" ").shift()

    var options = {
        month: "short",
        day: "numeric",
        year: "numeric",
    }

    const itDateString = new Date(splittedDate).toLocaleString("it-IT", options)

    return itDateString
}

/*******************************************************************************
 *
 * @function classNames.
 * 
 * @param { string[] } classes 
 * @returns string
 * 
 * @dev Given classes
 * @dev Returns joined classes.
 *
 */
export function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
}

// is string and string is not empty
export function isNotEmptyString(string) {
    return typeof string === "string" && string.length > 0
}

/*******************************************************************************
 * 
 * @function downloadDocument
 * 
 * @param { Base64 } base64String
 * @param { string } fileName
 * @returns void
 * 
 */
export function downloadDocument(base64String, fileName) {
    // Get the function arg.
    const sourceLink = base64String

    /**
     * 
     * @function elementTriggerDownload
     * 
     * @param { Base64 } sourceLink
     * @param { string } fileName 
     * @returns void
     */
    function elementTriggerDownload(sourceLink, fileName) {
        // Create an HTML element "a".
        const downloadLink = document.createElement("a")
        // Assign to href prop the base64 file.
        downloadLink.href = sourceLink
        // Download with file name.
        downloadLink.download = fileName
        // Download trigger.
        downloadLink.click()
    }

    if ((fileName === undefined || "") && (sourceLink !== undefined)) {
        if (sourceLink.includes(PDF_BASE64_PREFIX)) {
            fileName = "allegato-senza-nome.pdf"
            elementTriggerDownload(sourceLink, fileName)
        } else if (sourceLink.includes(PNG_BASE64_PREFIX)) {
            fileName = "allegato-senza-nome.png"
            elementTriggerDownload(sourceLink, fileName)
        } else if (sourceLink.includes(TEXT_BASE64_PREFIX)) {
            fileName = "allegato-senza-nome.txt"
            elementTriggerDownload(sourceLink, fileName)
        } else if (sourceLink.includes(JSON_BASE64_PREFIX)) {
            fileName = "allegato-senza-nome.json"
            elementTriggerDownload(sourceLink, fileName)
        } else {
            console.error("`downloadDocument`: ", `"${sourceLink}" or "${fileName}" not a valid document.`)
            alert("`downloadDocument`: nessun file disponibile per il download.")
        }
    } else if (fileName !== undefined) {
        if (
            fileName.includes(PDF_FILE_EXTENSION) &&
            sourceLink.includes(PDF_BASE64_PREFIX)
        ) {
            elementTriggerDownload(sourceLink, fileName)
        } else if (
            fileName.includes(PNG_FILE_EXTENSION) &&
            sourceLink.includes(PNG_BASE64_PREFIX)
        ) {
            elementTriggerDownload(sourceLink, fileName)
        } else if (
            fileName.includes(TEXT_FILE_EXTENSION) &&
            sourceLink.includes(TEXT_FILE_EXTENSION)
        ) {
            elementTriggerDownload(sourceLink, fileName)
        } else if (
            fileName.includes(JSON_FILE_EXTENSION) &&
            sourceLink.includes(JSON_FILE_EXTENSION)
        ) {
            elementTriggerDownload(sourceLink, fileName)
        } else {
            //console.error("`downloadDocument`: ",`"${sourceLink}" or "${fileName}" not a valid document.`)
            // alert("`downloadDocument`: nessun file disponibile per il download.")
            elementTriggerDownload(sourceLink, fileName)
        }
    }
    //
    // WAIT A GOOD RESPONSE BEFORE DELETE IT.
    //
    /*
    if (
        (fileName === undefined || "") &&
        (sourceLink !== undefined) &&
        (!sourceLink.includes("data:directory;base64,"))
    ) {
        if (sourceLink.includes(PDF_BASE64_PREFIX)) {
            fileName = "allegato-senza-nome.pdf"
            elementTriggerDownload(sourceLink,fileName)
        } else if (sourceLink.includes(PNG_BASE64_PREFIX)) {
            fileName = "allegato-senza-nome.png"
            elementTriggerDownload(sourceLink,fileName)
        } else if (sourceLink.includes("data:;base64,") || !sourceLink.includes("data:directory;base64,")) {
            console.error("`downloadDocument`: ",`"${sourceLink}" not a valid document.`)
        }
    }
 
    if (fileName !== undefined) {
        if (fileName.includes(PDF_FILE_EXTENSION) && sourceLink.includes(PDF_BASE64_PREFIX)) {
            elementTriggerDownload(sourceLink,fileName)
        } else if (fileName.includes(PNG_FILE_EXTENSION) && sourceLink.includes(PNG_BASE64_PREFIX)) {
            elementTriggerDownload(sourceLink,fileName)
        } else {
            console.error("`downloadDocument`: ",`"${sourceLink}" or "${fileName}" not a valid document.`)
        }
    }
    */
}

/*******************************************************************************
 *
 * @function showDocument
 * 
 * @param { string } doc
 * @returns void
 * 
 * @dev Creates an anchor element `<a></a>` with
 * @dev the base64 pdf source and a filename with the
 * @dev HTML5 `target='_blank'` attribute then clicks on it.
 *
 */
export function showDocument(doc) {
    /**
     * 
     * @function base64toBlob
     * 
     * @param {*} data 
     * @returns Blob
     * 
     */
    function base64toBlob(data) {
        // Remove the prefix from the raw base 64.
        if (data.includes(PDF_BASE64_PREFIX)) {

            const base64WithoutPrefix = data.substr(PDF_BASE64_PREFIX.length)
            const bytes = Base64.atob(base64WithoutPrefix)
            let length = bytes.length
            const uint8Array = new Uint8Array(length)

            while (length--) {
                uint8Array[length] = bytes.charCodeAt(length)
            }

            return new Blob([uint8Array], { type: "application/pdf" })

        } else if (data.includes(PNG_BASE64_PREFIX)) {

            const base64WithoutPrefix = data.substr(PNG_BASE64_PREFIX.length)
            const bytes = Base64.atob(base64WithoutPrefix)
            let length = bytes.length
            const uint8Array = new Uint8Array(length)

            while (length--) {
                uint8Array[length] = bytes.charCodeAt(length)
            }

            return new Blob([uint8Array], { type: "image/png" })

        } else if (data.includes(JPEG_BASE64_PREFIX)) {

            const base64WithoutPrefix = data.substr(JPEG_BASE64_PREFIX.length)
            const bytes = Base64.atob(base64WithoutPrefix)
            let length = bytes.length
            const uint8Array = new Uint8Array(length)

            while (length--) {
                uint8Array[length] = bytes.charCodeAt(length)
            }

            return new Blob([uint8Array], { type: "image/jpeg" })

        } else if (data.includes(TEXT_BASE64_PREFIX)) {

            const base64WithoutPrefix = data.substr(TEXT_BASE64_PREFIX.length)
            const bytes = Base64.atob(base64WithoutPrefix)
            let length = bytes.length
            const uint8Array = new Uint8Array(length)

            while (length--) {
                uint8Array[length] = bytes.charCodeAt(length)
            }

            return new Blob([uint8Array], { type: "text/plain;charset=UTF-8" })

        } else if (data.includes(JSON_BASE64_PREFIX)) {

            const base64WithoutPrefix = data.substr(JSON_BASE64_PREFIX.length)
            const bytes = Base64.atob(base64WithoutPrefix)
            let length = bytes.length
            const uint8Array = new Uint8Array(length)

            while (length--) {
                uint8Array[length] = bytes.charCodeAt(length)
            }

            return new Blob([uint8Array], { type: "application/json" })
        }
    }

    //
    // Implementation.
    //
    //
    if (
        //doc === "data:;base64," ||
        //doc === "data:directory;base64," ||
        doc === undefined ||
        doc === "" ||
        doc.length <= 0
    ) {
        alert("`showDocument`: nessun file disponibile per la visualizzazione.")
    } else if (doc === PNG_BASE64_PREFIX) {
        console.error("`showDocument`: ", `"${doc}" string is not enough :(`);
    } else if (doc === JPEG_BASE64_PREFIX) {
        console.error("`showDocument`: ", `"${doc}" string is not enough :(`);
    } else if (doc === PDF_BASE64_PREFIX) {
        console.error("`showDocument`: ", `"${doc}" string is not enough :(`);
    } else if (doc === TEXT_BASE64_PREFIX) {
        console.error("`showDocument`: ", `"${doc}" string is not enough :(`);
    } else if (doc === JSON_BASE64_PREFIX) {
        console.error("`showDocument`: ", `"${doc}" string is not enough :(`);
    } else if (
        (doc.includes(PNG_BASE64_PREFIX)) ||
        (doc.includes(JPEG_BASE64_PREFIX)) ||
        (doc.includes(PDF_BASE64_PREFIX)) ||
        (doc.includes(TEXT_BASE64_PREFIX)) ||
        (doc.includes(JSON_BASE64_PREFIX))
    ) {
        try {
            const blob = base64toBlob(doc)
            const url = URL.createObjectURL(blob)
            const downloadLink = document.createElement("a")

            downloadLink.href = url
            downloadLink.target = "_blank"
            downloadLink.click()

        } catch (error) {
            console.error("`showDocument`: ", error);
        }
    }
}

/*******************************************************************************
 *
 * @function buildBreadCrumbItemsForEmployees
 * 
 * @param { any[] } location
 * @param { bool } needFirstBreadCrumb
 * @param { string } id
 * @param { any[] } employeeInfo
 * @param { number } listElement
 * @returns any[]
 * 
 * @dev Need for stick employer name instead of it id.
 *
 */
export function buildBreadCrumbItemsForEmployees(
    location,
    needFirstBreadCrumb,
    id,
    employeeInfo,
    listElement
) {
    // Get path splitted items.
    const splittedPathNames = location.pathname.split("/")
    // tmp array of objects.
    const tmpBreadCrumbItems = new Array(splittedPathNames.length - 2)

    // Escape first path element or not.
    const firstElement = needFirstBreadCrumb ? 0 : 1
    // Reverse Cicle to be sure to always keep the last path.
    for (
        let i = tmpBreadCrumbItems.length - 1; // Init iterator to items length - 1.
        i >= firstElement; // Eventually escape first path.
        i-- // Decrement the iterator.
    ) {
        const iSpn = i + 2

        tmpBreadCrumbItems[i] = {
            text: splittedPathNames[iSpn],
            path: splittedPathNames.join("/"),
            paddingLeft: tmpBreadCrumbItems.length > 1 ? "pl-0" : "pl-14",
        }

        // Get the employee id from url params.
        const employeeIdFromLocation = id
        const employeeId = employeeInfo.id_box_lavoratori
        const employeeName = employeeInfo.nome
        const employeeSurname = employeeInfo.cognome

        if (tmpBreadCrumbItems[listElement].text !== undefined) {
            tmpBreadCrumbItems[listElement].text =
                employeeIdFromLocation === employeeId ?
                    `${employeeName}  ${employeeSurname}`
                    :
                    employeeId
        }

        splittedPathNames.pop(iSpn)
    }

    return tmpBreadCrumbItems
}

/*******************************************************************************
 *
 * @function buildBreadCrumbItemsForMachine
 * 
 * @param { any[] } location
 * @param { bool } needFirstBreadCrumb
 * @param { string } id
 * @param { any[] } machineInfo
 * @param { number } listElement
 * @returns any[]
 * 
 * @dev Need for stick employer name instead of it id.
 *
 */
export function buildBreadCrumbItemsForMachine(
    location,
    needFirstBreadCrumb,
    id,
    machineInfo,
    listElement
) {
    // Get path splitted items.
    const splittedPathNames = location.pathname.split("/")
    // tmp array of objects.
    const tmpBreadCrumbItems = new Array(splittedPathNames.length - 2)

    // Escape first path element or not.
    const firstElement = needFirstBreadCrumb ? 0 : 1
    // Reverse Cicle to be sure to always keep the last path.
    for (
        let i = tmpBreadCrumbItems.length - 1; // Init iterator to items length - 1.
        i >= firstElement; // Eventually escape first path.
        i-- // Decrement the iterator.
    ) {
        const iSpn = i + 2

        tmpBreadCrumbItems[i] = {
            text: splittedPathNames[iSpn],
            path: splittedPathNames.join("/"),
            paddingLeft: tmpBreadCrumbItems.length > 1 ? "pl-0" : "pl-14",
        }

        // Get the machine id from url params.
        const machineIdFromLocation = id
        const machineId = machineInfo?.id_box_macchinari || ''
        const machineName = machineInfo?.nome || ''

        if (tmpBreadCrumbItems[listElement].text !== undefined) {
            tmpBreadCrumbItems[listElement].text =
                machineIdFromLocation === machineId ? machineName : machineId
        }

        splittedPathNames.pop(iSpn)
    }

    return tmpBreadCrumbItems
}

/*******************************************************************************
 *
 * @function buildBreadCrumbItemsForSettlements
 *
 * @param { any[] } location
 * @param { bool } needFirstBreadCrumb
 * @param { string } id
 * @param { any[] } employeeInfo
 * @param { number } listElement
 * @returns any[]
 * 
 * @dev Need for stick employer name instead of it id.
 *
 */
export function buildBreadCrumbItemsForSettlements(
    location,
    needFirstBreadCrumb,
    id,
    settlementInfo,
    listElement
) {
    // Get path splitted items.
    const splittedPathNames = location.pathname.split("/")
    // tmp array of objects.
    const tmpBreadCrumbItems = new Array(splittedPathNames.length - 2)
    // Escape first path element or not.
    const firstElement = needFirstBreadCrumb ? 0 : 1
    // Reverse Cicle to be sure to always keep the last path.
    for (
        let i = tmpBreadCrumbItems.length - 1; // Init iterator to items length - 1.
        i >= firstElement; // Eventually escape first path.
        i-- // Decrement the iterator.
    ) {
        const iSpn = i + 2

        tmpBreadCrumbItems[i] = {
            text: splittedPathNames[iSpn],
            path: splittedPathNames.join("/"),
            paddingLeft: tmpBreadCrumbItems.length > 1 ? "pl-0" : "pl-14",
        }

        // Get the settlement id from url params.
        const settlementIdFromLocation = id
        const settlementId = settlementInfo.id_box_insediamenti
        const settlementName = settlementInfo.nome

        if (tmpBreadCrumbItems[listElement].text !== undefined) {
            tmpBreadCrumbItems[listElement].text =
                settlementIdFromLocation === settlementId ?
                    settlementName
                    :
                    settlementId
        }

        splittedPathNames.pop(iSpn)
    }

    return tmpBreadCrumbItems
}

/*******************************************************************************
 *
 * @function buildBreadCrumbItems
 *
 * @param { Location } location - @see https://github.com/remix-run/history/blob/main/docs/api-reference.md#location
 * @param { bool } needFirstBreadCrumb
 *
 * @returns  any[]
 * 
 * @dev compose the object for the consumer breadcrumb.
 *
 */
export function buildBreadCrumbItems(location, needFirstBreadCrumb) {
    // Get path splitted items.
    const splittedPathNames = location.pathname.split("/")
    // tmp array of objects.
    const tmpBreadCrumbItems = new Array(splittedPathNames.length - 2)
    // Escape first path element or not.
    const firstElement = needFirstBreadCrumb ? 0 : 1
    // Reverse Cicle to be sure to always keep the last path.
    for (
        let i = tmpBreadCrumbItems.length - 1; // Init iterator to items length - 1.
        i >= firstElement; // Eventually escape first path.
        i-- // Decrement the iterator.
    ) {
        const iSpn = i + 2

        tmpBreadCrumbItems[i] = {
            text: splittedPathNames[iSpn],
            path: splittedPathNames.join("/"),
            paddingLeft: tmpBreadCrumbItems.length > 1 ? "pl-0" : "pl-14",
        }

        splittedPathNames.pop(iSpn)
    }
    return tmpBreadCrumbItems
}

export function emptyString(string) {
    if (typeof string !== "string") { return false };
    return string.trim().length === 0
}

export function DataScadenzaSort(a, b) {
    if (typeof a.data_scadenza !== 'string') {
        return false
    };
    const scadenzaAParts = a.data_scadenza.split("/");
    if (scadenzaAParts.length < 3) {
        return -1
    };
    const scadenzaAObj = new Date(parseInt(scadenzaAParts[2]), parseInt(scadenzaAParts[1]) - 1, parseInt(scadenzaAParts[0]));
    const scadenzaBParts = b.data_scadenza.split("/");
    if (scadenzaBParts.length < 3) {
        return 1
    };
    const scadenzaBObj = new Date(parseInt(scadenzaBParts[2]), parseInt(scadenzaBParts[1]) - 1, parseInt(scadenzaBParts[0]));
    return scadenzaAObj > scadenzaBObj ? -1 : 1
};

export function ScadenzaSort(a, b) {
    if (typeof a.scadenza !== 'string') {
        return false
    };
    const scadenzaAParts = a.scadenza.split("/");
    if (scadenzaAParts.length < 3) {
        return -1
    };
    const scadenzaAObj = new Date(parseInt(scadenzaAParts[2]), parseInt(scadenzaAParts[1]) - 1, parseInt(scadenzaAParts[0]));
    const scadenzaBParts = b.scadenza.split("/");
    if (scadenzaBParts.length < 3) {
        return 1
    };
    const scadenzaBObj = new Date(parseInt(scadenzaBParts[2]), parseInt(scadenzaBParts[1]) - 1, parseInt(scadenzaBParts[0]));
    return scadenzaAObj > scadenzaBObj ? -1 : 1
};

/**
 *
 * @notice CantiereSlice to handle state of Cantiere globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 */

// Redux.
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit"
// Axios.
import axios from "axios"
// gmapi.
import { API_KEY,listaCantieriAPI } from "../../../common/gmApi"
// cookie.
import { tkbox,getCookie } from "../../../common/cookie"

/**
 *
 *  @dev Get yards data.
 *
 */
export const getYards = createAsyncThunk(
    // Action route.
    "cantiere/getYards",
    //
    // Async Function with axios http req.
    //
    // First param: null.
    //
    // Second param: "thunkAPI" callback function that should return a promise
    // handle with extraReducers to update the State.
    // It takes two parameter too: first is the value of the dispatched action,
    // and the second is the Thunk API config.
    //
    async (thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                listaCantieriAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                    },
                }
            )

            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    //console.error('Error "getYards":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "getYards":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "getYards":',error.message)

            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Create "cantiereSlice" feature.
 *
 */
export const cantiereSlice = createSlice({
    // Name, used in action types.
    name: "cantiere",
    // The initial state for the reducer.
    initialState: {
        // All yards.
        yardItems: [],
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMsg: "",
    },
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {},
    // AsyncThunk Reducers.
    // A "builder callback" function used to add more reducers, or
    // an additional object of "case reducers", where the keys should be other
    // action types.
    extraReducers: (builder) => {
        builder
            /**
             *
             * getYards.
             *
             */
            .addCase(getYards.fulfilled,(state,action) => {
                state.isFetching = false
                state.isSuccess = true
                state.yardItems = action.payload.gmapi.response.data
            })
            .addCase(getYards.pending,(state) => {
                state.isFetching = true
            })
            .addCase(getYards.rejected,(state,action) => {
                state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
            })
    },
})

// Export the reducer.
//export const { clearState } = cantiereSlice.actions

// Export the Slice by name.
export const cantiereSelector = (state) => state.cantiere

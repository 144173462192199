// React.
import { useEffect, useState } from "react"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// DocumentiMasterSlice.
import { documentiMasterSelector,getMasterDocs, downloadMasterDocById, clearMasterDocSenderId} from "../../features/documenti-master/DocumentiMasterSlice"
// UI.
import LayoutDetail from "../LayoutDetail"
import LayoutDetailContainer from "../LayoutDetailContainer"
import Button from "../../base/Button"
import Grid from "../../base/Grid"
// Icons.
import { DocumentText, OvalLoader } from "../../base/Icons"

/**
 * 
 * @dev Documenti Master view section.
 *
 * @function DocumentiMaster
 *
 * @returns JSX.Element
 *
 */
export default function DocumentiMaster() {
    const dispatch = useDispatch()

    // gridItems State.
    const [gridItems, setGridItems] = useState([])
    // gridHeaderTitleItems State.
    const [gridHeaderTitleItems, setGridHeaderTitleItems] = useState([])

    // documentiMasterSlice State.
    const {
        masterDocsItems,
        isFetching,
        isSuccess,
        isError,
        masterDocSenderId,
        isFetchingMasterDoc,
    } = useSelector(documentiMasterSelector)

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        dispatch(getMasterDocs())
    }, [dispatch])

    useEffect(() => {
        const items = []

        if (masterDocsItems.length < 1) {
            setGridItems(items)
        } else {
            masterDocsItems.map((masterDoc) => {
                const gridItem = [
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "Nome Documento",
                        additionalText: "",
                        twColSpan: "col-span-6",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "",
                        twColSpan: "col-span-5",
                        twAdditionalClass: "justify-self-end",
                        component: {},
                    },
                ]

                // Item 0.
                gridItem[0].component = (
                    <div
                        className="
                            flex 
                            space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                            justify-self-start 
                            text-afm-box-light-gray-text"
                    >
                        <DocumentText />
                        <span className="border-l border-afm-box-light-gray-text"></span>
                    </div>
                )
                // Item 1.
                gridItem[1].text = masterDoc.descrizione
                // Item 2.
                gridItem[2].component = (
                    <div className="flex items-center space-x-2">

                        {/* If isFetchingDoc and masterDocSenderId State correspond to Button id pressed, than show the OvalLoader. */}
                        {(isFetchingMasterDoc) && (masterDocSenderId === masterDoc.id_box_documenti_master) && (
                            <OvalLoader strokeColor="#aaa" />
                        )}

                        <Button
                            disabled={false}
                            text="DOWNLOAD"
                            id={masterDoc.id_box_documenti_master}
                            twTextColor="text-white"
                            twBackground="bg-afm-box-blue"
                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                            actions={[
                                clearMasterDocSenderId(),
                                downloadMasterDocById(masterDoc.id_box_documenti_master)
                            ]}
                        />
                    </div>
                )

                items.push(gridItem)

                return setGridItems(items)
            })
        }

        setGridHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionClass: "",
                component: "",
            },
            {
                title: "Documento",
                twColSpan: "col-span-6",
                twAdditionClass: "",
                component: "",
            },
            // {
            //     title: "",
            //     twColSpan: "col-span-5 justify-self-end",
            //     twAdditionClass: "",
            //     component: <SearchBox />,
            // },
        ])
    }, [
        masterDocsItems,
        isFetching, 
        isSuccess, 
        isError,
        isFetchingMasterDoc,
        masterDocSenderId
    ])

    /**
     *
     * Component render.
     *
     */
    return (
        <LayoutDetail title="Documenti Master">
            <LayoutDetailContainer>
                {/* Skeleton. */}
                {isFetching && <DocMasterSkeleton />}
                {!isFetching && !isError && isSuccess && (
                    <Grid
                        twGridTemplateColumns="grid-cols-12"
                        headerTitleItems={gridHeaderTitleItems}
                        items={gridItems}
                    />
                )}
                {isError && !isSuccess && (
                    <div className="flex items-center justify-center my-14">
                        <p className="text-xl">...Recupero dei dati fallito</p>
                    </div>
                )}
            </LayoutDetailContainer>
        </LayoutDetail>
    )
}

/**
 * 
 * @dev DocMasterSkeleton skeleton UI preview component.
 *
 * @function DocMasterSkeleton
 *
 * @returns JSX.Element
 *
 */
function DocMasterSkeleton() {
    return (
        <div className="animate-pulse">
            <div className="flex flex-col">
                <header className="h-20 grid grid-cols-6 gap-10 items-center justify-items-start font-semibold">
                    <div className="col-span-1 h-10 w-full bg-inherit rounded"></div>
                    <div className="col-span-4 h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-neutral-300 rounded"></div>
                </header>
                <span className="flex-grow mb-2 border-t border-gray-400"></span>
                <div className="flex space-x-4 group my-1 p-1 rounded-md items-center justify-center justify-items-start">
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div className="flex space-x-4 group my-1 p-1 rounded-md items-center justify-center justify-items-start">
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div className="flex space-x-4 group my-1 p-1 rounded-md items-center justify-center justify-items-start">
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div className="flex space-x-4 group my-1 p-1 rounded-md items-center justify-center justify-items-start">
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div className="flex space-x-4 group my-1 p-1 rounded-md items-center justify-center justify-items-start">
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div className="flex space-x-4 group my-1 p-1 rounded-md items-center justify-center justify-items-start">
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
            </div>
        </div>
    )
}

// React.
import { useState, useEffect, Fragment } from "react"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// DomaSlice.
import {
    getDoma,
    domaSelector,
    clearDomaDocSenderId,
    showDomaDoc,
    downloadDomaDoc,
    deleteDoma
} from "../../features/generale/doma/DomaSlice"
// DomaModalSlice.
import { domaModalSelector, openModal } from "../../features/generale/doma/DomaModalSlice"
// SearchSlice.
import { searchSelector, setSearchList,reset } from "../../features/search/SearchSlice"
// UI.
import LayoutDetailContainer from "../LayoutDetailContainer"
import Grid from "../../base/Grid"
import SearchBox from "../../base/SearchBox"
import SubHeader from "../../base/SubHeader"
import Button from "../../base/Button"
import DomaModal from "./DomaModal"
import PdfViewer from "../../base/PdfViewer"
import DropDown from "../../base/DropDown"
// Icons.
import { PluSm, DocumentText, OvalLoader } from "../../base/Icons"
import { ScadenzaSort } from "../../../common/util"

/**
 *
 * @dev Generale Subsection.
 * 
 * @function Doma
 * 
 * @returns JSX.Element
 *
 */
export default function Doma() {
    const dispatch = useDispatch()

    // Initialize it with useMemo hook, to clean up the variable after update effects.
    const [gridItems, setGridItems] = useState([])
    // gridHeaderTitleItems State.
    const [gridHeaderTitleItems, setGridHeaderTitleItems] = useState([])

    // domaSlice State.
    const {
        domaItems,
        isSuccess,
        isError,
        isFetching,
        //errorMsg,
        //domaDoc,
        domaDocSenderId,
        isFetchingDoc,
    } = useSelector(domaSelector)

    // domaModalSlice State.
    const { modalIsOpen, modalIsSuccess } = useSelector(domaModalSelector);

    // searchSlice State.
    const { searchResults, searchList, searchQuery } = useSelector(searchSelector);

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        dispatch(getDoma())
    }, [
        dispatch, 
        modalIsSuccess
    ]);
    useEffect(() => {
        dispatch(reset())
    }, []);

    useEffect(() => {
        const items = [];
        const emptySearch = (searchQuery || '').trim() === "";
        const searchQueryResults = emptySearch ? searchList : searchResults

        if (searchQueryResults.length < 1) {
            return setGridItems(items)
        } else {
            searchQueryResults
            .map(item => item)
            .sort(ScadenzaSort)
            .map((item) => {
                const gridItem = [
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "Data Caricamento",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Doma",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Scadenza",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Allegato",
                        twColSpan: "col-span-1",
                        component: {},
                    },
                    {
                        text: "",
                        twColSpan: "col-span-4",
                        twAdditionalClass: "justify-self-end",
                        component: {},
                    },
                ]

                gridItem[0].component = (
                    <div
                        className="
                            flex 
                            space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                            justify-self-start 
                            text-afm-box-light-gray-text"
                    >
                        <DocumentText />
                        <span className="border-l border-afm-box-light-gray-text"></span>
                    </div>
                )
                gridItem[1].text = item.data_ora_inserimento
                gridItem[2].text = item.data
                gridItem[3].text = item.scadenza

                gridItem[4].component = <PdfViewer action={showDomaDoc(item.id_box_doma)} />

                gridItem[5].component = (
                    <div className="flex items-center space-x-2">
                        {/* If isFetchingDoc and domaDocSenderId State correspond to Button id pressed, than show the OvalLoader. */}
                        {isFetchingDoc && domaDocSenderId === item.id_box_doma && (
                            <OvalLoader strokeColor="#aaa" />
                        )}

                        <Button
                            text="DOWNLOAD"
                            id={item.id_box_doma}
                            twTextColor="text-white"
                            twBackground="bg-afm-box-blue"
                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                            actions={[
                                clearDomaDocSenderId(), 
                                downloadDomaDoc(
                                    {
                                        idBoxDoma: item.id_box_doma,
                                        docName: item.file_download
                                    }
                                )
                            ]}
                        />
                        <DropDown
                            buttonItems={[
                                {
                                    type: "delete",
                                    method: deleteDoma(item.id_box_doma) 
                                }
                            ]}
                        />
                    </div>

                    
                )

                items.push(gridItem)

                return setGridItems(items)
            })
        }

        // Set grid header titles.
        setGridHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
            },
            {
                title: "Data Caricamento",
                twColSpan: "col-span-2",
            },
            {
                title: "Data Doma",
                twColSpan: "col-span-2",
            },
            {
                title: "Data Scadenza",
                twColSpan: "col-span-2",
            },
            {
                title: "Allegato",
                twColSpan: "col-span-1",
            },
            {
                title: "",
                twColSpan: "col-span-4 justify-self-end",
                twAdditionalClass: "",
                component: <SearchBox placeholder={"Cerca Doma"} />,
            },
        ])
    }, [searchList,
        searchQuery,
        searchResults, domaDocSenderId, isFetchingDoc]);

    /**
     *
     *  if can get visura items list correctly
     *  then is possible to init and configure the search feature.
     *
     */
    useEffect(() => {
        if (isSuccess) {
            // if dataItems are been fetched then call setSearchList giving:
            // list, search key and an additional list of search key.
            dispatch(
                setSearchList({
                    dataItems: domaItems,
                    searchIndex: Object.keys(domaItems.length ? domaItems[0] : [''])[0],
                    additionalIndex: [
                        Object.keys(domaItems.length ? domaItems[0] : [''])[1],
                        Object.keys(domaItems.length ? domaItems[0] : [''])[2],
                        Object.keys(domaItems.length ? domaItems[0] : [''])[3],
                    ],
                })
            )
        }
    }, [
        dispatch,
        domaItems,
        isFetching,
        isSuccess,
        isError
    ])

    useEffect(() => {}, [isSuccess, isError, isFetching])

    /**
     *
     * Component render.
     *
     */
    return (
        <Fragment>
            <SubHeader
                subHeaderTitle={"Doma"}
                firstBtnVisibile={false}
                secondBtnVisibile={true}
                secondBtnText="Aggiungi file"
                secondBtnIcon={<PluSm />}
                secondBtnTwBg="bg-afm-box-dark-gray"
                secondBtnTwFocusBg="focus:bg-afm-box-dark-gray/[0.7]"
                secondBtnTwTextColor="text-white"
                secondBtnActions={[openModal()]}
            />
            <LayoutDetailContainer>
                {/* Skeleton. */}
                {isFetching && <DomaSkeleton />}

                {!isFetching && !isError && isSuccess && (
                    <Fragment>
                        <Grid
                            twGridTemplateColumns="grid-cols-12"
                            headerTitleItems={gridHeaderTitleItems}
                            items={gridItems}
                        />
                    </Fragment>
                )}

                {/* If Modal window is open, then render. */}

                {modalIsOpen && <DomaModal />}

                {isError && !isSuccess && (
                    <div className="flex items-center justify-center my-14">
                        <p className="text-xl">...Recupero dei dati fallito</p>
                    </div>
                )}
            </LayoutDetailContainer>
        </Fragment>
    )
}

/**
 *
 * @dev Doma skeleton component.
 * 
 * @function DomaSkeleton
 *
 * @returns JSX.Element
 *
 */
function DomaSkeleton() {
    return (
        <div className="animate-pulse">
            <div className="flex flex-col">
                <header
                    className="
                        h-20 
                        grid grid-cols-6 
                        gap-4 
                        items-center justify-items-start 
                        font-semibold"
                >
                    <div className="col-span-1 h-10 w-full bg-inherit rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-neutral-300 rounded"></div>
                </header>
                <span className="flex-grow mb-2 border-t border-gray-400"></span>

                <div
                    className="
                        flex space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                    flex 
                    space-x-4 
                    group 
                    my-1 
                    p-1 
                    rounded-md 
                    items-center 
                    justify-center 
                    justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                    flex 
                    space-x-4 
                    group 
                    my-1 
                    p-1 
                    rounded-md 
                    items-center 
                    justify-center 
                    justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                    flex 
                    space-x-4 
                    group 
                    my-1 
                    p-1 
                    rounded-md 
                    items-center 
                    justify-center 
                    justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                    flex 
                    space-x-4 
                    group 
                    my-1 
                    p-1 
                    rounded-md 
                    items-center 
                    justify-center 
                    justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                    flex 
                    space-x-4 
                    group 
                    my-1 
                    p-1 
                    rounded-md 
                    items-center 
                    justify-center 
                    justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
            </div>
        </div>
    )
}

// UI.
import Button from "../base/Button"

import { useLocation } from "react-router-dom"

// Icons.
import { Printer } from "../base/Icons"
import { getUserInfo, userSelector } from "../features/user/UserSlice"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { downloadDatiAziendaById } from "../features/generale/azienda/AziendaSlice"

/**
 *
 * @function LayoutDetailContainer
 *
 * @dev Layout of the components that live outside the Main layout.
 *
 * @param { string } title
 *
 * @param { bool } firstBtnVisibile
 * @param { string } firstBtnText
 * @param { JSX.Element } firstBtnIcon
 * @param { string } firstBtnTwBg
 * @param { string } firstBtnTwFocusBg
 * @param { string } firstBtnTwTextColor
 * @param { any[] } firstBtnActions
 * 
 * @param { bool } secondBtnVisibile
 * @param { string } secondBtnText
 * @param { JSX.Element } secondBtnIcon
 * @param { string } secondBtnTwBg
 * @param { string } secondBtnTwFocusBg
 * @param { string } secondBtnTwTextColor
 * @param { any[] } secondBtnActions
 * 
 * @returns JSX.Element
 *
 */
export default function LayoutDetail(props) {
    const location = useLocation()
    const { userName, type, role, adminUser, adminMode, company } = useSelector(userSelector)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getUserInfo())
    }, [
        dispatch,
        userName
    ])

    return (
        <section
            className="
                flex flex-col
                overflow-y-auto
                overflow-x-auto
                max-h-screen
                w-full"
        >
            <header
                id="layout-detail"
                className="
                    flex items-center 
                    py-10 
                    px-2 sm:px-5 xl:px-10 
                    text-left text-2xl font-bold
                    bg-gray-100"
            >
                <div className="flex-grow">
                    {props.title}
                </div>

                <div
                    className="
                        flex 
                        items-center justify-end 
                        space-x-8                          
                        text-base"
                >
                    {location.pathname.includes('documenti-master') &&
                        <button 
                            className={`
                                bg-white
                                flex 
                                gap-1 
                                w-auto 
                                h-10 
                                px-7 
                                items-center justify-center 
                                uppercase whitespace-nowrap 
                                font-semibold 
                                hover:opacity-80 
                                focus:outline-none`}
                            onClick={() => {
                                console.log('print');
                                window.print();
                            }}
                        >
                            <Printer /> stampa
                        </button>    
                    }
                    {
                        location.pathname.includes('azienda') &&
                        <button 
                            className={`
                                bg-white
                                flex 
                                gap-1 
                                w-auto 
                                h-10 
                                px-7 
                                items-center justify-center 
                                uppercase whitespace-nowrap 
                                font-semibold 
                                hover:opacity-80 
                                focus:outline-none`}
                            onClick={() => {
                                    dispatch(downloadDatiAziendaById(company));
                                    // console.log(getUserInfo());
                                 // console.log('print');
                                 // window.print();
                            }}
                        >
                            <Printer /> stampa
                        </button>
                    }
                    {props.firstBtnVisibile && (
                        <Button
                            twBackground={props.firstBtnTwBg}
                            twFocusBg={props.firstBtnTwFocusBg}
                            twTextColor={props.firstBtnTwTextColor}
                            icon={props.firstBtnIcon}
                            text={props.firstBtnText}
                            actions={props.firstBtnActions}
                        />
                    )}
                    {props.secondBtnVisibile && (
                        <Button
                            twBackground={props.secondBtnTwBg}
                            twFocusBg={props.secondBtnTwFocusBg}
                            twTextColor={props.secondBtnTwTextColor}
                            icon={props.secondBtnIcon}
                            text={props.secondBtnText}
                            actions={props.secondBtnActions}
                        />
                    )}
                </div>
            </header>
            {props.children}
        </section>
    )
}

// React.
import { useEffect, useState } from "react"
// Router.
import { NavLink, Outlet } from "react-router-dom"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// ComunicazioniSlice.
import {
    comunicazioniSelector,
    getComunicazioni,
} from "../../features/comunicazioni/ComunicazioniSlice"
// UI.
import LayoutDetail from "../LayoutDetail"
import LayoutDetailContainer from "../LayoutDetailContainer"
// Util.
import { formatDateTimeIT } from "../../../common/util"


/**
 *
 * @dev Comunicazioni view section.
 *
 * @function Comunicazioni
 *
 * @returns JSX.Element
 *
 */
export default function Comunicazioni() {
    const dispatch = useDispatch()

    const {
        noticesItems,
        isFetching,
        isSuccess,
        isError,
        //errorMsg,
    } = useSelector(comunicazioniSelector)

    /**
     *
     * Notices component.
     *
     * @param { notices } any[] props
     *
     */
    const Notices = (props) => {
        const [
            notices, 
            //setNotices
        ] = useState(props.notices)

        return notices === undefined ? (
            <p className="text-afm-box-dark-gray text-lg">
                ... Non sono presenti comunicazioni e avvisi
            </p>
        ) : (
            notices.map((notice) => {
                // Format the date.
                const date = formatDateTimeIT(notice.data_dal)

                return (
                    <div key={notice.id_box_avvisi}>
                        <NavLink to={`./dettaglio-comunicazione-${notice.id_box_avvisi}`}>
                            <div className="flex space-x-4 p-2 border-b hover:bg-gray-100">
                                
                                <span 
                                    className={`
                                        h-2
                                        w-2 
                                        rounded-full 
                                        mt-2 
                                        p-1
                                        ${notice.letto ?  
                                                "bg-afm-box-light-gray-text" 
                                            : 
                                                "bg-afm-box-blue"}`
                                    }
                                ></span>

                                <div className="flex flex-col w-full">
                                    <p 
                                        className={`
                                            flex-grow 
                                            font-bold 
                                            ${notice.letto ?  
                                                    "text-afm-box-light-gray-text" 
                                                : 
                                                    "text-afm-box-dark-gray"}`
                                        }
                                    >
                                        {notice.titolo}
                                    </p>
                                    <div
                                        className="text-xs text-afm-box-light-gray-text max-w-screen-2xl"
                                        dangerouslySetInnerHTML={{ __html: notice.avviso }}
                                    ></div>

                                </div>
                                <div 
                                    className="
                                        w-full
                                        basis-1/4
                                        pt-1 
                                        text-end text-afm-box-light-gray-text text-xs"
                                >
                                    <p>{date}</p>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                )
            })
        )
    }

    /**
     *
     * Component update effects.
     *
     */
    useEffect(() => { dispatch(getComunicazioni()) }, [dispatch])

    useEffect(() => { }, [noticesItems, isFetching, isError, isSuccess])

    return (
        <LayoutDetail title="Comunicazioni">
            <LayoutDetailContainer>
                <div className="flex space-x-4 lg:space-x-4 xl:space-x-14">
                    <div className="flex-1">
                        <h2 className="text-xl text-afm-box-dark-gray font-medium mb-8">
                            Avvisi e Comunicazioni
                        </h2>

                        {/* Skeleton. */}
                        {isFetching && <ComunicazioniSkeleton />}

                        <div className="flex flex-col h-fit">
                            {!isFetching && !isError && isSuccess && <Notices notices={noticesItems} />}
                        </div>

                        {isError && !isSuccess && (
                            <div className="flex items-center justify-center my-14">
                                <p className="text-xl">
                                    ...Recupero dei dati fallito
                                </p>
                            </div>
                        )}
                    </div>
                        
                    <div className="flex-1">
                        <Outlet />
                    </div>
                </div>
            </LayoutDetailContainer>
        </LayoutDetail>
    )
}

/**
 *
 * @function ComunicazioniSkeleton
 *
 * @dev Comunicazioni skeleton component.
 *
 * @returns JSX.Element
 *
 */
function ComunicazioniSkeleton() {
    return (
        <div className="animate-pulse">
            <div className="flex space-x-8 p-2">

                <span className="h-2 w-2 rounded-full bg-neutral-300 mt-2 p-1"></span>

                <div className="h-8 w-full bg-neutral-300 rounded"></div>
                <div className="h-8 w-3/4 bg-neutral-300 rounded"></div>
            </div>

            <div className="flex space-x-8 p-2">
                <span className="h-2 w-2 rounded-full bg-neutral-300 mt-2 p-1"></span>

                <div className="h-8 w-full bg-neutral-300 rounded"></div>
                <div className="h-8 w-3/4 bg-neutral-300 rounded"></div>
            </div>

            <div className="flex space-x-8 p-2">
                <span className="h-2 w-2 rounded-full bg-neutral-300 mt-2 p-1"></span>

                <div className="h-8 w-full bg-neutral-300 rounded"></div>
                <div className="h-8 w-3/4 bg-neutral-300 rounded"></div>
            </div>

            <div className="flex space-x-8 p-2">
                <span className="h-2 w-2 rounded-full bg-neutral-300 mt-2 p-1"></span>

                <div className="h-8 w-full bg-neutral-300 rounded"></div>
                <div className="h-8 w-3/4 bg-neutral-300 rounded"></div>
            </div>

            <div className="flex space-x-8 p-2">
                <span className="h-2 w-2 rounded-full bg-neutral-300 mt-2 p-1"></span>

                <div className="h-8 w-full bg-neutral-300 rounded"></div>
                <div className="h-8 w-3/4 bg-neutral-300 rounded"></div>
            </div>
            <div className="flex space-x-8 p-2">
                <span className="h-2 w-2 rounded-full bg-neutral-300 mt-2 p-1"></span>

                <div className="h-8 w-full bg-neutral-300 rounded"></div>
                <div className="h-8 w-3/4 bg-neutral-300 rounded"></div>
            </div>
            <div className="flex space-x-8 p-2">
                <span className="h-2 w-2 rounded-full bg-neutral-300 mt-2 p-1"></span>

                <div className="h-8 w-full bg-neutral-300 rounded"></div>
                <div className="h-8 w-3/4 bg-neutral-300 rounded"></div>
            </div>
        </div>
    )
}

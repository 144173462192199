// React.
import { useState, useEffect, Fragment } from "react"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// AziendaSlice.
import { getFactory, aziendaSelector, downloadFactoryLogo, uploadFactoryLogo } from "../../features/generale/azienda/AziendaSlice"
// UI.
import SubHeader from "../../base/SubHeader"
import LayoutDetailContainer from "../LayoutDetailContainer"
import Input from "../../base/Input"
import ListBox from "../../base/ListBox"
import ImgUploader from "../../base/ImgUploader"
//import BtnAddField from "../../base/BtnAddField"; Not now.
//import BtnRemoveField from "../../base/BtnRemoveField"; Not now.
//import Button from "../../base/Button"; Not now.
// Icons.
import {
    Nbsp,
    //Printer 
} from "../../base/Icons"

/**
 *
 * @dev Generale Subsection view.
 * 
 * @function Azienda
 *
 * @returns JSX.Element
 *
 */
export default function Azienda() {
    // useDispatch hook.
    const dispatch = useDispatch()

    /**
     *  Init sedeOperativaInputList state array.
     *  Handle input List.
     */
    const [
        sedeOperativaInputList,
        //setSedeOperativaInputList
    ] = useState([])

    // sedeOperativa input.
    const SedeOperativa = (props) => (
        <Input
            labelHtmlFor="company-operational-headquarters"
            labelTitle="Sede Operativa"
            inputType="text"
            inputName="company-operational-headquarters"
            placeholder=""
            inputValue={props?.value}
            required={true}
            readOnly={true}
        />
    )

    // Add an Input to the sedeOperativaInputList array.
    /*
    function addSedeOperativa () {

        let copy = sedeOperativaInputList;

        copy.push(<SedeOperativa />);

        // Get the old state and update it.
        setSedeOperativaInputList((prevSedeOperativaInputList) => [...prevSedeOperativaInputList]);

    };
    */

    // Remove an Input to the sedeOperativaInputList array.
    /*
    function removeSedeOperativa () {

        let copy = sedeOperativaInputList;

        copy.pop(<SedeOperativa />);

        // Get the old state and update it.
        setSedeOperativaInputList((prevSedeOperativaInputList) => [...prevSedeOperativaInputList]);

    };
    */

    /**
     *  Init sedeOperativaInputList state array.
     *  Handle input List.
     */
    const [
        codiceAtecoInputList,
        //setCodiceAtecoInputList
    ] = useState([])

    // codiceAteco input.
    const CodiceAteco = (props) => {
        if (!Array.isArray(props?.value)) { return; }
        if (props?.value.length == 0) { return; }
        // return (<ListBox
        //     labelTitle="Codice ATECO"
        //     people={props.value.filter(x => x != undefined)}
        // />);

        return props.value.map(function (atecoCode, index) {
            return (<Input
                key={atecoCode.value}
                labelHtmlFor="company-ateco-codes"
                labelTitle={index === 0 ? "Codice ATECO" : false}
                inputType="text"
                inputName="company-ateco-codes"
                // placeholder=""
                inputValue={atecoCode.value + " - " + atecoCode.name}
                required={true}
                readOnly={true}
            />)
        });
    }

    // Add an Input to the codiceAtecoInputList array.
    /*
    function addCodiceAteco () {

        let copy = codiceAtecoInputList;

        copy.push(<CodiceAteco />);

        // Get the old state and update it.
        setCodiceAtecoInputList((prevCodiceAtecoInputList) => [...prevCodiceAtecoInputList]);

    };
    */

    // Remove an Input to the codiceAtecoInputList array.
    /*
    function removeCodiceAteco () {

        let copy = codiceAtecoInputList;

        copy.pop(<CodiceAteco />);

        // Get the old state and update it.
        setSedeOperativaInputList((prevCodiceAtecoInputList) => [...prevCodiceAtecoInputList]);

    };
    */

    //
    // AziendaSlice State.
    //
    const { factoryItems, factoryLogo, isSuccess, isError, isFetching } = useSelector(aziendaSelector);

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        dispatch(getFactory())
        dispatch(downloadFactoryLogo())
    }, [
        dispatch,
    ])

    useEffect(() => { }, [
        isSuccess,
        isError,
        isFetching,
        sedeOperativaInputList,
        codiceAtecoInputList,
        factoryLogo
    ])

    /**
     *
     * Component render.
     *
     */
    return (
        <Fragment>
            <SubHeader
                subHeaderTitle="Azienda"
                firstBtnVisibile={false}
                //firstBtnText="Stampa"
                //firstBtnIcon={<Printer />}
                //firstBtnTwBg="bg-white"
                //firstBtnTwFocusBg="focus:bg-slate-100"
                secondBtnVisibile={false}
            />
            <LayoutDetailContainer>
                {/* Skeleton. */}
                {isFetching && <AziendaSkeleton />}

                {!isFetching && !isError && isSuccess && (
                    <div className="flex gap-10">
                        <div className="relative">
                            <ImgUploader img={factoryLogo} action={uploadFactoryLogo} />
                        </div>
                        <form action="" method="" className="w-full pr-6">
                            <div className="flex gap-10">
                                <div className="flex-1">
                                    <Input
                                        labelHtmlFor="company-name"
                                        labelTitle="Denominazione"
                                        inputType="text"
                                        inputName="company-name"
                                        placeholder=""
                                        required={true}
                                        readOnly={true}
                                        inputValue={factoryItems.denominazione}
                                    />
                                </div>
                                <div className="flex-1"></div>
                            </div>

                            <div className="flex gap-10">
                                <div className="flex-1">
                                    <Input
                                        labelHtmlFor="company-cf"
                                        labelTitle="Codice Fiscale"
                                        inputType="text"
                                        inputName="company-cf"
                                        placeholder=""
                                        required={true}
                                        readOnly={true}
                                        inputValue={factoryItems.codfiscale}
                                    />
                                </div>
                                <div className="flex-1">
                                    <Input
                                        labelHtmlFor="company-piva"
                                        labelTitle="Partita IVA"
                                        inputType="text"
                                        inputName="company-piva"
                                        placeholder=""
                                        required={true}
                                        readOnly={true}
                                        inputValue={factoryItems.partitaiva}
                                    />
                                </div>
                            </div>

                            <div className="flex gap-10">
                                <div className="flex-1">
                                    <Input
                                        labelHtmlFor="company-phone"
                                        labelTitle="N° di telefono"
                                        inputType="phone"
                                        inputName="company-phone"
                                        placeholder=""
                                        required={true}
                                        readOnly={true}
                                        inputValue={factoryItems?.telefono}
                                    />
                                </div>
                                <div className="flex-1">
                                    <Input
                                        labelHtmlFor="company-email"
                                        labelTitle="Email"
                                        inputType="email"
                                        inputName="company-email"
                                        placeholder=""
                                        required={true}
                                        readOnly={true}
                                        inputValue={factoryItems.email}
                                    />
                                </div>
                            </div>
                            <div className="w-full mb-6">
                                <Input
                                    labelHtmlFor="company-registered-office"
                                    labelTitle="Sede Legale"
                                    inputType="text"
                                    inputName="company-registered-office"
                                    placeholder=""
                                    required={true}
                                    readOnly={true}
                                    inputValue={factoryItems?.indirizzo}
                                />
                                {
                                    factoryItems.insediamenti && (Array.isArray(factoryItems.insediamenti) ? factoryItems.insediamenti : [factoryItems.insediamenti]).map((insediamento, id) => (
                                    <div key={id}>
                                        <SedeOperativa value={insediamento.nome} />
                                    </div>))
                                }

                                {/* Feature disabled.*/}
                                {/*
                                {sedeOperativaInputList.map((item,id) => {
                                    console.log(item);
                                    return (
                                        <div key={id} className="flex gap-2">
                                            <div className="flex-1">
                                                {item}
                                            </div>
                                            <BtnRemoveField onClick={removeSedeOperativa} />
                                        </div>
                                    );
                                })}

                                <BtnAddField
                                    text='Aggiungi sede Operativa'
                                    onClick={addSedeOperativa}
                                />
                                */}
                            </div>

                            <div className="w-full mb-6">
                                {/* {factoryItems.codici_ateco.map((codiceAteco, id) => (
                                    <div key={id}>
                                        <CodiceAteco value={codiceAteco.codice_ateco} />
                                    </div>
                                ))} */}
                                <CodiceAteco value={factoryItems.codici_ateco && (Array.isArray(factoryItems.codici_ateco) ? factoryItems.codici_ateco : [factoryItems.codici_ateco]).map((item, id) => {
                                    return { name: item.descrizione_ateco, value: item.codice_ateco }
                                })} />
                                {/* Feature disabled.*/}
                                {/*
                                {codiceAtecoInputList.map((item,id) => {
                                    return (
                                        <div key={id} className="flex gap-2">
                                            <div className="flex-1">
                                                {item}
                                            </div>
                                            <BtnRemoveField onClick={removeCodiceAteco} />
                                        </div>
                                    );
                                })}

                                <BtnAddField
                                    text='Aggiungi codice ATECO'
                                    onClick={addCodiceAteco}
                                />
                                */}
                            </div>

                            {/*
                            <Button
                                text='Salva Modifiche'
                                twTextColor='text-white'
                                twBackground='bg-afm-box-blue'
                                twFocusBG='focus:bg-afm-box-blue/[0.5]'
                            />
                            */}
                        </form>
                    </div>
                )}

                {isError && !isSuccess && (
                    <div className="flex items-center justify-center my-14">
                        <p className="text-xl">...Recupero dei dati fallito</p>
                    </div>
                )}
            </LayoutDetailContainer>
        </Fragment>
    )
}

/**
 *
 * @function AziendaSkeleton
 *
 * @dev Azienda Skeleton component ui preview.
 *
 * @returns JSX.Element
 *
 */
function AziendaSkeleton() {
    return (
        <div className="animate-pulse flex space-x-4">
            <div className="flex gap-10 w-full">
                <div className="bg-neutral-300 h-40 w-52"></div>
                <div className="w-full pr-6">
                    <div className="flex gap-10">
                        <div className="flex-1">
                            <div className="h-10 my-6 w-full bg-neutral-300 rounded">
                                <Nbsp />
                            </div>
                        </div>
                        <div className="flex-1"></div>
                    </div>
                    <div className="flex gap-10">
                        <div className="flex-1">
                            <div className="h-10 my-6 w-full bg-neutral-300 rounded">
                                <Nbsp />
                            </div>
                        </div>
                        <div className="flex-1">
                            <div className="h-10 my-6 w-full bg-neutral-300 rounded">
                                <Nbsp />
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-10">
                        <div className="flex-1">
                            <div className="h-10 my-6 w-full bg-neutral-300 rounded">
                                <Nbsp />
                            </div>
                        </div>
                        <div className="flex-1">
                            <div className="h-10 my-6 w-full bg-neutral-300 rounded">
                                <Nbsp />
                            </div>
                        </div>
                    </div>
                    <div className="w-full my-14">
                        <div className="h-10 w-full bg-neutral-300 rounded">
                            <Nbsp />
                        </div>
                    </div>
                    <div className="w-full mb-6">
                        <div className="h-10 w-full bg-neutral-300 rounded">
                            <Nbsp />
                        </div>
                    </div>
                    <div className="h-10 w-1/6 mt-20 bg-neutral-300 rounded">
                        <Nbsp />
                    </div>
                </div>
            </div>
        </div>
    )
}

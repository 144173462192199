// React.
import { useState, useEffect, Fragment } from "react"
// Router.
import { useParams } from "react-router-dom"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// InsediamentiSlice.
import {
    getSettlementInfoById,
    getSettlementDocsById,
    clearSettlementDocSenderId,
    insediamentiSelector,
    downloadSettlementDoc,
    updateInfo,
    isFetching
} from "../../features/insediamenti/InsediamentiSlice"
// UI.
import SubHeader from "../../base/SubHeader"
import LayoutDetailContainer from "../LayoutDetailContainer"
import Tabs from "../../base/Tabs"
import Grid from "../../base/Grid"
//import PrototypeGrid from "../../base/PrototypeGrid"
import Input from "../../base/Input"
// import SearchBox from "../../base/SearchBox"
import LayoutDetail from "../LayoutDetail"
import Button from "../../base/Button"
import PrototypeGrid from "../../base/PrototypeGrid"
import { PaperClip } from "../../base/Icons"
//import PdfViewer from "../../base/PdfViewer"
// Icons.
import {
    DocumentText,
    //Printer, 
    //PaperClip 
    OvalLoader
} from "../../base/Icons"

import { emailPattern, emptyString, DataScadenzaSort } from "../../../common/util"
import { useForm } from "react-hook-form"
import { switchUser } from "../../features/generale/admin/AdminSlice"
import Select from "react-select"
// SearchSlice.
import { searchSelector, setSearchList, reset } from "../../features/search/SearchSlice"
import SearchBox from "../../base/SearchBox"

/**
 *
 * @dev Insediamenti detail UI Compoenent.
 * 
 * @function InsediamentiDetail
 *
 * @returns JSX.Element
 *
 */
export default function InsediamentiDetail() {
    const dispatch = useDispatch()
    // Get the url parameter.
    const { id } = useParams()
    // Tab categories.
    const [categories, setCategories] = useState([])
    // Documenti grid header title.
    const [documentiGridHeaderTitleItems, setDocumentiGridHeaderTitleItems] = useState([])
    // Documenti grid items.
    const [documentiGridItems, setDocumentiGridItems] = useState([])

    // Nomine grid header title.
    const [nomineGridHeaderTitleItems, setNomineGridHeaderTitleItems] = useState([])
    // Nomine grid items.
    const [nomineGridItems, setNomineGridItems] = useState([])

    // Autorizzazioni grid header title.
    const [autorizzazioniGridHeaderTitleItems, setAutorizzazioniGridHeaderTitleItems] = useState([])
    // Autorizzazioni grid items.
    const [autorizzazioniGridItems, setAutorizzazioniGridItems] = useState([])

    // Ambiente grid header title.
    const [ambienteGridHeaderTitleItems, setAmbienteGridHeaderTitleItems] = useState([])
    // Ambiente grid items.
    const [ambienteGridItems, setAmbienteGridItems] = useState([]);

    const [currentCategory, setCurrentCategory] = useState('documenti');
    // searchSlice State.
    const { searchResults, searchList, searchQuery } = useSelector(searchSelector);

    const {
        settlementInfo,
        settlementDocs,
        isFetching,
        isSuccess,
        isError,
        settlementDocSenderId,
        settlementDoc,
        isFetchingDoc,
        isSuccessDoc
    } = useSelector(insediamentiSelector)

    useEffect(() => {
        setValue('settlementStreet', settlementInfo.indirizzo);
        setValue('settlementNumber', settlementInfo.civico);
        setValue('settlementDistrict', settlementInfo.citta);
        setValue('settlementRegion', settlementInfo.provincia);
        setValue('settlementCap', settlementInfo.cap);
        setValue('settlementReferent', settlementInfo.nome_referente);
        setValue('settlementReferentEmail', settlementInfo.email_referente);
        setValue('settlementReferentPhone', settlementInfo.telefono_referente);
    }, [settlementInfo]);

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        setValue,
        formState,
        formState: { errors }, // subscription.
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {
            settlementStreet: settlementInfo.indirizzo,
            settlementNumber: settlementInfo.civico,
            settlementDistrict: settlementInfo.citta,
            settlementRegion: settlementInfo.provincia,
            settlementCap: settlementInfo.cap,
            settlementReferent: settlementInfo.nome_referente,
            settlementReferentEmail: settlementInfo.email_referente,
            settlementReferentPhone: settlementInfo.telefono_referente,
            formErrors: "",
        },
    }) // UseForm Hook.

    const onSubmit = (data) => {
        clearErrors("formErrors");
        console.log(data, id)
        dispatch(updateInfo({ data, id }))
    };

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        dispatch(getSettlementInfoById(id));
        dispatch(getSettlementDocsById(id));
        //dispatch(getSettlementNominationsById(id));
        //dispatch(getSettlementAuthorizationsById(id));
        //dispatch(getSettlementEnviromentsById(id));
    }, [dispatch, id])

    useEffect(() => {
        const docsDocumenti = settlementDocs.filter((doc) => doc.tipo == 'Documenti').map(item => item);
        const docsNomine = settlementDocs.filter((doc) => doc.tipo == 'Nomine').map(item => item);
        const docsAutorizzazioni = settlementDocs.filter((doc) => doc.tipo == 'Autorizzazioni').map(item => item);
        const docsAmbiente = settlementDocs.filter((doc) => doc.tipo == 'Ambiente').map(item => item);
        // Build Grid items for Documenti Category.
        const documentiItems = [];
        const emptySearch = (searchQuery || '').trim() === "";
        const results = emptySearch ? searchList : searchResults;
        const resultsDocumenti = currentCategory === 'documenti' ? results.map(item => item) : docsDocumenti;
        const resultsNomine = currentCategory === 'nomine' ? results.map(item => item) : docsNomine;
        const resultsAutorizzazioni = currentCategory === 'autorizzazioni' ? results.map(item => item) : docsAutorizzazioni;
        const resultsAmbiente = currentCategory === 'ambiente' ? results.map(item => item) : docsAmbiente;
        if (resultsDocumenti.length === 0) {
            setDocumentiGridItems([]);
        }

        resultsDocumenti
            .sort(DataScadenzaSort)
            .map((doc) => {
                const documentiGridItem = [
                    {
                        title: "",
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: (
                            <div
                                className="
                                flex 
                                space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                                justify-self-start 
                                text-afm-box-light-gray-text"
                            >
                                <DocumentText />
                                <span className="border-l border-afm-box-light-gray-text"></span>
                            </div>
                        ),
                    },
                    {
                        title: "Documento",
                        text: doc.descrizione_documento,
                        additionalText: "",
                        twColSpan: "col-span-5",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        title: "Data Esecuzione",
                        text: doc.data_caricamento,
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        title: "Data scadenza",
                        text: doc.data_scadenza,
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        title: "",
                        text: "",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "justify-self-end",
                        component: (
                            <div className="flex items-center space-x-2">
                                {/* If isFetchingDoc and domaDocSenderId State correspond to Button id pressed, than show the OvalLoader. */}
                                {/* {(isFetchingDoc && (settlementDocSenderId === doc.id_box_verifica_documentale)) &&
                                <OvalLoader strokeColor='#aaa' />} */}
                                {(!emptyString(doc.allegato)) &&
                                    <Button
                                        text="DOWNLOAD"
                                        id={doc.id_box_verifica_documentale}
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-blue"
                                        twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                        actions={[
                                            clearSettlementDocSenderId(),
                                            downloadSettlementDoc({ docId: doc.id_box_verifica_documentale, docName: doc.allegato, settlementId: doc.id_box_insediamenti }),
                                        ]} />
                                }
                            </div>
                        ),
                    },
                ];
                documentiItems.push(documentiGridItem);
                return setDocumentiGridItems(documentiItems);
            });

        setNomineGridItems(resultsNomine.length === 0 ? [] :
            resultsNomine
            .sort(DataScadenzaSort)
            .map((doc) => {
                return [
                    {
                        title: "",
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: (
                            <div
                                className="
                                flex 
                                space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                                justify-self-start 
                                text-afm-box-light-gray-text"
                            >
                                <DocumentText />
                                <span className="border-l border-afm-box-light-gray-text"></span>
                            </div>
                        ),
                    },
                    {
                        title: "Documento",
                        text: doc.descrizione_documento,
                        additionalText: "",
                        twColSpan: "col-span-5",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        title: "Data Esecuzione",
                        text: doc.data_caricamento,
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        title: "Data scadenza",
                        text: doc.data_scadenza,
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        title: "",
                        text: "",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "justify-self-end",
                        component: (
                            <div className="flex items-center space-x-2">
                                {/* If isFetchingDoc and domaDocSenderId State correspond to Button id pressed, than show the OvalLoader. */}
                                {/* {(isFetchingDoc && (settlementDocSenderId === doc.id_box_verifica_documentale)) &&
                                <OvalLoader strokeColor='#aaa' />} */}
                                {(!emptyString(doc.allegato)) &&
                                    <Button
                                        text="DOWNLOAD"
                                        id={doc.id_box_verifica_documentale}
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-blue"
                                        twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                        actions={[
                                            clearSettlementDocSenderId(),
                                            downloadSettlementDoc({ docId: doc.id_box_verifica_documentale, docName: doc.allegato, settlementId: doc.id_box_insediamenti }),
                                        ]} />
                                }
                            </div>
                        ),
                    },
                ];
            }));
        setAutorizzazioniGridItems(resultsAutorizzazioni.length === 0 ? [] :
            resultsAutorizzazioni
            .sort(DataScadenzaSort)
            .map((doc) => {
                return [
                    {
                        title: "",
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: (
                            <div
                                className="
                                flex 
                                space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                                justify-self-start 
                                text-afm-box-light-gray-text"
                            >
                                <DocumentText />
                                <span className="border-l border-afm-box-light-gray-text"></span>
                            </div>
                        ),
                    },
                    {
                        title: "Documento",
                        text: doc.descrizione_documento,
                        additionalText: "",
                        twColSpan: "col-span-5",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        title: "Data Esecuzione",
                        text: doc.data_caricamento,
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        title: "Data scadenza",
                        text: doc.data_scadenza,
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        title: "",
                        text: "",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "justify-self-end",
                        component: (
                            <div className="flex items-center space-x-2">
                                {/* If isFetchingDoc and domaDocSenderId State correspond to Button id pressed, than show the OvalLoader. */}
                                {/* {(isFetchingDoc && (settlementDocSenderId === doc.id_box_verifica_documentale)) &&
                                <OvalLoader strokeColor='#aaa' />} */}
                                {(!emptyString(doc.allegato)) &&
                                    <Button
                                        text="DOWNLOAD"
                                        id={doc.id_box_verifica_documentale}
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-blue"
                                        twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                        actions={[
                                            clearSettlementDocSenderId(),
                                            downloadSettlementDoc({ docId: doc.id_box_verifica_documentale, docName: doc.allegato, settlementId: doc.id_box_insediamenti }),
                                        ]} />
                                }
                            </div>
                        ),
                    },
                ];
            }));
        setAmbienteGridItems(resultsAmbiente.length === 0 ? [] :
            resultsAmbiente
            .sort(DataScadenzaSort)
            .map((doc) => {
                return [
                    {
                        title: "",
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: (
                            <div
                                className="
                                flex 
                                space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                                justify-self-start 
                                text-afm-box-light-gray-text"
                            >
                                <DocumentText />
                                <span className="border-l border-afm-box-light-gray-text"></span>
                            </div>
                        ),
                    },
                    {
                        title: "Documento",
                        text: doc.descrizione_documento,
                        additionalText: "",
                        twColSpan: "col-span-5",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        title: "Data Esecuzione",
                        text: doc.data_caricamento,
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        title: "Data scadenza",
                        text: doc.data_scadenza,
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        title: "",
                        text: "",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "justify-self-end",
                        component: (
                            <div className="flex items-center space-x-2" >
                                {/* If isFetchingDoc and domaDocSenderId State correspond to Button id pressed, than show the OvalLoader. */}
                                {/* {(isFetchingDoc && (settlementDocSenderId === doc.id_box_verifica_documentale)) &&
                                <OvalLoader strokeColor='#aaa' />} */}
                                {(!emptyString(doc.allegato)) &&
                                    <Button
                                        text="DOWNLOAD"
                                        id={doc.id_box_verifica_documentale}
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-blue"
                                        twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                        actions={
                                            [
                                                clearSettlementDocSenderId(),
                                                downloadSettlementDoc({ docId: doc.id_box_verifica_documentale, docName: doc.allegato, settlementId: doc.id_box_insediamenti }),
                                            ]} />
                                }
                            </div >
                        ),
                    },
                ];
            }));

        const commonHeaderTitle = [
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Documento",
                twColSpan: "col-span-5",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data Esecuzione",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data scadenza",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "",
                twColSpan: "col-span-2",
                twAdditionalClass: "justify-self-end",
                component: <SearchBox />,
            },
        ];
        setDocumentiGridHeaderTitleItems(commonHeaderTitle);
        setNomineGridHeaderTitleItems(commonHeaderTitle);
        setAutorizzazioniGridHeaderTitleItems(commonHeaderTitle);
        setAmbienteGridHeaderTitleItems(commonHeaderTitle);
    }, [
        searchList,
        searchQuery,
        searchResults,
        settlementDocs,
        settlementInfo,
        isSuccess,
        isFetching,
        isError
    ])

    useEffect(() => {
        /**
         *
         *  Build Tab categories.
         *
         */
        const categoryItems = {
            Informazioni: [
                {
                    id: 1,
                    component: (
                        <Fragment>
                            <div className="h-16 items-center flex space-x-8 p-3 rounded-sm bg-gray-100">
                                <h1 className="text-left text-lg font-bold">
                                    Informazioni
                                </h1>
                            </div>

                            <div className="flex gap-8 my-8">
                                <form action="" className="w-full pr-6" onSubmit={handleSubmit(onSubmit)} method="POST">
                                    <div className="flex gap-10">
                                        <div className="flex-1 mb-6">
                                            <label htmlFor="settlementStreet"
                                                className="block mb-2 text-left text-sm text-gray-800 font-medium">Via
                                                / Piazza</label>

                                            <input
                                                type="text"
                                                name="settlementStreet"
                                                className="w-full  px-3  py-2  bg-afm-box-light-gray
                                                text-afm-box-dark-gray  placeholder-afm-box-dark-gray/[.50] border
                                                border-gray-300  rounded-md  focus:outline-none
                                                focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                                {...register("settlementStreet", {
                                                    //required: true,
                                                    //validate: value => (value !== '')
                                                })}
                                            />

                                        </div>
                                        <div className="flex-2">
                                            <label htmlFor="settlementNumber"
                                                className="block mb-2 text-left text-sm text-gray-800 font-medium">Numero
                                                civico</label>

                                            <input
                                                type="text"
                                                name="settlementNumber"
                                                className="w-full  px-3  py-2  bg-afm-box-light-gray
                                                text-afm-box-dark-gray  placeholder-afm-box-dark-gray/[.50] border
                                                border-gray-300  rounded-md  focus:outline-none
                                                focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                                {...register("settlementNumber", {
                                                    //required: true,
                                                    //validate: value => (value !== '')
                                                })}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex gap-10 mb-6">
                                        <div className="flex-1">
                                            <label htmlFor="settlementDistrict"
                                                className="block mb-2 text-left text-sm text-gray-800 font-medium">Città</label>

                                            <input
                                                type="text"
                                                name="settlementDistrict"
                                                className="w-full  px-3  py-2  bg-afm-box-light-gray
                                                text-afm-box-dark-gray  placeholder-afm-box-dark-gray/[.50] border
                                                border-gray-300  rounded-md  focus:outline-none
                                                focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                                {...register("settlementDistrict", {
                                                    //required: true,
                                                    //validate: value => (value !== '')
                                                })}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <label htmlFor="settlementRegion"
                                                className="block mb-2 text-left text-sm text-gray-800 font-medium">Città</label>

                                            <input
                                                type="text"
                                                name="settlementRegion"
                                                className="w-full  px-3  py-2  bg-afm-box-light-gray
                                                text-afm-box-dark-gray  placeholder-afm-box-dark-gray/[.50] border
                                                border-gray-300  rounded-md  focus:outline-none
                                                focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                                {...register("settlementRegion", {
                                                    //required: true,
                                                    //validate: value => (value !== '')
                                                })}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <label htmlFor="settlementCap"
                                                className="block mb-2 text-left text-sm text-gray-800 font-medium">CAP</label>

                                            <input
                                                type="text"
                                                name="settlementCap"
                                                className="w-full  px-3  py-2  bg-afm-box-light-gray
                                                text-afm-box-dark-gray  placeholder-afm-box-dark-gray/[.50] border
                                                border-gray-300  rounded-md  focus:outline-none
                                                focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                                {...register("settlementCap", {
                                                    //required: true,
                                                    //validate: value => (value !== '')
                                                })}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex gap-10">
                                        <div className="flex-1 mb-6">
                                            <label htmlFor="settlementReferent"
                                                className="block mb-2 text-left text-sm text-gray-800 font-medium">Nome
                                                referente</label>

                                            <input
                                                type="text"
                                                name="settlementReferent"
                                                className="w-full  px-3  py-2  bg-afm-box-light-gray
                                                text-afm-box-dark-gray  placeholder-afm-box-dark-gray/[.50] border
                                                border-gray-300  rounded-md  focus:outline-none
                                                focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                                {...register("settlementReferent", {
                                                    //required: true,
                                                    //validate: value => (value !== '')
                                                })}
                                            />
                                        </div>
                                        <div className="flex-1"></div>
                                        <div className="flex-1"></div>
                                    </div>

                                    <div className="flex gap-10">
                                        <div className="flex-1 mb-6">
                                            <label htmlFor="settlementReferentEmail"
                                                className="block mb-2 text-left text-sm text-gray-800 font-medium">Email</label>

                                            <input
                                                type="text"
                                                name="settlementReferentEmail"
                                                className="w-full  px-3  py-2  bg-afm-box-light-gray
                                                text-afm-box-dark-gray  placeholder-afm-box-dark-gray/[.50] border
                                                border-gray-300  rounded-md  focus:outline-none
                                                focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                                {...register("settlementReferentEmail", {
                                                    //required: true,
                                                    //validate: value => (value !== '')
                                                })}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <label htmlFor="settlementReferentPhone"
                                                className="block mb-2 text-left text-sm text-gray-800 font-medium">Numero
                                                di telefono</label>

                                            <input
                                                type="text"
                                                name="settlementReferentPhone"
                                                className="w-full  px-3  py-2  bg-afm-box-light-gray
                                                text-afm-box-dark-gray  placeholder-afm-box-dark-gray/[.50] border
                                                border-gray-300  rounded-md  focus:outline-none
                                                focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                                {...register("settlementReferentPhone", {
                                                    //required: true,
                                                    //validate: value => (value !== '')
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex space-x-10">
                                        <button
                                            type="submit"
                                            value="Submit"
                                            disabled={isFetching}
                                            className={`
                                            ${isFetching ? "disabled:opacity-50" : null}
                                            flex 
                                            w-auto 
                                            px-7 
                                            h-10 
                                            gap-1 
                                            items-center justify-center 
                                            uppercase whitespace-nowrap font-semibold 
                                            text-white 
                                            bg-afm-box-blue 
                                            hover:opacity-80 
                                            focus:outline-none`}
                                        >
                                            {isFetching && <OvalLoader />} Salva Modifiche
                                        </button>
                                        {/* <input
                                            type="submit"
                                            value="Salva modifiche"
                                            className="bg-afm-box-blue undefined text-white flex gap-1 w-auto h-10 px-7
                                        items-center justify-center uppercase whitespace-nowrap font-semibold focus:outline-none"
                                        /> */}
                                    </div>
                                </form>
                            </div>
                        </Fragment>
                    ),
                },
            ],
            Documenti: [
                {
                    id: 1,
                    component: (
                        <Fragment>
                            <div className="h-16 items-center flex space-x-8 p-3 rounded-sm bg-gray-100">
                                <h1 className="text-left text-lg font-bold">
                                    Documenti
                                </h1>
                            </div>
                            <Grid
                                twGridTemplateColumns="grid-cols-12"
                                headerTitleItems={documentiGridHeaderTitleItems}
                                items={documentiGridItems}
                                isFetching={isFetching}
                                emptyMessage={<>{"Non sono presenti Documenti"}&hellip;</>}
                            />
                        </Fragment>
                    )
                }
            ],
            Nomine: [
                // {id: 1}
                {
                    id: 1,
                    component: (
                        <Fragment>
                            <div className="h-16 items-center flex space-x-8 p-3 rounded-sm bg-gray-100">
                                <h1 className="text-left text-lg font-bold">
                                    Nomine
                                </h1>
                            </div>
                            <Grid
                                twGridTemplateColumns="grid-cols-12"
                                headerTitleItems={nomineGridHeaderTitleItems}
                                items={nomineGridItems}
                                isFetching={isFetching}
                                emptyMessage={<>{'Non sono presenti Nomine'}&hellip;</>}
                            />
                        </Fragment>
                    ),
                },
            ],
            Autorizzazioni: [
                // { id: 2 }
                {
                    id: 1,
                    component: (
                        <Fragment>
                            <div className="h-16 items-center flex space-x-8 p-3 rounded-sm bg-gray-100">
                                <h1 className="text-left text-lg font-bold">
                                    Autorizzazioni
                                </h1>
                            </div>
                            <Grid
                                twGridTemplateColumns="grid-cols-12"
                                headerTitleItems={autorizzazioniGridHeaderTitleItems}
                                items={autorizzazioniGridItems}
                                isFetching={isFetching}
                                emptyMessage={<>{'Non sono presenti Autorizzazioni'}&hellip;</>}
                            />
                        </Fragment>
                    ),
                },
            ],
            Ambiente: [
                // { id: 3 }
                {
                    id: 1,
                    component: (
                        <Fragment>
                            <div className="h-16 items-center flex space-x-8 p-3 rounded-sm bg-gray-100">
                                <h1 className="text-left text-lg font-bold">
                                    Ambiente
                                </h1>
                            </div>
                            <Grid
                                twGridTemplateColumns="grid-cols-12"
                                headerTitleItems={ambienteGridHeaderTitleItems}
                                items={ambienteGridItems}
                                isFetching={isFetching}
                                emptyMessage={<>{'Non sono presenti documenti Ambiente'}&hellip;</>}
                            />
                        </Fragment>
                    ),
                },
            ],
        }
        return setCategories(categoryItems)
    }, [
        documentiGridHeaderTitleItems,
        documentiGridItems,
        nomineGridHeaderTitleItems,
        nomineGridItems,
        autorizzazioniGridHeaderTitleItems,
        autorizzazioniGridItems,
        ambienteGridHeaderTitleItems,
        ambienteGridItems,
        isFetching,
        isSuccess,
        isError
    ]);


    useEffect(() => {
        if (isSuccess) {
            // if dataItems are been fetched then call setSearchList giving:
            // list, search key and an additional list of search key
            const docsDocumenti = settlementDocs.filter((doc) => doc.tipo == 'Documenti').map(item => item);
            const docsNomine = settlementDocs.filter((doc) => doc.tipo == 'Nomine').map(item => item);
            const docsAutorizzazioni = settlementDocs.filter((doc) => doc.tipo == 'Autorizzazioni').map(item => item);
            const docsAmbiente = settlementDocs.filter((doc) => doc.tipo == 'Ambiente').map(item => item);
            const searchConfig = {
                dataItems: docsDocumenti,
                searchIndex: 'descrizione_documento',
                additionalIndex: [],
            };
            if (currentCategory === "documenti" && docsDocumenti.length <= 0) {
                dispatch(reset());
                return
            }
            if (currentCategory === "nomine" && docsNomine.length <= 0) {
                dispatch(reset());
                return
            }
            if (currentCategory === "autorizzazioni" && docsAutorizzazioni.length <= 0) {
                dispatch(reset());
                return
            }
            if (currentCategory === "ambiente" && docsAmbiente.length <= 0) {
                dispatch(reset());
                return
            }
            switch (currentCategory) {
                case 'nomine':
                    searchConfig.dataItems = docsNomine;
                    break;
                case 'autorizzazioni':
                    searchConfig.dataItems = docsAutorizzazioni;
                    break;
                case 'ambiente':
                    searchConfig.dataItems = docsAmbiente;
                    break;
            };
            dispatch(
                setSearchList(searchConfig)
            );
        }
    }, [
        searchQuery,
        settlementDocs,
        isFetching,
        isSuccess,
        isError,
        currentCategory,

        settlementInfo
    ]);

    useEffect(() => {
        dispatch(reset());
    }, []);

    /**
     *
     * Component render.
     *
     */
    return (
        <Fragment>
            <LayoutDetail title="Insediamenti">
                <SubHeader
                    subHeaderTitle="Insediamenti"
                    firstBtnVisibile={false}
                    //firstBtnText="stampa"
                    //firstBtnIcon={<Printer />}
                    //firstBtnTwBg="bg-white"
                    //firstBtnTwFocusBg="focus:bg-white/[0.7]"
                    //firstBtnTwTextColor="text-black"
                    secondBtnVisibile={true}
                    secondBtnText="Download ZIP"
                    secondBtnTwBg="bg-afm-box-dark-gray"
                    secondBtnTwFocusBg="focus:bg-afm-box-dark-gray/[0.7]"
                    secondBtnTwTextColor="text-white"
                />
                <LayoutDetailContainer>
                    {/* Skeleton. */}
                    {isFetching && <InsediamentiDetailSkeleton />}

                    {!isFetching && !isError && isSuccess && <Tabs
                        categories={categories}
                        onChange={(index) => {
                            const categoriesNames = Object.keys(categories);
                            const newCurrentCategory = (categoriesNames[index] || '').trim().toLowerCase();
                            dispatch(reset());
                            setCurrentCategory(newCurrentCategory);
                        }}
                    />}

                    {isError && !isSuccess && (
                        <div className="flex items-center justify-center my-14">
                            <p className="text-xl">...Recupero dei dati fallito</p>
                        </div>
                    )}
                </LayoutDetailContainer>
            </LayoutDetail>
        </Fragment>
    )
}

/**
 *
 * @dev InsediamentiDetail skeleton component.
 * 
 * @function InsediamentiDetailSkeleton
 *
 * @returns JSX.Element
 *
 */
function InsediamentiDetailSkeleton() {
    return (
        <div className="animate-pulse px-10">
            <div className="flex flex-col">
                <header
                    className="
                        h-20 
                        flex 
                        space-x-4 
                        items-center 
                        justify-items-start 
                        font-semibold"
                >
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                </header>

                <span className="flex-grow mb-2 border-t border-gray-400"></span>

                <div
                    className="
                        flex 
                        space-x-4 
                        my-1 
                        mb-4 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-14 w-full bg-neutral-300 rounded"></div>
                </div>

                <div
                    className="
                        flex 
                        space-x-4 
                        my-1 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        my-1 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        my-1 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        my-1 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        my-1 
                        rounded-md 
                        items-center 
                        justify-center 
                        justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
            </div>
        </div>
    )
}

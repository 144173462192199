// Status Class enums can be grouped as static members of a class.
export default class CompliantStatus {
    // Create new instances of the same class as static attributes.
    //
    // compliant namespace.
    static compliant = new CompliantStatus("compliant", "Conforme")
    // nonCompliant namespace.
    static nonCompliant = new CompliantStatus("non-compliant", "Non conforme")
    // waiting namespace.
    static waiting = new CompliantStatus("waiting", "In attesa di verifica")
    constructor(value, message) {
        this.value = value
        this.message = message
    }
}
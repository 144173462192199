/**
 * 
 * @dev 404 route.
 *
 * @function NoMatchingRoute
 *
 * @returns  JSX.Element
 *
 */
export default function NoMatchingRoute() {
    return (
        <main>
            <div className="w-full h-screen flex flex-col gap-8 items-center justify-center text-5xl">
                <p>🏝️</p>
                <p> Ops! sei fuori rotta ⛵</p>
                <p> Controlla bene l'URL...</p>
            </div>
        </main>
    )
}

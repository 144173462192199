// React.
import { useEffect } from "react"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// ScadenzeSlice.
import { getScadenze, scadenzeSelector } from "../../features/scadenze/ScadenzeSlice"
// ScadenzeModalSlice.
import { openModal, scadenzeModalSelector } from "../../features/scadenze/ScadenzeModalSlice"
// Util.
import { formatDateTimeIT } from "../../../common/util"
// UI.
import LayoutDetail from "../LayoutDetail"
import LayoutDetailContainer from "../LayoutDetailContainer"
import Pillow from "../../base/Pillow"
import ScadenzeModal from "./ScadenzeModal"
// Icons.
import { 
    Check, 
    PluSm, 
    //Printer 
} from "../../base/Icons"

/**
 *
 * @dev Scadenze UI Component.
 * @dev Scadenze view section.
 *
 * @function Scadenze
 *
 * @returns JSX.Element
 *
 */
export default function Scadenze() {
    const dispatch = useDispatch()

    // scadenzeSlice State.
    const {
        deadlinesItems,
        isFetching,
        isSuccess,
        isError,
        //errorMsg
    } = useSelector(scadenzeSelector)

    // scadenzeModalSlice State.
    const { modalIsOpen, modalIsSuccess } = useSelector(scadenzeModalSelector)

    /**
     *
     * Deadline component.
     *
     * @param { deadLines } any[] props
     *
     */
    const DeadLine = (props) => {
        const deadLines = props.deadLines

        return (
            <div className="flex flex-col">
                {deadLines === undefined ? (
                    <p className="text-afm-box-dark-gray text-lg">... Non sono presenti scadenze</p>
                ) : (
                    deadLines.map((deadLine) => {
                        // Format the date.
                        const date = formatDateTimeIT(deadLine.data_scadenza)

                        const deadLineIsClosed = deadLine.chiusa

                        return (
                            <div
                                key={deadLine.id_box_scadenze}
                                className="
                                    flex 
                                    w-full 
                                    space-x-4 
                                    p-2 
                                    py-2
                                    border-b
                                    hover:bg-gray-100"
                            >
                                <span
                                    className={`
                                        h-2 
                                        w-2 
                                        rounded-full 
                                        ${deadLineIsClosed === "No"
                                            ? "bg-afm-box-blue"
                                            : "bg-transparent"} 
                                        mt-2
                                        p-1`}
                                ></span>

                                <div className="flex flex-grow items-center">
                                    <div className="text-afm-box-dark-gray flex-grow">
                                        <p className="font-bold">
                                            {deadLine.titolo}
                                        </p>
                                        <p>
                                            {deadLine.insediamento}
                                        </p>
                                        <div className="text-sm text-afm-box-light-gray-text">
                                            {deadLine.note}
                                        </div>
                                    </div>

                                    <div
                                        className="
                                            flex 
                                            space-x-40 
                                            items-center justify-items-center"
                                    >
                                        <div className="font-medium">
                                            <Pillow
                                                text={date}
                                                twTextColor={`${
                                                    deadLineIsClosed === "No"
                                                        ? "text-white"
                                                        : "text-afm-box-dark-gray"
                                                }`}
                                                twBackground={`${
                                                    deadLineIsClosed === "No"
                                                        ? "bg-afm-box-blue"
                                                        : "bg-afm-box-light-gray"
                                                }`}
                                            />
                                        </div>

                                        <div
                                            className={`${
                                                deadLineIsClosed === "No"
                                                    ? "text-transparent"
                                                    : "text-afm-box-light-gray-text"
                                            } pr-10`}
                                        >
                                            <Check />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                )}
            </div>
        )
    }

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        dispatch(getScadenze())
    }, [dispatch, modalIsSuccess])

    useEffect(() => {}, [deadlinesItems, isFetching, isSuccess, isError])

    /**
     *
     * Component render.
     *
     */
    return (
        <LayoutDetail
            title="Scadenze"
            firstBtnVisibile={false}
            //firstBtnText="stampa"
            //firstBtnIcon={<Printer />}
            //firstBtnTwBg="bg-white"
            //firstBtnTwFocusBg="bg-white[0.7]"
            //firstBtnTwTextColor="text-black"
            //firstBtnAction=
            secondBtnVisibile={true}
            secondBtnText="aggiungi scadenza"
            secondBtnIcon={<PluSm />}
            secondBtnTwBg="bg-afm-box-dark-gray"
            secondBtnTwFocusBg="bg-afm-box-dark-gray[0.7]"
            secondBtnTwTextColor="text-white"
            secondBtnAction={openModal()}
        >
            <LayoutDetailContainer>
                <div className="block">
                    <h2 className="text-xl text-afm-box-dark-gray font-medium mb-8">Scadenze</h2>
                </div>

                {/* Skeleton. */}
                {isFetching && <ScadenzeSkeleton />}

                {!isFetching && !isError && isSuccess && <DeadLine deadLines={deadlinesItems} />}

                {/* If Modal window is open, then render. */}
                {modalIsOpen && <ScadenzeModal />}

                {isError && !isSuccess && (
                    <div className="flex items-center justify-center my-14">
                        <p className="text-xl">...Recupero dei dati fallito</p>
                    </div>
                )}
            </LayoutDetailContainer>
        </LayoutDetail>
    )
}

/**
 *
 * @function ScadenzeSkeleton
 *
 * @dev Scadenze skeleton Component.
 *
 * @returns JSX.Element
 *
 */
function ScadenzeSkeleton() {
    return (
        <div className="animate-pulse">
            <div className="flex space-x-8 p-2 pr-10">
                <span className="h-2 w-2 rounded-full bg-neutral-300 mt-1"></span>
                <div className="flex space-x-32 w-full">
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="h-10 w-40 bg-neutral-300 rounded-full"></div>
                    <div className="h-10 w-10 bg-neutral-300 rounded-full"></div>
                </div>
            </div>

            <div className="flex space-x-8 p-2 pr-10">
                <span className="h-2 w-2 rounded-full bg-neutral-300 mt-1"></span>
                <div className="flex space-x-32 w-full">
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="h-10 w-40 bg-neutral-300 rounded-full"></div>
                    <div className="h-10 w-10 bg-neutral-300 rounded-full"></div>
                </div>
            </div>

            <div className="flex space-x-8 p-2 pr-10">
                <span className="h-2 w-2 rounded-full bg-neutral-300 mt-1"></span>
                <div className="flex space-x-32 w-full">
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="h-10 w-40 bg-neutral-300 rounded-full"></div>
                    <div className="h-10 w-10 bg-neutral-300 rounded-full"></div>
                </div>
            </div>

            <div className="flex space-x-8 p-2 pr-10">
                <span className="h-2 w-2 rounded-full bg-neutral-300 mt-1"></span>
                <div className="flex space-x-32 w-full">
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="h-10 w-40 bg-neutral-300 rounded-full"></div>
                    <div className="h-10 w-10 bg-neutral-300 rounded-full"></div>
                </div>
            </div>

            <div className="flex space-x-8 p-2 pr-10">
                <span className="h-2 w-2 rounded-full bg-neutral-300 mt-1"></span>
                <div className="flex space-x-32 w-full">
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="h-10 w-40 bg-neutral-300 rounded-full"></div>
                    <div className="h-10 w-10 bg-neutral-300 rounded-full"></div>
                </div>
            </div>
        </div>
    )
}

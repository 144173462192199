// Router.
import { Outlet } from "react-router-dom"
// UI.
import LayoutDetail from "../LayoutDetail"

/**
 * 
 * @dev Privacy UI Component.
 * @dev Visualize the first component available under Privacy route.
 * 
 * @function Privacy
 * @returns JSX.Element
 * 
 */
export default function Privacy() {
    return (
        <LayoutDetail title={"Privacy"}>
            <Outlet />
        </LayoutDetail>
    )
}

// Redux.
import { useSelector } from "react-redux"
// Router.
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { checkCookie, tkbox } from "../common/cookie"
// UserSlice.
import { userSelector } from "./features/user/UserSlice"

/**
 * 
 * @dev Handle the Private routing.
 * @dev Get access to other routes only if the user is authenticated.
 *
 * @function ProtectedRoutes
 *
 * @returns JSX.Element
 *
 */
export default function ProtectedRoutes() {
    // Access redux State using hooks, to know if login succesfully succeed.
    const { isSuccess, userManutenzione } = useSelector(userSelector)

    const isLoggedIn = checkCookie(tkbox);

    const location = useLocation();

    const manutenzioneAllowedLocations = [
        '/login',
        '/dashboard/generale/azienda',
        '/dashboard/panoramica',
        '/dashboard/manutenzioni',
        '/dashboard/user-settings'
    ];

    /**
     *
     * Component render.
     *
     */
    // return isSuccess || isLoggedIn ? <Outlet /> : <Navigate to="/login" replace={true} />
    if (!isSuccess && !isLoggedIn) {
        return <Navigate to="/login" replace={true} />
    };
    if (!userManutenzione) {
        return <Outlet />
    };
    const matchSomeLocation = manutenzioneAllowedLocations.reduce((valid, allowedBaseLocation) => {
        if (location.pathname.startsWith(allowedBaseLocation)) {
            valid = true;
        }
        return valid;
    }, false);
    if (!matchSomeLocation) {
        return <Navigate to="/login" replace={true} />
    };
    return <Outlet />
}

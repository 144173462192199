// Redux.
import { useSelector } from "react-redux"
// Router.
import { Navigate, Outlet } from "react-router-dom"
import { checkCookie, tkbox } from "../common/cookie"
// UserSlice.
import { userSelector } from "./features/user/UserSlice"

/**
 * 
 * @dev Handle the Admin routing.
 * @dev Get access to other routes only if the user is authenticated.
 *
 * @function AdminRoutes
 *
 * @returns JSX.Element
 *
 */
export default function AdminRoutes() {
    // Access redux State using hooks, to know if login succesfully succeed.
    const { isSuccess } = useSelector(userSelector)

    const isLoggedIn = checkCookie(tkbox)

    /**
     *
     * Component render.
     *
     */
    return <Outlet /> 
    // return isSuccess || isLoggedIn ? <Outlet /> : <Navigate to="/login" replace={true} />
}

// React.
import { useState, useEffect, Fragment } from "react"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// PrivacySlice.
import {
    clearPrivacyDocSenderId,
    downloadPrivacyDocById,
    getPrivacyDocs,
    privacySelector,
    showPrivacyDocById,
} from "../../features/privacy/PrivacySlice"
// UI.
import SubHeader from "../../base/SubHeader"
import LayoutDetailContainer from "../LayoutDetailContainer"
import Tabs from "../../base/Tabs"
import Grid from "../../base/Grid"
// import SearchBox from "../../base/SearchBox"
import Button from "../../base/Button"
import PdfViewer from "../../base/PdfViewer"
// Icons.
import { LockClosed, OvalLoader, PluSm } from "../../base/Icons"
import { filePrivacyModalSelector, openModal as openFilePrivacyModal } from "../../features/privacy/PrivacyModalSlice"
import FileManutenzioniModal from "../manutenzioni/FileManutenzioniModal"
import FilePrivacyModal from "./FilePrivacyModal"
import { fileManutenzioniModalSelector } from "../../features/manutenzioni/FileManutenzioniModalSlice"
import { getFilesById } from "../../features/manutenzioni/ManutenzioniSlice"
// SearchSlice.
import { searchSelector, setSearchList, reset } from "../../features/search/SearchSlice"
import SearchBox from "../../base/SearchBox"
import {DataScadenzaSort} from "../../../common/util"

/**
 *
 * @dev PrivacyDoc UI Component.
 * @dev Privacy subsection
 * 
 * @function PrivacyDoc
 * @returns JSX.Element
 *
 */
export default function PrivacyDoc() {
    const dispatch = useDispatch()

    // Tab categories.
    const [categories, setCategories] = useState([])

    // Tab `Documenti Obbligatori` grid items.
    const [requiredDocsGridItems, setRequiredDocsGridItems] = useState([])
    // Tab `Documenti Obbligatori` grid header items.
    const [requiredDocsGridHeaderTitleItems, setRequiredDocsGridHeaderTitleItems] = useState([])

    // Tab `Altri Documenti` grid items.
    const [otherDocsGridItems, setOtherDocsGridItems] = useState([])
    // Tab `Altri Documenti` grid header items.
    const [otherDocsGridHeaderTitleItems, setOtherDocsGridHeaderTitleItems] = useState([])

    // Tab `Nomina addetti interni Trattamento Dati` grid items.
    const [appointInternalStaffDocsGridItems, setAppointInternalStaffDocsGridItems] = useState([])
    // Tab `Nomina addetti interni Trattamento Dati` grid header items.
    const [appointInternalStaffDocsGridHeaderTitleItems, setAppointInternalStaffDocsGridHeaderTitleItems] = useState([])

    // Tab `Responsabili esterni Trattamento Dati` grid items.
    const [externalManagersDocsGridItems, setExternalManagersDocsGridItems] = useState([])
    // Tab `Responsabili esterni Trattamento Dati` grid header items.
    const [externalManagersDocsGridHeaderTitleItems, setExternalManagersDocsGridHeaderTitleItems] = useState([]);

    const [currentCategory, setCurrentCategory] = useState('documenti obbligatori');
    // searchSlice State.
    const { searchResults, searchList, searchQuery } = useSelector(searchSelector);

    //
    // PrivacySlice State.
    //
    const {
        privacyDocs,
        requiredDocs,
        otherDocs,
        appointInternalStaffDocs,
        externalManagersDocs,
        isFetching,
        isSuccess,
        isError,
        //errorMsg,
        privacyDocSenderId,
        isFetchingDoc,
    } = useSelector(privacySelector)

    const filePrivacyModalStatus = useSelector(filePrivacyModalSelector)
    const [fileType, setFileType] = useState('');

    /**
     *
     * Component update effects.
     *
     */
    useEffect(() => {
        dispatch(getPrivacyDocs())
    }, [dispatch])

    useEffect(() => {
        //
        // Build Grid items for "Documenti Obbligatori" Category.
        //
        const requiredDocsItems = [];
        const emptySearch = (searchQuery || '').trim() === "";
        const results = emptySearch ? searchList : searchResults;
        const items = currentCategory === 'documenti obbligatori' ? results : requiredDocs;
        if (items.length === 0) {
            setRequiredDocsGridItems(items);
        }
        items
            .map(item => item)
            .sort(DataScadenzaSort)
            .map((doc) => {
                const requiredDocsGridItem = [
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "Documento",
                        additionalText: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Caricamento",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Scadenza",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass:
                            "inline-flex gap-2 justify-self-end items-center justify-items-center",
                        component: {},
                    },
                ]
                //
                // Item 0.
                requiredDocsGridItem[0].component = (
                    <div
                        className="
                        flex 
                        space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                        justify-self-start 
                        text-afm-box-light-gray-text"
                    >
                        <LockClosed />
                        <span className="border-l border-afm-box-light-gray-text"></span>
                    </div>
                )
                // Item 1.
                requiredDocsGridItem[1].text = doc.nome
                // Item 2.
                requiredDocsGridItem[2].text = doc.data_ora_inserimento
                // Item 3.
                requiredDocsGridItem[3].text = doc.data_scadenza
                // Item 4.
                requiredDocsGridItem[4].component = <PdfViewer action={showPrivacyDocById(doc.id_box_privacy)} />
                // Item 5.
                requiredDocsGridItem[5].component = (
                    <div className="flex items-center space-x-2">
                        {/* If isFetchingDoc and privacyDocSenderId State correspond to Button id pressed, than show the OvalLoader. */}
                        {isFetchingDoc && privacyDocSenderId === doc.id_box_privacy && (
                            <OvalLoader strokeColor="#aaa" />
                        )}

                        <Button
                            text="DOWNLOAD"
                            id={doc.id_box_privacy}
                            twTextColor="text-white"
                            twBackground="bg-afm-box-blue"
                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                            actions={[
                                clearPrivacyDocSenderId(),
                                downloadPrivacyDocById(doc.id_box_privacy),
                            ]}
                        />
                    </div>
                )

                requiredDocsItems.push(requiredDocsGridItem)

                return setRequiredDocsGridItems(requiredDocsItems)
            })

        setRequiredDocsGridHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Documento",
                twColSpan: "col-span-3",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data Caricamento",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data scadenza",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Allegato",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "",
                twColSpan: "col-span-3",
                twAdditionalClass: "justify-self-end",
                component: <SearchBox />,
            },
        ])
    }, [searchList,
        searchQuery,
        searchResults, isFetchingDoc, privacyDocSenderId])

    useEffect(() => {
        //
        // Build Grid items for "Altri Documenti" Category.
        //
        const otherDocsItems = [];
        const emptySearch = (searchQuery || '').trim() === "";
        const results = emptySearch ? searchList : searchResults;
        const items = currentCategory === 'altri documenti' ? results : otherDocs;
        if (items.length === 0) {
            setOtherDocsGridItems(items);
        }

        items
            .map(item => item)
            .sort((a, b) => {
                const scadenzaAParts = a.data_scadenza.split("/");
                if (scadenzaAParts.length < 3) {
                    return -1
                }
                const scadenzaAObj = new Date(parseInt(scadenzaAParts[2]), parseInt(scadenzaAParts[1]) - 1, parseInt(scadenzaAParts[0]));
                const scadenzaBParts = b.data_scadenza.split("/");
                if (scadenzaBParts.length < 3) {
                    return 1
                }
                const scadenzaBObj = new Date(parseInt(scadenzaBParts[2]), parseInt(scadenzaBParts[1]) - 1, parseInt(scadenzaBParts[0]));
                return scadenzaAObj > scadenzaBObj ? -1 : 1
            }).map((doc) => {
                const otherDocsGridItem = [
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "Documento",
                        additionalText: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Caricamento",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Scadenza",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass:
                            "inline-flex gap-2 justify-self-end items-center justify-items-center",
                        component: <SearchBox />,
                    },
                ]
                //
                // Item 0.
                otherDocsGridItem[0].component = (
                    <div
                        className="
                        flex 
                        space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                        justify-self-start 
                        text-afm-box-light-gray-text"
                    >
                        <LockClosed />
                        <span className="border-l border-afm-box-light-gray-text"></span>
                    </div>
                )
                // Item 1.
                otherDocsGridItem[1].text = doc.nome
                // Item 2.
                otherDocsGridItem[2].text = doc.data_ora_inserimento
                // Item 3.
                otherDocsGridItem[3].text = doc.data_scadenza
                // Item 4.
                otherDocsGridItem[4].component = <PdfViewer action={showPrivacyDocById(doc.id_box_privacy)} />
                // Item 5.
                otherDocsGridItem[5].component = (
                    <div className="flex items-center space-x-2">
                        {/* If isFetchingDoc and privacyDocSenderId State correspond to Button id pressed, than show the OvalLoader. */}
                        {isFetchingDoc && privacyDocSenderId === doc.id_box_privacy && (
                            <OvalLoader strokeColor="#aaa" />
                        )}

                        <Button
                            text="DOWNLOAD"
                            id={doc.id_box_privacy}
                            twTextColor="text-white"
                            twBackground="bg-afm-box-blue"
                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                            actions={[
                                clearPrivacyDocSenderId(),
                                downloadPrivacyDocById(doc.id_box_privacy),
                            ]}
                        />
                    </div>
                )

                otherDocsItems.push(otherDocsGridItem)

                return setOtherDocsGridItems(otherDocsItems)
            })

        setOtherDocsGridHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Documento",
                twColSpan: "col-span-3",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data Caricamento",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data scadenza",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Allegato",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "",
                twColSpan: "col-span-3",
                twAdditionalClass: "justify-self-end",
                component: <SearchBox />,
            },
        ])
    }, [searchList,
        searchQuery,
        searchResults, isFetchingDoc, privacyDocSenderId])

    useEffect(() => {
        //
        // Build Grid items for "Nomina addetti interni Trattamento Dati" Category.
        //
        const appointInternalStaffDocsItems = [];
        const emptySearch = (searchQuery || '').trim() === "";
        const results = emptySearch ? searchList : searchResults;
        const items = currentCategory === "nomina addetti interni trattamento dati" ? results : appointInternalStaffDocs;
        if (items.length === 0) {
            setAppointInternalStaffDocsGridItems(items);
        }

        items
            .map(item => item)
            .sort((a, b) => {
                const scadenzaAParts = a.data_scadenza.split("/");
                if (scadenzaAParts.length < 3) {
                    return -1
                }
                const scadenzaAObj = new Date(parseInt(scadenzaAParts[2]), parseInt(scadenzaAParts[1]) - 1, parseInt(scadenzaAParts[0]));
                const scadenzaBParts = b.data_scadenza.split("/");
                if (scadenzaBParts.length < 3) {
                    return 1
                }
                const scadenzaBObj = new Date(parseInt(scadenzaBParts[2]), parseInt(scadenzaBParts[1]) - 1, parseInt(scadenzaBParts[0]));
                return scadenzaAObj > scadenzaBObj ? -1 : 1
            }).map((doc) => {
                const appointInternalStaffDocsGridItem = [
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "Documento",
                        additionalText: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Caricamento",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Scadenza",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass:
                            "inline-flex gap-2 justify-self-end items-center justify-items-center",
                        component: {},
                    },
                ]
                //
                // Item 0.
                appointInternalStaffDocsGridItem[0].component = (
                    <div
                        className="
                        flex 
                        space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                        justify-self-start 
                        text-afm-box-light-gray-text"
                    >
                        <LockClosed />
                        <span className="border-l border-afm-box-light-gray-text"></span>
                    </div>
                )
                // Item 1.
                appointInternalStaffDocsGridItem[1].text = doc.nome
                // Item 2.
                appointInternalStaffDocsGridItem[2].text = doc.data_ora_inserimento
                // Item 3.
                appointInternalStaffDocsGridItem[3].text = doc.data_scadenza
                // Item 4.
                appointInternalStaffDocsGridItem[4].component = <PdfViewer action={showPrivacyDocById(doc.id_box_privacy)} />
                // Item 5.
                appointInternalStaffDocsGridItem[5].component = (
                    <div className="flex items-center space-x-2">
                        {/* If isFetchingDoc and privacyDocSenderId State correspond to Button id pressed, than show the OvalLoader. */}
                        {isFetchingDoc && privacyDocSenderId === doc.id_box_privacy && (
                            <OvalLoader strokeColor="#aaa" />
                        )}

                        <Button
                            text="DOWNLOAD"
                            id={doc.id_box_privacy}
                            twTextColor="text-white"
                            twBackground="bg-afm-box-blue"
                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                            actions={[
                                clearPrivacyDocSenderId(),
                                downloadPrivacyDocById(doc.id_box_privacy),
                            ]}
                        />
                    </div>
                )

                appointInternalStaffDocsItems.push(appointInternalStaffDocsGridItem)

                return setAppointInternalStaffDocsGridItems(appointInternalStaffDocsItems)
            })

        setAppointInternalStaffDocsGridHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Documento",
                twColSpan: "col-span-3",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data Caricamento",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data scadenza",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Allegato",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "",
                twColSpan: "col-span-3",
                twAdditionalClass: "justify-self-end",
                component: <SearchBox />,
            },
        ])
    }, [searchList,
        searchQuery,
        searchResults, isFetchingDoc, privacyDocSenderId])

    useEffect(() => {
        //
        // Build Grid items for "Responsabili esterni Trattamento Dati" Category.
        //
        const externalManagersDocsItems = [];
        const emptySearch = (searchQuery || '').trim() === "";
        const results = emptySearch ? searchList : searchResults;
        const items = currentCategory === "responsabili esterni trattamento dati" ? results : externalManagersDocs;
        if (items.length === 0) {
            setExternalManagersDocsGridItems(items);
        }

        items
            .map(item => item)
            .sort((a, b) => {
                const scadenzaAParts = a.data_scadenza.split("/");
                if (scadenzaAParts.length < 3) {
                    return -1
                }
                const scadenzaAObj = new Date(parseInt(scadenzaAParts[2]), parseInt(scadenzaAParts[1]) - 1, parseInt(scadenzaAParts[0]));
                const scadenzaBParts = b.data_scadenza.split("/");
                if (scadenzaBParts.length < 3) {
                    return 1
                }
                const scadenzaBObj = new Date(parseInt(scadenzaBParts[2]), parseInt(scadenzaBParts[1]) - 1, parseInt(scadenzaBParts[0]));
                return scadenzaAObj > scadenzaBObj ? -1 : 1
            })
            .map((doc) => {
                const externalManagersDocsGridItem = [
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "Documento",
                        additionalText: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Caricamento",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Scadenza",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass:
                            "inline-flex gap-2 justify-self-end items-center justify-items-center",
                        component: {},
                    },
                ]
                //
                // Item 0.
                externalManagersDocsGridItem[0].component = (
                    <div
                        className="
                        flex 
                        space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                        justify-self-start 
                        text-afm-box-light-gray-text"
                    >
                        <LockClosed />
                        <span className="border-l border-afm-box-light-gray-text"></span>
                    </div>
                )
                // Item 1.
                externalManagersDocsGridItem[1].text = doc.nome
                // Item 2.
                externalManagersDocsGridItem[2].text = doc.data_ora_inserimento
                // Item 3.
                externalManagersDocsGridItem[3].text = doc.data_scadenza
                // Item 4.
                externalManagersDocsGridItem[4].component = <PdfViewer action={showPrivacyDocById(doc.id_box_privacy)} />
                // Item 5.
                externalManagersDocsGridItem[5].component = (
                    <div className="flex items-center space-x-2">
                        {/* If isFetchingDoc and privacyDocSenderId State correspond to Button id pressed, than show the OvalLoader. */}
                        {isFetchingDoc && privacyDocSenderId === doc.id_box_privacy && (
                            <OvalLoader strokeColor="#aaa" />
                        )}

                        <Button
                            text="DOWNLOAD"
                            id={doc.id_box_privacy}
                            twTextColor="text-white"
                            twBackground="bg-afm-box-blue"
                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                            actions={[
                                clearPrivacyDocSenderId(),
                                downloadPrivacyDocById(doc.id_box_privacy),
                            ]}
                        />
                    </div>
                )

                externalManagersDocsItems.push(externalManagersDocsGridItem)

                return setExternalManagersDocsGridItems(externalManagersDocsItems)
            })

        setExternalManagersDocsGridHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Documento",
                twColSpan: "col-span-3",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data Caricamento",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data scadenza",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Allegato",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "",
                twColSpan: "col-span-3",
                twAdditionalClass: "justify-self-end",
                component: <SearchBox />,
            },
        ])
    }, [
        searchList,
        searchQuery,
        searchResults, isFetchingDoc, privacyDocSenderId])

    useEffect(() => {
        if (filePrivacyModalStatus.modalIsOpen === false) {
            dispatch(getPrivacyDocs())
        }
    }, [
        filePrivacyModalStatus.modalIsOpen,
    ])

    useEffect(() => {
        /**
         *
         *  Build Tab categories.
         *
         */
        const categoryItems = {
            "Documenti Obbligatori": [
                {
                    id: 1,
                    component: (
                        <Fragment>
                            <div className="h-16 items-center flex space-x-8 p-3 rounded-sm bg-gray-100">
                                <h1 className="text-left text-lg font-bold">
                                    Documenti Obbligatori
                                </h1>

                            </div>
                            <Grid
                                twGridTemplateColumns="grid-cols-12"
                                headerTitleItems={requiredDocsGridHeaderTitleItems}
                                items={requiredDocsGridItems}
                            />
                        </Fragment>
                    ),
                },
            ],
            // "Altri Documenti": [
            //     {
            //         id: 2,
            //         component: (
            //             <Fragment>
            //                 <div className="h-16 items-center flex space-x-8 p-3 rounded-sm bg-gray-100">
            //                     <h1 className="text-left text-lg font-bold">
            //                         Altri Documenti
            //                     </h1>
            //                     <div className="flex flex-1 justify-end space-x-5">
            //                         <Button
            //                             text="Aggiungi File"
            //                             twTextColor="text-white"
            //                             twBackground="bg-afm-box-dark-gray"
            //                             twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
            //                             onClick={() => {
            //                                 setFileType("Altri Documenti")
            //                                 dispatch(openFilePrivacyModal())
            //                             }}
            //                             icon={<PluSm />}
            //                         />
            //                     </div>
            //                 </div>

            //                 {otherDocsGridItems.length < 1 ? (
            //                     <div className="flex items-center justify-center my-14">
            //                         <p className="text-xl">
            //                             Non ci sono documenti...
            //                         </p>
            //                     </div>
            //                 ) : (
            //                     <Grid
            //                         twGridTemplateColumns="grid-cols-12"
            //                         headerTitleItems={otherDocsGridHeaderTitleItems}
            //                         items={otherDocsGridItems}
            //                     />
            //                 )}
            //             </Fragment>
            //         ),
            //     },
            // ],
            "Nomina addetti interni Trattamento Dati": [
                {
                    id: 3,
                    component: (
                        <Fragment>
                            <div className="h-16 items-center flex space-x-8 p-3 rounded-sm bg-gray-100">
                                <h1 className="text-left text-lg font-bold">
                                    Nomina addetti interni Trattamento Dati
                                </h1>
                                <div className="flex flex-1 justify-end space-x-5">
                                    <Button
                                        text="Aggiungi File"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        onClick={() => {
                                            setFileType("Nomina addetti interni Trattamento Dati")
                                            dispatch(openFilePrivacyModal())
                                        }}
                                        icon={<PluSm />}
                                    />
                                </div>
                            </div>
                            <Grid
                                twGridTemplateColumns="grid-cols-12"
                                headerTitleItems={appointInternalStaffDocsGridHeaderTitleItems}
                                items={appointInternalStaffDocsGridItems}
                            />
                        </Fragment>
                    ),
                },
            ],
            "Responsabili esterni Trattamento Dati": [
                {
                    id: 4,
                    component: (
                        <Fragment>
                            <div className="h-16 items-center flex space-x-8 p-3 rounded-sm bg-gray-100">
                                <h1 className="text-left text-lg font-bold">
                                    Responsabili esterni Trattamento Dati
                                </h1>
                                <div className="flex flex-1 justify-end space-x-5">
                                    <Button
                                        text="Aggiungi File"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        onClick={() => {
                                            setFileType("Responsabili esterni Trattamenti Dati")
                                            dispatch(openFilePrivacyModal())
                                        }}
                                        icon={<PluSm />}
                                    />
                                </div>
                            </div>
                            <Grid
                                twGridTemplateColumns="grid-cols-12"
                                headerTitleItems={externalManagersDocsGridHeaderTitleItems}
                                items={externalManagersDocsGridItems}
                            />
                        </Fragment>
                    ),
                },
            ],
        }

        setCategories(categoryItems)
    }, [
        privacyDocs,
        requiredDocsGridItems,
        requiredDocsGridHeaderTitleItems,
        otherDocsGridItems,
        otherDocsGridHeaderTitleItems,
        appointInternalStaffDocsGridItems,
        appointInternalStaffDocsGridHeaderTitleItems,
        externalManagersDocsGridItems,
        externalManagersDocsGridHeaderTitleItems,
        isFetching,
        isSuccess,
        isError,
    ]);

    useEffect(() => {
        if (isSuccess) {
            // if dataItems are been fetched then call setSearchList giving:
            // list, search key and an additional list of search key
            const searchConfig = {
                dataItems: requiredDocs,
                searchIndex: 'nome',
                additionalIndex: [],
            };
            if (currentCategory === "documenti obbligatori" && requiredDocs.length <= 0) {
                dispatch(reset());
                return
            }
            if (currentCategory === "altri documenti" && otherDocs.length <= 0) {
                dispatch(reset());
                return
            }
            if (currentCategory === "nomina addetti interni trattamento dati" && appointInternalStaffDocs.length <= 0) {
                dispatch(reset());
                return
            }
            if (currentCategory === "responsabili esterni trattamento dati" && externalManagersDocs.length <= 0) {
                dispatch(reset());
                return
            }
            switch (currentCategory) {
                case 'altri documenti':
                    searchConfig.dataItems = otherDocs;
                    break;
                case 'nomina addetti interni trattamento dati':
                    searchConfig.dataItems = appointInternalStaffDocs;
                    break;
                case 'responsabili esterni trattamento dati':
                    searchConfig.dataItems = externalManagersDocs;
                    break;
            };
            dispatch(
                setSearchList(searchConfig)
            );
        }
    }, [
        searchQuery,
        privacyDocs,
        externalManagersDocs,
        appointInternalStaffDocs,
        requiredDocs,
        otherDocs,
        isSuccess,
        currentCategory
    ]);

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        dispatch(reset())
    }, []);

    /**
     *
     *  Component render.
     *
     */
    return (
        <Fragment>
            <SubHeader
                subHeaderTitle="Documenti Privacy"
                firstBtnVisibile={false}
                secondBtnVisibile={false}
            />
            <LayoutDetailContainer>
                {/* Skeleton. */}
                {isFetching && <PrivacyDocSkeleton />}

                {!isFetching && !isError && isSuccess && <Tabs
                    categories={categories}
                    onChange={(index) => {
                        const categoriesNames = Object.keys(categories);
                        const currentCategory = (categoriesNames[index] || '').trim().toLowerCase();
                        dispatch(reset());
                        setCurrentCategory(currentCategory);
                    }}
                />}

                {isError && !isSuccess && (
                    <div className="flex items-center justify-center my-14">
                        <p className="text-xl">...Recupero dei dati fallito</p>
                    </div>
                )}
                {filePrivacyModalStatus.modalIsOpen && <FilePrivacyModal tipo={fileType} />}
            </LayoutDetailContainer>
        </Fragment>
    )
}

/**
 * 
 * @dev PrivacyDocSkeleton skeleton component.
 *
 * @function PrivacyDocSkeleton
 *
 * @returns JSX.Element
 *
 */
function PrivacyDocSkeleton() {
    return (
        <div className="animate-pulse px-10">
            <div className="flex flex-col">
                <header className="h-20 flex space-x-4 items-center justify-items-start font-semibold">
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                </header>
                <span className="flex-grow mb-2 border-t border-gray-400"></span>

                <div
                    className="
                        flex 
                        space-x-4 
                        my-1 
                        mb-4
                        rounded-md items-center justify-center justify-items-start"
                >
                    <div className="h-14 w-full bg-neutral-300 rounded"></div>
                </div>

                <div
                    className="
                        flex 
                        space-x-4 
                        my-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        my-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        my-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        my-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        my-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
            </div>
        </div>
    )
}

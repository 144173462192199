/**
 *
 * @notice InsediamentiSlice to handle state of Insediamenti globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 */

// Redux.
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
// Axios.
import axios from "axios"
// gmapi.
import {
    API_KEY,
    listaInsediamentiAPI,
    verificaDocumentaleInsediamentoAPI,
    dettaglioInsediamentiAPI,
    downloadSettlementDocAPI, updateSettlementAPI
} from "../../../common/gmApi"
// cookie.
import { tkbox, getCookie } from "../../../common/cookie"
// util.
import { downloadDocument, showDocument } from "../../../common/util"

/********************************************
 *                                          *
 * @notice AsyncThunk API(s) Implementation *
 *                                          *
 ********************************************/
/**
 *
 *  @dev Get settlements data.
 *
 */


export const updateInfo = createAsyncThunk(
    // Action route.
    "insediamenti/updateInfo",
    async ({ data, id }, thunkAPI) => {
        try {
            let dataModified = {}
            for(const [key, value] of Object.entries(data)) {
                let modifiedKey = key.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`);
                dataModified[modifiedKey] = value
            }

            const response = await axios.post(
                updateSettlementAPI,
                dataModified,
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_insediamenti: id,
                        data: dataModified
                    },
                }
            )

            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    // Build a custom response withx extradata.
                    return {
                        data: response.data,

                    }
                } else {
                    //console.error('Error "downloadDomaDoc":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "downloadDomaDoc":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "downloadSettlementDoc":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

export const getSettlements = createAsyncThunk(
    // Action route.
    "insediamenti/getSettlements",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                listaInsediamentiAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    //console.error('Error "getSettlements":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "getSettlements":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "getSettlements":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

export const getSettlementInfoById = createAsyncThunk(
    // Action route.
    "insediamenti/getSettlementInfoById",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { string } settlementId
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     * */
    async (settlementId, thunkAPI) => {

        try {
            const response = await axios.get(
                // url.
                dettaglioInsediamentiAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_insediamenti: settlementId,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    //console.error('Error "getSettlementInfoById":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "getSettlementInfoById":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "getSettlementInfoById":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Get settlements docs by id.
 *
 */
export const getSettlementDocsById = createAsyncThunk(
    // Action route.
    "insediamenti/getSettlementDocsById",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { string } settlementId
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (settlementId, thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                verificaDocumentaleInsediamentoAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_insediamenti: settlementId,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    //console.error('Error "getSettlementDocsById":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "getSettlementDocsById":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "getSettlementDocsById":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Download Settlement document.
 *
 */
export const downloadSettlementDoc = createAsyncThunk(
    // Action route.
    "insediamenti/downloadSettlementDoc",
    async ({ docId, settlementId, docName }, thunkAPI) => {
        try {
            const response = await axios.get(
                downloadSettlementDocAPI,
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_verifica_documentale: docId,
                        id_box_insediamenti: settlementId,
                    },
                }
            )

            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    // Build a custom response withx extradata.
                    return {
                        data: response.data,
                        eventSender: docId,
                    }
                } else {
                    //console.error('Error "downloadDomaDoc":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "downloadDomaDoc":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "downloadSettlementDoc":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

//TODO: Implement API calls when enpoints are available.
//getSettlementInfoById(id)
//getSettlementNominationsById(id)
//getSettlementAuthorizationsById(id)
//getSettlementEnviromentsById(id)

/********************************************
 *                                          *
 * @notice createSlice implementation.      *
 *                                          *
 * @dev Create "insediamentiSlice" feature. *
 * @dev UI views state controller.          *
 *                                          *
 ********************************************/
export const insediamentiSlice = createSlice({
    // Name, used in action types.
    name: "insediamenti",
    // The initial state for the reducer.
    initialState: {
        settlementsItems: [],           // All Settlements items.
        settlementInfo: {},             // Settlement info.
        settlementDocs: [],             // Settlement docs.
        //settlementNominations: [],    // Settlement nominations.
        //settlementAuthorizations: [], // Settlement authorizations.
        //settlementEnviroments: [],    // Settlement enviroments.
        isFetching: false,              // AsyncThunk is calling an API.
        isSuccess: false,               // AsyncThunk is success from API call.
        isError: false,                 // AsyncThunk is fail from API call.
        errorMsg: "",                   // Error message container.
        settlementDocSenderId: "",      // Settlement doc sender id.
        settlementDoc: "",              // Settlement doc.
        settlementDocName: "",              // Settlement doc name.
        isFetchingDoc: false, // AsyncThunk is calling an API.
        isSuccessDoc: false,  // AsyncThunk is success from API call.
        isErrorDoc: false,    // AsyncThunk is fail from API call.
        errorDocMsg: "",      // Error message container.
    },
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {
        // Find settlement by id from arguments
        // TODO: FIXME: state.settlementsItems is revoked.
        // need an API to populate settlementInfo during access to the `insediamentiDetail`
        findSettlementById: (state, action) => {
            // Get the settlement id from the action payload.
            const settlementId = action.payload.settlementId
            // Find the object wich corresponding id.
            const settlement = state.settlementsItems.findIndex((obj) => obj.id_box_insediamenti === settlementId)
            // Update the state.
            state.settlementInfo = settlement !== -1 ? settlement : {}

            return state
        },
        cleanUpSettlementInfo: (state) => {
            state.settlementInfo = {};
            return state;
        },
        cleanUpSettlementDocs: (state) => {
            state.settlementDocs = []

            return state
        },
        /**
         * @action clearSettlmentDocSenderId
         */
        clearSettlementDocSenderId: (state) => {
            state.settlementDocSenderId = "";
            state.settlementDoc = "";

            return state
        },
        /*
        cleanUpSettlementNominations: (state) => {
            state.settlementNominations = [];

            return state;
        },
        */
        /*
        cleanUpSettlementAuthorizations: (state) => {
            state.settlementAuthorizations = [];

            return state;
         },
         */
        /*
        cleanUpSettlementEnviroments: (state) => {
            state.settlementEnviroments = [];

            return state;
         },
         */
    },
    // AsyncThunk Reducers.
    // A "builder callback" function used to add more reducers, or
    // an additional object of "case reducers", where the keys should be other
    // action types.
    extraReducers: (builder) => {
        builder
            /*******************************************************************
             *
             * @dev getSettlements.
             *
             */
            .addCase(getSettlements.fulfilled, (state, action) => {
                state.isFetching = false
                state.isSuccess = true

                // Update settlementsItems sort by `data_ora_inserimento` key.
                const settlementsItems = action.payload.gmapi.response.data
                state.settlementsItems = settlementsItems.sort((firstEl, secondEl) => (
                    firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                ))
            })
            .addCase(getSettlements.pending, (state) => {
                state.isFetching = true
            })
            .addCase(getSettlements.rejected, (state, action) => {
                state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
            })
            /*******************************************************************
             *
             * @dev getSettlementInfoById.
             *
             */
            .addCase(getSettlementInfoById.fulfilled, (state, action) => {
                state.isFetching = false
                state.isSuccess = true
                const dataArray = action.payload.gmapi.response.data ? action.payload.gmapi.response.data.length !== undefined : [];
                state.settlementInfo = dataArray ? action.payload.gmapi.response.data[0] : {};
            })
            .addCase(getSettlementInfoById.pending, (state) => {
                state.isFetching = true
            })
            .addCase(getSettlementInfoById.rejected, (state, action) => {
                state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
            })
            /*******************************************************************
             *
             * @dev getSettlementDocsById.
             *
             */
            .addCase(getSettlementDocsById.fulfilled, (state, action) => {
                state.isFetching = false
                state.isSuccess = true
                state.settlementDocs = action.payload.gmapi.response.data
            })
            .addCase(getSettlementDocsById.pending, (state) => {
                state.isFetching = true
            })
            .addCase(getSettlementDocsById.rejected, (state, action) => {
                state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
            })
            /*******************************************************************
             *
             * @dev downloadSettlementDoc.
             *
             */
            .addCase(downloadSettlementDoc.fulfilled, (state, action) => {
                state.isFetchingDoc = false;
                state.isSuccessDoc = true;
                // Update the state with the file name from action payload.
                state.settlementDoc = action.payload.data.gmapi.response.data;
                // Update the state with the file name.
                state.settlementDocName = action.meta.arg.docName;

                // Document download trigger.
                downloadDocument(state.settlementDoc, state.settlementDocName);
            })
            .addCase(downloadSettlementDoc.pending, (state) => {
                state.isFetchingDoc = true;
                // state.settlementDocSenderId = action.meta.arg.idBoxDoma;
            })
            .addCase(downloadSettlementDoc.rejected, (state, action) => {
                state.isFetchingDoc = false;
                state.isErrorDoc = true;
                state.errorDocMsg = action.payload;
            })
            /*******************************************************************
             *
             * @dev updateInfo.
             *
             */
            .addCase(updateInfo.fulfilled, (state, action) => {
                state.isFetching = false;
                state.isSuccess = true;
            })
            .addCase(updateInfo.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(updateInfo.rejected, (state, action) => {
                state.isFetching = false;
                state.isError = true;
                state.errorMsg = action.payload;
            })
    },
})

// Export the reducer.
export const {
    findSettlementById,
    cleanUpSettlementInfo,
    cleanUpSettlementDocs,
    //cleanUpSettlementNominations,
    //cleanUpSettlementAuthorizations,
    //cleanUpSettlementEnviroments
    clearSettlementDocSenderId,
} = insediamentiSlice.actions

// Export the Slice by name.
export const insediamentiSelector = (state) => state.insediamenti

/**
 *
 * @notice Login Component.
 *
 * @dev Component as allow the user to get in the app or not.
 *
 */

// React.
import { Fragment, useEffect, useState } from "react"
// Router.
import {
    //Link,
    useNavigate
} from "react-router-dom"
// Redux.
import { useSelector, useDispatch } from "react-redux"
// Hook Form.
import { useForm } from "react-hook-form"
// Features.
import { loginUser, userSelector, clearState } from "./features/user/UserSlice"
// Icons.
import { OvalLoader } from "./base/Icons"
// logo.
import AfmBoxLogo from "../assets/afmbox_logo.svg"

/**
 *
 * @function Login
*
* @returns JSX.Element
*
*/
export default function Login() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // navigate("/admin/dashboard", { replace: true, state: { userview: userview } }) // Then navigate the the main component.

    // Boolean State to know if the form is fetching data.
    const [formIsFetching, setFormIsFetching] = useState(false)

    // Boolean State to know if the form has Error.
    const [formhasErrors, setFormhasErrors] = useState(false)

    const [userview, setUserview] = useState("");

    // UseForm Hook.
    const { register, handleSubmit, setError, clearErrors, formState, formState: { errors, isValid } } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {
            name: "",
            password: "",
            formErrors: "",
        },
    });

    // We can access redux State using hooks.
    const { isFetching, isSuccess, isError, errorMsg, userName, type, role, adminUser, adminMode } = useSelector(userSelector)

    // Now, when an user submits a login form, we need to dispatch an action by passing required data.
    const onSubmit = (data) => {
        if (data.formErrors.length > 0 && errorMsg) {
            setError("formErrors", {
                type: "server side",
                message: errorMsg,
            })
        } else {
            setFormhasErrors(false)
            clearErrors("formErrors")
        }

        if (data.name.length <= 0) {
            setError("name", {
                type: "name",
                message: "Inserisci username valido.",
            })
        }

        if (data.password.length <= 0) {
            setError("password", {
                type: "password",
                message: "Inserisci una password valida.",
            })
        }
        if (userview) {
            data.userview = userview
        }

        if (isValid) {
            dispatch(loginUser(data)) // Pass username and passowrd to the loginUser of UserSlice.}
        }
    }

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        // get current URL query parameter userview
        const userview = new URLSearchParams(window.location.search).get('userview');
        if (userview) {
            setUserview(userview)
        }
        return () => {
            dispatch(clearState())
        }
    })

    useEffect(() => {
        if (isError) {
            setFormIsFetching(false) // Stop showing Oval indicator.
            setFormhasErrors(true) // Set form errors.
            dispatch(clearState()) // Clean up the UserSlice State.
            return
        }
        if (isFetching) {
            setFormIsFetching(true) // Showing Oval indicator.
            return
        }
        if (!isSuccess) { return }
        setFormIsFetching(false) // Stop showing Oval indicator.
        if (adminMode === true && role === 'Amministratore') {
            navigate("/admin/dashboard", { replace: true }) // Then navigate the the main component.
        } else {
            navigate("/dashboard/panoramica", { replace: true }) // Then navigate the the main component.
        }
    }, [dispatch, formState, navigate, isSuccess, isError, isFetching, errorMsg, errors]);

    /**
     *
     * Component render.
     *
     */
    return (
        <Fragment>
            <div
                className="
                    min-h-screen 
                    flex flex-col 
                    justify-center 
                    py-12 
                    px-6 sm:px-6 lg:px-8
                    bg-gray-50"
            >
                <div
                    className="
                        mx-auto sm:mx-auto 
                        sm:w-full sm:max-w-md 
                        text-center"
                >
                    {/* Static logo */}
                    {/*
                    <h2
                        className="
                            mt-6 
                            text-center text-3xl font-bold text-gray-900"
                    >
                        AFM BOX
                    </h2>
                    */}
                    <img src={AfmBoxLogo} alt="AFMBOX logo" className="h-24 w-full p-1" />

                    <p className="text-afm-box-light-gray-text mt-6">
                        Benvenuto - accedi
                    </p>
                </div>

                <div
                    className="
                        flex 
                        justify-center items-center 
                        mt-8 sm:mx-auto 
                        w-auto sm:w-auto"
                >
                    <div className="bg-inherit py-8 px-4 sm:px-10">
                        <form
                            className="space-y-10"
                            onSubmit={handleSubmit(onSubmit)}
                            method="POST"
                        >
                            {/* Form Comes Here  */}
                            <div className="mb-10 group">
                                <div
                                    className={`
                                        flex 
                                        items-center 
                                        border-b
                                        ${errors.name
                                            ? `border-afm-box-error-red 
                                             group-active:border-afm-box-error-red
                                             group-target:border-afm-box-error-red
                                             group-focus-within:border-afm-box-error-red`
                                            : `border-gray-400 
                                             group-active:border-afm-box-blue 
                                             group-target:border-afm-box-blue 
                                             group-focus-within:border-afm-box-blue`
                                        } 
                                        py-2`}
                                >
                                    <input
                                        className="
                                            appearance-none 
                                            bg-transparent 
                                            border-none w-80 
                                            text-gray-700 
                                            mr-3 
                                            py-1 
                                            px-2 
                                            leading-tight 
                                            focus:outline-none"
                                        id="name"
                                        name="name"
                                        type="text"
                                        placeholder="Username"
                                        aria-label="Full name"
                                        //required
                                        {...register("name")}
                                    />
                                </div>
                                {errors.name && (
                                    <span className="text-sm text-afm-box-error-red">
                                        {errors.name?.message}
                                    </span>
                                )}
                            </div>

                            <div className="mb-10 group">
                                <div
                                    className={`
                                        flex 
                                        items-center 
                                        border-b 
                                        ${errors.password
                                            ? `border-afm-box-error-red 
                                             group-active:border-afm-box-error-red 
                                             group-target:border-afm-box-error-red
                                             group-focus-within:border-afm-box-error-red`
                                            : `border-gray-400 
                                             group-active:border-afm-box-blue
                                             group-target:border-afm-box-blue
                                             group-focus-within:border-afm-box-blue`
                                        } 
                                        py-2`}
                                >
                                    <input
                                        className="
                                            appearance-none 
                                            bg-transparent 
                                            border-none 
                                            w-80 
                                            mr-3
                                            py-1 
                                            px-2 
                                            text-gray-700 
                                            leading-tight 
                                            focus:outline-none"
                                        id="password"
                                        name="password"
                                        type="password"
                                        placeholder="Password"
                                        aria-label="password"
                                        autoComplete="current-password"
                                        //required
                                        {...register("password")}
                                    />
                                </div>
                                {errors.password && (
                                    <span className="text-sm text-afm-box-error-red">
                                        {errors.password?.message}
                                    </span>
                                )}
                            </div>

                            {/*
                            <div className="flex mb-10 p-px">
                                <div className="flex-1">
                                    <input
                                        type="checkbox"
                                        id="remember-me"
                                        name="remember-me"
                                        value={false}
                                        defaultChecked={true}
                                    />
                                    <label
                                        htmlFor="remember-me"
                                        className="pl-3 font-medium text-gray-600"
                                    >
                                        Ricordami
                                    </label>
                                </div>
                                <Link to="#" className="underline text-gray-600 hover:opacity-80">
                                    Password dimenticata?
                                </Link>
                            </div>
                           */}

                            <div className="mb-10 mx-auto">
                                <button
                                    className="
                                        w-64 
                                        h-10 
                                        px-7 
                                        flex 
                                        mx-auto 
                                        gap-1 
                                        items-center justify-center 
                                        uppercase whitespace-nowrap font-semibold 
                                        text-white text-lg
                                        bg-afm-box-dark-gray 
                                        hover:opacity-80 
                                        focus:outline-none"
                                    type="submit"
                                    value="Submit"
                                >
                                    {formIsFetching && <OvalLoader />} Accedi
                                </button>
                            </div>

                            <div className="mb-10 mx-auto">
                                {formhasErrors && (
                                    <div
                                        className="
                                            h-8 
                                            text-center 
                                            bg-afm-box-error-red/10 
                                            text-afm-box-error-red 
                                            border border-afm-box-error-red rounded-md"
                                    >
                                        <p>{errorMsg}</p>
                                    </div>
                                )}
                            </div>
                        </form>

                        {/*
                        <div className="mt-6">
                            <div className="relative">
                                <div className="relative flex justify-center text-sm">
                                                                        <span className="px-2 bg-white text-gray-500">
                                        Or
                                        <button onClick={() => { navigate('./register',{ replace: true }); }}>
                                            Register
                                        </button>
                                    </span>

                                </div>

                            </div>
                        </div>
                        */}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

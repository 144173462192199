/**
 *
 * @dev gmApi.
 *
 * @dev Contains "gmapi" API utilities.
 *
 */

// API KEY - DEV MODE.
export const API_KEY = process.env.REACT_APP_API_KEY;
// afmbox base api path.
const afmboxApiPath = process.env.REACT_APP_BASE_API;

/*** User *********************************************************************/
export const loginAfmboxAPI = `${afmboxApiPath}/login_afmbox?`;
export const cambiaPasswordAPI = `${afmboxApiPath}/cambia_password?`;
export const datiUtenteAPI = `${afmboxApiPath}/dati_utente?`;

/*** Azienda ******************************************************************/
export const datiAziendaAPI = `${afmboxApiPath}/dati_azienda?`;
export const downloadAziendaLogoAPI = `${afmboxApiPath}/download_logo?`;
export const caricaAziendaLogoAPI = `${afmboxApiPath}/cambia_logo?`;
export const downloadDatiAziendaAPI = `${afmboxApiPath}/dati_azienda_pdf?`;

/*** Visure *******************************************************************/
export const listaVisureAPI = `${afmboxApiPath}/lista_visure?`;
export const caircaVisuraAPI = `${afmboxApiPath}/carica_visura?`;
export const downloadVisuraAPI = `${afmboxApiPath}/download_visura?`;
export const eliminaVisuraAPI = `${afmboxApiPath}/elimina_visura?`;


/*** Doma *********************************************************************/
export const listaDomaAPI = `${afmboxApiPath}/lista_doma?`;
export const caricaDomaAPI = `${afmboxApiPath}/carica_doma?`;
export const downloadDomaAPI = `${afmboxApiPath}/download_doma?`;
export const eliminaDomaAPI = `${afmboxApiPath}/elimina_doma?`;

/*** DURC *******************************************************************/
export const listaDurcAPI = `${afmboxApiPath}/lista_durc?`;
export const caircaDurcAPI = `${afmboxApiPath}/carica_durc?`;
export const downloadDurcAPI = `${afmboxApiPath}/download_durc?`;
export const eliminaDurcAPI = `${afmboxApiPath}/elimina_durc?`;

/*** DURF *******************************************************************/
export const listaDurfAPI = `${afmboxApiPath}/lista_durf?`;
export const caircaDurfAPI = `${afmboxApiPath}/carica_durf?`;
export const downloadDurfAPI = `${afmboxApiPath}/download_durf?`;
export const eliminaDurfAPI = `${afmboxApiPath}/elimina_durf?`;

/*** Lavoratori ***************************************************************/
export const listaLavoratoriAPI = `${afmboxApiPath}/lista_lavoratori?`;
export const ruoliLavoratoriAPI = `${afmboxApiPath}/ruoli_lavoratori?`;

//TODO: New employee API.
export const nuovoLavoratoreAPI = `${afmboxApiPath}/nuovo_lavoratore?`;
export const updateLavoratoreAPI = `${afmboxApiPath}/update_lavoratore?`;
export const informazioniLavoratoreAPI = `${afmboxApiPath}/informazioni_lavoratore?`;
export const caricaFotoLavoratoreAPI = `${afmboxApiPath}/carica_foto_lavoratore?`;
export const downloadFotoLavoratoreAPI = `${afmboxApiPath}/download_foto_lavoratore?`;
export const corsiLavoratoreAPI = `${afmboxApiPath}/corsi_lavoratore?`;
export const caricaCorsoAPI = `${afmboxApiPath}/carica_corso?`;
export const caricaDocumentoAPI = `${afmboxApiPath}/carica_documento?`;
export const caricaVisitaAPI = `${afmboxApiPath}/aggiungi_visita_lavoratore?`;
export const downloadCorsoLavoratoreAPI = `${afmboxApiPath}/scarica_attestato_corso_lavoratore?`;
export const eliminaCorsoLavoratoreAPI = `${afmboxApiPath}/elimina_corso_lavoratore?`;
//export const downloadAllCorsiLavoratoreAPI = `${afmboxApiPath}/scarica_tutti_attestati_lavoratore?`;
export const visiteLavoratoreAPI = `${afmboxApiPath}/visite_lavoratore?`;
export const downloadVisitaLavoratoreAPI = `${afmboxApiPath}/scarica_visita_lavoratore?`;
export const caircaVisitaAPI = `${afmboxApiPath}/carica_visita?`;
export const eliminaVisiteLavoratoreAPI = `${afmboxApiPath}/elimina_visita_lavoratore`;
export const documentiLavoratoreAPI = `${afmboxApiPath}/documenti_lavoratore?`;
export const eliminaDocumentoLavoratoreAPI = `${afmboxApiPath}/elimina_documento_lavoratore?`;
export const scaricaDocumentoLavoratoreAPI = `${afmboxApiPath}/scarica_documento_lavoratore?`;

/*** Privacy ******************************************************************/
export const listaPrivacyAPI = `${afmboxApiPath}/lista_privacy?`;
export const downloadPrivacyDocAPI = `${afmboxApiPath}/download_privacy?`;
export const caricaPrivacyDocAPI = `${afmboxApiPath}/carica_privacy?`;

/*** Cantiere *****************************************************************/
export const listaCantieriAPI = `${afmboxApiPath}/lista_cantieri?`;

/*** Insediamenti *************************************************************/
export const listaInsediamentiAPI = `${afmboxApiPath}/lista_insediamenti?`;
export const dettaglioInsediamentiAPI = `${afmboxApiPath}/dettaglio_insediamenti?`;
export const verificaDocumentaleInsediamentoAPI = `${afmboxApiPath}/verifica_documentale_insediamento?`;
export const downloadSettlementDocAPI = `${afmboxApiPath}/scarica_documento_verifica_documentale?`;
export const updateSettlementAPI = `${afmboxApiPath}/modifica_insediamenti?`;

//TODO: insediamenti detail API's when available.

/*** Macchinari ***************************************************************/
export const listaMacchinariAPI = `${afmboxApiPath}/lista_macchinari?`;
export const dettaglioMacchinariAPI = `${afmboxApiPath}/dettaglio_macchinari?`;
export const aggiungiMacchinarioAPI = `${afmboxApiPath}/aggiungi_macchinari?`;
export const eliminaMacchinarioAPI = `${afmboxApiPath}/elimina_macchinari?`;
export const caricaFotoMacchinarioAPI = `${afmboxApiPath}/carica_foto_macchinario?`;
export const downloadFotoMacchinarioAPI = `${afmboxApiPath}/download_foto_macchinario?`;
export const filesMacchinariAPI = `${afmboxApiPath}/file_macchinari?`;
export const caricaFileMacchinariAPI = `${afmboxApiPath}/carica_file_macchinari?`;
export const downloadFileMacchinariAPI = `${afmboxApiPath}/download_file_macchinari?`;

/*** Documenti Master *********************************************************/
export const listaDocumentiMasterAPI = `${afmboxApiPath}/lista_documenti_master?`;
export const downloadDocumentiMasterAPI = `${afmboxApiPath}/download_documenti_master?`;

/*** Duvri ********************************************************************/
export const listaDuvriAPI = `${afmboxApiPath}/lista_aziende_duvri?`;
export const aggiungiDuvriAPI = `${afmboxApiPath}/aggiungi_aziende_duvri?`;
export const eliminaAziendeDuvriAPI = `${afmboxApiPath}/elimina_aziende_duvri?`;

/*** Comunicazioni ************************************************************/
export const listaAvvisiAPI = `${afmboxApiPath}/lista_avvisi?`;
export const avvisoLettoAPI = `${afmboxApiPath}/avviso_letto?`;
export const downloadAvvisiAPI = `${afmboxApiPath}/download_avvisi?`;

/*** Scadenze *****************************************************************/
export const listaScadenzeAPI = `${afmboxApiPath}/lista_scadenza?`;
export const aggiungiScadenzaAPI = `${afmboxApiPath}/aggiungi_scadenza?`;
export const downloadOrganicoAPI = `${afmboxApiPath}/organico_aziendale?`;
export const downloadOrganigrammaAPI = `${afmboxApiPath}/organigramma_pdf?`;
export const listaOrganigrammaAPI = `${afmboxApiPath}/lista_organigramma?`;
export const salvaOrganigrammaAPI = `${afmboxApiPath}/salva_organigramma?`;
export const downloadLavoratoreAPI = `${afmboxApiPath}/lavoratore_pdf?`;
export const downloadCorsiLavoratoreAPI = `${afmboxApiPath}/corsi_lavoratore_pdf?`;
export const downloadVisiteLavoratoreAPI = `${afmboxApiPath}/visite_lavoratore_pdf?`;
export const downloadDocumentiLavoratoreAPI = `${afmboxApiPath}/documenti_lavoratore_pdf?`;

/*** Admin ******************************************************************/
export const listaAziendeAPI = `${afmboxApiPath}/lista_aziende?`;
export const switchUserViewAPI = `${afmboxApiPath}/switch_user_view?`;

// React.
import { createContext, useEffect, useState } from "react"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// ScadenzeSlice.
import { getScadenze, scadenzeSelector } from "../features/scadenze/ScadenzeSlice"
import {
    comunicazioniSelector,
    getComunicazioni,
} from "../features/comunicazioni/ComunicazioniSlice"
// UI.
import Navigator from "./Navigator"
import UserBtn from "./UserBtn"
// Icons.
import { Menu } from "../base/Icons"
// logo.
import AfmBoxLogo from "../../assets/afmbox_logo_neg.svg"
import { useNavigate } from "react-router-dom"

//
// Create a context for SideBarMenu Component.
//
export const SideBarMenuContext = createContext(null)

/**
 *
 * @dev Sidebar menu navigator.
 * 
 * @function SideBarMenu
 *
 * @returns JSX.Element
 *
 */
export default function SideBarMenu() {
    //
    // SideBar menu toggle collapse state.
    //
    /**
     * 
     * @dev Forcing opened. 
     * 
    */
   const [
       sideBarIsOpen, 
       //setSideBarIsOpen
    ] = useState(true)
    //
    // ScadenzeSlice State.
    //
    const { deadlinesItems } = useSelector(scadenzeSelector)
    //    
    // ComunicazioniSlice State.
    //
    const { noticesItems } = useSelector(comunicazioniSelector)
    
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // SideBar menu toggle collapse handler.
    /**
     * 
     * @dev Forcing opened. 
     * 
     */
    /*
    function sideBarToggleCollapse() {
        return sideBarIsOpen ? setSideBarIsOpen(false) : setSideBarIsOpen(true)
    }
    */

    /**
     * 
     * @dev Forcing opened. 
     * 
     */
    const toggleSideBar = sideBarIsOpen ? "w-64" : "w-64" // "w-16"

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        if (noticesItems.length < 1) {
            dispatch(getComunicazioni())
        }
        if (deadlinesItems.length < 1) {
            dispatch(getScadenze())
        }
    }, [dispatch, noticesItems.length, deadlinesItems.length])

    /*
     *
     * Component render.
     *
     */
    return (
        <section id="sidebar-menu" className="flex flex-col h-screen">
            <header
                className={`
                        ${toggleSideBar}
                        h-20
                        flex items-center
                        text-lg text-left text-white 
                        bg-afm-box-dark-gray`}
            >
                <button
                    className="flex items-center mx-5 font-bold"
                    //onClick={sideBarToggleCollapse}
                    onClick={() => navigate("panoramica", true)}
                >
                    {sideBarIsOpen ?
                           <img src={AfmBoxLogo} alt="AFMBOX logo" className="h-24 w-auto p-1"/>
                        : 
                            <Menu />
                    }

                </button>
            </header>
            <div
                className={`
                    ${toggleSideBar}
                    float-left
                    scrollbar-thumb-afm-box-dark-gray-opacity-80
                    scrollbar-thin
                    scrollbar-corner-white
                    scrollbar-afm-box-dark-gray-opacity-90
                    bg-zinc-800`}
            >
                {/* 
                    Pass sideBarIsOpen state via context provider to allow child 
                    components whether sideBarMenu is open or not. 
                */}
                <SideBarMenuContext.Provider value={sideBarIsOpen}>
                    <Navigator />
                </SideBarMenuContext.Provider>
            </div>
            <SideBarMenuContext.Provider value={sideBarIsOpen}>
                <UserBtn />
            </SideBarMenuContext.Provider>
        </section>
    )
}

// React.
import { useState, useEffect, Fragment } from "react"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// ManutenzioniSlice.
import {
    deleteMachine,
    getManutenzioni,
    manutenzioniSelector,
} from "../../features/manutenzioni/ManutenzioniSlice"
// ManutenzioniModalSlice.
import {
    manutenzioniModalSelector,
    openModal,
} from "../../features/manutenzioni/ManutenzioniModalSlice"
// UI.
import LayoutDetail from "../LayoutDetail"
import LayoutDetailContainer from "../LayoutDetailContainer"
import Grid from "../../base/Grid"
// import SearchBox from "../../base/SearchBox"
import Button from "../../base/Button"
import DropDown from "../../base/DropDown"
import ManutenzioniModal from "./ManutenzioniModal"
import {cleanUpFiles} from "../../features/manutenzioni/FileManutenzioniModalSlice"
// Icons.
import { 
    DocumentText, 
    PluSm, 
    //Printer 
} from "../../base/Icons"

/**
 * 
 * @dev Manutenzioni UI Component.
 * 
 * @fucntion Manutenzioni
 * 
 * @returns JSX.Element 
 * 
 */
export default function Manutenzioni() {
    const dispatch = useDispatch()

    // gridItems State.
    const [gridItems, setGridItems] = useState([])
    // gridHeaderTitleItems State.
    const [gridHeaderTitleItems, setGridHeaderTitleItems] = useState([])

    // manutenzioniSlice State.
    const {
        machineryItems,
        isFetching,
        isSuccess,
        isError,
        //errorMsg
    } = useSelector(manutenzioniSelector)

    // manutenzioniModalSlice State.
    const { modalIsOpen, modalIsSuccess } = useSelector(manutenzioniModalSelector)

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        dispatch(getManutenzioni())
        dispatch(cleanUpFiles())
    }, [dispatch, modalIsSuccess])

    useEffect(() => {
        const items = []

        if (machineryItems.length < 1) {
            // // TEST.
            // const tmpItems = [
            //     [
            //         {
            //             text: "",
            //             additionalText: "",
            //             twColSpan: "col-span-1",
            //             twAdditionalClass:
            //                 "flex justify-self-end mr-20 text-afm-box-light-gray-text",
            //             component: (
            //                 <Fragment>
            //                     <DocumentText />
            //                     <span className="flex-grow ml-11 border-l border-afm-box-light-gray-text"></span>
            //                 </Fragment>
            //             ),
            //         },
            //         {
            //             text: "Nome insediamento Lorem Ipsum",
            //             additionalText: "",
            //             twColSpan: "col-span-2",
            //             twAdditionalClass: "",
            //             component: "",
            //         },
            //         {
            //             text: "",
            //             additionalText: "",
            //             twColSpan: "col-span-2",
            //             twAdditionalClass: "text-white",
            //             component: <Fragment></Fragment>,
            //         },
            //         {
            //             text: "",
            //             additionalText: "",
            //             twColSpan: "col-span-1",
            //             twAdditionalClass: "inline-flex space-x-2 justify-self-start",
            //             component: (
            //                 <Fragment>
            //                     <Button
            //                         text="dettaglio"
            //                         twTextColor="text-white"
            //                         twBackground="bg-afm-box-blue"
            //                         twFocusBG="focus:bg-afm-box-blue/[0.5]"
            //                         navigateTo="./dettaglio"
            //                     />
            //                     <DropDown/>
            //                 </Fragment>
            //             ),
            //         },
            //     ],
            // ]
            // //return setGridItems(items);
            // return setGridItems(tmpItems)
        } else {
            machineryItems.map((machine) => {
                const gridItem = [
                    {
                        text: "",
                        additionalText: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "Macchinario",
                        additionalText: "",
                        twColSpan: "col-span-6",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "",
                        additionalText: "",
                        twColSpan: "col-span-5",
                        twAdditionalClass: "justify-self-end",
                        component: {},
                    },
                ]

                // Item 0.
                gridItem[0].component = (
                    <div
                        className="
                            flex 
                            space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                            justify-self-start 
                            text-afm-box-light-gray-text"
                    >
                        <DocumentText />
                        <span className="border-l border-afm-box-light-gray-text"></span>
                    </div>
                )
                // Item 1.
                gridItem[1].text = machine.nome
                // Item 2.
                gridItem[2].component = (
                    <div className="flex space-x-2">
                        <Button
                            disabled={false}
                            text="dettaglio"
                            twTextColor="text-white"
                            twBackground="bg-afm-box-blue"
                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                            navigateTo={`./dettaglio-macchina-${machine.id_box_macchinari}`}
                        />
                        <DropDown 
                            buttonItems={[
                                {
                                    type: "delete",
                                    method: deleteMachine(machine.id_box_macchinari)
                                }
                            ]}
                        />
                    </div>
                )

                items.push(gridItem)

                return setGridItems(items)
            })
        }

        setGridHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Macchinario",
                twColSpan: "col-span-6",
                twAdditionalClass: "",
                component: "",
            },
            // {
            //     title: "",
            //     twColSpan: "col-span-5",
            //     twAdditionalClass: "justify-self-end",
            //     component: <SearchBox />,
            // },
        ])
    }, [machineryItems])

    /**
     *
     * Component render.
     *
     */
    return (
        <LayoutDetail
            title="Manutenzioni"
            firstBtnVisibile={false}
            //firstBtnText="Stampa"
            //firstBtnIcon={<Printer />}
            //firstBtnTwBg="bg-white"
            //firstBtnTwTextColor="text-black"
            secondBtnVisibile={true}
            secondBtnText="Aggiungi Macchina"
            secondBtnIcon={<PluSm />}
            secondBtnTwBg="bg-afm-box-dark-gray"
            secondBtnTwFocusBg="bg-afm-box-dark-gray[0.7]"
            secondBtnTwTextColor="text-white"
            secondBtnActions={[openModal()]}
        >
            <LayoutDetailContainer>
                {/* Skeleton. */}
                {isFetching && <ManutenzioniSkeleton />}

                {
                    !isFetching && 
                    !isError && 
                    isSuccess && 
                    <Grid
                        twGridTemplateColumns="grid-cols-12"
                        headerTitleItems={gridHeaderTitleItems}
                        items={gridItems}
                    />
                }

                {isError && !isSuccess && (
                    <div className="flex items-center justify-center my-14">
                        <p className="text-xl">
                            ...Recupero dei dati fallito
                        </p>
                    </div>
                )}

                {/* If Modal window is open, then render. */}
                {modalIsOpen && <ManutenzioniModal />}
            </LayoutDetailContainer>
        </LayoutDetail>
    )
}

/**
 *
 * @dev Manutenzioni skeleton Component.
 * 
 * @function ManutenzioniSkeleton
 *
 * @returns JSX.Element
 *
 */
function ManutenzioniSkeleton() {
    return (
        <div className="animate-pulse">
            <div className="flex flex-col">
                <header
                    className="
                        h-20 
                        grid grid-cols-12
                        gap-10 
                        items-center 
                        justify-items-start 
                        font-semibold"
                >
                    <div className="col-span-1 h-10 w-full bg-inherit rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-inherit rounded"></div>
                    <div className="col-span-5 h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="col-span-5 h-10 w-full bg-neutral-300 rounded"></div>
                </header>
                <span className="flex-grow mb-2 border-t border-gray-400"></span>

                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex 
                        space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
            </div>
        </div>
    )
}

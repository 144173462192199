// React.
import { useState, useEffect, Fragment } from 'react'
// Router.
import { useParams } from "react-router-dom"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// ManutenzioniSlice.
import {
    clearState,
    getMachineDocsById,
    getMachineInfoById,
    manutenzioniSelector,
    uploadManutenzioniImage,
    getManutenzioniImgById,
    getFilesById,
} from "../../features/manutenzioni/ManutenzioniSlice"
// UI.
import SubHeader from "../../base/SubHeader"
import LayoutDetail from "../LayoutDetail"
import LayoutDetailContainer from "../LayoutDetailContainer"
import Tabs from "../../base/Tabs"
import Input from "../../base/Input"
//import Button from "../../base/Button"
import ImgUploader from "../../base/ImgUploader"
import Button from '../../base/Button'
// import SearchBox from '../../base/SearchBox'
import Grid from '../../base/Grid'
// Icons.
import {
    LockClosed,
    PaperClip,
    //Printer, 
    PluSm
} from "../../base/Icons"
import {
    downloadMachineFileById,
    fileManutenzioniModalSelector,
    openModal as openFileManutenzioniModal,
    showMachineFileById
} from "../../features/manutenzioni/FileManutenzioniModalSlice"
import FileManutenzioniModal from "./FileManutenzioniModal"
import PdfViewer from "../../base/PdfViewer"
import {
    clearEmployeeCourseDocSenderId, deleteEmployeeDocById,
    downloadEmployeeDocById,
    showEmployeeDocById
} from "../../features/generale/organico/OrganicoSlice"
// SearchSlice.
import { searchSelector, setSearchList, reset } from "../../features/search/SearchSlice"
import SearchBox from "../../base/SearchBox"
import { DataScadenzaSort } from "../../../common/util"

/**
 *
 * @dev ManutenzioniDetail UI Component.
 * @dev Machine Detail.
 * 
 * @function ManutenzioniDetail
 *
 * @returns JSX.Element
 *
 */
//TODO: implement show document and download document when API are available.
export default function ManutenzioniDetail() {
    const dispatch = useDispatch()

    const [categories, setCategories] = useState([]) // Tab categories.

    // Tab `Contratti Manutenzioni` grid items.
    const [maintenanceContractsDocsGridItems, setMaintenanceContractsDocsGridItems] = useState([])
    // Tab `Contratti Manutenzioni` grid header items.
    const [maintenanceContractsDocsGridHeaderTitleItems, setMaintenanceContractsDocsGridHeaderTitleItems] = useState([])

    // Tab `Scheda di intervernto` grid items.
    const [interventionSheetDocsGridItems, setInterventionSheetDocsGridItems] = useState([])
    // Tab `Scheda di intervernto` grid header items.
    const [interventionSheetDocsHeaderTitleItems, setInterventionSheetDocsHeaderTitleItems] = useState([])

    // Tab `Registro Manutenzioni` grid items.
    const [maintenanceLedgerDocsGridItems, setMaintenanceLedgerDocsGridItems] = useState([])
    // Tab `Registro Manutenzioni` grid header items.
    const [maintenanceLedgerDocsHeaderTitleItems, setMaintenanceLedgerDocsHeaderTitleItems] = useState([])

    // Tab `Libretto Uso e Manutenzioni` grid items.
    const [userBookletAndMaintenanceDocsGridItems, setUserBookletAndMaintenanceDocsGridItems] = useState([])
    // Tab `Libretto Uso e Manutenzioni` grid header items.
    const [userBookletAndMaintenanceDocsHeaderTitleItems, setUserBookletAndMaintenanceDocsHeaderTitleItems] = useState([])

    const [markingCEDocsGridItems, setMarkingCEDocsGridItems] = useState([])
    const [markingCEDocsHeaderTitleItems, setMarkingCEDocsHeaderTitleItems] = useState([]);

    const [currentCategory, setCurrentCategory] = useState('documenti obbligatori');
    // searchSlice State.
    const { searchResults, searchList, searchQuery } = useSelector(searchSelector);

    // manutenzioniSlice.
    const {
        machineImg,
        machineInfo,
        defaultTabIndex,
        isFetching,
        isSuccess,
        isError,
        maintenanceContractsDocs,
        interventionSheetDocs,
        maintenanceLedgerDocs,
        userBookletAndMaintenanceDocs,
        files
    } = useSelector(manutenzioniSelector)

    // Get the url parameter.
    const { id } = useParams()
    const fileManutenzioniModalStatus = useSelector(fileManutenzioniModalSelector)
    const [fileType, setFileType] = useState('');

    // Pdf Component as a link to pdf file.
    const Pdf = (props) => (
        <a
            className="flex underline text-afm-box-dark-gray"
            href={props.pdf}
            target="_blank"
            rel="noopener noreferrer"
        >
            <PaperClip />
            PDF
        </a>
    )

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        dispatch(getMachineInfoById(id))
        dispatch(getMachineDocsById(id))
        dispatch(getManutenzioniImgById(id))
        dispatch(getFilesById(id))
        //
        // Clean up.
        //
        dispatch(clearState())
    }, [
        dispatch,
        id
    ])

    useEffect(() => {
        //
        // Build Grid items for "Contratti Manutenzioni" Category.
        //
        const maintenanceContractsDocsItems = [];
        const docs = files ? (files?.CNTR || []) : [];
        const emptySearch = (searchQuery || '').trim() === "";
        const results = emptySearch ? searchList : searchResults;
        const items = currentCategory === 'contratti manutenzioni' ? results : docs;
        if (items.length === 0) {
            setMaintenanceContractsDocsGridItems(items);
        }

        items
            .map(item => item)
            .sort(DataScadenzaSort)
            .map((doc) => {
                const maintenanceContractsGridItem = [
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "Documento",
                        additionalText: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Caricamento",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Scadenza",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass:
                            "inline-flex gap-2 justify-self-end items-center justify-items-center",
                        component: {},
                    },
                ]
                //
                // Item 0.
                maintenanceContractsGridItem[0].component = (
                    <div
                        className="
                        flex
                        space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                        justify-self-start
                        text-afm-box-light-gray-text"
                    >
                        <LockClosed />
                        <span className="border-l border-afm-box-light-gray-text"></span>
                    </div>
                )
                // Item 1.
                maintenanceContractsGridItem[1].text = doc.descrizione
                // Item 2.
                maintenanceContractsGridItem[2].text = doc.data_caricamento
                // Item 3.
                maintenanceContractsGridItem[3].text = doc.data_scadenza
                /*
                                documentiGridItem[5].component = <PdfViewer action={showEmployeeDocById(doc.id_box_documenti_lavoratore)} />
                                // Item 6.
                                documentiGridItem[6].component = (
                                    <div className="flex space-x-2">
                                        <Button
                                            text="download"
                                            twTextColor="text-white"
                                            twBackground="bg-afm-box-blue"
                                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                            actions={[
                                                clearEmployeeCourseDocSenderId(),
                                                downloadEmployeeDocById(
                                                    {
                                                        idBoxCorsiLavoratore: doc.id_box_corsi_lavoratore,
                                                        docName: doc.allegato
                                                    }
                                                ),
                                            ]}
                                        />
                                        {/!* Only Admin can remove docs. *!/}
                                        {employeeInfo.id_origine === "0" &&
                                            <DropDown
                                                buttonItems={[
                                                    {
                                                        type: "delete",
                                                        method: deleteEmployeeDocById({
                                                            idBoxLavoratori: id,
                                                            idBoxDocumentiLavoratore: doc.id_box_documenti_lavoratore
                                                        })
                                                    }
                                                ]}
                                            />
                                        }
                                    </div>
                                )
                */

                // Item 4.
                maintenanceContractsGridItem[4].component = <PdfViewer action={showMachineFileById({ idBoxDocumentiMacchinari: doc.id_box_documenti_macchinari })} />
                // Item 5.
                maintenanceContractsGridItem[5].component = (
                    <div className="flex items-center space-x-2">
                        <Button
                            text="DOWNLOAD"
                            id={doc.id_box_documenti_macchinari}
                            twTextColor="text-white"
                            twBackground="bg-afm-box-blue"
                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                            actions={[
                                downloadMachineFileById(
                                    {
                                        idBoxDocumentiMacchinari: doc.id_box_documenti_macchinari,
                                        docName: doc.allegato
                                    }
                                ),
                            ]}
                        />
                    </div>
                )

                maintenanceContractsDocsItems.push(maintenanceContractsGridItem)
                return setMaintenanceContractsDocsGridItems(maintenanceContractsDocsItems)
            })


        setMaintenanceContractsDocsGridHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Documento",
                twColSpan: "col-span-3",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data Caricamento",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data scadenza",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Allegato",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "",
                twColSpan: "col-span-3",
                twAdditionalClass: "justify-self-end",
                component: <SearchBox />,
            },
        ])
    }, [
        searchList,
        searchQuery,
        searchResults,
        //isFetchingDoc, 
        //privacyDocSenderId
    ])

    useEffect(() => {
        //
        // Build Grid items for "Scheda di intervento" Category.
        //
        const interventionSheetDocsItems = [];
        const docs = files ? (files?.SCDI || []) : [];
        const emptySearch = (searchQuery || '').trim() === "";
        const results = emptySearch ? searchList : searchResults;
        const items = currentCategory === 'scheda di intervento' ? results : docs;
        if (items.length === 0) {
            setInterventionSheetDocsGridItems(items);
        }

        items
            .map(item => item)
            .sort(DataScadenzaSort)
            .map((doc) => {
                const interventionSheetGridItem = [
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "Documento",
                        additionalText: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Caricamento",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Scadenza",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass:
                            "inline-flex gap-2 justify-self-end items-center justify-items-center",
                        component: {},
                    },
                ]
                //
                // Item 0.
                interventionSheetGridItem[0].component = (
                    <div
                        className="
                        flex
                        space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                        justify-self-start
                        text-afm-box-light-gray-text"
                    >
                        <LockClosed />
                        <span className="border-l border-afm-box-light-gray-text"></span>
                    </div>
                )

                // Item 1.
                interventionSheetGridItem[1].text = doc.descrizione
                // Item 2.
                interventionSheetGridItem[2].text = doc.data_caricamento
                // Item 3.
                interventionSheetGridItem[3].text = doc.data_scadenza
                // Item 4.
                interventionSheetGridItem[4].component = <PdfViewer action={showMachineFileById({ idBoxDocumentiMacchinari: doc.id_box_documenti_macchinari })} />

                // Item 5.
                interventionSheetGridItem[5].component = (
                    <div className="flex items-center space-x-2">
                        <Button
                            text="DOWNLOAD"
                            id={doc.id_box_documenti_macchinari}
                            twTextColor="text-white"
                            twBackground="bg-afm-box-blue"
                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                            actions={[
                                clearEmployeeCourseDocSenderId(),
                                downloadMachineFileById(
                                    {
                                        idBoxDocumentiMacchinari: doc.id_box_documenti_macchinari,
                                        docName: doc.allegato
                                    }
                                ),
                            ]}
                        />
                    </div>
                )

                interventionSheetDocsItems.push(interventionSheetGridItem)

                return setInterventionSheetDocsGridItems(interventionSheetDocsItems)

            });
        setInterventionSheetDocsHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Documento",
                twColSpan: "col-span-3",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data Caricamento",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data scadenza",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Allegato",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "",
                twColSpan: "col-span-3",
                twAdditionalClass: "justify-self-end",
                component: <SearchBox />,
            },
        ])
    }, [
        searchList,
        searchQuery,
        searchResults,
        //isFetchingDoc, 
        //privacyDocSenderId
    ])

    useEffect(() => {
        //
        // Build Grid items for "Registro Manutenzioni" Category.
        //
        const maintenanceLedgerDocsItems = [];
        const docs = files ? (files?.RGMN || []) : [];
        const emptySearch = (searchQuery || '').trim() === "";
        const results = emptySearch ? searchList : searchResults;
        const items = currentCategory === 'registro manutenzioni' ? results : docs;
        if (items.length === 0) {
            setMaintenanceLedgerDocsGridItems(items);
        }
        items
            .map(item => item)
            .sort(DataScadenzaSort)
            .map((doc) => {

                const maintenanceLedgerGridItem = [
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "Documento",
                        additionalText: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Caricamento",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Scadenza",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass:
                            "inline-flex gap-2 justify-self-end items-center justify-items-center",
                        component: {},
                    },
                ]
                //
                // Item 0.
                maintenanceLedgerGridItem[0].component = (
                    <div
                        className="
                        flex
                        space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                        justify-self-start
                        text-afm-box-light-gray-text"
                    >
                        <LockClosed />
                        <span className="border-l border-afm-box-light-gray-text"></span>
                    </div>
                )
                // Item 1.
                maintenanceLedgerGridItem[1].text = doc.descrizione
                // Item 2.
                maintenanceLedgerGridItem[2].text = doc.data_caricamento
                // Item 3.
                maintenanceLedgerGridItem[3].text = doc.data_scadenza
                // Item 4.
                maintenanceLedgerGridItem[4].component = <PdfViewer action={showMachineFileById({ idBoxDocumentiMacchinari: doc.id_box_documenti_macchinari })} />

                // Item 5.
                maintenanceLedgerGridItem[5].component = (
                    <div className="flex items-center space-x-2">
                        <Button
                            text="DOWNLOAD"
                            id={doc.id_box_documenti_macchinari}
                            twTextColor="text-white"
                            twBackground="bg-afm-box-blue"
                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                            actions={[
                                clearEmployeeCourseDocSenderId(),
                                downloadMachineFileById(
                                    {
                                        idBoxDocumentiMacchinari: doc.id_box_documenti_macchinari,
                                        docName: doc.allegato
                                    }
                                ),
                            ]}
                        />
                    </div>
                )

                maintenanceLedgerDocsItems.push(maintenanceLedgerGridItem)

                return setMaintenanceLedgerDocsGridItems(maintenanceLedgerDocsItems)
            })


        setMaintenanceLedgerDocsHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Documento",
                twColSpan: "col-span-3",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data Caricamento",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data scadenza",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Allegato",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "",
                twColSpan: "col-span-3",
                twAdditionalClass: "justify-self-end",
                component: <SearchBox />,
            },
        ])
    }, [
        searchList,
        searchQuery,
        searchResults,
        //isFetchingDoc, 
        //privacyDocSenderId
    ]);

    useEffect(() => {
        //
        // Build Grid items for "Libretto Uso e Manutenzioni" Category.
        //
        const userBookletAndMaintenanceDocsItems = [];
        const docs = files ? (files?.LBUM || []) : [];
        const emptySearch = (searchQuery || '').trim() === "";
        const results = emptySearch ? searchList : searchResults;
        const items = currentCategory === 'libretto uso e manutenzioni' ? results : docs;
        if (items.length === 0) {
            setUserBookletAndMaintenanceDocsGridItems(items);
        }

        items
            .map(item => item)
            .sort(DataScadenzaSort)
            .map((doc) => {

                const userBookletAndMaintenanceGridItem = [
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "Documento",
                        additionalText: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Caricamento",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Scadenza",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass:
                            "inline-flex gap-2 justify-self-end items-center justify-items-center",
                        component: {},
                    },
                ]
                //
                // Item 0.
                userBookletAndMaintenanceGridItem[0].component = (
                    <div
                        className="
                        flex
                        space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                        justify-self-start
                        text-afm-box-light-gray-text"
                    >
                        <LockClosed />
                        <span className="border-l border-afm-box-light-gray-text"></span>
                    </div>
                )

                // Item 1.
                userBookletAndMaintenanceGridItem[1].text = doc.descrizione
                // Item 2.
                userBookletAndMaintenanceGridItem[2].text = doc.data_caricamento
                // Item 3.
                userBookletAndMaintenanceGridItem[3].text = doc.data_scadenza
                // Item 4.
                userBookletAndMaintenanceGridItem[4].component = <PdfViewer action={showMachineFileById({ idBoxDocumentiMacchinari: doc.id_box_documenti_macchinari })} />

                // Item 5.
                userBookletAndMaintenanceGridItem[5].component = (
                    <div className="flex items-center space-x-2">
                        <Button
                            text="DOWNLOAD"
                            id={doc.id_box_documenti_macchinari}
                            twTextColor="text-white"
                            twBackground="bg-afm-box-blue"
                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                            actions={[
                                clearEmployeeCourseDocSenderId(),
                                downloadMachineFileById(
                                    {
                                        idBoxDocumentiMacchinari: doc.id_box_documenti_macchinari,
                                        docName: doc.allegato
                                    }
                                ),
                            ]}
                        />
                    </div>
                )

                userBookletAndMaintenanceDocsItems.push(userBookletAndMaintenanceGridItem)

                return setUserBookletAndMaintenanceDocsGridItems(userBookletAndMaintenanceDocsItems)
            })



        setUserBookletAndMaintenanceDocsHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Documento",
                twColSpan: "col-span-3",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data Caricamento",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data scadenza",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Allegato",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "",
                twColSpan: "col-span-3",
                twAdditionalClass: "justify-self-end",
                component: <SearchBox />,
            },
        ])
    }, [
        searchList,
        searchQuery,
        searchResults,
        //isFetchingDoc, 
        //privacyDocSenderId
    ]);

    useEffect(() => {
        const markingCEDocsGridItems = [];
        const docs = files ? (files?.MCE || []) : [];
        const emptySearch = (searchQuery || '').trim() === "";
        const results = emptySearch ? searchList : searchResults;
        const items = currentCategory === 'marcatura ce' ? results : docs;
        if (items.length === 0) {
            setMarkingCEDocsGridItems(items);
        }
        items
            .map(item => item)
            .sort(DataScadenzaSort)
            .map((doc) => {

                const item = [
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "Documento",
                        additionalText: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Caricamento",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Data Scadenza",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass:
                            "inline-flex gap-2 justify-self-end items-center justify-items-center",
                        component: {},
                    },
                ]
                //
                // Item 0.
                item[0].component = (
                    <div
                        className="
                        flex
                        space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                        justify-self-start
                        text-afm-box-light-gray-text"
                    >
                        <LockClosed />
                        <span className="border-l border-afm-box-light-gray-text"></span>
                    </div>
                )

                // Item 1.
                item[1].text = doc.descrizione
                // Item 2.
                item[2].text = doc.data_caricamento
                // Item 3.
                item[3].text = doc.data_scadenza
                // Item 4.
                item[4].component = <PdfViewer action={showMachineFileById({ idBoxDocumentiMacchinari: doc.id_box_documenti_macchinari })} />

                // Item 5.
                item[5].component = (
                    <div className="flex items-center space-x-2">
                        <Button
                            text="DOWNLOAD"
                            id={doc.id_box_documenti_macchinari}
                            twTextColor="text-white"
                            twBackground="bg-afm-box-blue"
                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                            actions={[
                                clearEmployeeCourseDocSenderId(),
                                downloadMachineFileById(
                                    {
                                        idBoxDocumentiMacchinari: doc.id_box_documenti_macchinari,
                                        docName: doc.allegato
                                    }
                                ),
                            ]}
                        />
                    </div>
                )

                markingCEDocsGridItems.push(item)

                return setMarkingCEDocsGridItems(markingCEDocsGridItems)
            })



        setMarkingCEDocsHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Documento",
                twColSpan: "col-span-3",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data Caricamento",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Data scadenza",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Allegato",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "",
                twColSpan: "col-span-3",
                twAdditionalClass: "justify-self-end",
                component: <SearchBox />,
            },
        ]);

    }, [
        searchList,
        searchQuery,
        searchResults,
    ]);

    useEffect(() => {
        /**
         *
         *  Build Tab categories.
         *
         */
        const categoryItems = {
            Informazioni: [
                {
                    id: 1,
                    component: (
                        <Fragment>
                            <div
                                className="
                                    h-16 
                                    flex 
                                    items-center 
                                    p-3 
                                    space-x-8 
                                    rounded-sm 
                                    bg-gray-100"
                            >
                                <h1 className="text-left text-lg font-bold">
                                    Informazioni
                                </h1>
                            </div>
                            <div className="flex gap-10 pt-10">
                                <div className="relative">
                                    <ImgUploader
                                        img={machineImg}
                                        action={uploadManutenzioniImage}
                                        id={machineInfo.id_box_macchinari}
                                    />
                                </div>
                                <form action="" method="POST" className="w-full pr-6">
                                    <div className="flex gap-10">
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="machineName"
                                                labelTitle="Nome Macchina"
                                                inputType="text"
                                                inputName="machineName"
                                                placeholder="Nome macchina"
                                                required={true}
                                                inputValue={machineInfo.nome}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="machineUnit"
                                                labelTitle="Reparto"
                                                inputType="text"
                                                inputName="machineUnit"
                                                placeholder="Reparto"
                                                required={true}
                                                inputValue={machineInfo.reparto}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-10">
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="machineNumber"
                                                labelTitle="Matricola"
                                                inputType="text"
                                                inputName="machineNumber"
                                                placeholder="Matricola"
                                                required={true}
                                                inputValue={machineInfo.matricola}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="machineInternalNumber"
                                                labelTitle="Matricola interna"
                                                inputType="text"
                                                inputName="machineInternalNumber"
                                                placeholder="Matricola interna"
                                                required={true}
                                                inputValue={machineInfo.matricola_interna}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-10">
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="machineYear"
                                                labelTitle="Anno"
                                                inputType="text"
                                                inputName="machineYear"
                                                placeholder="Anno"
                                                required={true}
                                                inputValue={machineInfo.anno}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="machineCE"
                                                labelTitle="Marcatura CE"
                                                inputType="text"
                                                inputName="machineCE"
                                                placeholder="Marcatura CE"
                                                required={true}
                                                inputValue={machineInfo.marcatura_ce}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-10">
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="machineAssistenzaTecnica"
                                                labelTitle="Assistenza tecnica"
                                                inputType="text"
                                                inputName="machineAssistenzaTecnica"
                                                placeholder=""
                                                required={true}
                                                inputValue={machineInfo.assistenza_tecnica}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="machineNomeReferente"
                                                labelTitle="Nome referente"
                                                inputType="text"
                                                inputName="machineNomeReferente"
                                                placeholder=""
                                                required={true}
                                                inputValue={machineInfo.nome_referente}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-10">
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="machineNumeroAssistenza"
                                                labelTitle="Numero di telefono"
                                                inputType="text"
                                                inputName="machineNumeroAssistenza"
                                                placeholder=""
                                                required={true}
                                                inputValue={machineInfo.numero_assistenza}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="machineEmailAssistenza"
                                                labelTitle="Email"
                                                inputType="text"
                                                inputName="machineEmailAssistenza"
                                                placeholder=""
                                                required={true}
                                                inputValue={machineInfo.email_assistenza}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex space-x-10 mt-6">
                                        {/*
                                        <Button
                                            disabled={true}
                                            text="Salva modifiche"
                                            twTextColor="text-white"
                                            twBackground="bg-afm-box-blue"
                                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                        />
                                        */}
                                    </div>
                                </form>
                            </div>
                        </Fragment>
                    ),
                },
            ],
            "Contratti Manutenzioni": [
                {
                    id: 2,
                    component: (
                        <Fragment>
                            <div
                                className="h-16 items-center flex space-x-8 p-3 rounded-sm bg-gray-100">
                                <h1 className="text-left text-lg font-bold flex">
                                    Contratti Manutenzioni
                                </h1>
                                <div className="flex flex-1 justify-end space-x-5">
                                    <Button
                                        text="Aggiungi contratto"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        onClick={() => {
                                            // setModalComponent(<CorsoModal id={id} />);
                                            // dispatch(openModal())
                                            setFileType('CNTR')
                                            dispatch(openFileManutenzioniModal(fileType));
                                        }}
                                        icon={<PluSm />}
                                    />
                                </div>
                            </div>
                            {files && files?.CNTR && files?.CNTR.length < 1 ? (
                                <div className="flex items-center justify-center my-14">
                                    <p className="text-xl">
                                        Non ci sono documenti...
                                    </p>
                                </div>
                            ) : (
                                <Grid
                                    twGridTemplateColumns="grid-cols-12"
                                    headerTitleItems={maintenanceContractsDocsGridHeaderTitleItems}
                                    items={maintenanceContractsDocsGridItems}
                                />
                            )}
                        </Fragment>
                    ),
                },
            ],
            "Scheda di Intervento": [
                {
                    id: 3,
                    component: (
                        <Fragment>
                            <div className="h-16 items-center flex space-x-8 p-3 rounded-sm bg-gray-100">
                                <h1 className="text-left text-lg font-bold flex"> Scheda di intervento </h1>
                                <div className="flex flex-1 justify-end space-x-5">
                                    <Button
                                        text="Aggiungi scheda"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        onClick={() => {
                                            // setModalComponent(<CorsoModal id={id} />);
                                            // dispatch(openModal())
                                            setFileType('SCDI');
                                            dispatch(openFileManutenzioniModal());
                                        }}
                                        icon={<PluSm />}
                                    />
                                </div>
                            </div>
                            {files && files?.SCDI && files?.SCDI.length < 1 ? (
                                <div className="flex items-center justify-center my-14">
                                    <p className="text-xl">
                                        Non ci sono documenti...
                                    </p>
                                </div>
                            ) : (
                                <Grid
                                    twGridTemplateColumns="grid-cols-12"
                                    headerTitleItems={interventionSheetDocsHeaderTitleItems}
                                    items={interventionSheetDocsGridItems}
                                />
                            )}
                        </Fragment>
                    ),
                },
            ],
            "Registro Manutenzioni": [
                {
                    id: 4,
                    component: (
                        <Fragment>
                            <div className="h-16 items-center flex space-x-8 p-3 rounded-sm bg-gray-100">
                                <h1 className="text-left text-lg font-bold flex">Registro Manutenzioni</h1>
                                <div className="flex flex-1 justify-end space-x-5">
                                    <Button
                                        text="Aggiungi registro"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        onClick={() => {
                                            // setModalComponent(<CorsoModal id={id} />);
                                            // dispatch(openModal())
                                            setFileType('RGMN');
                                            dispatch(openFileManutenzioniModal());
                                        }}
                                        icon={<PluSm />}
                                    />
                                </div>
                            </div>
                            {files && files?.RGMN && files?.RGMN.length < 1 ? (
                                <div className="flex items-center justify-center my-14">
                                    <p className="text-xl">
                                        Non ci sono documenti...
                                    </p>
                                </div>
                            ) : (
                                <Grid
                                    twGridTemplateColumns="grid-cols-12"
                                    headerTitleItems={maintenanceLedgerDocsHeaderTitleItems}
                                    items={maintenanceLedgerDocsGridItems}
                                />
                            )}
                        </Fragment>
                    ),
                },
            ],
            "Libretto Uso e Manutenzioni": [
                {
                    id: 5,
                    component: (
                        <Fragment>
                            <div className="h-16 items-center flex space-x-8 p-3 rounded-sm bg-gray-100">
                                <h1 className="text-left text-lg font-bold">
                                    Libretto Uso e Manutenzioni
                                </h1>
                                <div className="flex flex-1 justify-end space-x-5">
                                    <Button
                                        text="Aggiungi libretto"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        onClick={() => {
                                            // setModalComponent(<CorsoModal id={id} />);
                                            // dispatch(openModal())
                                            setFileType('LBUM');
                                            dispatch(openFileManutenzioniModal());
                                        }}
                                        icon={<PluSm />}
                                    />
                                </div>
                            </div>
                            {files && files?.LBUM && files?.LBUM.length < 1 ? (
                                <div className="flex items-center justify-center my-14">
                                    <p className="text-xl">
                                        Non ci sono documenti...
                                    </p>
                                </div>
                            ) : (
                                <Grid
                                    twGridTemplateColumns="grid-cols-12"
                                    headerTitleItems={userBookletAndMaintenanceDocsHeaderTitleItems}
                                    items={userBookletAndMaintenanceDocsGridItems}
                                />
                            )}
                        </Fragment>
                    ),
                },
            ],
            "Marcatura CE": [
                {
                    id: 6,
                    component: (
                        <Fragment>
                            <div className="h-16 items-center flex space-x-8 p-3 rounded-sm bg-gray-100">
                                <h1 className="text-left text-lg font-bold">
                                    Marcatura CE
                                </h1>
                                <div className="flex flex-1 justify-end space-x-5">
                                    <Button
                                        text="Aggiungi marcatura CE"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        onClick={() => {
                                            // setModalComponent(<CorsoModal id={id} />);
                                            // dispatch(openModal())
                                            setFileType('MCE');
                                            dispatch(openFileManutenzioniModal());
                                        }}
                                        icon={<PluSm />}
                                    />
                                </div>
                            </div>
                            {files && files?.MCE && files?.MCE.length < 1 ? (
                                <div className="flex items-center justify-center my-14">
                                    <p className="text-xl">
                                        Non ci sono documenti...
                                    </p>
                                </div>
                            ) : (
                                <Grid
                                    twGridTemplateColumns="grid-cols-12"
                                    headerTitleItems={markingCEDocsHeaderTitleItems}
                                    items={markingCEDocsGridItems}
                                />
                            )}
                        </Fragment>
                    ),
                },
            ],
        }

        return setCategories(categoryItems)
    }, [
        machineImg,
        machineInfo,
        maintenanceContractsDocs,
        maintenanceContractsDocsGridHeaderTitleItems,
        maintenanceContractsDocsGridItems,
        interventionSheetDocs,
        interventionSheetDocsHeaderTitleItems,
        interventionSheetDocsGridItems,
        maintenanceLedgerDocs,
        maintenanceLedgerDocsHeaderTitleItems,
        maintenanceLedgerDocsGridItems,
        userBookletAndMaintenanceDocs,
        userBookletAndMaintenanceDocsHeaderTitleItems,
        userBookletAndMaintenanceDocsGridItems,
        markingCEDocsGridItems,
        files

    ]);

    useEffect(() => {
        if (isSuccess) {
            // if dataItems are been fetched then call setSearchList giving:
            // list, search key and an additional list of search key
            const searchConfig = {
                dataItems: files?.CNTR || [],
                searchIndex: 'descrizione',
                additionalIndex: [],
            };
            if (currentCategory === "contratti manutenzioni" && (files?.CNTR || []).length <= 0) {
                dispatch(reset());
                return
            }
            if (currentCategory === "scheda di intervento" && (files?.SCDI || []).length <= 0) {
                dispatch(reset());
                return
            }
            if (currentCategory === "registro manutenzioni" && (files?.RGMN || []).length <= 0) {
                dispatch(reset());
                return
            }
            if (currentCategory === "libretto uso e manutenzioni" && (files?.LBUM || []).length <= 0) {
                dispatch(reset());
                return
            }
            if (currentCategory === "marcatura ce" && (files?.MCE || []).length <= 0) {
                dispatch(reset());
                return
            }
            switch (currentCategory) {
                case 'scheda di intervento':
                    searchConfig.dataItems = (files?.SCDI || []);
                    break;
                case 'registro manutenzioni':
                    searchConfig.dataItems = (files?.RGMN || []);
                    break;
                case 'libretto uso e manutenzioni':
                    searchConfig.dataItems = (files?.LBUM || []);
                    break;
                case 'marcatura ce':
                    searchConfig.dataItems = (files?.MCE || []);
                    break;
            };
            dispatch(
                setSearchList(searchConfig)
            );
        }
    }, [
        searchQuery,
        files,
        isSuccess,
        currentCategory
    ]);

    useEffect(() => {
        if (fileManutenzioniModalStatus.modalIsOpen === false) {
            dispatch(getFilesById(id))
        }
    }, [
        fileManutenzioniModalStatus.modalIsOpen,
    ]);

    /*
     *
     * Component render.
     *
     */
    return (
        <LayoutDetail title="Manutenzioni">
            <SubHeader
                subHeaderTitle="Manutenzioni"
                needFirstBreadCrumb={true}
                firstBtnVisibile={false}
                //firstBtnText="stampa"
                //firstBtnIcon={<Printer />}
                //firstBtnTwBg="bg-white"
                //firstBtnTwFocusBg="focus:bg-white/[0.7]"
                //firstBtnTwTextColor="text-black"
                secondBtnVisibile={false}
                secondBtnText="Aggiungi lavoratore"
                secondBtnIcon={<PluSm />}
                secondBtnTwBg="bg-afm-box-dark-gray"
                secondBtnTwFocusBg="focus:bg-afm-box-dark-gray/[0.7]"
                secondBtnTwTextColor="text-white"
            //secondBtnActions={[openModal()]}
            />

            <LayoutDetailContainer>

                {/* Skeleton. */}
                {isFetching && <ManutenzioniDetailSkeleton />}

                {
                    !isFetching &&
                    !isError &&
                    isSuccess &&
                    <Tabs
                        categories={categories}
                        tabDefaultIndex={defaultTabIndex}
                        onChange={(index) => {
                            const categoriesNames = Object.keys(categories);
                            const newCurrentCategory = (categoriesNames[index] || '').trim().toLowerCase();
                            dispatch(reset());
                            setCurrentCategory(newCurrentCategory);
                        }}
                    />
                }

                {isError && !isSuccess && (
                    <div className="flex items-center justify-center my-14">
                        <p className="text-xl">...Recupero dei dati fallito</p>
                    </div>
                )}

                {fileManutenzioniModalStatus.modalIsOpen && <FileManutenzioniModal id={id} tipo={fileType} />}

            </LayoutDetailContainer>
        </LayoutDetail>
    )
}

/**
 *
 * @dev ManutenzioniDetailSkeleton UI preview component.
 * 
 * @function ManutenzioniDetailSkeleton
 *
 * @returns JSX.Element
 *
 */
function ManutenzioniDetailSkeleton() {
    return (
        <div className="animate-pulse px-6">
            <div className="flex flex-col">
                <header
                    className="
                        h-16
                        flex 
                        space-x-4
                        items-center justify-items-start font-semibold"
                >
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                    <div className="h-8 w-full bg-neutral-300 rounded"></div>
                </header>
                <span className="flex-grow mb-2 border-t border-gray-400"></span>

                <div
                    className="
                        flex 
                        space-x-4 
                        my-1 mb-14
                        rounded-md 
                        items-center justify-center justify-items-start"
                >
                    <div className="h-14 w-full bg-neutral-300 rounded"></div>
                </div>
                <div className='space-y-10'>
                    <div
                        className="
                            flex 
                            space-x-10 
                            my-1 
                            rounded-md 
                            items-center justify-center justify-items-start"
                    >
                        <div className="h-10 w-full bg-neutral-300 rounded"></div>
                        <div className="h-10 w-full bg-neutral-300 rounded"></div>
                    </div>

                    <div
                        className="
                            flex 
                            space-x-10 
                            my-1 
                            rounded-md 
                            items-center justify-center justify-items-start"
                    >
                        <div className="h-10 w-full bg-neutral-300 rounded"></div>
                        <div className="h-10 w-full bg-neutral-300 rounded"></div>
                    </div>

                    <div
                        className="
                            flex 
                            space-x-10 
                            my-1 
                            rounded-md 
                            items-center justify-center justify-items-start"
                    >
                        <div className="h-10 w-full bg-neutral-300 rounded"></div>
                        <div className="h-10 w-full bg-neutral-300 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

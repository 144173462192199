import Tooltip from "./Tooltip"
/**
 *
 * @dev Circle UI component.
 * 
 * @const Circle
 *
 * @param { string } message
 *
 * @returns JSX.Element
 *
 */
export default function Circle(props) {
    /**
     * getStatus function.
     * It return the TailwindCSS class token as the corresponding status.
     */
    const getStatus = (status) => {
        switch (status) {
            case "compliant":
                return "bg-afm-box-success-green"

            case "non-compliant":
                return "bg-afm-box-error-red"

            case "waiting":
                return "bg-afm-box-waiting-yellow"

            default:
                return "bg-afm-box-light-gray"
        }
    }
    return (
        <Tooltip message={props.message} twBackground="bg-afm-box-blue">
            <div
                className={`
                        rounded-full 
                        h-3 
                        w-3 
                        ${getStatus(props.status)}`}
            ></div>
        </Tooltip>
    )
}
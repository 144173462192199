// React.
import { Fragment, useEffect, useState } from "react"
// Icons.
import { Check } from "./Icons"

/**
 * 
 * @dev Handle docs uploading process.
 *
 * @function DocUploader
 *
 * @returns JSX.Element
 *
 */
export default function DocUploader(props) {
    const accept = props.accept || 'image/*,.pdf';

    // Init doc state.
    const [doc, setDoc] = useState("")

    // Init isUploaded state.
    const [isUploaded, setIsUploaded] = useState(false)

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => { }, [doc])

    /**
     *
     * Uploader Component.
     *
     */
    const Uploader = (props) => {
        const name = props.name || 'picture';
        // const accept = props.accept || 'image/*,.pdf'; //'.jpg, .JPG, .jpeg, .png, .PNG';

        function onChangeHandler(event) {
            const file = event.target.files[0]
            const fileURL = URL.createObjectURL(file) // Create a blob url with uploaded image.

            if (fileURL) {
                setDoc(fileURL)

                setIsUploaded(true)

            }
            if (props.onChange && file) {
                props.onChange(file, fileURL || '')
            }
        }

        return (
            <div className="inline-flex align-middle opacity-100 text-xs">
                <div
                    className="
                        relative 
                        w-32 
                        overflow-hidden 
                        outline outline-1 
                        my-4 
                        p-2 
                        text-black"
                >
                    <input
                        type="file"
                        name={name}
                        accept={accept}
                        //size=""
                        onChange={onChangeHandler}
                        className="absolute top-0 right-0 cursor-pointer opacity-0 w-full h-full"
                    />

                    <p className="text-base">Scegli file...</p>
                </div>

                <div className="flex items-center justify-center ml-10">
                    {isUploaded ? (
                        <Fragment>
                            <p className="text-base">File caricato con successo</p>
                            <span
                                className="
                                    ml-2 
                                    rounded-full 
                                    h-6 
                                    w-6 
                                    bg-afm-box-success-green 
                                    text-white"
                            >
                                <Check />
                            </span>
                        </Fragment>
                    ) : (
                        <p className="text-base">Nessun file caricato</p>
                    )}
                </div>
            </div>
        )
    }

    /**
     *
     * Component render.
     *
     */
    return (
        <div className="flex items-center">
            <Uploader name={props.name} onChange={props.onChange} />
        </div>
    )
}

// React.
import { useState, useEffect, Fragment } from "react"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// VisuraSlice.
import {
    getDoc,
    downloadDoc,
    dataSelector,
    clearDocSenderId,
    showDoc,
    deleteDoc,
} from "../../features/generale/durc/DurcSlice"
// VisuraModalSlice.
import { durcModalSelector, openModal } from "../../features/generale/durc/DurcModalSlice"
// SearchSlice.
import { searchSelector, setSearchList, reset } from "../../features/search/SearchSlice"
// UI.
import LayoutDetailContainer from "../LayoutDetailContainer"
import Grid from "../../base/Grid"
import SubHeader from "../../base/SubHeader"
import SearchBox from "../../base/SearchBox"
import Button from "../../base/Button"
import PdfViewer from "../../base/PdfViewer"
import DropDown from "../../base/DropDown"
import DurcModal from "./DurcModal"
//Icons.
import { PluSm, DocumentText, OvalLoader } from "../../base/Icons";
import { ScadenzaSort } from "../../../common/util"

/**
 *
 * @dev Generale Subsection.
 * 
 * @function Durc
 *
 * @returns JSX.Element
 *
 */
export default function Durc() {
    const dispatch = useDispatch()
    // gridItems State.
    const [gridItems, setGridItems] = useState([])

    // visuraSlice State.
    const {
        dataItems,
        isFetching,
        isSuccess,
        isError,
        //errorMsg,
        doc,
        docSenderId,
        isFetchingDoc,
    } = useSelector(dataSelector);

    // visuraModalSlice State.
    const { modalIsOpen, modalIsSuccess } = useSelector(durcModalSelector);

    // searchSlice State.
    const { searchResults, searchList, searchQuery } = useSelector(searchSelector);

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        dispatch(getDoc())
    }, [dispatch, modalIsSuccess]);
    useEffect(() => {
        dispatch(reset())
    }, []);

    /**
     *
     *  Build the grid items.
     *
     */
    useEffect(() => {
        const items = [];
        const emptySearch = (searchQuery || '').trim() === "";
        const searchQueryResults = emptySearch ? searchList : searchResults

        if (searchQueryResults.length < 1) {
            return setGridItems(items)
        } else {
            searchQueryResults
                .map(item => item)
                .sort(ScadenzaSort)
                .map((item) => {
                    const gridItem = [
                        {
                            text: "",
                            twColSpan: "col-span-1",
                            twAdditionalClass: "",
                            component: (
                                <div
                                    className="
                                    flex 
                                    space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
                                    justify-self-start 
                                    text-afm-box-light-gray-text"
                                >
                                    <DocumentText />
                                    <span className="border-l border-afm-box-light-gray-text"></span>
                                </div>
                            ),
                        },
                        {
                            text: "Data Caricamento",
                            twColSpan: "col-span-2",
                            twAdditionalClass: "",
                            component: "",
                        },
                        {
                            text: "Data DURC",
                            twColSpan: "col-span-2",
                            twAdditionalClass: "",
                            component: "",
                        },
                        {
                            text: "Data Scadenza",
                            twColSpan: "col-span-2",
                            twAdditionalClass: "",
                            component: "",
                        },
                        {
                            text: "",
                            twColSpan: "col-span-2",
                            component: {},
                        },
                        {
                            text: "",
                            twColSpan: "col-span-3",
                            twAdditionalClass: "justify-self-end",
                            component: {},
                        }
                    ];
                    // Item 1.
                    gridItem[1].text = item.data_ora_inserimento
                    // Item 2.
                    gridItem[2].text = item.data
                    // Item 3.
                    gridItem[3].text = item.scadenza
                    // Item 4.
                    gridItem[4].component = <PdfViewer action={showDoc(item.id_box_durc)} />
                    // Item 5.
                    gridItem[5].component = (
                        <div className="flex items-center space-x-2">
                            {/* If isFetchingDoc and visuraDocSenderId State correspond to Button id pressed, than show the OvalLoader. */}
                            {isFetchingDoc && (
                                <OvalLoader strokeColor="#aaa" />
                            )}
                            <Button
                                text="DOWNLOAD"
                                id={item.id_box_durc}
                                twTextColor="text-white"
                                twBackground="bg-afm-box-blue"
                                twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                actions={[
                                    clearDocSenderId(),
                                    downloadDoc({
                                        docId: item.id_box_durc,
                                        docName: item.file_download
                                    }),
                                ]}
                            />
                            <DropDown
                                buttonItems={[{
                                    type: "delete",
                                    method: deleteDoc(item.id_box_durc)
                                }]}
                            />
                        </div>
                    );
                    items.push(gridItem)
                    return setGridItems(items)
                })
        }
    }, [
        searchList,
        searchQuery,
        searchResults,
        doc,
        isFetchingDoc,
        docSenderId
    ]);

    /**
     *
     *  if can get visura items list correctly
     *  then is possible to init and configure the search feature.
     *
     */
    useEffect(() => {
        if (isSuccess) {
            // if dataItems are been fetched then call setSearchList giving:
            // list, search key and an additional list of search key.
            dispatch(
                setSearchList({
                    dataItems: dataItems,
                    searchIndex: Object.keys(dataItems.length ? dataItems[0] : [''])[0],
                    additionalIndex: [
                        Object.keys(dataItems.length ? dataItems[0] : [''])[1],
                        Object.keys(dataItems.length ? dataItems[0] : [''])[2],
                        Object.keys(dataItems.length ? dataItems[0] : [''])[3],
                    ],
                })
            )
        }
    }, [
        dispatch,
        dataItems,
        isFetching,
        isSuccess,
        isError
    ])

    /**
     *
     * Component render.
     *
     */
    return (
        <Fragment>
            <SubHeader
                subHeaderTitle="DURC"
                firstBtnVisibile={false}
                secondBtnVisibile={true}
                secondBtnText="Nuovo DURC"
                secondBtnIcon={<PluSm />}
                secondBtnTwBg="bg-afm-box-dark-gray"
                secondBtnTwFocusBg="focus:bg-afm-box-dark-gray/[0.7]"
                secondBtnTwTextColor="text-white"
                secondBtnActions={[openModal()]}
            />
            <LayoutDetailContainer>
                {/* Skeleton. */}
                {isFetching && <Skeleton />}
                {!isFetching && !isError && isSuccess && (
                    <Fragment>
                        <Grid
                            twGridTemplateColumns="grid-cols-12"
                            headerTitleItems={[
                                { title: "", twColSpan: "col-span-1" },
                                { title: "Data Caricamento", twColSpan: "col-span-2" },
                                { title: "Data DURC", twColSpan: "col-span-2" },
                                { title: "Data Scadenza", twColSpan: "col-span-2" },
                                { title: "Allegato", twColSpan: "col-span-2" },
                                { title: "", twColSpan: "col-span-3", twAdditionalClass: "justify-self-end",
                                    component: <SearchBox placeholder={"Cerca DURC"} />,
                                },
                            ]}
                            items={gridItems}
                        />
                    </Fragment>
                )}
                {/* If Modal window is open, then render. */}
                {modalIsOpen && <DurcModal />}
                {isError && !isSuccess && (
                    <div className="flex items-center justify-center my-14">
                        <p className="text-xl">...Recupero dei dati fallito</p>
                    </div>
                )}
            </LayoutDetailContainer>
        </Fragment>
    )
}

/**
 *
 * @dev Skeleton Component that Performs an UI flashing preview.
 * 
 * @function Skeleton
 *
 * @returns JSX.Element
 *
 */
function Skeleton() {
    return (
        <div className="animate-pulse">
            <div className="flex flex-col">
                <header
                    className="
                        h-20 
                        grid grid-cols-12 
                        gap-4 
                        items-center justify-items-start
                        font-semibold"
                >
                    <div className="col-span-1 h-10 w-full bg-inherit rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-inherit rounded"></div>
                    <div className="col-span-2 h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="col-span-2 h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="col-span-2 h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="col-span-1 h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="col-span-3 h-10 w-full bg-neutral-300 rounded"></div>
                </header>
                <span className="flex-grow mb-2 border-t border-gray-400"></span>

                <div
                    className="
                        flex space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center 
                        justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center 
                        justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center 
                        justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center 
                        justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center 
                        justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                        flex space-x-4 
                        group 
                        my-1 
                        p-1 
                        rounded-md 
                        items-center 
                        justify-center justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
            </div>
        </div>
    )
}

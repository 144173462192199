// React.
import React from "react"

/**
 *
 * @function SideBarMenuTooltip
 *
 * @param { string } message
 * @param { string } twBackground
 * @param { JSX.Element } children
 *
 * @returns JSX.Element
 *
 */
export default function SideBarMenuTooltip(props) {
    return (
        <div className="absolute isolate z-20 items-center visible">
            <div
                className="
                    group
                    absolute 
                    -top-3 
                    left-12 
                    mb-6 
                    w-max 
                    items-center"
            >
                <div
                    className={`
                        relative 
                        -left-1 
                        top-5 
                        w-3 
                        h-3 
                        rotate-45 
                        ${props.twBackground}`}
                ></div>
                <span
                    className={`
                        relative 
                        p-2 
                        text-xs 
                        leading-none 
                        text-white 
                        whitespace-no-wrap 
                        ${props.twBackground} 
                        shadow-lg`}
                >
                    {props.message}
                </span>
            </div>
        </div>
    )
}

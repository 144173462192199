/**
 *
 * @notice ComunicazioniSlice to handle state of Comunicazioni globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 */

// Redux.
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit"
// Axios.
import axios from "axios"
// gmapi.
import {
    API_KEY,
    listaAvvisiAPI,
    avvisoLettoAPI,
    downloadAvvisiAPI
} from "../../../common/gmApi"
// cookie.
import { tkbox,getCookie } from "../../../common/cookie"
// util.
import { downloadDocument,showDocument } from "../../../common/util"

/********************************************
 *                                          *
 * @notice AsyncThunk API(s) Implementation *
 *                                          *
 ********************************************/

/**
 *
 *  Get "Comunicazioni" data.
 *
 */
export const getComunicazioni = createAsyncThunk(
    // Action route.
    "comunicazioni/getComunicazioni",
    //
    // Async Function with axios http req.
    //
    // First param: null.
    //
    // Second param: "thunkAPI" callback function that should return a promise
    // handle with extraReducers to update the State.
    // It takes two parameter too: first is the value of the dispatched action,
    // and the second is the Thunk API config.
    //
    async (thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                listaAvvisiAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    //console.error('Error "getComunicazioni":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "getComunicazioni":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "getComunicazioni":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  Get Notice by id async.
 *
 */
export const getNoticeById = createAsyncThunk(
    // Action route.
    "comunicazioni/getNoticeById",
    //
    // Async Function with axios http req.
    //
    // First param: null.
    //
    // Second param: "thunkAPI" callback function that should return a promise
    // handle with extraReducers to update the State.
    // It takes two parameter too: first is the value of the dispatched action,
    // and the second is the Thunk API config.
    //
    async ({ id },{ getState,rejectWithValue }) => {
        const state = getState().comunicazioni

        try {
            const response = await state.noticesItems.filter((item) => item.id_box_avvisi === id)

            if (response.length > 0) {
                return response
            } else {
                console.error('Error "getNoticeById":',response)
                return rejectWithValue(response)
            }
        } catch (error) {
            console.error('Error "getNoticeById":',error.message)
            return rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Show Notice document.
 *
 */
export const showNoticeDoc = createAsyncThunk(
    // Action route.
    "comunicazioni/showNoticeDoc",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param idBoxAvvisi: string

    * @param thunkAPI: <AsyncThunkConfig/> 
     * @dev Callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AxiosResponse<any, any>
     * 
     */
    async (idBoxAvvisi,thunkAPI) => {
        try {
            const response = await axios.post(
                // url.
                downloadAvvisiAPI,
                // body.
                null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_avvisi: idBoxAvvisi,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    // Build a custom response with extradata.
                    return response
                } else {
                    // else reject gmapi response error.
                    console.error('Error "showNoticeDoc":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                console.error('Error "showNoticeDoc":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            // If something get wrong then reject the error.
            console.error('Error "showNoticeDoc":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Download Notice document.
 *
 */
export const downloadNoticeDoc = createAsyncThunk(
    // Action route.
    "comunicazioni/downloadNoticeDoc",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param {
     *      idBoxAvvisi: string
     *      docName: string
     *  } 
     * @param thunkAPI: <AsyncThunkConfig/> 
     * @dev Callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AxiosResponse<any, any>
     * 
     */
    async ({ idBoxAvvisi,docName },thunkAPI) => {
        try {
            const response = await axios.post(
                // url.
                downloadAvvisiAPI,
                // body.
                null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_avvisi: idBoxAvvisi,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    // Build a custom response with extradata.
                    return response
                } else {
                    // else reject gmapi response error.
                    console.error('Error "downloadNoticeDoc":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                console.error('Error "downloadNoticeDoc":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            // If something get wrong then reject the error.
            console.error('Error "downloadNoticeDoc":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 * @dev avvisoLetto.
 * @dev Mark notice as read.
 *
 */
export const avvisoLetto = createAsyncThunk(
    // Action route.
    "comunicazioni/avvisoLetto",
    //
    // Async Function with axios http req.
    //
    // First param:
    //  {
    //      idBoxAvvisi: string
    //      letto: string | bool
    //  }
    //
    // Second param: "thunkAPI" callback function that should return a promise
    // handle with extraReducers to update the State.
    // It takes two parameter too: first is the value of the dispatched action,
    // and the second is the Thunk API config.
    //
    async ({ idBoxAvvisi,letto },thunkAPI) => {
        try {
            const response = await axios.post(
                // url.
                avvisoLettoAPI,
                // body.
                null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_avvisi: idBoxAvvisi,
                        letto: letto
                    },
                }
            )
            // If response status is OK and the gmapi response status is OK then return response data.
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    // Build a custom response with extradata.
                    return {
                        data: response.data,
                        eventSender: idBoxAvvisi,
                    }
                } else {
                    // Else reject gmapi response error.
                    //console.error('Error "avvisoLetto":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "avvisoLetto":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            // If something get wrong then reject the error.
            console.error('Error "avvisoLetto":',error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/*********************************************
 *                                           *
 * @notice createSlice implementation.       *
 *                                           *
 * @dev Create `comunicazioniSlice` feature. *
 * @dev UI views state controller.           *
 *                                           *
 *********************************************/
export const comunicazioniSlice = createSlice({
    // Name, used in action types.
    name: "comunicazioni",
    // The initial state for the reducer.
    initialState: {
        noticesItems: [],                // All notices.
        isFetching: false,               // AsyncThunk is calling an API to fetch all notices. 
        isSuccess: false,                // AsyncThunk is success from API call. All notice are get succesfully.
        isError: false,                  // AsyncThunk is fail from API call, while retrieving data.
        errorMsg: "",                    // Error message container.
        noticeSelected: [],              // Single notice selected detail.
        isFetchingNoticeSelected: false, // AsyncThunk is calling an API to fetch notice's detail. 
        isSuccessNoticeSelected: false,  // AsyncThunk is success from API call. notice's detail is retrieved succesfully.
        isErrorNoticeSelected: false,    // AsyncThunk is success from API call. notice's detail is retrieved succesfully.
        noticeDoc: [],                   // Notice document.
        noticeDocName: "",               // Notice Attachment name.
        noticeDocSenderId: "",           // Notice sender.
        noticehasBeenRed: false,         // Notice ha been red.
        isFetchingNoticeDoc: false,      // AsyncThunk is calling an API to fetch a notice attachment.
        isSuccessNoticeDoc: false,       // AsyncThunk is success from API call. A notice attachment id obtained succesfully.
        isErrorNotice: false,            // AsyncThunk is fail from API call, while retrieving data. 
        noticeDocErrorMsg: [],           // Notice Doc error message.
    },
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {
        /**
         * @action clearNoticeDocSenderId
         */
        clearNoticeDocSenderId: (state) => {
            state.noticeDocSenderId = ""
            return state
        },
    },
    // AsyncThunk Reducers.
    // A "builder callback" function used to add more reducers, or
    // an additional object of "case reducers", where the keys should be other
    // action types.
    extraReducers: (builder) => {
        builder
            /*******************************************************************
             *
             * @dev getComunicazioni.
             *
             */
            .addCase(getComunicazioni.fulfilled,(state,action) => {
                state.isFetching = false
                state.isSuccess = true
                state.noticesItems = action.payload.gmapi.response.data
            })
            .addCase(getComunicazioni.pending,(state) => {
                state.isFetching = true
            })
            .addCase(getComunicazioni.rejected,(state,action) => {
                state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
            })
            /*******************************************************************
             *
             * @dev getNoticeById.
             *
             */
            .addCase(getNoticeById.fulfilled,(state,action) => {
                state.isFetchingNoticeSelected = false
                state.isSuccessNoticeSelected = true

                state.noticeSelected = action.payload[0]
            })
            .addCase(getNoticeById.pending,(state) => {
                state.isFetchingNoticeSelected = true
                state.isSuccessNoticeSelected = false
            })
            .addCase(getNoticeById.rejected,(state,action) => {
                state.isFetchingNoticeSelected = false
                state.isSuccessNoticeSelected = false
                state.isErrorNoticeSelected = true
                state.errorMsg = action.payload
            })
            /*******************************************************************
             *
             * @dev showNoticeDoc.
             *
             */
            .addCase(showNoticeDoc.fulfilled,(state,action) => {
                // Needless.
                //state.isFetchingNoticeDoc = false
                //state.isSuccessNoticeDoc = true

                // get the base64 string.
                state.noticeDoc = action.payload.data.gmapi.response.data
                // Get the file name.
                //state.noticeDocName = action.meta.arg.docName

                showDocument(state.noticeDoc)
            })
            .addCase(showNoticeDoc.pending,(state,action) => {
                // Needless.
                //state.isFetchingNoticeDoc = true
            })
            .addCase(showNoticeDoc.rejected,(state,action) => {
                // Needless.
                //state.isFetchingNoticeDoc = false
                //state.isErrorNotice = true
                state.noticeDocErrorMsg = action.payload
            })
            /*******************************************************************
             *
             * @dev downloadNoticeDoc.
             *
             */
            .addCase(downloadNoticeDoc.fulfilled,(state,action) => {
                state.isFetchingNoticeDoc = false
                state.isSuccessNoticeDoc = true

                // Update the state with the file name from action payload.
                state.noticeDoc = action.payload.data.gmapi.response.data
                // Update the state with the file name.
                state.noticeDocName = action.meta.arg.docName

                // Document download trigger.
                downloadDocument(state.noticeDoc,state.noticeDocName)
            })
            .addCase(downloadNoticeDoc.pending,(state,action) => {
                state.isFetchingNoticeDoc = true
                // Update the state with doc id.
                state.noticeDocSenderId = action.meta.arg.idBoxAvvisi
            })
            .addCase(downloadNoticeDoc.rejected,(state,action) => {
                state.isFetchingNoticeDoc = false
                state.isErrorNotice = true
                state.noticeDocErrorMsg = action.payload;
            })
            /*******************************************************************
             *
             * @dev avvisoLetto.
             *
             */
            .addCase(avvisoLetto.fulfilled,(state,action) => {
                // Get the eventSender from the action payload.
                const idBoxAvvisi = action.payload.eventSender
                // Get the noticesItems state from the action payload.
                const noticesItemsCopy = [...state.noticesItems]
                // Get the noticeSelected state from the action payload.
                const noticeSelectedCopy = { ...state.noticeSelected }

                // Find the object wich corresponding id.
                const noticeIndexFound = noticesItemsCopy.findIndex((item) => item.id_box_avvisi === idBoxAvvisi)
                //
                //
                // If the item as been found.
                if (noticeIndexFound > -1) {
                    //
                    // For an item of the list, if correspond then write it's prop.
                    //
                    for (const item of noticesItemsCopy) {
                        if (item.id_box_avvisi === idBoxAvvisi) {
                            item.letto = !item.letto
                        }
                    }
                    //
                    // For every key in the object an item of the list, if correspond then write it's prop.
                    //
                    for (const key in noticeSelectedCopy) {
                        if (Object.hasOwnProperty.call(noticeSelectedCopy,key)) {
                            if (key === "letto") {
                                const keyValue = noticeSelectedCopy[key]
                                noticeSelectedCopy[key] = !keyValue
                            }
                        }
                    }

                    // Update the state.
                    state.noticesItems = noticesItemsCopy
                    state.noticeSelected = noticeSelectedCopy
                }
            })
            .addCase(avvisoLetto.pending,(state,action) => {
                state.noticeDocSenderId = action.meta.arg // Access to thunk argument passed in "downloadVisuraDoc" AsyncThunk.
            })
            .addCase(avvisoLetto.rejected,(state,action) => {
                state.isErrorNotice = true
                state.noticeDocErrorMsg = action.payload;
            })
        /*******************************************************************
         *
         * getComunicazioni.
         *
         */
        /*
        [getComunicazioni.fulfilled]: (state,action) => {
            state.isFetching = false
            state.isSuccess = true
            state.noticesItems = action.payload.gmapi.response.data
        },
        [getComunicazioni.pending]: (state) => {
            state.isFetching = true
        },
        [getComunicazioni.rejected]: (state,action) => {
            state.isFetching = false
            state.isError = true
            state.errorMsg = action.payload
        },
        */
    },
})

// Export the reducer.
export const { setNoticesItems,clearNoticeDocSenderId } = comunicazioniSlice.actions

// Export the Slice by name.
export const comunicazioniSelector = (state) => state.comunicazioni

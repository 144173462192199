// React.
import { Fragment, useContext, useEffect } from "react"
// Router.
import { useNavigate } from "react-router-dom"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// UserSlice.
import { logoutUser, clearState, userSelector, getUserInfo } from "../features/user/UserSlice"
// Context SideBarMenu.
import { SideBarMenuContext } from "./SideBarMenu"
// UI.
import { Popover, Transition } from "@headlessui/react"
// Icons.
import { Cog, Logout } from "../base/Icons"
import { isNotEmptyString } from "../../common/util";

/**
 *
 * @dev Special user button in the bottom of the SideBarMenu.
 * 
 * @function UserBtn
 *
 * @returns JSX.Element
 *
 */
export default function UserBtn() {
    //
    // Use Context to know if the sideBarMenu is open or not.
    //
    const sideBarIsOpen = useContext(SideBarMenuContext)
    //
    // userSlice state.
    //
    const {
        userName,
        companyName,
        userNameFirstLetter,
        companyFirstLetter,
        adminMode,
        adminUser,
        isFetching,
        isSuccess,
        isError
    } = useSelector(userSelector)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const userView = adminMode ? (isNotEmptyString(adminUser)) : false;

    /**
     * 
     * @dev Logout the user by dispatching logoutUser() AsyncThunk.
     * 
     * @const logOut
     * @param void
     * @returns void
     * 
     */
    const logOut = async () => {
        try {
            const response = await dispatch(logoutUser()).unwrap()

            if (response) {
                dispatch(clearState())
                navigate("/login", { replace: true })
            } else {
                console.log("UserBtn: does tkbox still exist?", response)
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * 
     * @dev Navigate to user settings.
     * 
     * @const goToUserSettings
     * @param void
     * @returns void
     * 
     */
    const goToUserSettings = async () => {
        navigate("user-settings", { replace: false })
    }

    // Pophover list items.
    const popoverItems = [
        {
            name: "Impostazioni",
            description: "",
            href: "##",
            icon: Cog,
            separator: true,
            action: goToUserSettings,
        },
        {
            name: "Disconnetti",
            description: "",
            href: "##",
            icon: Logout,
            separator: false,
            action: logOut,
        },
    ]

    useEffect(() => {
        dispatch(getUserInfo())
    }, [
        dispatch,
        userName,
        userNameFirstLetter,
    ])

    /**
     *
     * Component render.
     *
     * @dev  Forcing sidebar is open "w-64" instead of "w-16"
     * 
     */
    return (
        <div
            className={`
                menu-user
                ${sideBarIsOpen ? "w-64" : "w-64"}
                flex items-center 
                p-3 
                h-16
                text-left
                bg-zinc-800
                ${userView ? 'userview' : ''}
            `}
        >

            {isFetching && <UserBtnSkeleton />}

            {!isFetching && !isError && isSuccess && (
                <Fragment>
                    {/*<div className="fixed top-16 w-full max-w-sm px-4">*/}
                    <Popover className="relative w-full">
                        {({ open }) => (
                            <Fragment>
                                <Popover.Button
                                    className={
                                        `
                                        ${open ? "" : "text-opacity-90"}
                                        w-full
                                        group inline-flex items-center 
                                        space-x-4 
                                        text-base font-medium text-white 
                                        hover:text-opacity-100 
                                        focus:outline-none`
                                        //focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75
                                    }
                                >
                                    <div
                                        className="
                                            user-icon
                                            h-10 
                                            w-10 
                                            rounded-full 
                                            flex items-center justify-center 
                                            bg-afm-box-green text-white"
                                    >
                                        {isNotEmptyString(companyFirstLetter) ? companyFirstLetter : userNameFirstLetter}
                                    </div>

                                    <span className="whitespace-nowrap f-initial w-2 text-white/50">
                                        {companyName}
                                    </span>
                                </Popover.Button>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                >
                                    <Popover.Panel
                                        className={`
                                            ${sideBarIsOpen
                                                ? "-top-[170px] left-[380px]"
                                                : "-top-[170px] left-[190px]"}
                                            absolute 
                                            z-10 
                                            mt-3 
                                            w-60 
                                            -translate-x-1/2 transform 
                                            px-4 sm:px-0`}
                                    >
                                        {({ close }) => (
                                            <Fragment>
                                                {/*<Popover.Panel className="absolute right-72 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">*/}
                                                <div
                                                    className="
                                                        overflow-hidden 
                                                        rounded-sm 
                                                        shadow-lg shadow-neutral-600 
                                                        ring-1 ring-black ring-opacity-5"
                                                >
                                                    <div className="bg-black p-4">
                                                        <span className="flex items-center">
                                                            <span className="font-medium text-white/50">
                                                                {companyName}
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <div
                                                        className="
                                                            flex flex-col 
                                                            gap-4 
                                                            p-7 
                                                            lg:grid-cols-2
                                                            bg-black"
                                                    >
                                                        {popoverItems.map((item) => (
                                                            <div key={item.name} className="flex flex-col">
                                                                <button
                                                                    onClick={async () => {
                                                                        if (item.action !== null || undefined) {
                                                                            await item.action() // Execute the function associated.
                                                                        } else {
                                                                            return // Do nothing.
                                                                        }
                                                                        close() // Close the <Popover.Panel/>
                                                                    }}
                                                                    className="
                                                                        flex items-center
                                                                        -m-4
                                                                        p-2
                                                                        rounded-sm 
                                                                        transition duration-150 ease-in-out 
                                                                        hover:bg-neutral-800 
                                                                        focus:outline-nones"
                                                                >
                                                                    <div className="flexshrink-0 items-center justify-center text-white/50">
                                                                        <item.icon aria-hidden="true" />
                                                                    </div>
                                                                    <div className="ml-4">
                                                                        <p className="text-sm font-medium text-white">
                                                                            {item.name}
                                                                        </p>
                                                                    </div>
                                                                </button>

                                                                {item?.separator && (
                                                                    <span className="flex-grow mt-8 mb-4 border-t border-gray-400"></span>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )}
                                    </Popover.Panel>
                                </Transition>
                            </Fragment>
                        )}
                    </Popover>
                </Fragment>
            )}

            {/*
            {isError && !isSuccess && (
                <div className="flex items-center justify-center my-14">
                    <p className="text-sm">...Recupero dei dati fallito</p>
                </div>
            )}
            */}

        </div>
    )
}

/**
 *
 * @dev UserBtn skeleton component.
 * 
 * @function UserBtnSkeleton
 *
 * @returns JSX.Element
 *
 */
function UserBtnSkeleton() {
    return (
        <div className="animate-pulse w-full mt-2">
            <Popover className="relative">
                <Popover.Button
                    className={`
                        inline-flex
                        w-full
                        items-center 
                        space-x-4
                        pr-4`
                    }
                >
                    <div
                        className="
                            h-10 
                            w-10
                            flex-shrink-0 
                            rounded-full 
                            bg-afm-box-green"
                    ></div>
                    <div className="h-6 w-full bg-zinc-700 rounded"></div>
                </Popover.Button>
            </Popover>
        </div>
    )
}
